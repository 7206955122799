import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireUploadTask, AngularFireStorage } from "@angular/fire/storage";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { tap, finalize } from "rxjs/operators";
import * as firebase from "firebase";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() form: FormGroup;
  @Input() path: string; // e.g. /users/{{username}}/tickets/
  @Input() index: number;
  @Input() canManage: boolean;

  @Output() deleteFile = new EventEmitter<boolean>();
  @Output() uploadedFileUrl = new EventEmitter<string>();

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadUrl;

  constructor(
    private storage: AngularFireStorage,
  ) { }

  ngOnInit() {
    console.log("upload-task ==========>",this.form);
    if(this.form.get('images').value[this.index] != null && this.form.get('images').value[this.index] != '') {
      console.log('((((((((((((( changing percentage to 100');
      this.percentage = new Observable(observer => {
        observer.next(100);
        observer.complete();
        this.downloadUrl = this.form.get('images').value[this.index];
        if(typeof this.downloadUrl != 'string'){
          this.downloadUrl = this.downloadUrl.url; // for proposal 
        }
        console.log(this.downloadUrl); 
      });
    }
    else {
      this.startUpload();
    }
  }

  startUpload() {
    // console.log('file upload started');
    const path = environment.storageUrl + this.path + Date.now() + this.file.name;
    // console.log(path);
    const ref = this.storage.ref(path);
    // console.log(ref);
    // console.log('reference and path sorted');
    this.task = this.storage.upload(path, this.file);

    // console.log('uploading...');

    this.percentage = this.task.percentageChanges();

    this.task.then(snapshot => {
      // console.log('current task state '+snapshot.state.toString());
      if(snapshot.state == firebase.storage.TaskState.SUCCESS) {
        // console.log('fetching download url');
        ref.getDownloadURL().subscribe((url) => { this.downloadUrl = url; this.uploadedFileUrl.emit(this.downloadUrl);});
      }
    });
  }

  deleteImage() {
    this.deleteFile.emit(this.downloadUrl);
    console.log(this.deleteFile);
    // let images = [];
    // this.form.get('images').value.forEach((url) => {
    //   if(url != this.downloadUrl) { images.push(url);}
    // });
    // this.form.get('images').reset();
    // this.form.get('images').setValue(images);
  }

}