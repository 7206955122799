import { Component, ContentChild, HostListener, ElementRef, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, filter, take, switchMapTo } from 'rxjs/operators';

@Component({
    selector: 'editable',
    template: `<ng-container *ngTemplateOutlet="currentView"></ng-container>`,
    styleUrls: ['./editable.component.css']
})

export class EditableComponent {
    @ContentChild(ViewModeDirective, {static: false}) viewModeTpl: ViewModeDirective;
    @ContentChild(EditModeDirective, {static: false}) editModeTpl: EditModeDirective;
    @Input() currentMode: string;
    @Output() update = new EventEmitter();

    editMode = new Subject();
    editMode$ = this.editMode.asObservable();

    mode = 'view';
    private subscription: Subscription = new Subscription();

    constructor(private host: ElementRef) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.mode = this.currentMode;
    }

    private get element() {
        return this.host.nativeElement;
    }

    get currentView() {
        return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
    }
}