import { Injectable } from "@angular/core";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state?: string;
  name?: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const allRoles = [
  "amplus_analytics",
  "cluster_admin",
  "amplus_ops_manager",
  "amplus_sales_manager",
  "associate",
  "amplus_admin",
  "amplus_business",
  "hs_finance",
 
  "amplus_technical",
  "partner_epc",
  "partner_sales",
  "amplus_executive",
  "amplus_designer",
  "discom_user"
];

const MENUITEMS = [
  {
    state: "report",
    name: "Performance Reporting",
    type: "sub",
    icon: "../../../../assets/images/partner-management-copy.png",
    roles: ["amplus_business", "cluster_admin", "amplus_ops_manager", "amplus_admin", "amplus_sales_manager", "associate", "partner_epc","amplus_executive"],
    children: [
      {
        state: "lead",
        name: "Property Dashboard",
        roles: ["cluster_admin", "associate", "amplus_admin", "amplus_business", "amplus_sales_manager", "amplus_ops_manager"],
        icon: "../../../../assets/images/dashboard2.png",
      },
      {
        state: "lead-report",
        name: "Acquisition Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "associate", "amplus_admin", "amplus_business", "amplus_sales_manager", "amplus_ops_manager"],
      },
      {
        state: "cluster-generation",
        name: "Generation Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "associate", "amplus_admin", "amplus_business", "partner_epc", "amplus_sales_manager", "amplus_ops_manager"],
      },
      {
        state: "activity-dashboard",
        name: "Activity Dashboard",
        icon: "../../../../assets/images/dashboard2.png",
        roles: ["cluster_admin", "associate", "amplus_admin", "amplus_business", "amplus_sales_manager", "amplus_ops_manager","amplus_executive"],
      },
    ],
  },
  {
    state: "lead",
    name: "Lead Management",
    type: "sub",
    icon: "../../../../assets/images/partner-management-copy.png",
    roles: allRoles,
    children: [
      {
        state: "list-view",
        name: "Cluster Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: allRoles.filter(role => ["discom_user"].indexOf(role) == -1),
      },
      {
        state: "discom-leads",
        name: "Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["discom_user"],
        children: [
          {
            state: "view",
            name: "Properties",
            visible: "true",
            icon: "../../../../assets/images/home2.png",
            roles: ["discom_user"],
          },
        ]
      },
      {
        state: "property-generation",
        name: "Property Generation",
        icon: "../../../../assets/images/dashboard2.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "cluster_operation_manager",
          "associate",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
          "partner_epc",
          "partner_sales",
        ],
        visible: "false",
      },
      {
        state: "property-list",
        name: "New Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["amplus_business", "amplus_executive", "amplus_admin", "amplus_sales_manager", "associate"],
      },
      {
        state: "pre-sales-lead",
        name: "PreSales Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["amplus_executive"]
      },
      {
        state: "ha-leads",
        name: "Automation Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["amplus_ops_manager", "amplus_technical", "amplus_analytics", "amplus_business"]
      },
      {
        state: "disaggregation-list",
        name: "Disaggregation Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["amplus_business", "amplus_ops_manager", "amplus_technical", "amplus_analytics"]
      },
      {
        state: "aqi-list",
        name: "AQI Properties",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: ["amplus_business", "amplus_ops_manager", "amplus_technical", "amplus_analytics",]
      },
      {
        state: "map-view",
        name: "Map View",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "associate",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
        ],
      },
      {
        state: "stage-view",
        name: "Lead List View",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "associate",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
          "partner_epc",
          "partner_sales",
          "amplus_designer"
        ],
      },
      {
        state: "service-tickets",
        name: "Tickets",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: [
          "amplus_analytics",
          "cluster_operation_manager",
          "cluster_admin",
          "associate",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
          "partner_epc",
          "partner_sales",
          "amplus_designer"
        ],
      },
      {
        state: "configure-iot",
        name: "Lead IOT Configure",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: ["amplus_technical"],
      },
      {
        state: "amc",
        name: "AMC Configure",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: ["amplus_technical"],
      },
      // {
      //   state: "no-property",
      //   name: "New Customers",
      //   visible: "true",
      //   icon: "../../../../assets/images/partner-management-copy.png",
      //   roles: ["amplus_business"]
      // },
      {
        state: "active-properties-dashboard",
        name: "Dashboard",
        visible: "false",
        icon: "../../../../assets/images/partner-management-copy.png",
        roles: ["amplus_business", "amplus_analytics", "amplus_technical"],
      },
      {
        state: "channel-partners",
        name: "Channel Partners",
        roles: ["cluster_admin", "amplus_ops_manager", "amplus_business", "amplus_admin", "partner_sales", "amplus_sales_manager", "amplus_executive"],
        icon: "../../../../assets/images/home2.png",
      },
    ],
  },
  {
    state: "asset",
    name: "Asset Management",
    type: "sub",
    icon: "../../../../assets/images/partner-management-copy.png",
    roles: [
      "amplus_business",
      "hs_finance",
      
      "amplus_admin",
      "amplus_designer",
      "amplus_ops_manager",
      "associate"
    ],
    children: [
      {
        state: "spare-items",
        name: "Spare Items",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: [
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_designer",
          "amplus_ops_manager",
          "associate"
        ],
      },
      {
        state: "warranty-items",
        name: "Warranty Items",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: [
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_designer",
          "amplus_ops_manager",
          "associate"
        ],
      },
      {
        state: "cluster-cost-sheet",
        name: "Cluster Cost Sheet",
        visible: "true",
        icon: "../../../../assets/images/home2.png",
        roles: [
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "amplus_designer",
          "amplus_ops_manager",
          "associate"
        ],
      },
    ],
  },
  {
    state: "finance",
    name: "Finance",
    type: "sub",
    icon: "../../../../assets/images/amplus-management.png",
    roles: [
      "cluster_admin",
      "amplus_ops_manager",
      "amplus_sales_manager",
      "amplus_admin",
      "amplus_business",
      "hs_finance",
      "amplus_finance",
      "amplus_designer"
    ],
    children: [
      {
        state: "payment-plans",
        name: "Payment Plans",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_sales_manager",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
         
          "amplus_designer"
        ],
      },
      {
        state: "payment-approvals",
        name: "Payment Approvals",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_sales_manager",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          "amplus_finance",
          "amplus_designer"
        ],
      },
    ],
  },
  {
    state: "maintenance",
    name: "Maintenance",
    type: "sub",
    icon: "../../../../assets/images/amplus-management.png",
    roles: [
      "amplus_analytics",
      "cluster_admin",
      "amplus_ops_manager",
      "amplus_sales_manager",
      "amplus_designer",
      "amplus_admin",
      "amplus_business",
      "hs_finance",
      
      "partner_epc",
      "partner_sales",
      "amplus_executive"
    ],
    children: [
      {
        state: "global-sr",
        name: "Service Requests",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_sales_manager",
          "amplus_designer",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "partner_epc",
          "partner_sales",
          "amplus_executive"
        ],
      },
      {
        state: "amc",
        name: "AMC Dashboard",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_sales_manager",
          "amplus_designer",
          "amplus_admin",
          "amplus_business",
          "hs_finance",
          
          "partner_epc",
          "partner_sales",
          "amplus_executive"
        ],
      },
      {
        state: "amc-scheduler",
        name: "AMC Scheduler",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_sales_manager",
          "amplus_admin",
          "amplus_designer",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
          "partner_epc",
          "partner_sales",
          "amplus_executive"
        ],
      },
      {
        state: "ticket-dashboard",
        name: "Ticket Dashboard",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: [
          "amplus_analytics",
          "cluster_admin",
          "amplus_ops_manager",
          "amplus_admin",
          "amplus_designer",
          "amplus_sales_manager",
          "amplus_business",
          "hs_finance",
          
          "amplus_technical",
          "partner_epc",
          "partner_sales",
          "amplus_executive"
        ],
      },
    ],
  },
  {
    state: "account",
    name: "People Management",
    type: "sub",
    icon: "../../../../assets/images/amplus-management.png",
    roles: ["amplus_admin", "amplus_business", "cluster_admin", "amplus_ops_manager", "amplus_sales_manager"],
    children: [
      {
        state: "users",
        name: "Amplus Users",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_admin", "amplus_business"],
      },
      {
        state: "admin",
        name: "Cluster Admins",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_business", "amplus_admin", "cluster_admin", "amplus_ops_manager"],
      },
      {
        state: "technician",
        name: "Cluster Technicians",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_business", "amplus_admin", "cluster_admin", "amplus_ops_manager"],
      },
      // {
      //   state: "departments",
      //   name: "Department Management",
      //   visible: "true",
      //   roles: ["amplus_business"]
      // },
      {
        state: "dep-org",
        name: "Cluster Departments",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_business", "amplus_admin", "amplus_ops_manager", "cluster_admin"],
      },
      {
        state: "partners",
        name: "Cluster Partners",
        roles: ["amplus_ops_manager", "cluster_admin", "amplus_business", "amplus_admin", "amplus_sales_manager"],
        icon: "../../../../assets/images/partner-management-copy.png",
      },
      {
        state: "discom-users",
        name: "Discom Users",
        visible: "true",
        icon: "../../../../assets/images/amplus-management.png",
        roles: ["amplus_admin", "amplus_business"],
      },
    ],
  },
  // {
  //   state: "associate",
  //   name: "Associate Management",
  //   type: "sub",
  //   icon: "../../../../assets/images/associate-management.png",
  //   roles: ["cluster_admin"],
  //   children: [
  //     {
  //       state: "create",
  //       name: "Create Associate",
  //       roles: ["cluster_admin"]
  //     }
  //   ]
  // },
  // {
  //   state: "partner",
  //   name: "Cluster Partners",
  //   type: "sub",
  //   icon: "../../../../assets/images/partner-management-copy.png",
  //   roles: ["cluster_admin"],
  //   children: [
  //     // {
  //     //   state: "create",
  //     //   name: "Create Partner",
  //     //   roles: ["cluster_admin"]
  //     // },
  //     // {
  //     //   state: "link",
  //     //   name: "Partner Linking",
  //     //   visible: "true",
  //     //   roles: ["cluster_admin"]
  //     // },
  //     {
  //       state: "manage",
  //       name: "Cluster Partners",
  //       roles: ["cluster_admin","amplus_business"],
  //       icon: "../../../../assets/images/partner-management-copy.png"
  //     }
  //   ]
  // },
  {
    state: "stage",
    name: "Workflow Management",
    type: "sub",
    icon: "../../../../assets/images/workflow-management.png",
    roles: ["amplus_business", "amplus_ops_manager", "amplus_admin"],
    children: [
      {
        state: "create",
        name: "Stages",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business", "amplus_ops_manager"],
      },
      {
        state: "field",
        name: "Stage Fields",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business", "amplus_ops_manager"],
      },
      {
        state: "access",
        name: "Stage Access",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business", "amplus_ops_manager"],
      },
      {
        state: "link",
        name: "Workflow Path",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_business", "amplus_ops_manager"],
      },
      {
        state: "workflow",
        name: "Cluster Workflow",
        icon: "../../../../assets/images/workflow-management.png",
        roles: ["amplus_ops_manager", "amplus_business", "amplus_admin"],
      },
    ],
  },
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}

// const MENUITEMS = [
//   {
//     type: 'title',
//     name: 'MAIN'
//   },
//   {
//     state: '/',
//     name: 'HOME',
//     type: 'link',
//     icon: 'ion-ios-speedometer'
//   },
//   {
//     state: 'docs',
//     name: 'DOCS',
//     type: 'link',
//     icon: 'ion-ios-help'
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'APPS'
//   },
//   {
//     state: 'email',
//     name: 'EMAIL',
//     type: 'link',
//     icon: 'ion-ios-email',
//     badge: [
//       {
//         type: 'warning',
//         value: 'NEW'
//       }
//     ]
//   },
//   {
//     state: 'social',
//     name: 'SOCIAL',
//     type: 'link',
//     icon: 'ion-ios-person'
//   },
//   {
//     state: 'calendar',
//     name: 'CALENDAR',
//     type: 'link',
//     icon: 'ion-ios-calendar'
//   },
//   {
//     state: 'taskboard',
//     name: 'TASKBOARD',
//     type: 'link',
//     icon: 'ion-ios-paper'
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'UI'
//   },
//   {
//     state: 'components',
//     name: 'COMPONENTS',
//     type: 'sub',
//     icon: 'ion-ios-albums',
//     badge: [
//       {
//         type: 'success',
//         value: '5'
//       }
//     ],
//     children: [
//       {
//         state: 'buttons',
//         name: 'BUTTONS'
//       },
//       {
//         state: 'accordion',
//         name: 'ACCORDION'
//       },
//       {
//         state: 'alert',
//         name: 'ALERT'
//       },
//       {
//         state: 'carousel',
//         name: 'CAROUSEL'
//       },
//       {
//         state: 'collapse',
//         name: 'COLLAPSE'
//       },
//       {
//         state: 'datepicker',
//         name: 'DATEPICKER'
//       },
//       {
//         state: 'dropdown',
//         name: 'DROPDOWN'
//       },
//       {
//         state: 'modal',
//         name: 'MODAL'
//       },
//       {
//         state: 'pagination',
//         name: 'PAGINATION'
//       },
//       {
//         state: 'popover',
//         name: 'POPOVER'
//       },
//       {
//         state: 'progress',
//         name: 'PROGRESS'
//       },
//       {
//         state: 'rating',
//         name: 'RATING'
//       },
//       {
//         state: 'tabs',
//         name: 'TABS'
//       },
//       {
//         state: 'timepicker',
//         name: 'TIMEPICKER'
//       },
//       {
//         state: 'tooltip',
//         name: 'TOOLTIP'
//       },
//       {
//         state: 'typeahead',
//         name: 'TYPEAHEAD'
//       }
//     ]
//   },
//   {
//     state: 'cards',
//     name: 'CARDS',
//     type: 'sub',
//     icon: 'ion-ios-photos',
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'draggable',
//         name: 'DRAGGABLE'
//       }
//     ]
//   },
//   {
//     state: 'forms',
//     name: 'FORMS',
//     type: 'sub',
//     icon: 'ion-ios-box',
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'masks',
//         name: 'MASKS'
//       },
//       {
//         state: 'validation',
//         name: 'VALIDATION'
//       },
//       {
//         state: 'upload',
//         name: 'UPLOAD'
//       }
//     ]
//   },
//   {
//     state: 'tables',
//     name: 'TABLES',
//     type: 'sub',
//     icon: 'ion-ios-grid-view',
//     badge: [
//       {
//         type: 'primary',
//         value: '2'
//       }
//     ],
//     children: [
//       {
//         state: 'basic',
//         name: 'BASIC'
//       },
//       {
//         state: 'responsive',
//         name: 'RESPONSIVE'
//       }
//     ]
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'DATAVIZ'
//   },
//   {
//     state: 'datatable',
//     name: 'DATATABLES',
//     type: 'sub',
//     icon: 'ion-ios-list',
//     children: [
//       {
//         state: 'fullscreen',
//         name: 'FULLSCREEN'
//       },
//       {
//         state: 'editing',
//         name: 'EDITING'
//       },
//       {
//         state: 'filter',
//         name: 'FILTER'
//       },
//       {
//         state: 'paging',
//         name: 'PAGING'
//       },
//       {
//         state: 'pinning',
//         name: 'PINNING'
//       },
//       {
//         state: 'selection',
//         name: 'SELECTION'
//       },
//       {
//         state: 'sorting',
//         name: 'SORTING'
//       }
//     ]
//   },
//   {
//     state: 'charts',
//     name: 'CHARTS',
//     type: 'sub',
//     icon: 'ion-ios-analytics',
//     badge: [
//       {
//         type: 'danger',
//         value: 'HOT'
//       }
//     ],
//     children: [
//       {
//         state: 'bar',
//         name: 'BAR'
//       },
//       {
//         state: 'pie',
//         name: 'PIE'
//       },
//       {
//         state: 'line',
//         name: 'LINE'
//       },
//       {
//         state: 'misc',
//         name: 'MISC'
//       }
//     ]
//   },
//   {
//     state: 'maps',
//     name: 'MAPS',
//     type: 'sub',
//     icon: 'ion-ios-navigate',
//     children: [
//       {
//         state: 'google',
//         name: 'GOOGLE'
//       },
//       {
//         state: 'fullscreen',
//         name: 'FULLSCREEN'
//       }
//     ]
//   },
//   {
//     type: 'divider'
//   },
//   {
//     type: 'title',
//     name: 'EXTRAS'
//   },
//   {
//     state: 'account',
//     name: 'ACCOUNT',
//     type: 'sub',
//     icon: 'ion-ios-people',
//     children: [
//       {
//         state: 'signin',
//         name: 'SIGNIN'
//       },
//       {
//         state: 'signup',
//         name: 'SIGNUP'
//       },
//       {
//         state: 'forgot',
//         name: 'FORGOT'
//       },
//       {
//         state: 'lockscreen',
//         name: 'LOCKSCREEN'
//       }
//     ]
//   },
//   {
//     state: 'pages',
//     name: 'PAGES',
//     type: 'sub',
//     icon: 'ion-ios-folder',
//     children: [
//       {
//         state: 'forum',
//         name: 'FORUM'
//       },
//       {
//         state: 'timeline',
//         name: 'TIMELINE'
//       },
//       {
//         state: 'activity',
//         name: 'ACTIVITY'
//       },
//       {
//         state: 'pricing',
//         name: 'PRICING'
//       },
//       {
//         state: 'blank',
//         name: 'BLANK'
//       }
//     ]
//   },
//   {
//     state: 'error',
//     name: 'ERROR',
//     type: 'sub',
//     icon: 'ion-ios-information',
//     children: [
//       {
//         state: '404',
//         name: '404'
//       },
//       {
//         state: '500',
//         name: '500'
//       }
//     ]
//   },
//   {
//     state: 'media',
//     name: 'MEDIA',
//     type: 'sub',
//     icon: 'ion-ios-film',
//     children: [
//       {
//         state: 'grid',
//         name: 'GRID'
//       },
//       {
//         state: 'tile',
//         name: 'TILE'
//       }
//     ]
//   }
// ];
