import {
  Component,
  OnInit,
  getModuleFactory,
  AfterViewInit,
  DoCheck,
  NgZone,
  ElementRef
} from "@angular/core";
import { FieldConfig } from "../field.interface";
import { FormGroup } from "@angular/forms";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-geo-point",
  template: `
    <mat-form-field class="demo-full-width">
      <input
        matInput
        id="{{ field.name }}"
        [(ngModel)]="display_text"
        [placeholder]="field.label"
        type="text"
        autocomplete="off"
      />
      <mat-hint class="geo-code">{{ geoCode }}</mat-hint>
      <mat-error *ngIf="group.get(field.name).hasError('required')"
        >{{ field.name }} is required</mat-error
      >
    </mat-form-field>
    <br />
    <br />
  `,
  styleUrls: ["./geo-point.component.scss"]
})
export class GeoPointComponent implements OnInit, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  display_text: any;
  geoCode: string = "(lat: , lng: )";

  constructor(private zone: NgZone) {}

  ngOnInit() {
    console.log("printing response in geocode");
    console.log(this.field);

    console.log('extra validation found in datetime field');
    console.log(this.validation);

    if (this.field.value) {
      let field_obj = JSON.parse(this.field.value);
      this.display_text = field_obj.title;
      this.geoCode =
        "(lat: " +
        field_obj.geocode[0] +
        " , lng: " +
        field_obj.geocode[1] +
        ")";
    }

    console.log("value of geoCode object");
    console.log(this.geoCode);
  }

  ngAfterViewInit() {
    var input = document.getElementById(this.field.name);

    var restrictOptions = {
      componentRestrictions: { country: "in" },
      fields: ["formatted_address", "geometry", "name","address_components"],
    };

    this.validation.forEach(val => {
      if(val['geo_point'] && val['geo_point'] == 'cities') {
        restrictOptions['types'] = ['(cities)'];
      }
    });

    console.log('restrict options found');
    console.log(restrictOptions);
    var autoComplete = new google.maps.places.Autocomplete(
      input,
      restrictOptions
    );
    console.log("search box initialized");
    console.log(autoComplete);

    google.maps.event.clearInstanceListeners(autoComplete);
    google.maps.event.addListener(autoComplete, "place_changed", () => {
      let place = autoComplete.getPlace();
      this.display_text = $("#" + this.field.name)[0].value;
      console.log("place object found out");
      console.log(place);
      console.log("the data got for places");
      console.log(this.display_text);

      // this.display_text = place.formatted_address;
      this.geoCode =
        "(lat: " +
        place.geometry.location.lat() +
        " , lng: " +
        place.geometry.location.lng() +
        ")";
      // $('#pac-input').val(this.display_text);
      window.dispatchEvent(new Event("resize"));
      //let field_value = '"{"title":' + '"' + place.formatted_address + '", "geoCode":' + '[' + place.geometry.location.lat() + ',' + place.geometry.location.lng() + ']}"';
      let state = ""
      place.address_components.forEach((item) => {
         if(item.types.indexOf("administrative_area_level_1") >= 0) {
            state = item.long_name;
         }
        console.log(item.types.indexOf("administrative_area_level_1"), state);
      })
      
      let field_value = {
        title: this.display_text,
        geocode: [place.geometry.location.lat(), place.geometry.location.lng()],
        state: state
      };
      // let field_value = {title:'new place', geocode: [23.34,123.34]};
      this.group.get(this.field.name).setValue(JSON.stringify(field_value));
    });
  }

  ngDoCheck() {
    var input = document.getElementById(this.field.name);
    // console.log("value changed");
    // console.log(input);
    if (this.display_text == "") {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.group.get(this.field.name).reset();
          this.geoCode = "(lat: , lng: )";
          this.group.get(this.field.name).setValue('');
        }, 100);
      });
    }
  }
}
