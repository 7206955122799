import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  paymentPlansUrl = 'v1/finance/plan/list';
  propertyPlanUrl = 'v1/finance/property';
  paymentCrudUrl = 'v1/finance/payment';
  paymentReportUrl = 'v1/finance/payment/report';
  paymentPlanUrl = 'v1/finance/plan';
  erpCodeUrl = 'v1/finance/property/erp';
  moduleListUrl = 'v1/emarket/list';
  //stdBOMUrl = 'v1/emarket/sbk_crud';
  stdBOMUrl = 'v1/emarket/bom';
  typeListUrl = 'v1/emarket/type_list';
  typeDescUrl = 'v1/emarket/type_discription';
  structureTypeUrl = 'v1/emarket/structure_module';
  //createBomUrl = 'v1/emarket/selling_bom_kit';
  createBomUrl = 'v1/emarket/bom';
  refreshNotifier = new Subject();

  constructor(
    private http: HttpClient,
  ) { }

  getPaymentPlans() {
    const url = environment.serverURl + this.paymentPlansUrl;
    return this.http.get(url);
  }

  addPlanToProperty(data) {
    return this.http.put(environment.serverURl + this.propertyPlanUrl, data);
  }

  viewPropertyPlan(data) {
    return this.http.get(environment.serverURl + this.propertyPlanUrl + '?uuid=' + data);
  }

  deletePropertyPlan(data) {
    return this.http.delete(environment.serverURl + this.propertyPlanUrl + '?uuid=' + data);
  }

  upadteErpCode(data) {
    return this.http.put(environment.serverURl + this.erpCodeUrl, data);
  }

  addPayment(data) {
    return this.http.post(environment.serverURl + this.paymentCrudUrl, data);
  }
  getPaymentLogs(uuid) {
    return this.http.get(environment.serverURl + this.paymentCrudUrl + '/list?uuid=' + uuid);
  }

  getGlobalPaymentLogs(params) {
    return this.http.get(environment.serverURl + this.paymentCrudUrl + '/global',
      { params: params });
  }

  getModuleList(params) {
    return this.http.get(environment.serverURl + this.moduleListUrl,
      { params: params });
  }

  getTypeList() {
    return this.http.get(environment.serverURl + this.typeListUrl);
  }

  getStandardBOM(params) {
    return this.http.post(environment.serverURl + this.stdBOMUrl,params);
  }

  getTypeDescription(params) {
    return this.http.get(environment.serverURl + this.typeDescUrl,
      { params: params });
  }

  getStructureType(params) {
    return this.http.get(environment.serverURl + this.structureTypeUrl,
      { params: params });
  }

  createBomKit(data) {
    return this.http.post(environment.serverURl + this.createBomUrl, data);
  }

  updateBomKit(data){
    return this.http.put(environment.serverURl + this.createBomUrl, data);
  }

  updatePayment(data) {
    return this.http.put(environment.serverURl + this.paymentCrudUrl, data);
  }

  updatePaymentStatus(data) {
    return this.http.put(environment.serverURl + this.paymentCrudUrl + '/approval', data);
  }

  addPaymentPlan(data) {
    return this.http.post(environment.serverURl + this.paymentPlanUrl, data);
  }

  downloadPaymentReport() {
    return this.http.get(environment.serverURl + this.paymentReportUrl, {
      responseType: 'arraybuffer'
    })
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
  }

  downloadListByUrl(url, data, name) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          name + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.xls'
        );
      });
  }
}
