import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MessageToastService } from "../toast/message-toast.service";
import { extend } from "webdriver-js-extender";
import { BaseService } from "../base.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class StageService {
  stageURL = "v1/stage/info?";
  stageListURL = "v1/stage/list?";
  stageFieldURL = "v1/stage/field?";
  columnListURL = "v1/stage/column/list";
  stageLinkURL = "v1/stage/link";
  stageLinkDataURL = "v1/stage/next?";
  stageLinkDeleteURL = "v1/stage/link?";
  stageDepartmentURL = "v1/stage/department?";
  stageDepartmentAccessListURL = "v1/stage/department/access/list?";
  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, "", messageService);
  }

  saveStageData(data) {
    return this.http.post(environment.serverURl + this.stageURL, data);
  }

  updateStageData(data) {
    return this.http.put(environment.serverURl + this.stageURL, data);
  }

  deleteStageData(uuid) {
    return this.http.delete(
      environment.serverURl + this.stageURL + "uuid=" + uuid
    );
  }

  getStageList(queryParams) {
    return this.http.get(
      environment.serverURl + this.stageListURL + queryParams
    );
  }

  getStageData(queryParams) {
    return this.http.get(environment.serverURl + this.stageURL + queryParams);
  }

  saveStageFieldData(data) {
    console.log('value received in the save stage API');
    console.log(data);
    return this.http.post(environment.serverURl + this.stageFieldURL, data);
  }

  updateStageFieldData(data) {
    return this.http.put(environment.serverURl + this.stageFieldURL, data);
  }

  deleteStageFieldData(uuid) {
    return this.http.delete(
      environment.serverURl + this.stageFieldURL + "uuid=" + uuid
    );
  }

  getStageFieldList(queryParams) {
    return this.http.get(
      environment.serverURl + this.stageFieldURL + queryParams
    );
  }

  getColumnNameDetails(data) {
    console.log('sending data for column name');
    console.log(data);
    return this.http.get(environment.serverURl + this.columnListURL, {
      params: {uuid: data}
    });
  }

  saveStageLinkData(data) {
    return this.http.post(environment.serverURl + this.stageLinkURL, data);
  }

  getStageLinkDetails(queryParams) {
    return this.http.get(
      environment.serverURl + this.stageLinkDataURL + queryParams
    );
  }

  deleteStageLinkData(queryParams) {
    return this.http.delete(
      environment.serverURl + this.stageLinkDeleteURL + queryParams
    );
  }

  setStageDepartmentAccess(data) {
    return this.http.post(
      environment.serverURl + this.stageDepartmentURL,
      data
    );
  }

  getStageDepartmentAccessList(queryParams) {
    return this.http.get(
      environment.serverURl + this.stageDepartmentAccessListURL + queryParams
    );
  }

  deleteStageDepartmentAccess(queryParams) {
    return this.http.delete(
      environment.serverURl + this.stageDepartmentURL + queryParams
    );
  }
}
