import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DatePipe } from '@angular/common';
import { LeadService } from 'src/app/common/service/lead/lead.service';
declare var $: any;
@Component({
  selector: 'app-hold-stage',
  templateUrl: './hold-stage.component.html',
  styleUrls: ['./hold-stage.component.scss']
})
export class HoldStageComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  hold_started_on: any;
  hold_end_on: any;
  total_days: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: MessageToastService,
    private leadService: LeadService,
    public dialogRef: MatDialogRef<HoldStageComponent>,
    private datepipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      property_uuid: [this.data.property_uuid, Validators.required],
      stage_uuid: [this.data.stage_uuid, Validators.required],
      on_hold: [this.data.on_hold, Validators.required],
      hold_remark: ["", Validators.required]
    })
    console.log("data--->", this.data);
    if (this.data.state == 'info') {
      this.leadService.getPropertyStage(this.data.property_uuid, this.data.stage_uuid).subscribe((val) => {
        console.log("printing the cleaned value--------------->");
        console.log(val);
        if (val["payload"].stage) {
          console.log("printing the cleaned value--------------->");
          let newValues = val['payload'].stage;
          console.log(newValues);
          this.form.patchValue({
            property_uuid: this.data.property_uuid,
            stage_uuid: this.data.stage_uuid,
            on_hold: this.data.on_hold,
            hold_remark: newValues.hold_remark
          });
          this.hold_started_on = newValues.hold_started_on;
          this.hold_end_on = newValues.hold_end_on;
          this.total_days = newValues.hold_days_count;
        }
        else {
          this.form.patchValue({
            property_uuid: this.data.property_uuid,
            stage_uuid: this.data.stage_uuid,
            on_hold: this.data.on_hold,
            hold_remark: this.data.hold_remark
          });
        }
      });
    }
  }

  ngAfterViewInit() {

    // react on modal closed by clicking beside it
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('result->', this.form.valid);
      this.status.emit(this.form.valid);
      if(!this.form.valid){
      }
    });
  
  }


  submit() {
    if (this.form.valid) {
      this.leadService.holdStage(this.form.value).subscribe(val => {
        if (val && val['status']) {
          this.toast.success(val['message']);
          this.status.emit('Your data, new check');
          this.dialogRef.close();
        }
        else {
          this.toast.error(val['message']);
        }
      });
    }
  }
}