import { Component, OnInit, Input } from '@angular/core';
import { AMCService } from '../../service/amc/amc.service';
import { Subscription, Observable } from 'rxjs';
import { TicketService } from '../../service/ticket/ticket.service';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit {

  @Input() ticket_uuid: String;
  @Input() ticket_type: String;
  commentList = [];
  private notifierSubscription: Subscription;

  @Input() notifier: Observable<void>;
  constructor(
    private amcService: AMCService,
    private ticketService: TicketService
  ) { }

  ngOnInit() {
    this.getComments();
    this.notifierSubscription = this.notifier.subscribe(() => this.getComments());
  }

  getComments() {
    if(this.ticket_type && this.ticket_type == 'pre_sales') {
      // fetch pre sales logs
      this.ticketService.getTicketLogs({uuid: this.ticket_uuid}).subscribe((val: any) => {
        if (val &&  val.status) {
          this.commentList = [];
          console.log("log data found out: ", val.payload);
          val.payload.data.forEach((item) => {
            item.log = item.log ? JSON.parse(item.log) : null;
            if(item.log) {
              item.log.forEach((fieldItem) => {
                if(fieldItem.field == 'images') {
                  fieldItem.old_value = JSON.parse(fieldItem.old_value)
                  fieldItem.new_value = JSON.parse(fieldItem.new_value)
                }
              })
            }
            this.commentList.push(item);
          });
        }
      });
    } else {
      this.amcService.getTicketComments({ticket_uuid: this.ticket_uuid})
        .subscribe((response: any) => {
          this.commentList = [];
          response.payload.data.forEach((item)=> {
           
            item.images = item.images ? JSON.parse(item.images) : null;
            item.log = item.log ? JSON.parse(item.log) : null;
            if(item.log) {
              item.log.forEach((fieldItem) => {
                if(fieldItem.field == 'images') {
                  fieldItem.old_value = JSON.parse(fieldItem.old_value)
                  fieldItem.new_value = JSON.parse(fieldItem.new_value)
                }
              })
            }
            this.commentList.push(item);

          });
            this.commentList = [...this.commentList];
            console.log("comments >>>>>>>>>>>>>>", this.commentList);
            
        })
    }
  }

  ngOnDestroy() {
    this.notifierSubscription.unsubscribe();
  }
}
