import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";

@Component({
  selector: "app-text-area",
  template: `
  <mat-form-field class="demo-full-width" [formGroup]="group" >
      <textarea
        matInput
        [formControlName]="field.name"
        [placeholder]="field.label"
        rows='3'
      ></textarea>
      <mat-error *ngIf="group.get(field.name).hasError('required')">{{field.name}} is required</mat-error>
    </mat-form-field>
    <br/>
  `,
  styles: []
})
export class TextAreaComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  display: string;
  text: string;

  constructor() {}
  ngOnInit() {
    this.text = 'asjkdbgklar;ijsdfokds';
    // this.display = 'none';
    // if(this.field.name == 'name')
    // this.group.get(this.field.name).disable();
    // [(ngModel)]="text"
    // appearance={{display}}
    // floatLabel="always"
    //class='input-text'
  }
}
