import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss']
})
export class MilestonesComponent implements OnInit {

  @Input() property_uuid: string;

  typeList = [{'label':'iPhone Charged', 'value':'iphone_charged'}, {'label':'Water Saved', 'value':'water_saved'},
   {'label':'Seedling Planted', 'value':'seedlings_planted'},
   {'label':'AC Used', 'value':'ac_used'},
   {'label':'CO2 Prevented', 'value':'co2_prevented'},
   {'label':'Urban House Powered', 'value':'urban_house_powered'},
   {'label':'Waste Prevented', 'value':'waste_prevented'}
   ];
  form: any;
  milestonesList;
  constructor(
    private amcService: AMCService,
    private fb: FormBuilder,
    private toast: MessageToastService

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      property_uuid: [this.property_uuid, Validators.required],
      type: ["", Validators.required],
      quantity: [0, Validators.compose([Validators.required,  Validators.minLength(2), Validators.maxLength(9)])],
    });
    this.getMilestones();
  }

  addTrigger() {
    console.log("form data", this.form.value)
    if(this.form.valid) {
        let quantity = this.form.value.quantity;
        if(quantity > 99999999) {
          this.toast.error("Quantity can't be more than 99999999");
          return;
        }
        this.amcService.addMilestonesTrigger(this.form.value).subscribe(
          (response:any) => {
              this.form.reset();
              this.form.get("property_uuid").setValue(this.property_uuid);
              this.toast.success("Trigger Added Successfully!");
              this.getMilestones()
          },
          (error:any) => {
            this.toast.error("OPERATION FAILED");
          }
          )
    }  else {
      this.toast.error("INVALID FORM DATA");
    }
  }

  displayMilestone(triggerValue) {
    const idx = this.milestonesList.findIndex(x => x.key == triggerValue);
    return idx >= 0 && this.milestonesList[idx]['triggers'].length > 0;
  }

  rewardList(triggerValue) {
    const idx = this.milestonesList.findIndex(x => x.key == triggerValue);
    return idx >= 0 ? this.milestonesList[idx]['triggers'] : []; 
  }

  getMilestones() {
    this.amcService.getMilestonesList({ property_uuid: this.property_uuid}).subscribe(
      (response:any) => {
          console.log("milestones list data", response);
          this.milestonesList = response.payload.data;
      },
      (error:any) => {
        this.toast.error("OPERATION FAILED");
      }
      )
  }
}
