import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { calculateViewDimensions } from '@swimlane/ngx-charts';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../service/auth-service/auth.service';
import { ClusterService } from '../../service/cluster/cluster.service';
import { FinanceService } from '../../service/finance/finance.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { UserService } from '../../service/user-service/user.service';
import { ConfirmationDialogComponent } from "src/app/common/component/confirmation-dialog/confirmation-dialog.component";
import { type } from 'os';
@Component({
    selector: 'e-market-place',
    templateUrl: './e-market-place.component.html',
    styleUrls: ['./e-market-place.component.scss']
})
export class EMarketPlaceComponent implements OnInit {

    form: any;
    formBom: any;
    module: any = {
        'id': '',
        'specification': ''
    };
    structuresList: any = {
        'id': '',
        'specification': ''
    };
    inverters: any = {
        'id': '',
        'specification': ''
    };
    noBomKit = false;
    hideButtons: boolean = false;
    editable: boolean = false;
    formCanEdit:boolean = false;
    withoutBom: boolean = false;
    loaded: boolean = false;
    moduleList: any;
    availableQty: number;
    showProgress: boolean = false;
    phase = [{ 'key': 1, 'value': 1 },
    { 'key': 3, 'value': 3 }]
    modulesArray: any;
    typeList: any;
    selectedIndex: number;
    strDelBtn: boolean = false;
    module_id_list: any;
    structures: any;
    structureList: any;
    structureIndex: number;
    selectedModule: number;
    descList: any;
    type: any;
    description: any;
    quantity: any;
    invertersItem:any;
    structuresItem:any;
    editAccess: boolean = false;
    emarketPresent: boolean = false;
    stdBOMPresent: boolean = false;
    finalCommStatus: boolean = false;
    canUpdatePrice : boolean = false;
    eMarketEditAccessDesigner: boolean = false;
    readAbleOnlyInverter : any;
    readAbleOnlyModule : any;
    readAbleOnlyStructure : any;
    viewOnly :any;
    selectedModuleId: any;
    selectedStructureId: any;
    selectedInverterId: any;
    
    @Output() status: EventEmitter<any> = new EventEmitter();

    constructor(private fb: FormBuilder,
        private toast: MessageToastService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EMarketPlaceComponent>,
        private financeService: FinanceService,
        private authService: AuthService,
        private userService: UserService,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        let user = JSON.parse(this.authService.getUser());

        //alert(this.userService.getClusterAdminEditAccess(user));
       // this.editAccess = this.userService.getEmarketEditAccess(user);
        this.canUpdatePrice = this.userService.getClusterAdminEditAccess(user);

        this.eMarketEditAccessDesigner = this.userService.getEmarketEditAccessDesigner(user);

        console.log('data', this.data);
        console.log('final commercial present', this.data.finalcomm_status);
        this.form = this.fb.group({
            lead_property_uuid: [this.data.uuid],
            module_type: [null, Validators.required],
            no_of_modules: [null, Validators.required],
            phase_no: [null, Validators.required],
            price_amount: [null],
                        
            inverter_id: [null, Validators.required],
            structure_id: [null, Validators.required],
            
            electrical_bom: this.fb.array([]),
            structure: this.fb.array([]),
            logistics: [0, Validators.required],
            business_services: [0, Validators.required],
            lead_generation: [0, Validators.required],
        });
        this.getModuleList();
        
    }

    get electrical_bom() {
        return this.form.controls.electrical_bom as FormArray;
    }

    get structure() {
        return this.form.controls.structure as FormArray;
    }

    getModuleList() {
        let params = { 'uuid': this.data.uuid };
        this.financeService.getModuleList(params).subscribe((res: any) => {
           
            
            console.log("res module list--->", this.moduleList);
               if(res.payload.form_type === 'edit'){
                     this.editable = true;
                     this.formCanEdit= true;                  
                    }
                if(res.payload.form_type === 'view'){
                    this.canUpdatePrice = true;
                    this.viewOnly = 'view';
                    }

            this.moduleList = res.payload.modules.filter((item: any) => !item.is_disabled);
            this.structuresItem = res.payload.structures.filter((item: any) => !item.is_disabled);
            this.invertersItem = res.payload.inverters.filter((item: any) => !item.is_disabled); 
            
            if (res.payload.module !== null) {
                this.moduleList.push(res.payload.module);
                this.readAbleOnlyModule =`${res.payload.module.make} ${res.payload.module.specification}`
            }
            
            if (res.payload.structure !== null) {
                this.structuresItem.push(res.payload.structure);
                this.readAbleOnlyStructure =`${res.payload.structure.make} ${res.payload.structure.specification}`
            
            }
            
            if (res.payload.inverter !== null) {
                this.invertersItem.push(res.payload.inverter);
               this.readAbleOnlyInverter =`${res.payload.inverter.make} ${res.payload.inverter.specification}`
            }

            // Set selected IDs with null checks
            this.selectedModuleId = res.payload.module ? res.payload.module.id : null;
            this.selectedStructureId =  res.payload.structure ? res.payload.structure.id : null;
            this.selectedInverterId = res.payload.inverter ? res.payload.inverter.id : null;
            
            
            
            
            // if (this.editAccess) {
            //     if (this.data.finalcomm_status) {
            //         this.editable = false; //as Arkarup Biswas update
            //     }
            // }
            // else {
            //     this.editable = false;
            // }
            
           

            if (!res.status) {

                this.emarketPresent = true;
                this.showProgress = true;
                this.noBomKit = false;
                this._patchValue(res.payload);
            }
            else {
                this.noBomKit = true;
            }
        });
    }


    _patchValue(item) {
        let list = item;
        this.form.patchValue({
            module_type: list.module.id,
            no_of_modules: list.no_of_module,
            inverter_id: list.inverter.id,
            structure_id: list.structure.id,
            phase_no: list.phase,  // Assuming phase is part of the response
            logistics: list.logistics,
            business_services: list.business_services,
            lead_generation: list.lead_generation,
            price_amount: list.price
        });
    
        const ebom = this.electrical_bom;
        var stdBoM = item.type_list;
        for (var i = 0; i < stdBoM.length; i++) {
            var desc = stdBoM[i].description + ' (' + stdBoM[i].available_quantity + ')' + ' (' + stdBoM[i].uom + ') ';
            ebom.push(this.fb.group({
                bom_id: stdBoM[i].id,
                type: stdBoM[i].type,
                description: desc,
                availableQty: stdBoM[i].available_quantity,
                quantity: [stdBoM[i].quantity, [Validators.required, Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/), Validators.max(stdBoM[i].available_quantity)]],
            }));
        }
    
        const struct = this.structure;
        var str = item.structure_list;
        for (var i = 0; i < str.length; i++) {
            let desc = '';
            for (var j = 0; j < list.length; j++) {
                if (str[i].module_id == list[j].id) {
                    desc = list[j].description;
                }
            }
            struct.push(this.fb.group({
                id: str[i].structure.structure_id,
                type: str[i].structure.type,
                descriptions: str[i].structure.descriptions,
                height: str[i].structure.height,
                tilt: str[i].structure.tilt,
                module_type: desc,
                module_id: str[i].module_id,
                matrix: str[i].structure.matrix
            }));
        }
    }
    

    // _patchValue(item) {
    //     let list = item;
    //     this.module['specification'] = list.modules.specification;
    //     this.inverters['specification'] = list.inverters.specification;
    //     this.structuresList['specification'] = list.structures.specification;

    //     // alert(JSON.stringify(list));
    //     // for (var i = 0; i < list.length; i++) {
    //     //     console.log(list[i].id);
    //     //     if (item.module_type == list[i].id) {
    //     //         this.module['specification'] = list[i].specification;
    //     //         this.module['id'] = list[i].id;
    //     //     }
    //     // }
    //     this.form.patchValue({
    //         module_type: this.module.specification,
    //         no_of_modules: item.no_of_module,
    //         inverter_id: this.inverters.specification,
    //         structure_id: this.structuresList.specification,

    //         phase_no: item.phase,
    //         logistics: item.logistics,
    //         business_services: item.business_services,
    //         lead_generation: item.lead_generation,
    //         price_amount: item.price
    //     });
    //     const ebom = this.electrical_bom;
    //     var stdBoM = item.type_list;
    //     for (var i = 0; i < stdBoM.length; i++) {
    //         var desc = stdBoM[i].description + ' (' + stdBoM[i].available_quantity + ')' + ' (' + stdBoM[i].uom + ') ';
    //         ebom.push(this.fb.group({
    //             bom_id: stdBoM[i].id,
    //             type: stdBoM[i].type,
    //             description: desc,
    //             availableQty: stdBoM[i].available_quantity,
    //             quantity: [stdBoM[i].quantity, [Validators.required, Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/), Validators.max(stdBoM[i].available_quantity)]],
    //         })
    //         );
    //     }
    //     const struct = this.structure;
    //     var str = item.structure_list;
    //     for (var i = 0; i < str.length; i++) {
    //         let desc = '';
    //         for (var j = 0; j < list.length; j++) {
    //             if (str[i].module_id == list[j].id) {
    //                 desc = list[j].description;
    //             }
    //         }
    //         struct.push(this.fb.group({
    //             id: str[i].structure.structure_id,
    //             type: str[i].structure.type,
    //             descriptions: str[i].structure.descriptions,
    //             height: str[i].structure.height,
    //             tilt: str[i].structure.tilt,
    //             module_type: desc,
    //             module_id: str[i].module_id,
    //             matrix: str[i].structure.matrix
    //         })
    //         );
    //     }
    // }

    // proceedWithoutLoading() {
    //     console.log('proceedWithoutLoading clicked');
    //     if (this.form.value.module_type) {
    //         for (var i = 0; i < this.moduleList.length; i++) {
    //             console.log(this.moduleList[i].id);
                    
    //             if (this.form.value.module_type == this.moduleList[i].id) {
    //                 this.module['description'] = this.moduleList[i].description;
    //                 this.module['id'] = this.moduleList[i].id;
    //             }
    //         }
    //         this.form.patchValue({
    //             module_type: this.module.description,
    //         });
    //     }
    //     this.hideButtons = true;
    //     this.showProgress = true;
    //     this.withoutBom = true;
    //     this.loaded = false;
    //     this.getType();
    //     this.addBomRow();
    //     this.addStuctureRow();
    // }

    loadStandard() {
        console.log(this.form.value);
        console.log('loadStandard clicked');
        let params = {
            lead_property_uuid: this.form.value.lead_property_uuid,
            phase_no: this.form.value.phase_no,
            module_id: this.form.value.module_type,
            no_of_modules: this.form.value.no_of_modules,
            inverter_id: this.form.value.inverter_id,
            structure_id: this.form.value.structure_id         
        };
        this.financeService.getStandardBOM(params).subscribe((res: any) => {
            console.log("standard bom kit", res);

            if (res && res.status) {
                this.toast.success(res.message);
                this.dialogRef.close();
                window.location.reload();
                const ebom = this.electrical_bom;
                var stdBoM = res.payload.type_list;
                for (var i = 0; i < stdBoM.length; i++) {
                    var desc = stdBoM[i].description + ' (' + stdBoM[i].available_quantity + ')' + ' (' + stdBoM[i].uom + ') ';
                    ebom.push(this.fb.group({
                        bom_id: stdBoM[i].id,
                        type: stdBoM[i].type,
                        description: desc,
                        availableQty: stdBoM[i].available_quantity,
                        quantity: [stdBoM[i].quantity, [Validators.required, Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/), Validators.max(stdBoM[i].available_quantity)]],
                    }));
                }
                for (var i = 0; i < this.moduleList.length; i++) {
                    console.log(this.moduleList[i].id);
                    if (this.form.value.module_type == this.moduleList[i].id) {
                        this.module['description'] = this.moduleList[i].description;
                        this.module['id'] = this.moduleList[i].id;
                    }
                }
                
                this.form.patchValue({
                    module_type: this.module.description,
                });


                this.getType();
                this.addStuctureRow();
                this.hideButtons = true;
                this.showProgress = true;
                this.loaded = true;
                this.stdBOMPresent = true;

                this.dialogRef.close();
               window.location.reload();
            }
            else {
                this.toast.error(res.message);
                this.hideButtons = false;
                this.showProgress = false;
                this.loaded = false;
            }
        })
    }

    getDescription(type, index) {
        let formItem = this.electrical_bom.controls[index];
        formItem.get('description').setValue('');
        this.financeService.getTypeDescription({ 'type': type }).subscribe((res: any) => {
            this.descList = res.payload.description_list;
            console.log('type --- description-->', this.descList);
        });
    }

    insertBoM(item, index) {
        console.log("insertBoM data>>>>", index, item);
        let formItem = this.electrical_bom.controls[index];
        var desc = item.description + ' (' + item.available_quantity + ')' + ' (' + item.uom + ') ';
        formItem.patchValue({
            bom_id: item.id,
            type: item.type,
            description: desc,
            availableQty: item.available_quantity,
            quantity: 0,
        });
        if (this.withoutBom) {
            this.getStructureType();
        }
    }

    insertStructure(item, index) {
        console.log("insertStructure data>>>>", index, item, this.selectedModule);
        let formItem = this.structure.controls[index];
        console.log(this.structure.controls[index], "form item structure");
        formItem.patchValue({
            id: item.structure_id,
            type: item.type,
            descriptions: item.descriptions,
            height: item.height,
            tilt: item.tilt,
            module_id: this.selectedModule,
            matrix: item.matrix
        });
        this.structureIndex = index;
        console.log("structure index", this.structureIndex);
    }

    onQuantityChange(qty, index) {
        let formItem = this.electrical_bom.controls[index];
        formItem.patchValue({
            quantity: qty,
            qtyColor: qty > formItem.value.available_quantity ? true : false
        });
        console.log(formItem);
        if (qty > formItem.value.availableQty) {
            this.selectedIndex = index;
        }
    }

    deleteBoM(index: number) {
        if (this.electrical_bom.length > 1) {
            this.electrical_bom.removeAt(index);
        } else {
            this.insertBoM({ bom_id: '', type: '', description: '', availableQty: '', quantity: '' }, 0);
        }
    }

    deleteStructure(index: number) {
        if (this.structure.length > 1) {
            this.structure.removeAt(index);
        } else {
            this.insertStructure({
                id: '',
                module_type: '',
                module_id: '',
                type: '',
                descriptions: '',
                height: '',
                tilt: '',
                matrix: ''
            }, 0);
        }
    }


    defaultBom(item, index) {
        console.log("default bom data>>>>", index, item);
    }
    defaultStructure(item, index) {
        console.log("default structure data>>>>", index, item);
    }

    getType() {
        this.financeService.getTypeList().subscribe((res: any) => {
            console.log('type list---->', res.payload.type_list);
            this.typeList = res.payload.type_list;
            let list = [];
            for (var i = 0; i < this.typeList.length; i++) {
                list.push({
                    key: this.typeList[i],
                    value: this.typeList[i]
                });
            }
            this.typeList = list;
            console.log('type list---->', this.typeList);
        });
        console.log(this.type, "<---------type");
        console.log(this.electrical_bom);
    }

    addBomRow() {
        this.getType();
        this.electrical_bom.push(this.fb.group({
            bom_id: '',
            type: '',
            description: '',
            availableQty: '',
            quantity: ''
        }));
    }

    getStructureType() {
        console.log(this.electrical_bom, 'getStructureType');
        let bom = this.electrical_bom.value;
        console.log(this.electrical_bom.value.length);
        console.log(bom);
        let module_ids = [];
        console.log(this.form.value);
        this.form.value.electrical_bom.forEach(item => {
            if (item.type == "Module" || item.type == "module") {
                module_ids.push(item.bom_id);
            }
        })
        console.log(module_ids);
        let params = { module_id: module_ids };
        console.log(params);
        if (module_ids.length > 0) {
            this.financeService.getStructureType(params).subscribe((res: any) => {
                console.log(res, 'get structure type');
                if (res && res.status) {
                    this.structures = res.payload;
                }
                else {
                    this.toast.error(res.message);
                }
            });
        }
        else {
            this.toast.warning("You have not selected modules.");
        }
    }

    getStructures(module_id, index) {
        console.log(module_id, this.structures);
        let formItem = this.structure.controls[index];
        formItem.get('type').setValue('');
        formItem.get('height').setValue('');
        formItem.get('tilt').setValue('');
        formItem.get('matrix').setValue('');
        formItem.get('descriptions').setValue('');
        this.selectedModule = module_id;
        for (let i = 0; i < this.structures.length; i++) {
            if (module_id == this.structures[i].module) {
                this.structureList = this.structures[i].strucutre;
            }
        }
    }

    addStuctureRow(index?) {
        console.log("-----------here---------");
        if (!this.withoutBom) {
            this.getStructureType();
        }
        let next = index + 1;
        this.structure.push(this.fb.group({
            id: '',
            module_type: '',
            module_id: '',
            type: '',
            descriptions: '',
            height: '',
            tilt: '',
            matrix: ''
        }));
        if (next > 0) {
            let formItem = this.structure.controls[next];
            let item = this.structure.controls[index].value;
            console.log(this.structure.controls[next], "form item structure");
            formItem.patchValue({
                id: item.id,
                type: item.type,
                descriptions: item.descriptions,
                height: item.height,
                module_type: item.module_type,
                module_id: item.module_id,
                tilt: item.tilt,
                matrix: item.matrix
            });
        }
    }

    createBomKit() {
        console.log(this.form.value, "<---------------form value submit");
        this.form.get('module_type').setValue(this.module.id);
        let totalModules = 0;
        let totalMatrix = 0;
        this.form.value.electrical_bom.forEach(item => {
            if (item.type == "Module" || item.type == "module") {
                totalModules += item.quantity;
            }
        })
        console.log(totalModules);
        this.form.value.structure.forEach(item => {
            var newarr = item.matrix.split("X");
            let matrixProduct = newarr[0] * newarr[1];
            totalMatrix += matrixProduct;
        })
        console.log(totalMatrix);
        if (Number.isNaN(totalMatrix)) {
            totalMatrix = 0;
        }
        if (totalMatrix >= totalModules) {
            let module_type = this.form.value.module_type;
            if (module_type == "") {
                this.form.get('module_type').setValue(0);
            }
            if (this.form.value.structure[0].id == '') {
                this.structure.removeAt(0);
            }
            this.financeService.createBomKit(this.form.value).subscribe((res: any) => {
                if (res && res.status) {
                    console.log(res);
                    this.toast.success(res.message);
                    this.dialogRef.close();
                    window.location.reload();
                }
                else {
                    this.toast.error(res.message);
                }
            });
        } else {
            console.log(totalMatrix, totalModules);
            this.toast.error('Number of Modules and Matrix entered is not compatible. Please check.');
        }
    }


    updateBomKit(){


        let params = {
            lead_property_uuid: this.form.value.lead_property_uuid,
            business_services:0,
            lead_generation:0,
            logistics:0,
            price: this.form.value.price_amount,            
        };
        
        // "lead_property_uuid" : "5e72a5b7-580f-45d7-a26f-6569b66f293a",
        // "lead_generation" : 1000,
        // "logistics" : 101,
        // "business_servics" :20, 
        // "price" : 5



        this.financeService.updateBomKit(params).subscribe((res: any) => {
            // alert(JSON.stringify(this.form.value));
            if (res && res.status) {
                this.toast.success(res.message);
                this.dialogRef.close();

                window.location.reload();
            }
        
        })


    }

    // updateBomKit() {
    //     console.log(this.form.value, "<---------------form value update");
    //     this.form.get('module_type').setValue(this.module.id);
    //     let totalModules = 0;
    //     let totalMatrix = 0;
    //     this.form.value.electrical_bom.forEach(item => {
    //         if (item.type == "Module" || item.type == "module") {
    //             totalModules += item.quantity;
    //         }
    //     })
    //     console.log(totalModules);
    //     this.form.value.structure.forEach(item => {
    //         var newarr = item.matrix.split("X");
    //         let matrixProduct = newarr[0] * newarr[1];
    //         totalMatrix += matrixProduct;
    //     });
    //     if (Number.isNaN(totalMatrix)) {
    //         totalMatrix = 0;
    //     }
    //     console.log(totalMatrix);
    //     if (totalMatrix >= totalModules) {
    //         let module_type = this.form.value.module_type;
    //         if (module_type == "") {
    //             this.form.get('module_type').setValue(0);
    //         }
    //         if (this.form.value.structure[0].id == '') {
    //             this.structure.removeAt(0);
    //         }
    //         this.financeService.updateBomKit(this.form.value).subscribe((res: any) => {
    //             alert(JSON.stringify(this.form.value))
    //             if (res && res.status) {
    //                 console.log(res);
    //                 this.toast.success(res.message);
    //                 this.status.emit(true);
    //                 this.dialogRef.close();
    //                 window.location.reload();
    //             }
    //             else {
    //                 this.toast.error(res.message);
    //             }
    //         });
    //     }
    //     else {
    //         this.toast.error('Number of Modules and Matrix entered is not compatible. Please check.');
    //     }

    // }

    cancel() {
        this.dialogRef.close();
    }
}
