import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";

@Component({
  selector: "app-ops-management",
  templateUrl: "./ops-management.component.html",
  styleUrls: ["./ops-management.component.scss"]
})
export class OpsManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  clusterList = [];
  isCollapsed = false;
  isEditMode = false;
  opsManagerList = [];
  opsManagerDetails = [];
  form: FormGroup;
  formValid: boolean = false;
  sidenavText: String = 'CREATE MANAGER';

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone_number: ["", Validators.required],
      country_code: ["+91", Validators.required],
      cluster_id_list: ["", Validators.required]
    });

    this.form.valueChanges.subscribe(() => {
      this.formValid = this.form.valid;
    });

    this.getOpsManagerDetails();
    this.getClusterDetails();

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });
  }

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        console.log("form - value ------->", this.form.value);
        this.clusterService
          .updateOpsManager(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("OPERATION MANAGER UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = 'CREATE MANAGER';
              this.getOpsManagerDetails();
            }
          });
      } else {
        let data = JSON.parse(JSON.stringify(this.form.value));
        console.log("data form ---------->", data);
        this.clusterService
          .createOpsManager(data)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("OPERATION MANAGER CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = 'CREATE MANAGER';
              this.getOpsManagerDetails();
            } else {
              this.toast.error(result.message.toUpperCase());
            }
          });
      }
    }
  }

  editAccount(row) {
    let clusterIds = [];
    row.cluster_list.forEach(item => {
      clusterIds.push(item.uuid)
    });
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      // password: 'random',
      phone_number: row.phone_number,
      country_code: row.country_code,
      // is_business: row.is_business,
      cluster_id_list: clusterIds
    });
    console.log("edit manager form value ------>", this.form.value);
    this.sidenavBar.toggle();
    // this.form.get("password").disable();
    this.sidenavText = 'EDIT MANAGER';
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.clusterService
      .deleteOpsManager(row.uuid)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.toast.success("OPERATION MANAGER  DELETED SUCCESSFULLY");
          this.reset();
          this.getOpsManagerDetails();
        }
        else {
          this.toast.error('OPERATION FAILED');
        }
      });
      }});
  }

  reset() {
    this.form.reset();
    this.sidenavText = 'CREATE MANAGER';
    // this.form.get("password").enable();
    this.form.get("cluster_id_list").setValue("");
    this.form.get("country_code").setValue("+91");
    this.isEditMode = false;
  }

  getOpsManagerDetails() {
    this.clusterService.getOpsManagerList().subscribe((result: any) => {
      if (result && result.status) {
        this.opsManagerList = [];
        result.payload.data.forEach(admin => {
          let identifiers = "";
          admin.cluster_list.forEach(item => {
            identifiers += item.identifier + ", "
          });
          identifiers = identifiers.length > 2 ? identifiers.substr(0, identifiers.length - 2) : identifiers
          admin.cluster_identifiers = identifiers;
          this.opsManagerList.push(admin);
        });
        this.opsManagerList = [...this.opsManagerList];
        this.offset = 0;
      }
    });
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.clusters.forEach(element => {
          this.clusterList.push({
            label: element.identifier,
            value: element.uuid
          });
        });
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {
  }

}