import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./core";
import { AuthLayoutComponent } from "./core";
import { LoginComponent } from "./core/account-management/login/login.component";
import { LeadNoPropertyComponent } from "./core/lead-management/lead-no-property/lead-no-property.component";
import { LeadListViewComponent } from "./core/lead-management/lead-list-view/lead-list-view.component";
import { UserManagementComponent } from "./core/account-management/user-management/user-management.component";
import { ClusterManagementComponent } from "./core/cluster-management/cluster-management/cluster-management.component";
import { ClusterDepartmentManagementComponent } from "./core/cluster-management/cluster-department-management/cluster-department-management.component";
import { ClusterAdminManagementComponent } from "./core/cluster-management/cluster-admin-management/cluster-admin-management.component";
import { PropertyClusterAssignmentComponent } from "./core/lead-management/property-cluster-assignment/property-cluster-assignment.component";
import { LoginGuard } from "./common/service/login-guard/login.guard";
import { AssociateManagementComponent } from "./core/associate-management/associate-management/associate-management.component";
import { AuthGuard } from "./common/service/auth-guard/auth.guard";
import { Error4Component } from "./common/component/error/error4/error4.component";
import { StageViewComponent } from "./core/lead-management/stage-view/stage-view.component";
// import { PartnerManagementComponent } from "./core/partner-management/partner-management/partner-management.component";
import { CreateStageComponent } from "./core/cluster-workflow/create-stage/create-stage.component";
import { CreateStageFieldComponent } from "./core/cluster-workflow/create-stage-field/create-stage-field.component";
import { CreateStageLinkComponent } from "./core/cluster-workflow/create-stage-link/create-stage-link.component";
import { MapViewComponent } from "./core/lead-management/map-view/map-view.component";
import { DashboardComponent } from "./core/lead-management/dashboard/dashboard.component";
import { IotConfigurationComponent } from "./core/lead-management/iot-configuration/iot-configuration.component";
import { DepartmentStageAccessComponent } from "./core/cluster-workflow/department-stage-access/department-stage-access.component";
import { PartnerLinkComponent } from "./core/partner-management/partner-link/partner-link.component";
import { LeadPerformanceComponent } from "./core/performance-reporting/lead-performance/lead-performance.component";
import { ClusterGenerationComponent } from "./core/performance-reporting/cluster-generation/cluster-generation.component";
import { DepartmentOrganisationComponent } from "./core/people-management/department-organisation/department-organisation/department-organisation.component";
import { PartnerOrganisationComponent } from "./core/partner-management/partner-org/partner-organisation/partner-organisation.component";
import { PropertyGenerationComponent } from "./core/performance-reporting/property-generation/property-generation/property-generation.component";
import { LeadReportComponent } from "./core/performance-reporting/lead-report/lead-report/lead-report.component";
import { WorkflowManagementComponent } from "./core/cluster-workflow/workflow-management/workflow-management.component";
import { ActivityLogListComponent } from "./core/lead-management/activity-log-list/activity-log-list.component";
import { ActivityDashboardComponent } from "./core/performance-reporting/activity-dashboard/activity-dashboard.component";
import { AmcComponent } from "./core/lead-management/amc/amc.component";
import { ClusterTechnicianManagementComponent } from "./core/cluster-management/cluster-technician-management/cluster-technician-management.component";
import { PropertyViewWrapperComponent } from "./core/lead-management/property-view-wrapper/property-view-wrapper.component";
import { ManageActivityLogComponent } from "./core/lead-management/manage-activity-log/manage-activity-log.component";
import { SpareItemsComponent } from "./core/asset-management/spare-items/spare-items.component";
import { WarrantyItemsComponent } from "./core/asset-management/warranty-items/warranty-items.component";
import { GlobalServiceRequestsComponent } from "./core/maintenance/global-service-requests/global-service-requests.component";
import { UserDetailsComponent } from "./core/lead-management/user-details/user-details.component";
import { RecommendedListViewComponent } from "./core/lead-management/recommended-list-view/recommended-list-view.component";
import { AmcDashboardComponent } from "./core/maintenance/amc-dashboard/amc-dashboard.component";
import { DiscomManagementComponent } from "./core/account-management/discom-management/discom-management.component";
import { DiscomLeadListComponent } from "./core/lead-management/discom-lead-list/discom-lead-list.component";
import { DiscomGenerationViewComponent } from "./core/performance-reporting/property-generation/discom-generation-view/discom-generation-view.component";
import { ChannelPartnerComponent } from "./core/people-management/channel-partner/channel-partner.component";
import { PreSalesLeadComponent } from "./core/lead-management/pre-sales-lead/pre-sales-lead.component";
import { PaymentPlansComponent } from "./common/component/finance/payment-plans/payment-plans.component";
import { HaPropertiesComponent } from "./core/lead-management/ha-properties/ha-properties.component";
import { PropertyPaymentLogComponent } from "./common/component/finance/property-payment-log/property-payment-log.component";
import { PaymentApprovalComponent } from "./common/component/finance/payment-approval/payment-approval.component";
import { ServiceTicketsComponent } from "./common/component/service-tickets/service-tickets.component";
import { TicketDashboardComponent } from "./common/component/ticket-dashboard/ticket-dashboard.component";
import { RayconComponent } from "./core/lead-management/raycon/raycon.component";
import { HomeAutomationComponent } from "./core/lead-management/home-automation/home-automation.component";
import { AQIComponent } from "./core/lead-management/aqi/aqi.component";
import { AmcSchedularComponent } from "./core/maintenance/amc-schedular/amc-schedular.component";
import { OpsManagementComponent } from "./core/people-management/ops-management/ops-management.component";
import { DisaggregationPropertiesComponent } from "./core/lead-management/disaggregation-properties/disaggregation-properties.component";
import { AQIPropertiesComponent } from "./core/lead-management/aqi-properties/aqi-properties.component";
import { CostSheetComponent } from "./core/asset-management/cost-sheet/cost-sheet.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "error",
        component: Error4Component,
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "lead/list-view",
        component: LeadListViewComponent,
        data: {
          heading: "All Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/pre-sales-lead",
        component: PreSalesLeadComponent,
        data: {
          heading: "Pre-Sales Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/ha-leads",
        component: HaPropertiesComponent,
        data: {
          heading: "Automation Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/disaggregation-list",
        component: DisaggregationPropertiesComponent,
        data: {
          heading: "Disaggregation Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/aqi-list",
        component: AQIPropertiesComponent,
        data: {
          heading: "AQI Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/discom-leads",
        component: DiscomLeadListComponent,
        data: {
          heading: "Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/discom-leads/view",
        component: DiscomGenerationViewComponent,
        data: {
          heading: "Property Generation",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/map-view/:id",
        component: MapViewComponent,
        data: {
          heading: "Map View",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/view",
        component: PropertyViewWrapperComponent,
        data: {
          heading: "Property View",
        },
        children: [
          {
            path: "configure-iot",
            component: IotConfigurationComponent,
            data: {
              heading: "IOT",
            },
          },
          {
            path: "amc",
            component: AmcComponent,
            data: {
              heading: "AMC",
            },
          },
          {
            path: "property-generation",
            component: PropertyGenerationComponent,
            data: {
              heading: "Property Generation Reporting",
            },
          },
          {
            path: "stage-view",
            component: StageViewComponent,
            data: {
              heading: "Property Details",
            },
          },
          {
            path: "service-tickets",
            component: ServiceTicketsComponent,
            data: {
              heading: "Tickets",
            },
          },
          {
            path: "raycon",
            component: RayconComponent,
            data: {
              heading: "Raycon",
            },
          },
          {
            path: "home-automation",
            component: HomeAutomationComponent,
            data: {
              heading: "Home Automation",
            },
          },
          {
            path: "aqi",
            component: AQIComponent,
            data: {
              heading: "AQI",
            },
          },
          {
            path: "user-details",
            component: UserDetailsComponent,
            data: {
              heading: "User Details",
            },
          },
          {
            path: "activity-log",
            component: ManageActivityLogComponent,
            data: {
              heading: "Activity log",
            },
          },
          {
            path: "payments",
            component: PropertyPaymentLogComponent,
            data: {
              heading: "Payments",
            },
          },
        ],
      },
      {
        path: "lead/dashboard",
        component: DashboardComponent,
        data: {
          heading: "Active Properties Dashboard",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/property-list",
        component: PropertyClusterAssignmentComponent,
        data: {
          heading: "New Properties",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/channel-partners",
        component: ChannelPartnerComponent,
        data: {
          heading: "Channel Partners",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/stage-view/:id",
        component: StageViewComponent,
        data: {
          heading: "Property Details",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "lead/service-tickets/:id",
        component: ServiceTicketsComponent,
        data: {
          heading: "Tickets",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "asset/spare-items",
        component: SpareItemsComponent,
        data: {
          heading: "Spare Items",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "asset/warranty-items",
        component: WarrantyItemsComponent,
        data: {
          heading: "Warranty Items",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "asset/cluster-cost-sheet",
        component: CostSheetComponent,
        data: {
          heading: "Cluster Cost Sheet",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "maintenance/global-sr",
        component: GlobalServiceRequestsComponent,
        data: { heading: "Global Service Requests" },
        canActivate: [AuthGuard],
      },
      {
        path: "maintenance/amc",
        component: AmcDashboardComponent,
        data: { heading: "AMC Dashboard" },
        canActivate: [AuthGuard],
      },
      {
        path: "maintenance/amc-scheduler",
        component: AmcSchedularComponent,
        data: { heading: "AMC Scheduler" },
        canActivate: [AuthGuard],
      },
      {
        path: "maintenance/ticket-dashboard",
        component: TicketDashboardComponent,
        data: { heading: "Ticket Dashboard" },
        canActivate: [AuthGuard],
      },
      {
        path: "finance/payment-plans",
        component: PaymentPlansComponent,
        data: { heading: "Payment Plans" },
        canActivate: [AuthGuard],
      },
      {
        path: "finance/payment-approvals",
        component: PaymentApprovalComponent,
        data: { heading: "Payment Approvals" },
        canActivate: [AuthGuard],
      },
      {
        path: "account/users",
        component: UserManagementComponent,
        data: {
          heading: "Amplus Users",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/discom-users",
        component: DiscomManagementComponent,
        data: {
          heading: "Discom Users",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/departments",
        component: ClusterDepartmentManagementComponent,
        data: {
          heading: "Cluster Department Mangement",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "clusters/create",
        component: ClusterManagementComponent,
        data: {
          heading: "Cluster Mangement",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/admin",
        component: ClusterAdminManagementComponent,
        data: {
          heading: "Cluster Admins",
        },
        canActivate: [AuthGuard],
      },
      // {
      //   path: "account/operation-manager",
      //   component: OpsManagementComponent,
      //   data: {
      //     heading: "Operation Manager",
      //   },
      //   canActivate: [AuthGuard],
      // },
      {
        path: "account/technician",
        component: ClusterTechnicianManagementComponent,
        data: {
          heading: "Cluster Technicians",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/channel-partners",
        component: ChannelPartnerComponent,
        data: {
          heading: "Channel Partners",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/dep-org",
        component: DepartmentOrganisationComponent,
        data: {
          heading: "Cluster Organization",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "associate/create",
        component: AssociateManagementComponent,
        data: {
          heading: "Associate Management",
        },
        canActivate: [AuthGuard],
      },
      // {
      //   path: "partner/create",
      //   component: PartnerManagementComponent,
      //   data: {
      //     heading: "Partner Management"
      //   },
      //   canActivate: [AuthGuard]
      // },
      {
        path: "partner/link",
        component: PartnerLinkComponent,
        data: {
          heading: "Partner Linking",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "account/partners",
        component: PartnerOrganisationComponent,
        data: {
          heading: "Partner Organisation",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "stage/create",
        component: CreateStageComponent,
        data: {
          heading: "Stages",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "stage/access",
        component: DepartmentStageAccessComponent,
        data: {
          heading: "Stage Access",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "stage/field",
        component: CreateStageFieldComponent,
        data: {
          heading: "Stage Field",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "report/lead",
        component: LeadPerformanceComponent,
        data: {
          heading: "Property Dashboard",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "report/lead-report",
        component: LeadReportComponent,
        data: {
          heading: "Acquisition Dashboard",
        },
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'lead/property-generation/:id',
      //   component: PropertyGenerationComponent,
      //   data: {
      //     heading: 'Property Generation Reporting'
      //   },
      //   canActivate: [AuthGuard]
      // },
      {
        path: "report/cluster-generation",
        component: ClusterGenerationComponent,
        data: {
          heading: "Generation Dashboard",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "stage/link",
        component: CreateStageLinkComponent,
        data: {
          heading: "Workflow Path",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "stage/workflow",
        component: WorkflowManagementComponent,
        data: {
          heading: "Cluster Workflow",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "report/activity-dashboard",
        component: ActivityDashboardComponent,
        data: {
          heading: "Activity Dashboard",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "**",
        redirectTo: "error",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "error",
  },
];
