import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoggerService } from "src/app/common/service/logger/logger.service";
import { MatDialog } from "@angular/material/dialog";
import { IotDeviceModalComponent } from "src/app/common/component/iot-device-modal/iot-device-modal.component";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { AccountService } from "src/app/common/service/account/account.service";
import { TuyaDeviceModalComponent } from "src/app/common/component/tuya-device-modal/tuya-device-modal.component";
import { QueueStatusModalComponent } from "src/app/common/component/queue-status-modal/queue-status-modal.component";
import { IotCleanPwdModalComponent } from "src/app/common/component/iot-clean-pwd-modal/iot-clean-pwd-modal.component";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { UserService } from "src/app/common/service/user-service/user.service";
declare var $: any;
declare var google: any;

@Component({
  selector: "app-iot-configuration",
  templateUrl: "./iot-configuration.component.html",
  styleUrls: ["./iot-configuration.component.scss"],
})
export class IotConfigurationComponent implements OnInit {
  isCollapsed: boolean = false;
  devices: any = "";
  dropdown_data: any = "";
  logger_dropdown_data: any = "";
  lead_uuid: any;
  form: any;
  check: string = "no_device";
  loggerData: any = null;
  api_method_dropdown: any;
  health_dropdown: any;
  logger_type_dropdown: any;
  structure_type_dropdown: any;
  meter_type_dropdown: any;
  net_meter_type_dropdown: any;
  sim_provider_dropdown: any;
  tariff_currency_dropdown: any;
  boolean_dropdown: any;
  request_type_dropdown: any;
  selected_tab: number = 0;
  propertyData: any;
  loggerPropertyData: any;
  cardCollapsed = true;
  tuya_form: any;
  tuya_check: any = "no_device";
  tuya_devices: any;
  tuya_details: any;
  tuya_queue: any;
  solar_queue_form: any;
  solar_queue_status: any;
  hasIotEditAccess = false;
  displayText;
  displayLocation = "";
  geoCode;
  locationFieldValue;
  locationPresent = false;

  tuyaQueueStatusMap = [
    { key: 0, value: "Idle" },
    { key: 1, value: "Running" },
  ];

  queueStatusMap = [
    { key: 1, value: "Running" },
    { key: 0, value: "Idle" },
  ];

  @ViewChild("googleAutocomplete", { static: false })
  public searchElementRef: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private loggerService: LoggerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authService: AuthService,
    private toast: MessageToastService,
    private accountService: AccountService,
    private menuToggle: MenuToggleService,
    private userService: UserService
  ) {}

  ngOnInit() {
    let user = JSON.parse(this.authService.getUser());
    this.hasIotEditAccess = this.userService.getIotEditAccess(user);
    this.form = this.fb.group({
      installed_on: [0, Validators.required],
      logger_activated_on: ["", Validators.required],
      logger_type: ["", Validators.required],
      logger_identifier: ["", Validators.required],
      installation_capacity: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/),
        ]),
      ],
      structure_type: ["", Validators.required],
      location: ["", Validators.required],
      last_logged_on: [0, Validators.required],
      solar_to_grid_max_energy: 0,
      solar_to_home_max_energy: 0,
      grid_to_home_max_energy: 0,
      current_max_energy: 0,
      reset_factor: [0, Validators.required],
      reset_factored_on: [0, Validators.required],
      logger_flag: ["", Validators.required],
      meter_flag: [0, Validators.required],
      net_meter_flag: [0, Validators.required],
      is_data_available: [1, Validators.required],
      health: ["", Validators.required],
      tariff: ["", Validators.required],
      tariff_currency: ["", Validators.required],
      api_method: ["GET", Validators.required],
      api_url: [null, Validators.required],
      api_header: "",
      api_body: "",
      is_pulled: "",
      dongle_identifier: "",
      sim_phone_number: "",
      sim_country_code: "",
      sim_imei: "",
      wifi_available: "",
    });

    this.tuya_form = this.fb.group({
      home_id: ["", Validators.required],
      user_id: ["", Validators.required],
      is_logging: 0,
    });

    this.solar_queue_form = this.fb.group({
      property_uuid: "",
      is_logging: 0,
    });

    this.route.queryParams.subscribe((params) => {
      if (params && params["id"]) {
        this.lead_uuid = params["id"];
        this.getData();
        this.getTuyaData();
        this.getPropertyData({ property_id: this.lead_uuid });
      }
    });
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe((val) => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeGoogleAutoComplete();
      this.form.get("location").valueChanges.subscribe((res: any) => {
        console.log("new location value: ", res);
        this.displayLocation = res;
      });
    }, 1000);
  }

  initializeGoogleAutoComplete() {
    var restrictOptions = {
      componentRestrictions: { country: "in" },
      fields: ["ALL"],
    };

    var autoComplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      restrictOptions
    );
    console.log("search box initialized");
    console.log(autoComplete);

    google.maps.event.clearInstanceListeners(autoComplete);
    google.maps.event.addListener(autoComplete, "place_changed", () => {
      let place = autoComplete.getPlace();
      this.displayText = $("#location-field")[0].value;
      this.displayLocation = this.displayText;
      console.log("place object found out");
      console.log(place);
      console.log("the data got for places");
      console.log(this.displayText);

      this.geoCode =
        "(lat: " +
        place.geometry.location.lat() +
        " , lng: " +
        place.geometry.location.lng() +
        ")";
      window.dispatchEvent(new Event("resize"));
      this.locationFieldValue = {
        title: this.displayText,
        geocode: [place.geometry.location.lat(), place.geometry.location.lng()],
      };

      console.log("location field value set ", this.locationFieldValue);
    });
  }

  createErrorIcon(id) {
    let errorImg = document.createElement("IMG");
    errorImg["src"] = "assets/images/error-icon.png";
    errorImg.style.height = "18px";
    errorImg.style.width = "18px";
    errorImg.style.marginLeft = "10px";
    errorImg.id = id;

    return errorImg;
  }

  checkFormData() {
    console.log("tab labels ", $(".mat-tab-labels"));

    let tab1 = $(".mat-tab-labels")[0].children[0];
    let tab2 = $(".mat-tab-labels")[0].children[1];
    let tab3 = $(".mat-tab-labels")[0].children[2];

    // console.log("labels - ", $('.mat-tab-labels').children, $(".mat-tab-labels").children[0])

    let e1 = this.createErrorIcon("error-tab-1");
    let e2 = this.createErrorIcon("error-tab-2");
    let e3 = this.createErrorIcon("error-tab-3");

    if ($("#error-tab-1")) {
      $("#error-tab-1").remove();
    }

    if ($("#error-tab-2")) {
      $("#error-tab-2").remove();
    }

    if ($("#error-tab-3")) {
      $("#error-tab-3").remove();
    }

    if (
      this.form.get("installed_on").hasError("owlDateTimeParse") ||
      this.form.get("logger_activated_on").hasError("owlDateTimeParse") ||
      this.form.get("logger_type").hasError("required") ||
      this.form.get("logger_identifier").hasError("required") ||
      this.form.get("installation_capacity").hasError("required") ||
      this.form.get("installation_capacity").hasError("pattern") ||
      this.form.get("structure_type").hasError("required") ||
      this.form.get("last_logged_on").hasError("owlDateTimeParse") ||
      this.form.get("reset_factor").hasError("required") ||
      this.form.get("reset_factored_on").hasError("owlDateTimeParse") ||
      this.form.get("logger_flag").hasError("required") ||
      this.form.get("meter_flag").hasError("required") ||
      this.form.get("location").hasError("required") ||
      this.form.get("net_meter_flag").hasError("required") ||
      this.form.get("is_data_available").hasError("required") 
    ) {
      console.log("first tab has the error");
      console.log(tab1);

      tab1.appendChild(e1);
    }

    if (
      this.form.get("health").hasError("required") ||
      this.form.get("tariff").hasError("required") ||
      this.form.get("tariff_currency").hasError("required")
    ) {
      console.log("second tab has the error");
      console.log(tab2);
      tab2.appendChild(e2);
    }

    if (
      this.form.get("api_url").hasError("required") ||
      this.form.get("api_method").hasError("required")
    ) {
      console.log("third tab has the error");
      console.log(tab3);
      tab3.appendChild(e3);
    }
  }

  cleanData(val) {
    this.devices = [...val.payload.property_data.devices];
    this.dropdown_data = {
      device_type: val.payload.dropdown_data.device_type,
      device_provider: val.payload.dropdown_data.device_provider,
    };

    // this.logger_dropdown_data = {
    //   api_method: val.payload.dropdown_data.api_method,
    //   health: val.payload.dropdown_data.health,
    //   logger_type: val.payload.dropdown_data.logger_type,
    //   meter_type: val.payload.dropdown_data.meter_type,
    //   net_meter_type: val.payload.dropdown_data.net_meter_type,
    //   sim_provider: val.payload.dropdown_data.sim_provider,
    //   tariff_currency: val.payload.dropdown_data.tariff_currency
    // };

    this.boolean_dropdown = [
      { key: "True", value: 1 },
      { key: "False", value: 0 },
    ];

    this.request_type_dropdown = [
      { key: "Pull", value: 1 },
      { key: "Push", value: 0 },
    ];

    if (val.payload.dropdown_data.api_method) {
      this.api_method_dropdown = [];
      Object.keys(val.payload.dropdown_data.api_method).forEach((idx) => {
        this.api_method_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.api_method[idx],
        });
      });
    }

    if (val.payload.dropdown_data.health) {
      this.health_dropdown = [];
      Object.keys(val.payload.dropdown_data.health).forEach((idx) => {
        this.health_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.health[idx],
        });
      });
    }

    if (val.payload.dropdown_data.logger_type) {
      this.logger_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.logger_type).forEach((idx) => {
        this.logger_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.logger_type[idx],
        });
      });
    }

    if (val.payload.dropdown_data.meter_type) {
      this.meter_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.meter_type).forEach((idx) => {
        this.meter_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.meter_type[idx],
        });
      });
    }

    if (val.payload.dropdown_data.structure_type) {
      this.structure_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.structure_type).forEach((idx) => {
        this.structure_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.structure_type[idx],
        });
      });
    }

    if (val.payload.dropdown_data.net_meter_type) {
      this.net_meter_type_dropdown = [];
      Object.keys(val.payload.dropdown_data.net_meter_type).forEach((idx) => {
        this.net_meter_type_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.net_meter_type[idx],
        });
      });
    }

    if (val.payload.dropdown_data.sim_provider) {
      this.sim_provider_dropdown = [];
      Object.keys(val.payload.dropdown_data.sim_provider).forEach((idx) => {
        this.sim_provider_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.sim_provider[idx],
        });
      });
    }

    if (val.payload.dropdown_data.tariff_currency) {
      this.tariff_currency_dropdown = [];
      Object.keys(val.payload.dropdown_data.tariff_currency).forEach((idx) => {
        this.tariff_currency_dropdown.push({
          key: idx,
          value: val.payload.dropdown_data.tariff_currency[idx],
        });
      });
    }

    this.loggerData = val["payload"].property_data.logger;
    this.loggerPropertyData = val["payload"].property_data.property.location;

    if (this.loggerData && Object.keys(this.loggerData).length != 0) {
      this.check = "device_present";
      this.solar_queue_status = this.loggerData.is_logging;
    } else {
      this.solar_queue_status = 0;
    }

    this.reset();
  }

  addNewDevice(event) {
    let dialogRef = this.dialog.open(IotDeviceModalComponent, {
      data: { device_data: this.dropdown_data, uuid: this.lead_uuid },
    });

    dialogRef.componentInstance.status.subscribe((data) => {
      if (data == true) {
        this.getData();
      }
    });
  }

  addNewTuyaDevice(event) {
    let dialogRef = this.dialog.open(TuyaDeviceModalComponent, {
      data: { device_data: this.dropdown_data, uuid: this.lead_uuid },
    });

    dialogRef.componentInstance.status.subscribe((data) => {
      if (data == true) {
        this.getTuyaData();
      }
    });
  }

  collapse(event) {
    console.log("clicked button event");
    console.log(event.srcElement.className);
    console.log(event.srcElement.className.indexOf("modal-bottom-button"));
    if (
      event.srcElement.className != "mat-button-wrapper" &&
      event.srcElement.className.indexOf("modal-bottom-button") == -1
    ) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  submit(action) {
    let formData = {};

    Object.keys(this.form.value).forEach((key) => {
      formData[key] = this.form.value[key];
    });

    if (formData["installed_on"] && formData["installed_on"] != 0) {
      console.log("installed on received");
      console.log(formData["installed_on"]);
      formData["installed_on"] = Math.floor(
        formData["installed_on"].getTime() / 1000
      );
    }

    if (formData["last_logged_on"] && formData["last_logged_on"] != 0) {
      console.log("last logged on received");
      console.log(formData["last_logged_on"]);
      formData["last_logged_on"] = Math.floor(
        formData["last_logged_on"].getTime() / 1000
      );
    }

    if (formData["reset_factored_on"] && formData["reset_factored_on"] != 0) {
      console.log("reset factor on received");
      console.log(formData["reset_factored_on"]);
      formData["reset_factored_on"] = Math.floor(
        formData["reset_factored_on"].getTime() / 1000
      );
    }

    if (
      formData["logger_activated_on"] &&
      formData["logger_activated_on"] != 0
    ) {
      formData["logger_activated_on"] = Math.floor(
        formData["logger_activated_on"].getTime() / 1000
      );
    }
    console.log(
      "this.displayLocation ",
      this.locationFieldValue,
      "****",
      this.displayLocation
    );
    if (this.locationFieldValue.title == this.displayLocation) {
      formData["location"] = JSON.stringify(this.locationFieldValue);
    } else {
      console.log(
        "error found ",
        this.locationFieldValue.title,
        "*****",
        this.displayLocation
      );
      this.toast.error("PLEASE SELECT LOCATION FROM DROPDOWN");
      return;
    }

    console.log("form data submitting");
    console.log(formData);
    this.checkFormData();

    if (action == "update") {
      (<any>Object).values(this.form.controls).forEach((control) => {
        control.markAsTouched();
      });
      this.checkFormData();
      if (this.form.invalid) {
        console.log("form obj ", this.form);
        this.toast.error("PLEASE FILL MANDATORY FIELDS");
      } else {
        this.loggerService
          .updateLogger(formData, this.lead_uuid)
          .subscribe((res) => {
            if (res && res["status"]) {
              this.toast.success("SUCCESSFULLY UPDATED LOGGER");
              this.getData();
            } else {
              this.toast.error("OPERATION FAILED");
            }
          });
      }
    } else if (action == "create" && this.form.valid) {
      // create
      this.loggerService
        .addLogger(formData, this.lead_uuid)
        .subscribe((res) => {
          if (res && res["status"]) {
            this.toast.success("SUCCESSFULLY ADDED A LOGGER");
            this.getData();
          } else {
            this.toast.error(
              "OPERATION FAILED " + res["message"].toUpperCase()
            );
          }
        });
    } else {
      if (this.form.get("installation_capacity").hasError("pattern")) {
        this.toast.error("PLEASE FILL FORM FIELDS PROPERLY");
      } else {
        this.toast.error("PLEASE FILL THE MANDATORY FIELDS");
      }
    }
  }

  reset() {
    console.log("resetting");
    console.log(this.loggerData);

    this.form.patchValue({
      installed_on:
        this.loggerData.installed_on != 0
          ? new Date(this.loggerData.installed_on * 1000)
          : null,
      installation_capacity: this.loggerData.installation_capacity,
      last_logged_on:
        this.loggerData.last_logged_on != 0
          ? new Date(this.loggerData.last_logged_on * 1000)
          : null,
      solar_to_grid_max_energy: this.loggerData.solar_to_grid_max_energy,
      solar_to_home_max_energy: this.loggerData.solar_to_home_max_energy,
      grid_to_home_max_energy: this.loggerData.grid_to_home_max_energy,
      current_max_energy: this.loggerData.current_max_energy,
      reset_factor: this.loggerData.reset_factor,
      reset_factored_on:
        this.loggerData.reset_factored_on != 0
          ? new Date(this.loggerData.reset_factored_on * 1000)
          : null,
      logger_type: this.loggerData.logger_type,
      logger_identifier: this.loggerData.logger_identifier,
      logger_activated_on:
        this.loggerData.logger_activated_on != 0
          ? new Date(this.loggerData.logger_activated_on * 1000)
          : null,
      logger_flag: this.loggerData.logger_flag,
      meter_type: this.loggerData.meter_type,
      meter_identifier: this.loggerData.meter_identifier,
      meter_flag: this.loggerData.meter_flag,
      net_meter_flag: this.loggerData.net_meter_flag,
      is_data_available: this.loggerData.is_data_available,
      health: this.loggerData.health,
      tariff: this.loggerData.tariff,
      tariff_currency: this.loggerData.tariff_currency,
      dongle_identifier: this.loggerData.dongle_identifier,
      sim_phone_number: this.loggerData.sim_phone_number,
      structure_type: this.loggerData.structure_type,
      location: this.getLocation(),
      wifi_available: this.loggerData.wifi_available,
      sim_country_code: this.loggerData.sim_country_code,
      sim_imei: this.loggerData.sim_imei,
      api_method: this.loggerData.api_method,
      api_url: this.loggerData.api_url,
      api_header: this.loggerData.api_header,
      api_body: this.loggerData.api_body,
      is_pulled: this.loggerData.is_pulled,
    });

    this.solar_queue_form.patchValue({
      is_logging: this.loggerData.is_logging,
      property_uuid: this.lead_uuid,
    });
  }

  getLocation() {
    if (this.loggerPropertyData != null && this.loggerPropertyData != "") {
      this.locationPresent = true;
      this.locationFieldValue = JSON.parse(this.loggerPropertyData);
      this.displayText = this.locationFieldValue.title;
      this.displayLocation = this.displayText;
      console.log(
        "setting location value*** ",
        this.locationPresent,
        this.locationFieldValue,
        this.displayText
      );
      return this.displayText;
    }

    return "";
  }

  refresh() {
    console.log("refreshing the whole page");
    this.getData();
    this.getTuyaData();
  }

  addLogger() {
    this.check = "add_device";
    this.form.patchValue({
      installed_on: 0,
      last_logged_on: 0,
      solar_to_grid_max_energy: 0,
      solar_to_home_max_energy: 0,
      grid_to_home_max_energy: 0,
      current_max_energy: 0,
      installation_capacity: "",
      reset_factor: 0,
      reset_factored_on: 0,
      logger_type: "",
      logger_identifier: "",
      logger_activated_on: 0,
      logger_flag: 0,
      meter_type: "",
      meter_identifier: "",
      meter_flag: 0,
      net_meter_flag: 0,
      is_data_available: 1,
      health: "",
      tariff: 0,
      tariff_currency: "",
      dongle_identifier: "",
      sim_phone_number: "",
      wifi_available: 0,
      sim_country_code: "",
      sim_imei: "",
      api_method: "",
      api_url: "",
      api_header: "",
      api_body: "",
      is_pulled: 0,
    });
  }

  cancel() {
    this.check = "no_device";
  }

  getData() {
    this.loggerService.getLoggerDetails(this.lead_uuid).subscribe((res) => {
      console.log("data from logger service");
      console.log(res);
      this.cleanData(res);
    });
  }

  getTuyaData() {
    this.loggerService.getTuyaLoggerDetails(this.lead_uuid).subscribe((res) => {
      console.log("data from tuya logger");
      console.log(res);
      this.updateTuyaData(res);
    });
  }

  onTabChange(event) {
    console.log("tab changed");
    console.log(event);
    this.selected_tab = event.index;
  }

  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe((res) => {
      if (res && res["status"]) {
        console.log("property details found out");
        console.log(res);
        this.propertyData = res["payload"];
      }
    });
  }

  updateTuyaData(val) {
    this.tuya_details = val.payload.property_data.logger;
    this.tuya_devices = [...val.payload.property_data.devices];

    if (this.tuya_details && Object.keys(this.tuya_details).length != 0) {
      this.tuya_check = "device_present";

      this.tuya_form.patchValue({
        home_id: this.tuya_details.home_id,
        user_id: this.tuya_details.user_id,
        is_logging: this.tuya_details.is_logging,
      });

      this.tuya_queue = this.tuya_details.is_logging;

      this.tuya_check = "tuya_present";
    } else {
      this.tuya_check = "add_tuya";
      // this.addLogger();
    }

    console.log(
      "upadtetuyadata",
      this.tuya_details,
      this.tuya_devices,
      this.tuya_check
    );
  }

  submit_tuya(action) {
    console.log(this.tuya_form.value, action);
    let tuyaformData = {};

    Object.keys(this.tuya_form.value).forEach((key) => {
      tuyaformData[key] = this.tuya_form.value[key];
    });

    if (action == "create") {
      this.loggerService
        .addTuyaDetails(tuyaformData, this.lead_uuid)
        .subscribe((res) => {
          console.log("add tuya", res);
          if (res && res["status"]) {
            this.toast.success("SUCCESSFULLY ADDED TUYA LOGGER");
            this.getTuyaData();
          } else {
            this.toast.error(
              "OPERATION FAILED " + res["message"].toUpperCase()
            );
          }
        });
    } else {
      this.loggerService
        .updateTuyaDetails(tuyaformData, this.lead_uuid)
        .subscribe((res) => {
          console.log("update tuya", res);
          if (res && res["status"]) {
            this.toast.success("SUCCESFULL UPDATE TUYA LOGGER");
            this.getTuyaData();
          } else {
            this.toast.error("OPERATION FAILED" + res["message"].toUpperCase());
          }
        });
    }
  }

  submit_queue(type) {
    let queue_status;
    if (type == "tuya") {
      queue_status = this.tuya_form.value["is_logging"];
    } else {
      queue_status = this.solar_queue_form.value["is_logging"];
    }

    console.log("q status", queue_status);

    let dialogRef = this.dialog.open(QueueStatusModalComponent, {
      data: { queue_status: queue_status, uuid: this.lead_uuid, type: type },
    });

    dialogRef.componentInstance.status.subscribe((data) => {
      if (data == true) {
        this.getData();
        this.getTuyaData();
      }
    });
  }

  optionSelectChange(value) {
    this.tuya_form.patchValue({
      is_logging: value,
    });
  }

  optionSelect(value) {
    console.log("option", value);
    this.solar_queue_form.patchValue({
      is_logging: value,
    });
    console.log("after patch", this.solar_queue_form);
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  cleanIotData(type) {
    console.log(type);
    let dialogRef = this.dialog.open(IotCleanPwdModalComponent, {
      data: { uuid: this.lead_uuid, type: type },
    });

    dialogRef.componentInstance.status.subscribe((data) => {
      if (data == true) {
        this.getData();
        this.getTuyaData();
      }
    });
  }
}
