import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { LoggerService } from '../../service/logger/logger.service';

@Component({
  selector: 'app-queue-status-modal',
  templateUrl: './queue-status-modal.component.html',
  styleUrls: ['./queue-status-modal.component.scss']
})
export class QueueStatusModalComponent implements OnInit {
  button_text: string;

  @Output() status: EventEmitter<any> = new EventEmitter();
   
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QueueStatusModalComponent>,
    private loggerService: LoggerService,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    console.log('modal', this.data);
    
    if(this.data.queue_status == true) {
      this.button_text = 'ACTIVATE';
    }
    else {
      this.button_text = 'DEACTIVATE';
    }
  }

  status_toggle() {
    let formData = {property_uuid: this.data.uuid, is_logging: this.data.queue_status};
    console.log('queue modal', formData);
    
    
    if(this.data.type == 'tuya') {
        this.loggerService.changeTuyaQueueStatus(formData).subscribe(val => {
        if(val && val['status']) {
          this.toast.success('TUYA QUEUE STATUS CHANGED SUCCESSFULLY');
          this.status.emit('success');
        }
        else {
          this.toast.error('OPERATION FAILED');
          this.status.emit('error');
        }
        this.dialogRef.close();
      });
    }

    if(this.data.type == 'solar'){
      this.loggerService.manageQueue(formData).subscribe(val => {
        if(val && val['status']) {
          this.toast.success('SOLAR QUEUE STATUS UPDATED SUCCESSFULLY');
          this.status.emit('success');
        }
        else {
          this.toast.error('OPERATION FAILED');
          this.status.emit('error');
        }
        this.dialogRef.close();
      });
    }
  }

  option_no() {
    this.dialogRef.close();
  }
}
