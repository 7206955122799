import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { ActivityLogListComponent } from "src/app/core/lead-management/activity-log-list/activity-log-list.component";
import { RecommendedListViewComponent } from "src/app/core/lead-management/recommended-list-view/recommended-list-view.component";
import { AddClusterComponent } from "../add-cluster/add-cluster.component";
import { CreateActivityLogComponent } from "../create-activity-log/create-activity-log.component";
import { EditPropertyComponent } from "../edit-property/edit-property.component";

@Component({
  selector: "app-property-detail-modal",
  templateUrl: "./property-detail-modal.component.html",
  styleUrls: ["./property-detail-modal.component.scss"],
})
export class PropertyDetailModalComponent implements OnInit {
  curComponent: any = AddClusterComponent;
  tabCompMap = {
    "assign-cluster": AddClusterComponent,
    "recommendations": RecommendedListViewComponent,
    "log-list": ActivityLogListComponent,
    "create-log": CreateActivityLogComponent,
    "edit-prop": EditPropertyComponent,
  };

  @ViewChild("btnGroupWrapper", { static: false }) btnGroup: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.renderer.listen(this.btnGroup.nativeElement, "click", (event) => {
      if (event && event.target.className == "modal-btn") {
        console.log("btn clicked ", this.btnGroup.nativeElement);

        Array.from(this.btnGroup.nativeElement.children).forEach((btn) => {
          this.renderer.removeClass(btn, "active-modal-btn");
        });

        this.renderer.addClass(event.target, "active-modal-btn");
        this.curComponent = this.tabCompMap[event.target.id];
      }
    });
  }
}
