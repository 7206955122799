import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, Form, FormGroup } from '@angular/forms';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { Subscription } from "rxjs";
import { DatePipe } from '@angular/common';
import { AuthService } from "src/app/common/service/auth-service/auth.service";
declare var $: any;

@Component({
    selector: 'app-view-technician',
    templateUrl: './view-technician.component.html',
    styleUrls: ['./view-technician.component.scss']
})
export class ViewTechinicianComponent implements OnInit {


    minDate: any = "";
    maxDate: any = "";
    form: any;
    technicianArray = [];
    techArrayLen = 0;
    @Output() status: EventEmitter<any> = new EventEmitter();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ViewTechinicianComponent>,
        private toast: MessageToastService,
        private amcService: AMCService,
        private datepipe: DatePipe,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() - 1);
        this.maxDate = new Date('01-01-2030');
        this.form = this.fb.group({
            selectedDate: new Date()
        });
        console.log("data------->", this.data);
        console.log("min date------->", this.minDate);
        console.log("max date------->", this.maxDate);
        this.getTechnician();
    }

    onChange(event) {
        console.log("date changed");
        console.log(event);
        this.getTechnician();
    }

    download() {
        let len = this.data.clusterList.length;
        let params = {};
        if (len > 0) {
            params = {
                cluster_id_list: this.data.clusterList,
                curr_date: Math.floor((this.form.get('selectedDate').value).getTime() / 1000),
                download: true
            }
        }
        else {
            params = {
                curr_date: Math.floor((this.form.get('selectedDate').value).getTime() / 1000),
                download: true
            }
        }
        if(this.techArrayLen>0){
            this.amcService.downloadListByUrl(this.amcService.technicianSchedulerUrl, params, 'technician-distribution');
            console.log("Technician List Downloaded!!!!");
        }
       else{
        console.log("Technician List Empty!!!!");
       }
    }

    getTechnician() {
        // var unsorted = { "car": 1, "it": 2, "Bit": 3, "Technician 1": 1, "qwerty": 1, "rest": 1, "savi": 2, "tech": 1, "ury": 4, "vit": 1, "wer": 1, "xy": 2, "yo": 2, "zooooo": 4 };
        console.log("this.form.selectedDate", this.form.get('selectedDate').value);
        let len = this.data.clusterList.length;
        let params = {};
        if (len > 0) {
            params = {
                cluster_id_list: this.data.clusterList,
                curr_date: Math.floor((this.form.get('selectedDate').value).getTime() / 1000)
            }
        }
        else {
            params = {
                curr_date: Math.floor((this.form.get('selectedDate').value).getTime() / 1000)
            }
        }
        this.amcService.getTechnician(params).subscribe((res) => {
            console.log(res, "<--------------- res technician");
            let unsorted = res['payload']['data'];
            delete unsorted["null"];
            let technician_options = [];
            Object.keys(unsorted).forEach((key: any) => {
                technician_options.push({ 'label': key.toLowerCase(), 'value': unsorted[key] });
            });
            console.log("technician_options", technician_options);
            const technicianSorted = technician_options.sort((a, b) => a.label > b.label ? 1 : -1);

            const technicianGrouped = technicianSorted.reduce((groups, contact) => {
                const letter = contact.label.charAt(0);
                groups[letter] = groups[letter] || [];
                groups[letter].push(contact);
                return groups;
            }, {});
            console.log("grouped", technicianGrouped);
            this.technicianArray = [];
            Object.keys(technicianGrouped).forEach((key: any) => {
                this.technicianArray.push({ 'letter': key.toUpperCase(), 'values': technicianGrouped[key] });
            });
            this.techArrayLen = this.technicianArray.length;
            console.log("technician_options", this.technicianArray);
        });
    }

    sortObjectByKeys(o) {
        return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    }

    disableViewChange() {
        setTimeout(() => {
            $( "button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button"
            ).css("pointer-events", "none");
        }, 300);
    }
}