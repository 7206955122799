import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-activity-log-modal',
  templateUrl: './activity-log-modal.component.html',
  styleUrls: ['./activity-log-modal.component.scss']
})
export class ActivityLogModalComponent implements OnInit {
  multi_selection = false;
  form: any;
  city_geo_point = 'random_city';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivityLogModalComponent>
  ) {}

  ngOnInit() {
    this.data = this.data.data[0];

    console.log('data got from input');
    console.log(this.data);

    this.form = this.fb.group({
      address: [this.data.address]
    });

    this.city_geo_point = this.data.city;
  }

  submit() {}

  cancel() {
    this.dialogRef.close();
  }
}
