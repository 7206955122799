import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisaggregationService {
  baseUrl: any;
  disaggregationDataUrl = 'v1/report/dashboard/raycon';
  disaggregationMarkerUrl = 'v1/report/disaggregation';
  globalEquipmentListUrl = 'v1/logger/equipment/global/list';
  addGlobalEquipmentListUrl = 'v1/logger/global_equipment';
  observableListUrl = 'v1/report/dashboard/raycon/observable';

  constructor(
    private http: HttpClient,
  ) { 
    this.baseUrl = environment.serverURl;
  }

  getDisaggregationData(params) {
    return this.http.get(this.baseUrl + this.disaggregationDataUrl, { params: params });
  }

  saveMarkerList(data) {
    return this.http.post(this.baseUrl + this.disaggregationMarkerUrl, data);
  }

  getMarkerList(params) {
    return this.http.get(this.baseUrl + this.disaggregationMarkerUrl, { params: params });
  }

  deleteMarker(data) {
    return this.http.request( 'delete', this.baseUrl + this.disaggregationMarkerUrl, { body: data});
  }

  getGlobalEquipmentList() {
    return this.http.get(this.baseUrl + this.globalEquipmentListUrl);
  }

  addGlobalEquipmentList(data) {
    return this.http.post(this.baseUrl + this.addGlobalEquipmentListUrl, data);
  }

  getObservableList(params) {
    return this.http.get(this.baseUrl + this.observableListUrl, {params: params});
  }
}
