import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "src/app/common/service/account/account.service";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { MatDialog } from "@angular/material";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AddClusterComponent } from "src/app/common/component/add-cluster/add-cluster.component";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { LeadActivationModalComponent } from "src/app/common/component/lead-activation-modal/lead-activation-modal.component";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { EditRecommendationModalComponent } from "src/app/common/component/edit-recommendation-modal/edit-recommendation-modal.component";
import { UserService } from "src/app/common/service/user-service/user.service";
import { ReassignClusterComponent } from "src/app/common/component/reassign-cluster/reassign-cluster.component";

@Component({
  selector: "app-property-view-wrapper",
  templateUrl: "./property-view-wrapper.component.html",
  styleUrls: ["./property-view-wrapper.component.scss"],
})
export class PropertyViewWrapperComponent implements OnInit {
  propertyData = { activation_status: false };
  leadPropertyUUID: any;
  leadUUID: any;
  recommendation: any;
  isCollapsed: boolean = true;
  isBusinessUser = false;
  paymentLogsAccess = false;
  isPartner = false;
  reassignCluster = false;
  canDeleteLead = false;
  isAdminUser = false;
  isTechnicalUser = false;
  isFinanceUser = false;
  serviceTicketAccess = true;
  hasIotViewAcess = false;
  canActivate = false;
  totalAmount = 0;
  totalPending = 0;
  totalPaid = 0;
  erpCode: string = "";
  siteProbability: any;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private dialog: MatDialog,
    private toast: MessageToastService,
    private authService: AuthService,
    private userService: UserService,
    private clusterService: ClusterService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.leadPropertyUUID = params["id"];
      this.getPropertyData({ property_id: this.leadPropertyUUID });
    });
    let user = JSON.parse(this.authService.getUser());
    this.canActivate = this.userService.getActivatePropertyAccess(user);
    this.hasIotViewAcess = this.userService.getIotViewAccess(user);
    this.paymentLogsAccess = this.userService.getPaymentLogViewAccess(user);
    this.reassignCluster = this.userService.getReassignClusterAccess(user);
    this.canDeleteLead = this.userService.getDeleteLeadAccess(user);
    this.leadService.updatePropertyNotifier.subscribe((v) => {
      if (v == 'reload') {
        this.getPropertyData({ property_id: this.leadPropertyUUID });
      }
    })
  }

  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe((res) => {
      if (res && res["status"]) {
        console.log("property data found: ", res['payload']);
        this.propertyData = res["payload"];
        this.leadUUID = this.propertyData['uuid'];
        this.recommendation = this.propertyData['recommended_capacity'] && this.propertyData['recommended_structure_type']
          ? this.propertyData['recommended_capacity'].toString() + '_' +
          this.propertyData['recommended_structure_type'].toString() : null;
        this.serviceTicketAccess = this.propertyData['site_commissioned'] ? this.propertyData['site_commissioned'] : true;
        this.totalAmount = this.propertyData['total_expected_amount'];
        this.totalPending = this.propertyData['total_pending_amount'];
        this.totalPaid = this.propertyData['total_paid_amount'];
        this.erpCode = this.propertyData['customer_erp_code'];
        this.siteProbability = this.propertyData['site_survey_assignment_probability'];
        this.ref.detectChanges();
        let location;
        try {
          JSON.parse(this.propertyData['location']);
          location = JSON.parse(this.propertyData['location']);
        }
        catch (e) {
          location = null;
        }
        this.propertyData['locationObj'] = this.propertyData['location'] && this.propertyData['location'] != "" ? location : null;
        console.log("payload in wrapper ", this.propertyData);

        if (
          this.propertyData["cluster"] &&
          this.propertyData["cluster"]["identifier"]
        ) {
          console.log(
            "setting cluster in wrapper ",
            this.propertyData["cluster"]
          );
          this.clusterService.setLocalCluster(this.propertyData["cluster"]);
        } else {
          this.clusterService.setLocalCluster(null);
        }
        console.log("property data in wrapper ", this.propertyData);
      }
    });
  }

  disableLead() {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe((data) => {
      if (data == "success") {
        this.leadService
          .disableLead({ property_id: this.leadPropertyUUID })
          .subscribe((val) => {
            if (val && val["status"]) {
              this.toast.success("LEAD DELETED SUCCESSFULLY");
            } else {
              this.toast.error("OPERATION FAILED");
            }
          });
      }
    });
  }

  clusterReassign() {
    this.dialog.open(ReassignClusterComponent, {
      data: {
        uuid: this.leadPropertyUUID,
        lead: {
          uuid: this.leadUUID,
        },
        cluster: this.propertyData['cluster'],
      },
    });
  }

  activateProperty() {
    if (this.canActivate) {
      let dialogRef = this.dialog.open(LeadActivationModalComponent, {
        data: {
          activation_status: this.propertyData.activation_status,
          uuid: this.leadPropertyUUID,
        },
      });

      dialogRef.componentInstance.status.subscribe((data) => {
        if (data == "success") {
          this.getPropertyData({ property_id: this.leadPropertyUUID });
        }
      });
    }
  }

  editRecommendation(event) {
    event.stopPropagation();
    console.log("recommendation edit");

    let dialogRef = this.dialog.open(EditRecommendationModalComponent, {
      data: this.leadPropertyUUID,
    });

    dialogRef.componentInstance.status.subscribe((val) => {
      if (val && val.length > 0) {
        this.propertyData['recommended_capacity'] = parseInt(val.split('_')[0]);
        this.propertyData['recommended_structure_type'] = val.split('_')[1];

      }
    });
  }
}
