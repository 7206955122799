import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AMCService } from "../../service/amc/amc.service";

@Component({
  selector: 'app-amc-log',
  templateUrl: './amc-log.component.html',
  styleUrls: ['./amc-log.component.scss']
})
export class AmcLogComponent implements OnInit, OnChanges {
  @Input() amcUuid: string;

  logs = [];

  constructor(
    private amcService: AMCService
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    console.log("******** amc uuid obtained "+this.amcUuid);
    if(this.amcUuid != null && this.amcUuid != '') {
      this.fetchAMCLogs();
    }
  }

  fetchAMCLogs() {
    this.amcService.getAMCLogs({property_amc_uuid: this.amcUuid}).subscribe((val : any) => {
      if(val && val['status']) {
        console.log('logs found out....');
        console.log(val);
        this.logs = val['payload'].data;
      }
    });
  }

}
