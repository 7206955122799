import { JsonpClientBackend } from "@angular/common/http";
import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
} from "@angular/core";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AuthService } from "../../service/auth-service/auth.service";
import { FinanceService } from "../../service/finance/finance.service";
import { UserService } from "../../service/user-service/user.service";

@Directive({
  selector: "[appApproveRejectPayment]",
})
export class ApproveRejectPaymentDirective {
  @Input("popup-tooltip") popupTooltipTitle: string;
  @Input() placement: string = "top";
  @Input() delay: string = "300";
  @Input() data: any;
  @Input() day: any;
  selectedFirstLabel = null;
  selectedSecondLabel = null;
  label = [
    {
      title: "Approved",
      action: "Approving...",
    },
    {
      title: "Rejected",
      action: "Rejecting...",
    },
  ];
  actions = [
    {
      title: "Approved",
      action: "Approving...",
    },
    {
      title: "Skipped",
      action: "Approving...",
    },
    {
      title: "Rejected",
      action: "Rejecting...",
    },
  ];
  canApproveRejectSkip = false;
  canApproveRejectPayment = false;
  isAdminUser = false;
  private popupTooltip: HTMLElement;
  private offset = 10;
  private isMarked: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private userService: UserService,
    private financeService: FinanceService,
    private toast: MessageToastService,
    private authService: AuthService
  ) { }

  @HostListener("click", ["$event.target"]) onClick(element) {
    if (!this.isMarked) {
      this.show();
    }
  }

  show() {
    const val = this.create();
    if (val == 1) {
      this.setPosition();
      this.renderer.addClass(this.popupTooltip, "ng-popup-tooltip-show");
      this.popupTooltip.hidden = false;
    }
  }

  hide() {
    this.renderer.removeClass(this.popupTooltip, "ng-popup-tooltip-show");
    this.popupTooltip.hidden = true;
  }

  getRestrictedStatuses(status) {
    if (!status) {
      return [];
    }

    let res = [];
    if (status == "pending_skip") {
      res.push("approved");
    } else if (status == "pending_admin_skip") {
      res.push("approved");
      res.push("pending_admin_skip");
    } else if (status == "pending_finance") {
      res.push("skipped");
      res.push("pending_admin_skip");
    }

    
    if (!this.isAdminUser) {
      if (status == "pending_admin_skip") {
        if (res.indexOf("skipped") < 0) {
          res.push("skipped");
        }
      }
  
    } else if (!this.canApproveRejectPayment) {
      
      if (status == "pending_finance") {
        if (res.indexOf("approved") < 0) {
          res.push("approved");
        }
        if (res.indexOf("unapproved") < 0) {
          res.push("unapproved");
        }
      }
      if (status == "pending_amplus_finance") {
        
        if (res.indexOf("approved") < 0) {
          res.push("approved");
        }
        if (res.indexOf("unapproved") < 0) {
          res.push("unapproved");
        }
      }

    } else if (!this.canApproveRejectSkip) {
      if (status == "pending_skip") {
        if (res.indexOf("skipped") < 0) {
          res.push("skipped");
        }
      }
    }


    return res;
  }

  create() {
    let btnWrapper = null;
    console.log("data found in popup: - >", this.data);

    let user = JSON.parse(this.authService.getUser());
    this.canApproveRejectPayment = this.userService.getApproveRejectPaymentAccess(user);
    this.canApproveRejectSkip = this.userService.getApproveRejectSkipAccess(user);
    this.isAdminUser = this.userService.getAdminAccess(user);
    const notAllowed = this.getRestrictedStatuses(this.data.status);
    if (
      this.data.status == "skipped" ||
      this.data.status == "approved" ||
      this.data.status == "unapproved"
    ) {
      this.data.actions = null;
    }
    else if (this.data.status == "pending_skip") {
      let res = {};
      Object.keys(this.data.actions).forEach((action) => {
        if (notAllowed.indexOf(this.data.actions[action]) < 0) {
          res[action] = this.data.actions[action];
        }
      });
      this.data.actions = JSON.parse(JSON.stringify(res));
    } else if (this.data.status == "pending_admin_skip") {
      let res = {};
      Object.keys(this.data.actions).forEach((action) => {
        if (notAllowed.indexOf(this.data.actions[action]) < 0) {
          res[action] = this.data.actions[action];
        }
      });
      this.data.actions = JSON.parse(JSON.stringify(res));
    } else if (this.data.status == "pending_finance") {
      let res = {};
      console.log(this.data.actions, "<--------------actions");
      if (this.data.actions == JSON.parse(JSON.stringify({ Rejected: 'unapproved' }))) {
        this.data.actions = null;
        console.log(this.data.actions, "<----------inside if----actions");
      }
      Object.keys(this.data.actions).forEach((action) => {
        if (notAllowed.indexOf(this.data.actions[action]) < 0) {
          res[action] = this.data.actions[action];
        }
      });
      console.log(
        "**** not allowed-------------------> ",
        notAllowed,
        "res------------->",
        res
      );
      this.data.actions = JSON.parse(JSON.stringify(res));
    }
    if (
      // this.canApproveRejectPayment || this.canApproveRejectSkip || this.isAdminUser || 
      (this.canApproveRejectSkip && this.data.status == "pending_skip") ||
      (this.isAdminUser && this.data.status == "pending_admin_skip") ||
      (this.canApproveRejectPayment && this.data.status == "pending_finance") || 
      (this.canApproveRejectPayment && this.data.status == "pending_amplus_finance")) {
      if (this.data.actions && Object.keys(this.data.actions).length > 1) {
        console.log("actions found, creating popup ", this.data.actions);
        btnWrapper = this.renderer.createElement("div");
        btnWrapper.classList.add("button-wrapper");
        Object.keys(this.data.actions).forEach((action) => {
          let ctaBtn = this.renderer.createElement("button");
          ctaBtn.innerHTML = action;
          ctaBtn.classList.add("btn");
          ctaBtn.classList.add(this._getCTAMainClass(this.data.actions[action]));
          ctaBtn.classList.add(this.data.actions[action]);
          ctaBtn.classList.add("popup-action-btn");
          ctaBtn.setAttribute("uuid", this.data.uuid);
          ctaBtn.setAttribute("status", this.data.actions[action]);
          ctaBtn.setAttribute("action", action);
          ctaBtn.addEventListener("click", () => {
            ctaBtn.innerHTML = "Saving...";
            this.updatePaymentStatus(ctaBtn);
          });
          btnWrapper.appendChild(ctaBtn);
        });
        this.popupTooltip = this.renderer.createElement("div");
        this.popupTooltip.appendChild(
          this.renderer.createText(this.popupTooltipTitle)
        );
        this.renderer.appendChild(this.popupTooltip, btnWrapper);

        setTimeout(() => {
          this.popupTooltip.id = "mark-channel-partner-popup";
        }, 200);
        this.renderer.appendChild(document.body, this.popupTooltip);

        this.renderer.addClass(this.popupTooltip, "ng-popup-tooltip");
        this.renderer.addClass(
          this.popupTooltip,
          `ng-popup-tooltip-${this.placement}`
        );

        this.renderer.setStyle(
          this.popupTooltip,
          "-webkit-transition",
          `opacity ${this.delay}ms`
        );
        this.renderer.setStyle(
          this.popupTooltip,
          "-moz-transition",
          `opacity ${this.delay}ms`
        );
        this.renderer.setStyle(
          this.popupTooltip,
          "-o-transition",
          `opacity ${this.delay}ms`
        );
        this.renderer.setStyle(
          this.popupTooltip,
          "transition",
          `opacity ${this.delay}ms`
        );
        return 1;
      } else {
        return 0;
      }
    }
    else {
      this.data.actions = null;
    }
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.popupTooltip.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left - 80 + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "bottom") {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.popupTooltip, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.popupTooltip, "left", `${left}px`);
  }

  updatePaymentStatus(element) {
    if (!this.popupTooltip.classList.contains("ng-popup-tooltip-show")) {
      return;
    }
    let params = {
      uuid: this.data.uuid,
      approval_status: element.getAttribute("status"),
    };
    this.financeService.updatePaymentStatus(params).subscribe(
      (result: any) => {
        if (result && result.status) {
          this.toast.success("PAYMENT STATUS UPDATED");
          this.financeService.refreshNotifier.next("refresh");
          this.hide();
          location.reload();//add for finance/payment-approvals only
        } else {
          element.innerHTML = element.getAttribute("action");
          this.toast.error(result.message.toUpperCase());
        }
      },
      (error: any) => {
        element.innerHTML = element.getAttribute("action");
        this.toast.error("SOMETHING WENT WRONG");
      }
    );
  }

  _getCTAMainClass(action) {
    switch (action) {
      case "approved":
        return "btn-success";
      case "unapproved":
        return "btn-danger";
      default:
        return "btn-secondary-2";
    }
  }
}
