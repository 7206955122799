import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { calculateViewDimensions } from '@swimlane/ngx-charts';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../service/auth-service/auth.service';
import { ClusterService } from '../../service/cluster/cluster.service';
import { FinanceService } from '../../service/finance/finance.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { UserService } from '../../service/user-service/user.service';
import { ConfirmationDialogComponent } from "src/app/common/component/confirmation-dialog/confirmation-dialog.component";
@Component({
  selector: 'app-add-payment-plan',
  templateUrl: './add-payment-plan.component.html',
  styleUrls: ['./add-payment-plan.component.scss']
})
export class AddPaymentPlanComponent implements OnInit {

  form: any;
  planList = [];
  subsidyOptions = [{ 'key': 'Yes', 'value': 'Yes' }, { 'key': 'No', 'value': 'No' }];
  benchmark_cost_initial = 0;
  viewOnly = false;
  stageEditAccess = true;
  deletePaymentPlanAccess = false;
  constructor(private fb: FormBuilder,
    private toast: MessageToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPaymentPlanComponent>,
    private financeService: FinanceService,
    private clusterService: ClusterService,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    let user = JSON.parse(this.authService.getUser());
    this.deletePaymentPlanAccess = this.userService.paymentPlanDeleteAccess(user);

    this.clusterService.curPropertyCluster.subscribe(res => {
      console.log(res, "<---- cluster service in add payment plan");
      this.benchmark_cost_initial = res.benchmark_cost;
    })
    this.stageEditAccess = this.data.hasEditAccess;
    this.viewOnly = this.data.viewOnly;
    if (this.viewOnly || !this.stageEditAccess) {
      this._patchValue();
    }
    console.log(this.data, "<----this.data add-payment-plan");
    console.log(this.benchmark_cost_initial, "this.benchmark_cost_initial");
    this.form = this.fb.group({
      lead_property_uuid: [this.data.uuid],
      final_price: ['', Validators.required],
      payment_plan_uuid: ['', Validators.required],
      subsidy_amount: ['', Validators.required],
      benchmark_cost: [this.benchmark_cost_initial, Validators.required],
      final_system_capacity: ['', Validators.required],
      subsidy_applicable: ['No', Validators.required],
      total_amount: ['', Validators.required],
      billing_milestone_1: ['', Validators.required],
      billing_milestone_2: ['', Validators.required]
    });
    this._patchValue();
    this.financeService.getPaymentPlans().subscribe((val: any) => {
      if (val.status) {
        console.log("payment plan list : ", val);
        this.planList = [];
        val.payload['data'].forEach((p: any) => {
          this.planList.push({ 'key': p.uuid, 'value': p.plan_type });
        });
      }
    });

    this.form.get('final_price').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('subsidy_applicable').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('subsidy_amount').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('total_amount').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('benchmark_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('final_system_capacity').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('billing_milestone_1').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
    this.form.get('billing_milestone_2').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
      this.calculations();
    });
  }

  _patchValue() {
    this.financeService.viewPropertyPlan(this.data.uuid).subscribe(response => {
      console.log(response, "<------------ response");
      let data = response['payload'].data;
      if (this.stageEditAccess) {
        if (data.total_amount != null) {
          // alert("3");
          // 
          this.form.patchValue({
            lead_property_uuid: data.lead_property_uuid,
            final_price: data.final_price,
            payment_plan_uuid: data.payment_plan_uuid,
            subsidy_amount: data.subsidy_amount,
            benchmark_cost: this.benchmark_cost_initial,
            final_system_capacity: data.final_system_capacity,
            subsidy_applicable: data.subsidy_applicable,
            total_amount: data.total_amount,
            billing_milestone_1: data.billing_milestone_2,
            billing_milestone_2: data.billing_milestone_1
          });
          if (data.payment_plan_uuid == null) {
            this.viewOnly = false;
          }
        }
        else {

          this.viewOnly = false;
        }
      }
      else {
        this.form.patchValue({
          lead_property_uuid: data.lead_property_uuid,
          final_price: data.final_price,
          payment_plan_uuid: data.payment_plan_uuid,
          subsidy_amount: data.subsidy_amount,
          benchmark_cost: data.benchmark_cost,
          final_system_capacity: data.final_system_capacity,
          subsidy_applicable: data.subsidy_applicable,
          total_amount: data.total_amount,
          billing_milestone_1: data.billing_milestone_1,
          billing_milestone_2: data.billing_milestone_2
        });
        this.viewOnly = true;
      }
    });
  }


  calculations() {
    let formValue = this.form.value;
    // console.log(formValue.final_price && formValue.final_system_capacity && formValue.benchmark_cost, formValue.final_price && formValue.final_system_capacity)
    if (formValue.final_price && formValue.final_system_capacity) {
      // console.log(formValue.subsidy_applicable);
      if (formValue.subsidy_applicable == 'Yes') {
        if (formValue.benchmark_cost == 0) {
          // console.log(formValue.benchmark_cost, "here")
          this.form.get("benchmark_cost").setValue(this.benchmark_cost_initial);
          formValue.benchmark_cost = this.benchmark_cost_initial
        }
        // console.log(this.benchmark_cost_initial, formValue.benchmark_cost);
        let subsidy_amt = formValue.benchmark_cost * (0.4 * Math.min(3, formValue.final_system_capacity) + 0.2 * Math.min(7, Math.max(0, (formValue.final_system_capacity - 3))));
        this.form.get("subsidy_amount").setValue(subsidy_amt.toFixed(2));

        let bm1 = (formValue.final_system_capacity * formValue.benchmark_cost) - subsidy_amt;
        let bm2 = formValue.final_price - (formValue.benchmark_cost * formValue.final_system_capacity);
        this.form.get("billing_milestone_1").setValue(bm1.toFixed(2));
        this.form.get("billing_milestone_2").setValue(bm2.toFixed(2));

        let customr_share = bm1 + bm2;
        this.form.get("total_amount").setValue(customr_share.toFixed(2));
      }
      else if (formValue.subsidy_applicable == 'No') {
        let bc = 0;
        this.form.get("benchmark_cost").setValue(bc);
        let subsidy_amt = bc * (0.4 * Math.min(3, formValue.final_system_capacity) + 0.2 * Math.min(7, Math.max(0, (formValue.final_system_capacity - 3))));
        this.form.get("subsidy_amount").setValue(subsidy_amt.toFixed(2));

        let bm1 = (formValue.final_system_capacity * bc) - subsidy_amt;
        let bm2 = formValue.final_price - (bc * formValue.final_system_capacity);
        this.form.get("billing_milestone_1").setValue(bm1.toFixed(2));
        this.form.get("billing_milestone_2").setValue(bm2.toFixed(2));

        let customr_share = bm1 + bm2;
        this.form.get("total_amount").setValue(customr_share.toFixed(2));
      }
    }
  }

  deleteProperty() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "DELETE PAYMENT PLAN",
        desc: "Do you want to delete payment plan?",
      },
    });
    const sub = dialogRef.componentInstance.option.subscribe((data) => {
      if (data) {
        sub.unsubscribe();
        if (data == "success") {
          this.financeService.deletePropertyPlan(this.data.uuid).subscribe(res => {
            console.log(res);
            window.location.reload();
          })
        }
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      this.financeService.addPlanToProperty(this.form.value).subscribe((val: any) => {
        if (val && val.status) {
          window.location.reload();
        } else {
          this.toast.error(val.message);
        }
      });
    }
    else {
      this.toast.error("Please fill all the mandatory fields!!!");
    }
  }

}
