import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-validations",
  templateUrl: "./validations.component.html",
  styleUrls: ["./validations.component.scss"]
})
export class ValidationsComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() field: any;

  citySelect: boolean = false;

  fieldMap = {
    min_len: { name: "Minimum Length", type: "int" },
    max_len: { name: "Maximum Length", type: "int" },
    geo_point: { name: "Location Restrict", type: "dropdown" },
    start_date: { name: "Start Date", type: "date" },
    end_date: { name: "End Date", type: "date" },
    start_time: { name: "Start Time", type: "datetime" },
    end_time: { name: "End Time", type: "datetime" },
    min_value: { name: "Minimum Value", type: "int" },
    max_value: { name: "Maximum Value", type: "int" }
  };

  geo_point_dropdown = [
    { key: 'Select a value', value: ''},
    { key: "Any", value: "any" },
    { key: "Cities", value: "cities" }
  ];

  constructor() {}

  ngOnInit() {
    let formField = this.group.get(this.field.toString());
    if(this.fieldMap[this.field.toString()].type == 'datetime') {
      console.log('current value of the datetime field');
      console.log(formField.value);
      if(formField.value != 0 && formField.value != null)
      {formField.setValue(new Date(formField.value * 1000));}
      else {
        formField.setValue(null);
      }
      console.log('setting value of the datetime field');
      console.log(formField);
      console.log(formField.value);
    }
    else {
      console.log('this field is not datetime');
      console.log(this.field);
      formField.setValue(formField.value);
    }
  }

  ngAfterViewInit() {
    if(this.field.toString() == 'geo_point') {
      let obj = this.group.get(this.field.toString());
      if(obj.value == null) {
        obj.setValue('');
      }
      // this.group.get(this.field.toString()).setValue(this.group.get(this.field.toString()).value);
    }
  }
}
