import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-disaggregation-window-selection',
  templateUrl: './disaggregation-window-selection.component.html',
  styleUrls: ['./disaggregation-window-selection.component.scss']
})
export class DisaggregationWindowSelectionComponent implements OnInit {
  @Output() timeRange: EventEmitter<any> = new EventEmitter();

  form: any;
  frequencyList = [2,6,12,24];
  startTime;
  endTime;
  currentTime;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DisaggregationWindowSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.startTime = this.data['startTime'];
    this.currentTime = this.startTime;
    this.endTime = this.data['startTime'] + (parseInt(this.data['frequency']) * 60 * 60 * 1000);

    console.log("ngOnInit data ", this.startTime, this.endTime, this.data, parseInt(this.data['frequency']));
    this.form = this.fb.group({
      'frequency': [parseInt(this.data['frequency']), Validators.required],
      'startTime': [this.startTime, Validators.required]
    });

    this.form.get('frequency').valueChanges.subscribe((res) => {
      if(res) {
        this.endTime = this.startTime + parseInt(this.form.get('frequency').value) * 60 * 60 * 1000;
        this.resolveOverflow(true);
      }
    });
  }

  updateTimeRange(val) {
    const mult = val ? 1 : -1;
      this.startTime += (parseInt(this.form.get('frequency').value) * 60 * 60 * 1000) * mult;
      this.endTime += (parseInt(this.form.get('frequency').value) * 60 * 60 * 1000) * mult;
      this.resolveOverflow(val);
      this.form.get('startTime').setValue(this.startTime);
  }

  resolveOverflow(val) {
    if(val && (this.getMidnightTimestamp(this.endTime) > this.getMidnightTimestamp(this.currentTime))) {
      let endDate = new Date(this.getMidnightTimestamp(this.currentTime));
      endDate.setHours(23,59,59,0);
      this.endTime = Math.floor(endDate.getTime());
      this.startTime = this.endTime - (parseInt(this.form.get('frequency').value) * 60 * 60 * 1000);
    } else if(!val && (this.getMidnightTimestamp(this.startTime) < this.getMidnightTimestamp(this.currentTime))) {
      let startDate = new Date(this.getMidnightTimestamp(this.currentTime));
      startDate.setHours(0,0,0,0);
      this.startTime = Math.floor(startDate.getTime());
      this.endTime = this.startTime + (parseInt(this.form.get('frequency').value) * 60 * 60 * 1000);
    }
  }

  // returns in milliseconds
  getMidnightTimestamp(timestamp) {
    let date = new Date(timestamp);
    date.setHours(0,0,0,0);
    return date.getTime();
  }

  getData() {
    if(this.form.valid) {
      this.timeRange.emit(this.startTime.toString() + '**' + this.form.get('frequency').value.toString());
      this.dialogRef.close();
    }
  }

}
