import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSidenav, MatDialog } from "@angular/material";
import { DatePipe } from "@angular/common";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { environment } from "src/environments/environment";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { ViewAssetComponent } from 'src/app/common/component/view-asset/view-asset.component';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { LoaderService } from 'src/app/common/service/loader/loader.service';
declare var $: any;

@Component({
  selector: 'app-spare-items',
  templateUrl: './warranty-items.component.html',
  styleUrls: ['./warranty-items.component.scss']
})
export class WarrantyItemsComponent implements OnInit {
  form: any;
  isEditMode = false;
  count = 0;
  offset = 0;
  limit = 0;
  warranty_modules = ['Modules', 'Inverters', 'Structure', 'BOS'];
  filterForm: any;
  type_options = [];
  quality_options = [{ label: 'Select', value: '' }, { label: 'Modules', value: 'modules' }, { label: 'Structure', value: 'structure' }, { label: 'Inverters', value: 'inverters' }, { label: 'BOS', value: 'bos' }];
  assetList = [];
  params: any = { page: 1 };
  dropdown_data;
  serverUrl: string = environment.serverURl;
  uploadUrl: string = "v2/amc/module/upload";
  network_calls = 0;
  maxDate = new Date();
  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  @ViewChild('filter', { static: true }) filterBar: MatSidenav;

  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  uploader: FileUploader = new FileUploader({
    url: this.serverUrl + this.uploadUrl,
    isHTML5: true,
    method: "POST",
    itemAlias: "file",
    authTokenHeader: "authorization",
    authToken: this.authService.getAccessToken(),
    maxFileSize: 10 * 1024 * 1024,
  });
  constructor(
    private fb: FormBuilder,
    private amcService: AMCService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private datepipe: DatePipe

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      category: ["", Validators.required],
      make: ["", Validators.required],
      specification: ["", Validators.required],
      product_warranty_duration: ["", Validators.required],
      performance_warranty_duration: ["", Validators.required],
      basic_price: ["", Validators.required],
      gst_percentage: ["", Validators.required],
      quality_level: ["", Validators.required],
      date_of_purchase: ["", Validators.required],
      capacity: [""],
    });
    this.filterForm = this.fb.group({
      text: "",
      category: "",
      quality_level: "",
      // date_of_purchase: ""
    });
    this.filterForm.get('category').valueChanges.subscribe((value) => {
      console.log("value>>>>> ", value)
      this.filterForm.get('quality_level').reset()
      if (this.dropdown_data[value]) {
        this.quality_options = [{ label: 'Select Quality Level', value: '' }];

        Object.keys(this.dropdown_data[value]).forEach((idx) => {
          this.quality_options.push({
            label: idx,
            value: this.dropdown_data[value][idx],
          });
        });
        this.filterForm.controls['quality_level'].setValidators([Validators.required])
      } else {
        this.quality_options = []
        this.filterForm.controls['quality_level'].setValidators(null)
      }
      console.log(this.filterForm)
      this.filterForm.controls['quality_level'].updateValueAndValidity()
    })
    this.form.get('category').valueChanges.subscribe((value) => {
      console.log("value>>>>> ", value)
      this.form.get('quality_level').reset()
      if (this.dropdown_data[value]) {
        this.quality_options = [{ label: 'Select Quality Level', value: '' }];

        Object.keys(this.dropdown_data[value]).forEach((idx) => {
          this.quality_options.push({
            label: idx,
            value: this.dropdown_data[value][idx],
          });
        });
        this.form.controls['quality_level'].setValidators([Validators.required])
      } else {
        this.quality_options = []
        this.form.controls['quality_level'].setValidators(null)
      }
      console.log(this.form)
      this.form.controls['quality_level'].updateValueAndValidity()
    })

    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter, options);

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.loaderService.show();
      this.network_calls++;
      console.log("showing the input file size");
      console.log(file.file.size);
      if (file.file.size < 10485760) {
        file.upload();
      }
    };

    this.uploader.onCompleteItem = async (val) => {
      console.log(this.uploader.queue);
      this.network_calls--;
      if (this.network_calls == 0) {
        this.loaderService.hide();
      }
    };

    this.uploader.onSuccessItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);
    this.uploader.onErrorItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);

    this.getWarrantyItemList(this.params);
    this.menuToggle.toggleMenu(false);
  }

  getWarrantyItemList(param) {
    this.amcService.getWarrantyList(param).subscribe((val: any) => {

      if (val && val['status']) {
        this.count = val.payload.count;
        this.offset = val.payload.current_page - 1;
        this.limit = val.payload.data_per_page;
        this.assetList = [...val.payload.data];
        this.dropdown_data = val.payload.dropdown_data;
        this.type_options = []
        if (this.dropdown_data.warranty_module_category) {
          Object.keys(this.dropdown_data.warranty_module_category).forEach((idx) => {
            this.type_options.push({
              label: idx,
              value: this.dropdown_data.warranty_module_category[idx],
            });
          });
        }
      }
    });
  }

  uploadItems() {
    this.fileInput.nativeElement.click();
  }

  manageResponse(item, response, status, header) {
    let data = JSON.parse(response);
    if (data && data["status"]) {
      this.toast.success("FILE UPLOADED SUCCESSFULLY");
      this.getWarrantyItemList(this.params);
    } else {
      this.toast.error(data.message.toUpperCase());
    }
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case "fileSize":
        console.log("file with the error");
        console.log(item);
        this.toast.error(
          "ERROR UPLOADING -" +
          this.cutName(item.name) +
          " IS GREATER THAN 10MB"
        );
        break;
      case "mimeType":
        break;
      default:
    }
  }

  cutName(name) {
    return name.substring(0, 6) + "....";
  }

  sidenavOpen() {
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  filterOpen() {
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  filterClose() {
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  sidenavClose() {
    this.reset();
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  filterToggle() {
    this.filterBar.open()
  }

  submitFilter() {
    this.params = this.filterForm.value;
    // if (this.params['date_of_purchase'] != '') {
    //   this.params['date_of_purchase'] = this.datepipe.transform(this.params['date_of_purchase'], 'yyyy-MM-dd');
    // }
    // else {
    //   delete this.params['date_of_purchase'];
    // }
    if (this.params['text'] == '') {
      delete this.params['text'];
    }
    if (this.params['quality_level'] == '' || this.params['quality_level'] == null) {
      delete this.params['quality_level'];
    }
    if (this.params['category'] == '' || this.params['category'] == null) {
      delete this.params['category'];
    }
    this.getWarrantyItemList(this.params);
    this.filterBar.close();
    this.filterClose();
  }

  editWarrantyItem(row) {
    this.sidenavBar.open();
    this.reset();
    this.isEditMode = true;
    this.form.patchValue({
      uuid: row.uuid,
      category: row.category,
      make: row.make,
      specification: row.specification,
      product_warranty_duration: row.product_warranty_duration,
      performance_warranty_duration: row.performance_warranty_duration,
      date_of_purchase: row.date_of_purchase,
      basic_price: row.basic_price,
      gst_percentage: row.gst_percentage,
      quality_level: row.quality_level,
      capacity: row.capacity,
    });
  }

  reset() {
    this.form.patchValue({
      uuid: null,
      category: "",
      make: "",
      specification: "",
      product_warranty_duration: "",
      performance_warranty_duration: "",
      date_of_purchase: "",
      gst_percentage: "",
      basic_price: "",
      quality_level: "",
      capacity: ""
    });
    this.isEditMode = false;
  }

  resetFilter() {
    this.filterForm.patchValue({
      text: "",
      category: "",
      quality_level: ""
    });
  }

  onPage(event) {
    this.offset = event.offset;
    this.params = this.filterForm.value;
    this.params['page'] = this.offset + 1;
    if (this.params['text'] == '') {
      delete this.params['text'];
    }
    if (this.params['quality_level'] == '' || this.params['quality_level'] == null) {
      delete this.params['quality_level'];
    }
    if (this.params['category'] == '' || this.params['category'] == null) {
      delete this.params['category'];
    }
    this.getWarrantyItemList(this.params);
  }

  deleteWarrantyItem(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        this.amcService.deleteWarrantyItem({ uuid: row.uuid }).subscribe((val: any) => {
          if (val && val['status']) {
            this.toast.success('WARRANTY ITEM DELETED SUCCESSFULLY');
            let param = { page: this.offset + 1 }
            this.getWarrantyItemList(param);
          } else {
            this.toast.error('OPERATION FAILED');
          }
        });
      }
    });
  }

  submit() {
    let param = this.form.value;
    param['date_of_purchase'] = this.datepipe.transform(param['date_of_purchase'], 'yyyy-MM-dd');
    param.capacity = param.capacity ? param.capacity : null;
    if (this.isEditMode) {

      this.amcService.updateWarrantyItem(param).subscribe((val: any) => {
        if (val && val['status']) {
          this.toast.success('WARRANTY ITEM UPDATED SUCCESSFULLY');
          this.sidenavBar.close();
          this.sidenavClose();
          let params = { page: this.offset + 1 }
          this.getWarrantyItemList(params);
        } else {
          this.toast.error('OPERATION FAILED');
        }

      });
    } else {
      this.amcService.addWarrantyItem(param).subscribe((val: any) => {
        if (val && val['status']) {
          this.toast.success('WARRANTY ITEM CREATED SUCCESSFULLY');
          let params = { page: this.offset + 1 }
          this.getWarrantyItemList(params);
          this.sidenavBar.close();
          this.sidenavClose();
        } else {
          this.toast.error('OPERATION FAILED');
        }

      });
    }
  }

}
