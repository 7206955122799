import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountService } from '../../service/account/account.service';
import { TicketService } from '../../service/ticket/ticket.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { TicketLogComponent } from '../ticket-log/ticket-log.component';

@Component({
  selector: 'app-create-ticket-modal',
  templateUrl: './create-ticket-modal.component.html',
  styleUrls: ['./create-ticket-modal.component.scss']
})
export class CreateTicketModalComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  minDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  typeOptions = [];
  subtypeOptions;
  userList = [];
  uploadingFiles: boolean = false;
  selectedUser;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: MessageToastService,
    public dialogRef: MatDialogRef<CreateTicketModalComponent>,
    private ticketService: TicketService,
    private accountService: AccountService,
    private titleCasePipe: TitleCasePipe,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    console.log('data in init');
    console.log(this.data);

    this.typeOptions = Object.keys(this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES).map(key => {return {'label': key.split('_').join(' ').toUpperCase(), 'value': key}});

    this.form = this.fb.group({
      property_uuid: [this.data.property_uuid, Validators.required],
      type: ["", Validators.required],
      subtype: ["", Validators.required],
      assigned_to_uuid: ["", Validators.required],
      assigned_to_type: [""],
      deadline_on: [new Date((new Date((new Date()).setHours(0,0,0,0))).getTime() + 28800000), Validators.required],
      status: [ this.data.status ? this.data.status : "open", Validators.required],
      description: ["", Validators.required],
    });

    this.form.get("type").valueChanges.subscribe(val => {
      this.selectHandler(val);
    });

    this.getUsersDropdown();
  }

  getUsersDropdown() {
    this.accountService.getAllUsersList().subscribe((val: any)  => {
      if (val && val.status) {
        console.log("users list found out: ", val.payload);
        Object.keys(val.payload).forEach((type) => {
          val.payload[type].forEach((user) => {
            this.userList.push({'label': user.name + ' (' + this.titleCasePipe.transform(user.type) + ')' + ' - ' + this.getDetail(user), 'value': user.uuid + '**' + user.type});
          });
        });
      }
    });
  }

  getDetail(user) {
    if(user.email) { return  user.email; }
    return user.phone_number;
  }

  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  selectHandler(val) {
    console.log('value found in the type... ');
    console.log(val);
    this.typeOptions.forEach(option => {
      if(option.value.toLowerCase() == val) {
        console.log('changing value to...');
        console.log(option);
        this.subtypeOptions = [];
        this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES[option.value].forEach((optn) => this.subtypeOptions.push({'label': optn.split('_').join(' ').toUpperCase(), 'value': optn}));
        console.log('current value of subtype: '+this.subtypeOptions, this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES[option.value]);
      }
    });
  }

  submit() {
    if(this.form.valid) {
      let data = JSON.parse(JSON.stringify(this.form.value));
      
      data.deadline_on = Math.floor(this.form.get("deadline_on").value.getTime() / 1000);
      data.assigned_to_uuid = this.form.get('assigned_to_uuid').value.split('**')[0];
      data.assigned_to_type = this.form.get('assigned_to_uuid').value.split('**')[1];
      this.ticketService.createTicket(data).subscribe((val: any) => {
        if (val && val.status) {
          this.toast.success(val.message.toUpperCase());
          this.status.emit("success");
          this.openTicketDetails(val.payload['data']);
          this.dialogRef.close();
        }
        else{
          this.toast.error(val.message.toUpperCase());
        }
      })
    } else {
      this.toast.error("PLEASE FILL MANDATORY FIELDS");
      console.log(this.form);
    }
  }

  openTicketDetails(data) {
    data['property_uuid'] = this.data.property_uuid;
      let dialogRef = this.dialog.open(TicketLogComponent, {
        data: data,
      });
      
      dialogRef.componentInstance.status.subscribe(data => {
        if( data == 'success') {
          console.log("refreshing..");
          this.status.emit("success");
        }
      });
  }

}
