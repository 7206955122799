import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { LoggerService } from '../../service/logger/logger.service';
import { FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-iot-clean-pwd-modal',
  templateUrl: './iot-clean-pwd-modal.component.html',
  styleUrls: ['./iot-clean-pwd-modal.component.scss']
})
export class IotCleanPwdModalComponent implements OnInit {
  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<IotCleanPwdModalComponent>,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    console.log('modal data', this.data);   
    this.form = this.fb.group({
      pwd: ["", Validators.required]
    });
  }

  submit() {
    console.log(this.form);
    console.log(this.form.get('pwd'));
    let formData = { property_uuid: this.data['uuid'], pwd: this.form.get('pwd').value };
    console.log('formData',formData);

    this.loggerService.cleanSolarIotData(formData).subscribe(res => {
      console.log('clean solar', res);
      if(res && res["status"]){
        this.toast.success("Solar iot data cleaned sucessfully");
        this.status.emit('success');
      } 
      else {
        this.toast.error('OPERATION FAILED' + res["message"]);
        this.status.emit('error');
      }
      this.dialogRef.close();
    });
  }

}
