import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"]
})
export class UserManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode = false;
  userList = [];
  isCollapsed = false;
  userListDetails = [];
  form: FormGroup;
  formValid: boolean = false;
  sidenavText: String = 'CREATE USER';

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService
  ) {
    console.log(accountService);
  }

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone_number: [""],
      meeting_link: [""],
      is_analytic: [false],
      is_finance: [false],
      is_business: [false],
      is_technical: [false],
      is_admin: [false],
      is_executive: [false],
      is_designer: [false],
      is_ops_manager: [false],
      is_sales_manager: [false],
      amplus_finance:[false]
    });
    this.getUserDetails();

    this.form.valueChanges.subscribe(() => {
      this.formValid = this.form.valid;
    });

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });

     // Subscribe to the 'amplus_finance' checkbox value changes
this.form.get('amplus_finance').valueChanges.subscribe((value) => {
  const controlsToToggle = [
    'is_analytic',
    'is_finance',
    'is_business',
    'is_technical',
    'is_admin',
    'is_executive',
    'is_designer',
    'is_ops_manager',
    'is_sales_manager',
  ];

  if (value) {
    // If 'amplus_finance' is checked, uncheck and disable other checkboxes
    controlsToToggle.forEach(controlName => {
      const control = this.form.get(controlName);
      control.setValue(false);
      control.disable();
    });
  } else {
    // If 'amplus_finance' is unchecked, enable and set other checkboxes to true
    controlsToToggle.forEach(controlName => {
      const control = this.form.get(controlName);
       control.enable();
      // control.setValue(true);
    });
  }
});


  }

 
  

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        this.accountService
          .updateUserAccount(this.getFormData())
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error("ERROR OCCURED");
            }
          });
      } else {
        this.accountService
          .saveUserAccount(this.getFormData())
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error("ERROR OCCURED");
            }
          });
      }
    }
  }

  getFormData() {
    let data = JSON.parse(JSON.stringify(this.form.value));
    if(data['phone_number'] == null || data['phone_number'] == '') {
      delete data['phone_number'];
    }
    if(data['meeting_link'] == null || data['meeting_link'] == '') {
      delete data['meeting_link'];
    }

    if(data['amplus_finance'] == 1 || data['amplus_finance'] == '1') {
      data['is_sales_manager'] = '0';
      data['is_analytic'] ='0';
      data['is_finance'] ='0';
      data['is_business'] ='0';
      data['is_technical'] ='0';
      data['is_executive'] ='0';
      data['is_designer'] ='0';
      data['is_admin'] ='0';
      data['is_sales_manager'] ='0';
      data['is_ops_manager'] ='0';
    }

    return data;
  }

  editAccount(row) {
    this.sidenavBar.toggle();
    this.reset();
    this.sidenavText = 'EDIT USER';
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      phone_number: row.phone_number,
      meeting_link: row.meeting_link,
      // password: row.password,
      is_analytic: row.is_analytic,
      is_finance: row.is_finance,
      is_business: row.is_business,
      is_technical: row.is_technical,
      is_executive: row.is_executive,
      is_designer: row.is_designer,
      is_admin: row.is_admin,
      is_sales_manager: row.is_sales_manager,
      is_ops_manager: row.is_ops_manager,
      amplus_finance: row.amplus_finance
    });
    // this.form.get("password").disable();
    this.form.get("email").disable();
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.accountService.deleteUserAccount(row.uuid).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("USER DELETED SUCCESSFULLY");
            this.getUserDetails();
          }
        });
      }});
  }

  reset() {
    this.form.reset();
    this.sidenavText = 'CREATE USER';
    this.form.patchValue({
      is_analytic: false,
      is_finance: false,
      is_business: false,
      is_technical: false,
      is_executive: false,
      is_designer: false,
      is_admin: false,
      is_sales_manager: false,
      is_ops_manager: false,
      amplus_finance:false,
    });
    // this.form.get("password").enable();
    this.form.get("email").enable();
    this.isEditMode = false;
  }

  getUserDetails() {
    this.accountService.getAccountList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.data.forEach(element => {
          let type = "";
          let nameAdded = false;
          if (element.is_analytic) {
            type += "Analytics";
            nameAdded = true;
          }
          if (element.is_business) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Business";

            nameAdded = true;
          }
          if (element.is_admin) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Admin";

            nameAdded = true;
          }
          if (element.is_finance) {
            if (nameAdded) {
              type += ", ";
            }
            type += "HS Finance";

            nameAdded = true;
          }
          if (element.is_technical) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Technology";

            nameAdded = true;
          }
          if (element.is_executive) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Executive";
          }
          if (element.is_designer) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Designer";
          }
          if (element.is_sales_manager) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Sales Manager";
          }
          if (element.is_ops_manager) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Operation Manager";
          }
          if (element.amplus_finance) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Amplus Finance";
          }


          

          element["type"] = type;
        });
        this.userList = result.payload.data;
        this.userListDetails = [...this.userList];
        this.offset = 0;
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {
    // console.log(event);
    // const start = event.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // this.offset = event.offset;
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.userListDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.userList = rows;
    // this.userList = [...this.userList];
  }
}
