import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LeadService } from '../../service/lead/lead.service';
import { MessageToastService } from '../../service/toast/message-toast.service';

@Component({
  selector: 'app-tat-update',
  templateUrl: './tat-update.component.html',
  styleUrls: ['./tat-update.component.scss']
})
export class TatUpdateComponent implements OnInit {

  selected_tab = 0;
  form: any;
  tat_update_reasons = ["Site under construction", "Call not picked", "Rescheduled the call", 
  "Lead wants to wait over the go-ahead", "Site survey scheduled", "Other"];
  offset = 0;
  tat_logs = [];
  update_deadline = false;
  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private leadService: LeadService,
    private toast: MessageToastService,
    public dialogRef: MatDialogRef<TatUpdateComponent>
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      property_uuid: this.data.uuid,
      stage_uuid: this.data.stage_uuid,
      days: ["", Validators.required],
      remarks: ["", Validators.required]
    });
    console.log("data fetched: ", this.data);
    this.update_deadline = !this.data.communication_status;
    this.fetchTATdata();
  }

  fetchTATdata() {
    this.leadService.getPropertyTATdata({'property_uuid': this.data.uuid, "stage_uuid": this.data.stage_uuid,}).subscribe((res: any) => {
      if(res && res.status) {
        console.log("data fetched: ", res.payload);
        this.toast.success("DATA FETCHED SUCCESSFULLY");
        this.offset = res.payload['offset_deadline'];
        this.tat_logs = res.payload['logs'].reverse();
      } else {
        this.toast.error(res.message.toUpperCase());
      }
    });
  }

  onTabChange(event) {
    console.log('tab changed');
    console.log(event);
    this.selected_tab = event.index;
    if(this.selected_tab == 1) {
    } else {
    }
  }

  submit() {
    this.leadService.updatePropertyTATdata(this.form.value).subscribe((res: any) => {
      if(res && res.status) {
        this.toast.success("SUCCESSFULLY UPDATED THE DEADLINE");
        this.resetForm();
        this.fetchTATdata();
        this.status.emit(true);
      } else {
        this.toast.error(res.message.toUpperCase());
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  resetForm() {
    this.form.get('days').reset();
    this.form.get('remarks').reset();
  }

}
