import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material";
import { ReferralDetailComponent } from "src/app/common/component/referral-detail/referral-detail.component";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  @Input() lead_id: string;
  logs:any = [];
  totalCreditEarned = 0;
  
  constructor(
    private amcService: AMCService,
    private dialog: MatDialog,
    private toast: MessageToastService

  ) { }

  ngOnInit() {
    this.getReferrals();
  }

  getReferrals() {
    this.amcService.getReferralList({ lead_property_id: this.lead_id}).subscribe(
      (response:any) => {
          this.logs = [...response.payload.data];
          this.totalCreditEarned = response.payload.total_credit_earned;
          console.log("referral list data", this.logs);
      },
      (error:any) => {
        this.toast.error("OPERATION FAILED");
      }
      )
  }

  openReferralDetails(log) {
    console.log("referral ", log);
    let dialogRef = this.dialog.open(
      ReferralDetailComponent,
      {
        data: {
          log: log
        },
      }
    );
  }

}
