import { Component, OnInit, Inject, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { StageService } from "src/app/common/service/stage/stage.service";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-edit-stage-link",
  templateUrl: "./edit-stage-link.component.html",
  styleUrls: ["./edit-stage-link.component.scss"]
})
export class EditStageLinkComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  currentStage: any = "";
  @Output() status: EventEmitter<any> = new EventEmitter();
  stageLinkData = [];
  stageLinkDataDetails = [];
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditStageLinkComponent>,
    private stageService: StageService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    console.log("Data", this.data);
    this.stageLinkData = this.data.next_stages;
    this.stageLinkDataDetails = this.data.next_stages;
    this.currentStage = this.data.current_stage ? this.data.current_stage : "-";
  }

  delete(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        let queryParams =
      "stage_from_id=" + this.currentStage.uuid + "&stage_to_id=" + row.uuid;
    this.stageService.deleteStageLinkData(queryParams).subscribe(result => {
      this.status.emit("success");
      this.cancel();
    });
      }});
  }

  onPage(event) {
    console.log(event);
    const start = event.offset * this.limit;
    const end = start + this.limit;
    const rows = [];
    this.offset = event.offset;
    let index = 0;
    for (let i = start; i < end; i++) {
      index++;
    }
    console.log(rows);
  }

  cancel() {
    this.dialogRef.close();
  }
}
