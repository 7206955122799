import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatSidenav } from "@angular/material";
import { ManageSrComponent } from "src/app/common/component/sr-log/sr-log.component";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import * as FunnelGraph from "funnel-graph-js";
import { ManageAmcPlanComponent } from '../../lead-management/amc/manage-amc-plan/manage-amc-plan.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from 'src/app/common/service/user-service/user.service';
declare var $: any;

@Component({
  selector: 'app-amc-dashboard',
  templateUrl: './amc-dashboard.component.html',
  styleUrls: ['./amc-dashboard.component.scss']
})
export class AmcDashboardComponent implements OnInit {

  srOffset = 0;
  srCount = 0;
  srLimit = 10;
  srList = [];
  planStatusDropdown = [{ label: 'Select', value: '' }];
  planDropdown = [{ label: 'Select', value: '' }];
  hasEditAccess;
  isSalesPartner;
  canEditSR = false;
  form: any;
  graphForm: any;
  clusterUuid: any;
  performenceData: any;
  maxDate = new Date();
  colors = [
    "#90caf9",
    "#f4ab33",
    '#C0C0C0',
    "#1a4e6b",
    "#c068a8",
    "#ec7176",
  ];
  colorScheme = {
    domain: this.colors
  };
  schemeType = "ordinal";
  activeAMCReport = [];
  propChartData = [];
  leadView: any = [300, 400];
  selectedCluster = null;
  subscription = new Subscription();
  leadDataFlag = false;
  propDataFlag = false;
  globalSource = ['Platinum', 'Gold', 'Silver', 'Basic', 'Diamond'];

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  tooltipDisabled = false;
  xAxisLabel = "Days";
  showYAxisLabel = true;
  yAxisLabel_leads = "Active Subscriptions";
  yAxisLablel_props = "Number of Properties";
  showGridLines = true;
  innerPadding = 0;
  barPadding = 2;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  filterText = "";
  clusterIndependentAMCGraph = false;
  totalAMCInReport = 0;

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private menuToggle: MenuToggleService,
    private amcService: AMCService,
    private userService: UserService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private clusterService: ClusterService,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      page: 1,
      status: '',
      plan_id: '',
      validity_start_from_date: 0,
      validity_start_end_date: 0,
      validity_end_from_date: 0,
      validity_end_to_date: 0,
      cluster_independent: ''
    });

    this.graphForm = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()]
    });
    this.getFilterData();
    let userRoles = JSON.parse(this.authService.getUser());
    this.hasEditAccess = this.userService.getAMCDetailsAccess(userRoles);

    this.menuToggle.toggleMenu(false);
    this.clusterService.globalCluster.subscribe((val) => {
      if (val) {
        this.clusterUuid = val.uuid;
        this.getAMCData();
        this.getAMCAnalyticsReport();
      }
    })



  }



  getFilterData() {
    this.amcService.getAMCDashboardFilterData().subscribe((res: any) => {
      if (res && res.status) {
        console.log("element", res.payload['status'])
        this.planStatusDropdown = [...res.payload.status];
        this.planDropdown = [...res.payload.plan];
      }
    });
  }

  getAMCData() {

    if (this.form.value.cluster_independent) {
      this.form.value.cluster_id = '';
    } else {
      this.form.value.cluster_id = this.clusterUuid;
    }
    let params = this.form.value;
    let dateFields = ['validity_start_from_date', 'validity_start_end_date', 'validity_end_from_date', 'validity_end_to_date'];
    Object.keys(this.form.value).forEach(val => {
      console.log(val);
      if (dateFields.indexOf(val) >= 0 && this.form.get(val).value != 0 && this.form.get(val).value != null && this.form.get(val).value != '') {
        params[val] = Math.floor(this.form.get(val).value.getTime() / 1000);
      } else if (dateFields.indexOf(val) >= 0) {
        delete params[val];
      }
    });
    delete params['cluster_independent'];

    this.amcService.getAMCDashboardList(params).subscribe((val: any) => {
      if (val && val.status) {
        this.sidenavBar.close();
        this.srList = val.payload.data;
        this.srCount = val.payload.count;
        this.srOffset = val.payload.current_page - 1;
        this.srLimit = val.payload.data_per_page;
        this.performenceData = val.payload.performance_data;
      }
    });

  }

  onActivate(event) {
    if (event.type == "click" && event.cell != '0') {
      console.log('row clicked ', event);
      this.openSRLog(event.row);
    }
  }

  openSRLog(event: any) {
    let dialogRef = this.dialog.open(ManageAmcPlanComponent, {
      data: {
        path: '/' + event.lead_property.identifier + '/amc/',
        property_uuid: event.lead_property.uuid,
        ticket_uuid: event.uuid,
        label: 'Documents',
        can_edit: this.hasEditAccess,
        amc_details: JSON.parse(JSON.stringify(event))
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        this.getAMCData();
      }
    });
  }

  getAMCAnalyticsReport() {
    let params = { timestamp: Math.floor(new Date().getTime() / 1000), cluster_id: '' };
    if (this.graphForm.value.year && this.graphForm.value.year._d) {
      params.timestamp = Math.floor(this.graphForm.value.year._d.getTime() / 1000);
    }
    params.cluster_id = this.clusterUuid;
    if (this.clusterIndependentAMCGraph) {
      params.cluster_id = '';
    }

    this.amcService.getAMCAnalyticsReport(params).subscribe((val: any) => {
      if (val && val.status) {
        this.activeAMCReport = this.correctData(val.payload.data);
        this.totalAMCInReport = val.payload.total_count;
      }
    });
  }
  correctData(data): any {
    let columns = Object.keys(data).length;
    let width = columns < 10 ? 120 * columns : 10 * columns + 90;
    this.leadView = columns < 10 ? [width, 30 * Math.round(window.innerHeight / 100)] : null;
    let graphData: any = [];
    Object.keys(data).forEach(key => {
      if (key != "total_count") {
        let obj = {};
        obj["name"] = key;
        obj["series"] = [];
        this.globalSource.forEach(source => {
          if (data[key][source]) {
            obj["series"].push({ name: source, value: data[key][source] });
          } else {
            obj["series"].push({ name: source, value: 0 });
          }
        });
        graphData.push(obj);
      }
    });
    return graphData;
  }
  getTooltipTime(val) {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    return monthNames[parseInt(val) - 1];
  }

  onChange(event) {
    this.getAMCAnalyticsReport()
  }

  filter() {
    this.form.get("page").setValue(1);
    this.getAMCData();
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  reset() {
    this.form.patchValue({
      plan: "",
      status: "",
      validity_start_from_date: 0,
      validity_start_end_date: 0,
    });
  }

  onPage(event) {
    this.form.get("page").setValue(event.offset + 1);
    this.getAMCData();
  }

  sidenavOpen() {
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  onChangeClusterIndependent(isChecked) {
    console.log(isChecked, "ww", this.clusterIndependentAMCGraph);
    this.clusterIndependentAMCGraph = !isChecked;
    this.getAMCAnalyticsReport();
  }

}

