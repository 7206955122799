import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { ReportingService } from "src/app/common/service/reporting/reporting.service";
import * as shape from "d3-shape";
import { DatePipe } from "@angular/common";
import { MatSidenav } from "@angular/material/sidenav";
import { Subscription, BehaviorSubject } from "rxjs";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-cluster-generation",
  templateUrl: "./cluster-generation.component.html",
  styleUrls: ["./cluster-generation.component.scss"]
})
export class ClusterGenerationComponent implements OnInit {
  form: any;
  clusterList: any = [];
  formSubmitted: boolean = false;
  graphData: any;
  isCollapsed: boolean = false;
  private subscription: Subscription = new Subscription();

  // graph parameters
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  tooltipDisabled = false;
  xAxisLabel = "Days";
  showYAxisLabel = true;
  yAxisLabel = "Energy Generated (kWh)";
  showGridLines = true;
  barPadding = 2;
  roundDomains = false;
  view: any = [2000, 400];
  startDate = "";
  endDate = "";

  colorScheme = {
    domain: ["#f59240"]
  };
  schemeType = "ordinal";
  activeIcon = "../../../assets/images/location_marker_active.png";
  inactiveIcon = "../../../assets/images/location_marker_inactive.png";

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private clusterService: ClusterService,
    private reportingService: ReportingService,
    private datepipe: DatePipe,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    let cluster = "";
    let userDetails = JSON.parse(this.authService.getUser());

    this.view = [2000, 60 * Math.round(window.innerHeight / 100)];

    this.form = this.fb.group({
      cluster_uuid: [cluster, Validators.required],
      from_timestamp: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        Validators.required
      ],
      to_timestamp: [new Date(), Validators.required]
    });

    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          console.log("cluster value change in cluster generation");
          console.log(val);
          this.form.get("cluster_uuid").setValue(val.uuid);
          this.submit();
        }
      })
    );

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (100));
    });
  }

  submit() {
    let formData: any = {};
    formData["cluster_uuid"] = this.form.get("cluster_uuid").value;
    formData["from_timestamp"] = Math.floor(
      this.form.get("from_timestamp").value.getTime() / 1000
    );
    formData["to_timestamp"] = Math.floor(
      this.form.get("to_timestamp").value.getTime() / 1000
    );
    console.log("form submitted");
    console.log(formData);

    this.reportingService.getClusterGeneration(formData).subscribe(val => {
      if (
        Object.keys(val["payload"]).length != 0 &&
        Object.keys(val["payload"].data).length != 0
      ) {
        console.log("value from cluster generation");
        console.log(val);
        console.log(val["payload"]);
        console.log(typeof val["payload"]);
        setTimeout(() => {
          console.log("printing inner width");
          console.log(window.innerWidth);
          let len = Object.keys(val["payload"].data).length;
          this.view = null;
          // if ( len > 7 && len <=30 ) {
          //   this.view = null;
          // }
          // else {
          //   this.view = [
          //     Object.keys(val["payload"].data).length * 14,
          //     56 * Math.round(window.innerHeight / 100)
          //   ];
          // }
        }, 300);
        this.graphData = this.correctData(val["payload"].data);
        console.log(this.graphData);
        this.formSubmitted = true;
        this.toast.success("DATA FETCHED SUCCESSFULLY");
        this.sidenavBar.close();
      } else {
        this.formSubmitted = false;
        this.toast.error(val['message'].toUpperCase());
        this.sidenavBar.close();
      }
      $(".filter-header").click();
    });
  }

  correctData(data): any {
    let new_data = [];
    Object.keys(data).map((key: any, index) => {
      var point;
      if (data[key]) point = data[key];
      else point = 0;
      new_data.push({
        name: this.datepipe.transform(new Date(key), "MMM d"),
        value: point
      });
      if (index == 0) {
        this.startDate = this.datepipe.transform(new Date(key), "MMMM d, y");
      } else if (index == Object.keys(data).length - 1) {
        this.endDate = this.datepipe.transform(new Date(key), "MMMM d, y");
      }
    });

    // if (new_data.length < 7) {
    //   console.log("inside adding empty data points");
    //   let empty = "  ";
    //   for (let i = 0; i < 7 - new_data.length; i++) {
    //     new_data.push({
    //       name: empty.repeat(i + 1),
    //       value: 0
    //     });
    //   }
    // }
    console.log("correct data");
    console.log(new_data);
    return new_data;
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // this.handleList.forEach(handle => {
    //   google.maps.removeListener(handle);
    // });
    // this.handleList = [];
  }
}
