import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { FinanceService } from "src/app/common/service/finance/finance.service";
import { uiUpdate } from "@syncfusion/ej2-grids";
import { environment } from "src/environments/environment";
import { UserService } from 'src/app/common/service/user-service/user.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
declare var $: any;
@Component({
  selector: 'app-payment-approval',
  templateUrl: './payment-approval.component.html',
  styleUrls: ['./payment-approval.component.scss']
})
export class PaymentApprovalComponent implements OnInit {

  rows = [];
  count = 0;
  offset = 0;
  limit = 25;
  radio_selected = 0;
  logs: any;
  actionableCTA: any;
  currentState = { page: 1 };
  form: any;
  clusterUuid: any;
  maxDate = new Date();
  userAccess:any;
  fileExtension:any;
  showDocImg : boolean =false;
  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;

  constructor(
    private fb: FormBuilder,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService,
    private financeService: FinanceService,
    private clusterService: ClusterService,
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      query_string: "",
      page: 1,
      date_from: 0,
      date_to: 0,
      cluster_independent: "",
    });
    // this.getGlobalPayments(this.currentState);
    // this.getFilterData();
    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });

    this.clusterService.globalCluster.subscribe((val) => {
      if (val) {
        this.clusterUuid = val.uuid;
        this.getFilterData();
        this.getPaymentsList();
      }
    });

    // this.financeService.refreshNotifier.subscribe((v) => {
    //   if (v == 'refresh') {
    //     this.getGlobalPayments(this.currentState);
    //   }
    // });
    let user = JSON.parse(this.authService.getUser());
  
     this.userAccess = this.userService.getFinanceAccess(user);

     //alert(this.userAccess);
  
  }

  getGlobalPayments(data) {
    this.financeService.getGlobalPaymentLogs(data).subscribe(
      (response: any) => {
        this.logs = [];
        response.payload.data.forEach(element => {
          try {
            element.images = element.documents ? JSON.parse(element.documents) : [];

          } catch (e) {
            element.images = []
          }
          this.logs.push(element);
        });
        this.limit = response.payload.data_per_page;
        this.count = response.payload.count;
        this.offset = data["page"] - 1;
        this.actionableCTA = response.payload.dropdown_options;
      },
      (error: any) => {

      }
    );
  }

  getFilterData() {
    let params = { cluster_id: '', page: 1 };
    if (this.form.value.cluster_independent) {
      params.cluster_id = "";
    } else {
      params.cluster_id = this.clusterUuid;
    } if (this.offset)
      this.financeService.getGlobalPaymentLogs(params).subscribe((response: any) => {
        if (response && response.status) {
          response.payload.data.forEach(element => {
            try {
              element.images = element.documents ? JSON.parse(element.documents) : [];

            } catch (e) {
              element.images = []
            }
            this.logs.push(element);
          });
          this.limit = response.payload.data_per_page;
          this.count = response.payload.count;
          this.offset = response.payload.current_page - 1;
          this.actionableCTA = response.payload.dropdown_options;
        }
      });
  }

  filter() {
    this.form.get("page").setValue(1);
    this.getPaymentsList();
  }

  getPaymentsList() {
    if (this.form.value.cluster_independent) {
      this.form.value.cluster_id = '';
    } else {
      this.form.value.cluster_id = this.clusterUuid;
    }
    if (this.radio_selected == 1) {
      this.form.value.all = 1;
    }
    else if (this.radio_selected == 2) {
      this.form.value.all = 2;
    }
    else if (this.radio_selected == 3) {
      this.form.value.all = 3;
    }
    else {
      this.form.value.all = 0;
    }
    let params = this.form.value;
    let dateFields = ['date_from', 'date_to'];
    Object.keys(this.form.value).forEach(val => {
      if (dateFields.indexOf(val) >= 0 && this.form.get(val).value != 0 && this.form.get(val).value != null && this.form.get(val).value != '') {
        params[val] = Math.floor(this.form.get(val).value.getTime() / 1000);
      } else if (dateFields.indexOf(val) >= 0) {
        delete params[val];
      }
    });
// Assuming this code is within your Angular component


this.financeService.getGlobalPaymentLogs(this.form.value).subscribe((val: any) => {
  if (val && val.status) {
    this.sidenavBar.close();
    this.logs = [];

    val.payload.data.forEach(element => {
      try {
        element.images = element.documents ? JSON.parse(element.documents) : [];
      } catch (e) {
        element.images = [];
      }

        // Check file extensions and update URLs if needed
      if (element.business_approval_url) {
        const businessExtension = element.business_approval_url.split('.').pop().toLowerCase();
        if (businessExtension.toLowerCase() === 'jpg' || businessExtension.toLowerCase() === 'png') {
          element.business_approval_url_1 = element.business_approval_url;
        }else{
          element.business_approval_url_1 = '../../../../../assets/images/copy_clip.webp';
        }
      }

      if (element.cancelled_cheque_url) {
        const chequeExtension = element.cancelled_cheque_url.split('.').pop().toLowerCase();
        
        if (chequeExtension.toLowerCase() === 'jpg' || chequeExtension.toLowerCase() === 'png') {
          element.cancelled_cheque_url_1 = element.cancelled_cheque_url;          
        }else{
          element.cancelled_cheque_url_1 = '../../../../../assets/images/copy_clip.webp';
        }
      }


      this.logs.push(element);
      console.log(this.logs)
    });

    this.limit = val.payload.data_per_page;
    this.count = val.payload.count;
    this.offset = val.payload.current_page - 1;
    this.actionableCTA = val.payload.dropdown_options;
  }
});

// // Function to get file extension
// getFileExtension(url: string): string {
//   return url.split('.').pop().toLowerCase();
// }


// function isValidImageExtension(url: string): boolean {
//   // Add more valid extensions as needed
//   const validExtensions = ['.jpg', '.jpeg', '.png'];
//   const fileExtension = (url.split('.').pop() || '').toLowerCase();

//   return fileExtension !== null && validExtensions.includes(fileExtension);
// }


  }
  radioChange(event) {
    console.log("current button selected");
    console.log(event);
    this.currentState["all"] = event.value;
    this.radio_selected = this.currentState["all"];
    console.log("radio selected------->", this.radio_selected);
    this.clearState();
    this.reset();
    this.getPaymentsList();
    // this.getGlobalPayments(this.currentState);

  }

  clearState() {
    this.currentState["page"] = 1;
  }

  // downLoadFile(data, type, filename) {
  //   let blob = new Blob([data], { type: type });
  //   let url = window.URL.createObjectURL(blob);
  //   let downloadObject = document.createElement('a');
  //   downloadObject.href = url;
  //   downloadObject.download = filename;
  //   downloadObject.target = '_blank';
  //   document.body.appendChild(downloadObject);
  //   downloadObject.click();
  // }

  // downloadReport() {
  //   console.log("downloading report");

  //   this.financeService.downloadPaymentReport().subscribe((res: any) => {
  //     const timeObject = new Date();
  //     this.downLoadFile(
  //       res,
  //       'application/vnd.mx-excel',
  //       'payment-report' + '_' +
  //       timeObject.getDate() +
  //       '/' +
  //       timeObject.getMonth() +
  //       '/' +
  //       timeObject.getFullYear() +
  //       '__' +
  //       timeObject.getHours() +
  //       '-' +
  //       timeObject.getMinutes() +
  //       '-' +
  //       timeObject.getSeconds() +
  //       '.xls'
  //     );
  //   })
  // }

  downloadList() {
    if (this.logs.length > 0) {
      console.log('request for downloading list');
      console.log(this.form.value);
      let downloadState = JSON.parse(JSON.stringify(this.form.value));
      delete downloadState['page'];
      console.log('current angular download object');
      console.log(downloadState);
      downloadState['download'] = 1;
      this.financeService.downloadListByUrl(this.financeService.paymentCrudUrl + '/global', downloadState, 'Payments List ');
      console.log("Payments List downloaded!!!")
    }
    else {
      console.log("Payments List empty!");
    }
  }

  getPropertyUrl(row) {
    const baseUrl = environment.production ? 'https://astral.homescape.solar/' : 'https://devastral.homescape.solar/';
    return baseUrl + "lead/view/stage-view?id=" + row.lead_property.uuid;
  }

  getName(row) {
    let res = "";
    if (row.lead_property && row.lead_property.lead) {
      const lead = row.lead_property.lead;
      res = lead.first_name + (lead.last_name ? ' ' + lead.last_name : '');
    }
    return res;
  }

  onPage(event) {
    this.form.get("page").setValue(event.offset + 1);
    this.offset = event.offset + 1;
    this.getPaymentsList();
  }

  reset() {
    this.form.patchValue({
      query_string: "",
      date_from: 0,
      date_to: 0,
      cluster_independent: '',
    });
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  sidenavOpen() {
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toast.success("Copied Successfully!")
  }


  // handleImageError(imageType: string): void {
  //   const parts = imageType.split('.');
  //   this.fileExtension = parts.length > 1 ? parts.pop()!.toLowerCase() : null;
  
  //   if (this.fileExtension !== 'jpg' && this.fileExtension !== 'png') {
  //     console.log(`File extension: ++++++++++++++++++++++++++++ ${this.fileExtension}`);
  //     this.showDocImg = true;
  //   } else {
  //     this.showDocImg = false;
  //   }
  // }
  
  
}
