import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { FormBuilder, Validators } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute } from "@angular/router";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialog, MatOption } from "@angular/material";
import { AccountService } from "src/app/common/service/account/account.service";
import { EditRoomComponent } from "src/app/common/component/edit-room/edit-room.component";
import { TuyaService } from "src/app/common/service/tuya.service";
import { forkJoin, of } from "rxjs";
import * as shape from "d3-shape";
import { catchError } from "rxjs/operators";
import * as echarts from "echarts";

declare var $: any;
@Component({
    selector: "app-home-automation",
    templateUrl: "./home-automation.component.html",
    styleUrls: ["./home-automation.component.scss"],
})
export class HomeAutomationComponent implements OnInit {

    selectedDevice: any;
    selected_tab = 0;
    graph_freq: number = 1;
    uuid: any;
    roomList = [];
    deviceList = [];
    myChart: echarts.ECharts;
    chartOptions;
    days = [];
    consumption = [];
    deviceFlag = false;
    room: "";
    deviceId: "";
    deviceType: "";
    errorFlag = false;
    form: any;
    minDate: any = "";
    maxDate: any = "";
    time = 0;
    prevFreq = 1;
    freqForm: any;
    consumptionDate= [];
    monthMap = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December",
    };

    reverseMonthMap = {
        Jan: "0",
        Feb: "1",
        Mar: "2",
        Apr: "3",
        May: "4",
        Jun: "5",
        Jul: "6",
        Aug: "7",
        Sep: "8",
        Oct: "9",
        Nov: "10",
        Dec: "11",
    };

    freq_dropdown = [
        { key: "Daily", value: 1 },
        { key: "Monthly", value: 2 },
        { key: "Yearly", value: 3 },
        { key: "Lifetime", value: 4 },
    ];
    chartData: any = [{ name: "", series: [] }];
    //chart Options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    tooltipDisabled = false;
    xAxisLabel = "Hours";
    tooltip = "Hour";
    showYAxisLabel = true;
    yAxisLabel = "Energy Consumption (kWh)";
    showGridLines = true;
    innerPadding = 0;
    barPadding = 8;
    groupPadding = 16;
    roundDomains = false;
    maxRadius = 10;
    minRadius = 3;
    noBarWhenZero = true;
    // graph_freq color scheme
    colors1 = [
        "#f59240",
        "#5c6bc0",
        "#29b6f6",
        "#ffee58",
        "#ef5350",
        "#868e96",
        "#77EDB4",
        "#44611F",
        "#910F7F",
        "#DFBCDE",
        "#71DCF6",
        "#5B5901",
        "#71510A",
        "#A567B8",
        "#5916E6",
        "#C7517F",
        "#14A2E9",
        "#AD90A7",
        "#03796D",
        "#E60433",
        "#CA4B39",
        "#2F60F9",
        "#B8206F",
        "#A173CE",
        "#1C99EB",
        "#D7F76A",
        "#B22205",
        "#FC8C75",
        "#B866C7",
        "#256E2F",
        "#C8821F",
        "#1B6398",
        "#18A3FB",
        "#141130",
        "#0A687A",
    ];
    global_freq = [
        { key: "Daily", value: 1 },
        { key: "Monthly", value: 2 },
        { key: "Yearly", value: 3 },
        { key: "Lifetime", value: 4 },
    ];

    analytics_type = [
        { key: "Power", value: "cur_power" },
        { key: "Current", value: "cur_current" },
        { key: "Voltage", value: "cur_voltage" },
        { key: "Energy", value: "cur_energy" },
        { key: "Switch I", value: "switch_1" },
        { key: "Switch II", value: "switch_2" },
    ];
    // tuyaData and onsetData contains the complete data stored locally
    // tuyaGraphData has the data currently displayed
    analyticsData = {};
    analyticsGraphData = {};
    tuyaYAxisLabel = "Power Generated";
    tuyaXAxisLabel = "Time";
    tuyaLabels = [];
    tuyaSelectedLabels = [];
    freshLoad = true;
    prevChecked = true;
    updateLegends = true;
    analyticsForm;
    filterForm;
    tuyaDpCount = 0;
    colors = ["#5c6bc0"];
    colorScheme = {
        domain: this.colors,
    };
    areaColorScheme = { domain: this.colors1 };
    schemeType = "ordinal";
    // line interpolation
    curve = shape.curveLinear;
    rangeFillOpacity = 0.15;

    // line; area
    autoScale = true;
    timeline = false;


    @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
    @ViewChild(MatSlideToggleModule, { static: true }) matSlideToggle: MatSlideToggleModule;
    @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
    @ViewChild("allTuyaSelected", { static: false })
    private allTuyaSelected: MatOption;
    constructor(
        private menuToggle: MenuToggleService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private toast: MessageToastService,
        private accountService: AccountService,
        private fb: FormBuilder,
        private tuyaService: TuyaService
    ) { }

    ngOnInit() {
        // closing the sidemenu on page load
        this.menuToggle.toggleMenu(false);

        this.route.queryParams.subscribe(params => {
            this.uuid = params['id'];
            this.getRoomList();
        });

        this.deviceFlag = false;
        // filter for daily, monthly, yearly
        this.form = this.fb.group({
            day: [new Date(new Date().setHours(0, 0, 0, 0))],
            month: [new Date()],
            year: [new Date()],
        });

        this.freqForm = this.fb.group({
            chart_freq: ["1", Validators.required],
        });

        this.freqForm.get("chart_freq").valueChanges.subscribe((val: any) => {
            console.log("frequency changed ", this.freqForm.get("chart_freq"));
            console.log(val);
            if (val != this.prevFreq) {
                console.log("same value already present");
                this.radioChange({ value: val });
            }
            this.prevFreq = val;
        });

        this.analyticsForm = this.fb.group({
            type: ["cur_power"],
        });

        this.analyticsForm.get("type").valueChanges.subscribe((opt) => {
            this.drawTuyaGraph();
        });
        this.filterForm = this.fb.group({
            tuyaFilters: [["select all"]]
        });
    }

    onTabChange(event) {
        this.selected_tab = event.index;
        this.pageRefresh(this.selected_tab ); 
    }

    getRoomList() {
        let data = { uuid: this.uuid };
        this.accountService.getIOTRoomList(data).subscribe(val => {
            this.roomList = val['payload']['data'];
            for(let i=0; i<this.roomList.length; i++){
                let device = this.roomList[i].devices;
                for(let j=0; j<device.length; j++){
                    if( device[j].status == "on"){
                        device[j].status = true;
                    }
                    else{
                        device[j].status = false;
                    }
                }
                console.log("devices---->", device);
            }
        });
    }

    editRoom(room) {
        console.log("room==>", room);
        if (room) {
            let dialogRef = this.dialog.open(EditRoomComponent, {
                data: {
                    name: room.name,
                    description: room.description
                }
            });
            dialogRef.componentInstance.room_val.subscribe(val => {
                console.log("val --- inside edit room ----> ", val);
                if (val) {
                    let apiData = {
                        uuid: room.uuid,
                        name: val.name,
                        description: val.description,
                        image_url: "https://firebasestorage.googleapis.com/v0/b/homescape-7329a.appspot.com/o/homescape-staging%2Fstatic_files%2Flivingroom.jpeg?alt=media&token=38419400-b8b1-4e4b-834d-5986447b70bf"
                    };
                    console.log("received in parent " + val);
                    this.accountService
                        .updateIOTRoom(apiData)
                        .subscribe((result: any) => {
                            if (result && result.status) {
                                this.toast.success("ROOM UPDATED SUCCESSFULLY");
                                this.getRoomList();
                            }
                        },
                        (err)=>{
                            this.toast.error("FILL ALL FIELDS OR TRY AGAIN")
                        });
                }
            });
        }
    }

    // refreshing the page whenever freq (daily, monthly, yearly) is changed
    radioChange(event) {
        this.chartData = [];
        this.graph_freq = event.value;
        this.changeLabel(this.graph_freq);
        this.pageRefresh();
    }

    getGraphTooltipLabel(text) {
        let type = "";
        type = this.analytics_type.find(
            (x) => x.value == this.analyticsForm.get("type").value
        ).key;
    }

    // changing graph labels based on freq
    changeLabel(option) {
        switch (option) {
            case 1:
                this.xAxisLabel = "Hours";
                this.yAxisLabel = "Energy Consumption(kWh)";
                this.tooltip = "Hour";
                break;
            case 2:
                this.xAxisLabel = "Days";
                this.yAxisLabel = "Energy Consumption(kWh)";
                this.tooltip = "Day";
                break;
            case 3:
                this.xAxisLabel = "Months";
                this.yAxisLabel = "Energy Consumption(kWh)";
                this.tooltip = "Month";
                break;
            case 4:
                this.xAxisLabel = "Years";
                this.yAxisLabel = "Energy Consumption(kWh)";
                this.tooltip = "Year";
                break;
            default:
                this.xAxisLabel = "Days";
                this.yAxisLabel = "Energy Consumption(kWh)";
                this.tooltip = "Day";
        }
    }


    // function called whenever date is selected
    onChange(event) {
        console.log("date changed");
        console.log(event);
        this.pageRefresh("date-change");
    }

    pageRefresh(event?) {
        console.log("this.selected_tab------------------------>", this.selected_tab);
        if (this.selected_tab == 1) {
            console.log("this.selected_tab", this.selected_tab);
            this.freqForm.get("chart_freq").setValue("1");
            this.freq_dropdown = [{ key: "Daily", value: 1 }];
            this.getTuyaAnalyticsData(true);
        }
        else {
            this.deviceAnalytics(this.selectedDevice);
        }
    }

    // different frequency daily, monthly, yearly require different formats of timestamp in API call
    getTimestamp(option) {
        let timestamp = 0;
        if (option == 1) {
            timestamp = Math.floor(this.form.get("day").value.getTime() / 1000);
        } else if (option == 2) {
            console.log("inside month timestamp");
            console.log(this.form.get("month").value);
            console.log("passing values");
            console.log(
                "year: " + this.form.get("month").value.toString().split(" ")[3]
            );
            console.log(
                "month: " +
                this.reverseMonthMap[
                this.form.get("month").value.toString().split(" ")[1]
                ]
            );
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("month").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("month").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
            console.log("final time object");
            console.log(timestamp);
        } else if (option == 3) {
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("year").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("year").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
        }
        return timestamp;
    }

    disableViewChange() {
        setTimeout(() => {
            $(
                "button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button"
            ).css("pointer-events", "none");
        }, 300);
    }

    getConsumptionType() {
        if (this.graph_freq == 1) {
            return 'daily';
        }
        else if (this.graph_freq == 2) {
            return 'monthly';
        }
        else if (this.graph_freq == 3) {
            return 'yearly';
        }
        else if (this.graph_freq == 4) {
            return 'lifetime';
        }
    }

    deviceInstruction(device) {
        console.log("device information for sending instruction", device);
        let data = {
            supplier_type: device.supplier_type,
            device_id: device.device_id,
            value: !device.status,
            code: "switch_1"
        };
        console.log(data, "inside -- device-- instruction-- data--->");
        this.accountService.sendDeviceInstructions(data).subscribe(val => {
            console.log(val, "inside -- device-- instruction-- val--->");
            if (val['status']) {
                console.log(val['status'], "inside -- device-- instruction-- val--->");
                console.log("toggle", device.status);
                this.toast.success("Command sent successfully!!!");
            }
            else {
                device.status = !device.status;
                console.log(val['status'], "inside -- device-- instruction-- val--->");
                console.log("toggle", device.status);
                this.toast.error("Device is not connected!!! Please check and try again.")
            }
        });
    }

    deviceAnalytics(device) {
        this.deviceFlag = true;
        console.log("device information", device);
        this.selectedDevice = device;
        let params = {
            device_id: device.device_id,
            time: this.getTimestamp(1), //1636350187,
            consumption_type: this.getConsumptionType()
        }
        this.room = device.room.name;
        this.deviceId = device.device_id;
        this.deviceType = device.type;
        this.accountService.getIOTDeviceConsumption(params).subscribe(val => {
            this.errorFlag = false;
            console.log("getIOTDeviceConsumption : val:", val);
            if (this.graph_freq == 1) {
                val['payload']['daily_consumption'] = val['payload']['daily_consumption'];
                this.consumption = Object.values(val['payload']['daily_consumption']);
                this.consumptionDate = Object.keys(val['payload']['daily_consumption']);
            }
            else {
                this.consumption = [];
                this.consumptionDate =[];
            }
            this.getAnalyticsChart();
        },
            err => {
                this.errorFlag = true;
                console.error(err);
                this.toast.error("could not process request !!!");
            });
    }

    getAnalyticsChart() {
        let options = {
            xAxis: {
                name: 'Date',
                data: this.consumptionDate
            },
            yAxis: {
                name: 'Consumption (kwh)',
            },
            series: [
                {
                    data: this.consumption,
                    type: 'bar'
                }
            ],
            tooltip: {
                show: true,
                formatter: function (params) {
                    console.log(params);
                    let res;
                    let date = params.dataIndex + 1;
                    res = "Date: " + date + "<br>" +  "Consumption: " + params.value + " kwh";
                    return res;
                  },
            }
        };
        if(!this.myChart) {
            this.myChart = echarts.init(
                document.getElementById("data-chart") as HTMLCanvasElement
              );
        }
        console.log("mychart", this.myChart);
        this.myChart.setOption(options);
    }

    onChartInit(echarts) {
        this.myChart = echarts;
        console.log("inside onChartInit: ", this.myChart);
    }

    downloadTuyaAnalyticsData() {
        let params = {
            timestamp: this.getTimestamp(1),
            property_uuid: this.uuid,
            download: 1
        };
        this.tuyaService.downloadTuyaDataByUrl(this.tuyaService.tuyaDeviceAnalyticsUrl, params);
        console.log("TUYA DATA Downloaded!!!!");
    }

    getTuyaAnalyticsData(updateLegends = true) {
        let params = {
            timestamp: this.getTimestamp(1),
            property_uuid: this.uuid,
        };
        const tuyaAnalytics = this.tuyaService
            .getTuyaAnalytics(params)
            .pipe(catchError((e) => of(e.toString())));
        // const onsetAnalytics = this.tuyaService
        //     .getOnsetDeviceAnalytics(params)
        //     .pipe(catchError((e) => of(e.toString())));

        forkJoin([tuyaAnalytics]).subscribe((data: any[]) => {
            console.log("join data", data);

            if (data[1] && data[1].status) {
                console.log("onset data", data[1]);
                // this.analyticsData["onset"] = { cur_power: data[1].payload.data };
            }

            if (data[0] && data[0].status) {
                console.log("tuya data", data[0]);
                if (Object.keys(this.analyticsData).length > 0) {
                    Object.keys(data[0].payload.data).forEach((key) => {
                        this.analyticsData[key] = data[0].payload.data[key];
                    });
                } else {
                    this.analyticsData = data[0].payload.data;
                }
            }

            // freshLoad is bascially used to assign all the global legends to selected legends (means everything is selected)
            this.freshLoad = true;
            this.updateLegends = updateLegends;
            this.drawTuyaGraph();

            setTimeout(() => {
                this.updateLegends && this.toggleAllSelection("tuyaFilters");
            }, 1000);
        });
    }

    drawTuyaGraph() {
        this.setGraphAxisLabels();
        this.updateLegends && this.getLegends();

        let type = this.analyticsForm.get("type").value;
        let data = [];
        this.tuyaDpCount = 0;
        Object.keys(this.analyticsData).forEach((device) => {
            if (
                this.filterForm.controls.tuyaFilters.value.indexOf(
                    device.trim().toString()
                ) >= 0
            ) {
                let cleanedData = this.cleanData(
                    this.analyticsData[device.toString()][type],
                    this.getFactor(type, device),
                    type.toLowerCase() == "cur_energy"
                );
                data.push({
                    name: device,
                    series: cleanedData,
                });
                this.tuyaDpCount += cleanedData.length;
            } else {
                data.push({ name: device, series: [] });
            }
        });

        this.analyticsGraphData = this.normalizeTimeseries(data, type.toLowerCase() == "cur_energy");
        // this.analyticsGraphData = multi;

        console.log("final graph data", this.analyticsGraphData);
    }

    toggleSingleSelection(type) {
        if (this.allSelectedCheck(type)) {
            this.toggleAll(type, false);
        }

        const filterLen = this.tuyaLabels.length;

        if (this.filterForm.controls[type].value.length == filterLen) {
            this.toggleAll(type, true);
        }

        this.drawTuyaGraph();
    }

    toggleAll(type, select) {
        if (type == "tuyaFilters") {
            select ? this.allTuyaSelected.select() : this.allTuyaSelected.deselect();
        }
    }

    getTuyaTooltipTime(date) {
        // return date;
        try {
            return date.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
        } catch (e) {
            return new Date(date).toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
        }
    }

    toggleAllSelection(type) {
        console.log("all toggled");
        if (this.allSelectedCheck(type)) {
            const filterData = this.tuyaLabels;
            this.filterForm.controls[type].patchValue([...filterData, "select all"]);
        } else {
            this.filterForm.controls[type].patchValue([]);
        } this.drawTuyaGraph()
    }

    setGraphAxisLabels() {
        this.xAxisLabel = "Time";
        let type = this.analytics_type.find(
            (x) => x.value == this.analyticsForm.get("type").value
        ).key;
        this.tuyaYAxisLabel = type + " (" + this.getUnits(type) + ")";
    }

    getUnits(type) {
        switch (type.toLowerCase()) {
            case "power":
                return "W";
            case "current":
                return "A";
            case "voltage":
                return "V";
            case "pm 2.5":
                return "ug/m^3";
            case "pm 10":
                return "ug/m^3";
            case "pm 1":
                return "ug/m^3";
            case "co2":
                return "ug/m^3";
            case "temperature":
                return "C";
            case "humidity":
                return "%";
            case "energy":
                return "kWh";
            default:
                return "kW";
        }
    }

    getLegends() {
        console.log("getting legends");

        // update global labels
        // this.tuyaLabels = ['select all'];
        this.tuyaLabels = [];
        this.tuyaLabels.push(...Object.keys(this.analyticsData));

        // update selected labels
        if (this.freshLoad) {
            this.freshLoad = false;
            this.tuyaSelectedLabels = JSON.parse(JSON.stringify(this.tuyaLabels));
        } else {
            // if select all is checked but previously it wasn't
            if (this.tuyaSelectedLabels.indexOf("select all") >= 0 && !this.prevChecked) {
                this.tuyaSelectedLabels = JSON.parse(JSON.stringify(this.tuyaLabels));
            }

            // if select all is un-checked but previously it was checked
            if (this.tuyaSelectedLabels.indexOf("select all") < 0 && this.prevChecked) {
                this.tuyaSelectedLabels = [];
            }
        }

        this.prevChecked = this.tuyaSelectedLabels.indexOf("select all") >= 0;
    }

    // input data is of the form -> [{ '2021-01-04 00:01:23' : 2465 }, ...]
    // output data is of the form -> [{ name: '2021-01-04 00:01:23', value: 2465}, ...]
    cleanData(data, factor = 1, cummulative = false): any {
        // console.log("data got for cleaning", data);
        let cleanedData = [];
        let sum = 0;
        console.log("cummulative data found: ", cummulative);
        if (data && data != undefined && data.length > 0) {
            data.forEach((point) => {
                Object.keys(point).forEach((key) => {
                    cleanedData.push({
                        name: parseInt(key) * 1000,
                        value: parseFloat(this.getValue(point[key], factor).toFixed(2)) + sum,
                    }); // new Date(parseInt(key) * 1000),  parseFloat((this.getValue(point[key], factor)).toFixed(2))
                    if (cummulative) { sum += parseFloat(this.getValue(point[key], factor).toFixed(2)); }
                });
            });
        }

        // console.log("returning onset", cleanedData);
        return cleanedData;
    }

    getValue(data, factor) {
        if (data && !isNaN(data) && typeof data === "number") {
            return data / factor;
        }

        return 0;
    }

    getFactor(type, device) {
        // dividing tuya by 10 and multiplying onset by 1000
        if (device != "onset") {
            return 10;
        } else {
            return 0.001;
        }
    }
    normalizeTimeseries(data, cummulative = false) {
        const set = new Set();
        let res = [];
        data.forEach((device) => {
            device['series'].length > 0 && device['series'].forEach((dp) => set.add(dp.name));
        });
        const time_series = Array.from(set).sort(this.sortFunc);
        // console.log("timeseries found out ", time_series);
        data.forEach((device) => {
            let value = [];
            let prevValue = 0;
            time_series.forEach((timeData: number) => {
                const idx = device['series'].length > 0 ? device['series'].findIndex(x => x.name == timeData) : -1;
                value.push({ name: new Date(timeData), value: idx >= 0 ? device['series'][idx]['value'] : prevValue });
                if (cummulative) { prevValue = idx >= 0 ? device['series'][idx]['value'] : prevValue; }
            });
            res.push({ name: device['name'], series: value });
        });
        console.log("final data output ", res);
        return res;
    }

    sortFunc(x, y) {
        return x - y;
    }

    allSelectedCheck(type) {
        if (type == "tuyaFilters" && this.allTuyaSelected.selected) {
            return true;
        }
        else {
            return false;
        }
    }


}