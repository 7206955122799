import {
    Component, OnInit, ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {
    WeekService,
    View,
    MonthService,
    YearService,
    EventRenderedArgs,
    ScheduleComponent,
    EJ2Instance,
    ActionEventArgs,
    RenderCellEventArgs
} from '@syncfusion/ej2-angular-schedule';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { createElement } from '@syncfusion/ej2-base';
import { ManageSrComponent } from "src/app/common/component/sr-log/sr-log.component";
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { EventSettingsModel } from './events-settings-model';
import { Button } from '@syncfusion/ej2-buttons';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { MatDialog } from '@angular/material';
import { ViewTechinicianComponent } from './view-technician/view-technician.component';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { CreateSrModalComponent } from 'src/app/common/component/create-sr-modal/create-sr-modal/create-sr-modal.component';
import { UserService } from 'src/app/common/service/user-service/user.service';
@Component({
    selector: 'app-root',
    providers: [WeekService, MonthService, YearService],
    templateUrl: './amc-schedular.component.html',
    styleUrls: ['./amc-schedular.component.scss']
})
export class AmcSchedularComponent implements OnInit {
    title = 'shedulecomponents';
    @ViewChild("scheduleObj", { static: true })
    public scheduleObj: ScheduleComponent;
    public views: Array<string> = ['Week', 'Month', 'Year'];
    form: any;
    user: any;
    startDate: any;
    endDate: any;
    monthMap = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December",
    };

    reverseMonthMap = {
        Jan: "0",
        Feb: "1",
        Mar: "2",
        Apr: "3",
        May: "4",
        Jun: "5",
        Jul: "6",
        Aug: "7",
        Sep: "8",
        Oct: "9",
        Nov: "10",
        Dec: "11",
    };
    isSearch: Boolean = false;
    searchId = "";
    popUpOpenId: any;
    prevPopUpData: any = null;
    showHeaderBar: Boolean = true;
    confirmed: Boolean = false;
    nonConfirmed: Boolean = false;
    lessThan10: Boolean = false;
    moreThan10: Boolean = false;
    isTechnicianAssigned: Boolean = false;
    canEditSR = false;
    canCreateSR = false;
    hasEditAccess;
    isSalesPartner;
    datesLength = 0;
    scheduleView: View = 'Week';
    schedularData = [];
    clusterList = [];
    data = [];
    allData = [];
    searchData = [];
    holidayList = [];
    confirmedData = [];
    nonConfirmedData = [];
    occupiedData = [];
    dates = [];
    clusters = [];
    popUpData: any;
    popUpTarget: any;
    selectedValue: Date;
    eventSettings: EventSettingsModel = {
        allowDeleting: false,
        dataSource: this.data,
        fields: {
            id: 'id',
            subject: {
                name: 'name'
            },
            isAllDay: {
                name: 'isAllDay'
            },
            startTime: {
                name: 'startTime'
            },
            endTime: {
                name: 'endTime'
            },
            categoryColor: 'categoryColor'
        }
    };
    constructor(
        private menuToggle: MenuToggleService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private toast: MessageToastService,
        private amcService: AMCService,
        private userService: UserService,
        private clusterService: ClusterService,
        private authService: AuthService,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.selectedValue = new Date();
        this.selectedValue = new Date(this.datePipe.transform(this.selectedValue, 'yyyy, MM, dd'));
        console.log(this.selectedValue);
        this.menuToggle.toggleMenu(false);

        this.menuToggle.menuToggle.subscribe(val => {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 300);
            this.form = this.fb.group({
                day: [new Date()],
                month: [new Date()],
                year: [new Date()],
            });
        });

        this.user = JSON.parse(this.authService.getUser());
        this.canEditSR = this.userService.getEditSRRequest(this.user);
        this.canCreateSR = this.userService.getCreateSRAccess(this.user);

        this.getClusterDetails();
        this.fetchSRConfig();
    }

    ngViewInit() {
        let pageListener = document.body.addEventListener('click', function (e) {
            console.log("e target--------------->", e.target);
        });

    }


  fetchSRConfig() {
    this.amcService.getSRConfig().subscribe((res: any) => {
      if (res && res.status) {
        console.log("config found in amc", res.payload);
        localStorage.setItem("sr-config", JSON.stringify(res.payload));
      }
    });
  }

    viewTechnician() {
        let dialogRef = this.dialog.open(ViewTechinicianComponent, {
            data: {
                clusterList: this.clusters
            }
        });
        dialogRef.componentInstance.status.subscribe(data => {
            if (data == 'success') {
                console.log("success...");
            }
        });
    }


    technicianAssigned() {
        this.data = [];
        this.allData = [];
        this.confirmedData = [];
        this.nonConfirmedData = [];
        this.scheduleObj.eventSettings.dataSource = [];
        let params = {
            start_date: this.getTimestamp(1),
            end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60
        };
        this.getData(params);
    }

    onRenderCell(args: RenderCellEventArgs): void {
        if (this.scheduleView == 'Month') {
            let date = this.datePipe.transform(args.date, 'EEEE, MMMM d, y');
            for (var j = 0; j < this.holidayList.length; j++) {
                if (this.holidayList[j].date == date) {
                    args.element.classList.add('e-holiday-dates');
                    if (this.holidayList[j].type == 0) {
                        if (args.element['innerText'] != 'Monday' && args.element['innerText'] != 'Tuesday' && args.element['innerText'] != 'Wednesday' && args.element['innerText'] != 'Thursday' && args.element['innerText'] != 'Friday' && args.element['innerText'] != 'Saturday' && args.element['innerText'] != 'Sunday') {
                            args.element['innerText'] = args.element['innerText'] + " " + this.holidayList[j].name;
                        }
                    }
                    else if (this.holidayList[j].type == 2) {
                        if (args.element['innerText'] != 'Monday' && args.element['innerText'] != 'Tuesday' && args.element['innerText'] != 'Wednesday' && args.element['innerText'] != 'Thursday' && args.element['innerText'] != 'Friday' && args.element['innerText'] != 'Saturday' && args.element['innerText'] != 'Sunday') {
                            args.element['innerText'] = args.element['innerText'] + " " + this.holidayList[j].name + "(optional)";
                        }
                    }
                }
            }
        }
        //   }
        setTimeout(() => {
            let el: HTMLElement = <HTMLElement>(args.element.querySelector(".e-day"));
            if (el != null) {
                let title = el.title;
                for (var i = 0; i < this.dates.length; i++) {
                    let date = this.dates[i].dateCheck;
                    if (date == title) {
                        el.title = "Total Services: " + (this.dates[i].appointments.length).toString();
                        if (this.dates[i].appointments.length <= 10) {
                            el.style.borderBottom = "2px solid #FD6FF7";
                            el.style.marginBottom = "5px";
                        }
                        else if (this.dates[i].appointments.length > 10) {
                            el.style.borderBottom = "2px solid #8F5AFF";
                            el.style.marginBottom = "5px";
                        }
                    }
                }
                for (var j = 0; j < this.holidayList.length; j++) {
                    if (this.holidayList[j].date == title) {
                        if (this.holidayList[j].type == 1) {
                            el.style.color = "#dedede";
                        }
                        else if (this.holidayList[j].type == 0) {
                            if (el.title != this.holidayList[j].date) {
                                let old = el.title;
                                el.title = old + " (" + this.holidayList[j].name + ")";
                            }
                            else {
                                el.title = this.holidayList[j].name;
                            }
                            el.style.color = "#dedede";
                        }
                        else if (this.holidayList[j].type == 2) {
                            if (el.title != this.holidayList[j].date) {
                                let old = el.title;
                                el.title = old + " (" + this.holidayList[j].name + " (optional) )";
                            }
                            else {
                                el.title = this.holidayList[j].name + " (optional)";
                            }
                            el.style.color = "#dedede";
                        }
                    }
                }
            }
        }, 1000);
    }

    getHolidays() {
        let year = new Date().getFullYear();
        let stDate = year.toString() + ", 1, 1";
        let params = {
            start_date: Math.floor(new Date(stDate).getTime() / 1000),
            end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60
        };
        console.log("params holiday", params);
        this.amcService.getHolidayList(params).subscribe(result => {
            let res = result['payload']['data'];
            for (var i = 0; i < res.length; i++) {
                let date = new Date(res[i].date * 1000);
                res[i].date = this.datePipe.transform(date, 'EEEE, MMMM d, y');
            }
            this.holidayList = res;
            console.log("array holiday----->", this.holidayList);
        });
    }

    oneventRendered(args: EventRenderedArgs): void {
        // console.log("event rendered called", args);
        if (args.data.plan != null) {
            let row: HTMLElement = createElement("div", {
                className: "status-row",
                styles: "font-size: 12px; font-style: italic; display: flex; color: #656565",
                innerHTML: "<a>" + args.data.plan + "</a>"
            });
            let contentElement: HTMLElement = <HTMLElement>(
                args.element.querySelector(".e-subject")
            );
            contentElement.append(row);
        }
        let categoryColor: string = args.data['categoryColor'] as string;
        if (categoryColor == "#F59241") {
            args.element.style.backgroundColor = "rgb(255 230 209)";
            args.element.style.borderLeft = "5px solid #f59241";
            args.element.style.color = "#B45309";
        }
        else if (categoryColor == "#10B981") {
            args.element.style.backgroundColor = "rgb(197 235 223)";
            args.element.style.borderLeft = "5px solid #10B981";
            args.element.style.color = "#047857";
        }
    }

    onAppointmentEdit(args) {
        console.log("appointment edit-------->", args);
        args.cancel = true;
    }

    onPopupOpen(args) {
        if (args.target) {
            let isEmptyCell =
                args.target.classList.contains('e-work-cells') ||
                args.target.classList.contains('e-header-cells'); // checking whether the cell is empty or not 
            if ((args.type === 'QuickInfo' || args.type === 'Editor') && isEmptyCell) {
                args.cancel = true;
                return;
            }
        }
        this.popUpData = args.data;
        this.popUpTarget = args.target;
        if (this.popUpOpenId != args.data.id) {
            if (this.prevPopUpData != null) {
                this.onPopupClose(this.prevPopUpData);
            }
        }
        let categoryColor: string = args.data['categoryColor'] as string;
        if (categoryColor == "#F59241") {
            args.target.style.backgroundColor = "#f59241";
            args.target.style.borderLeft = "5px solid #f59241";
            args.target.style.color = "#fff";
            var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-edit" : ".e-schedule-dialog .e-event-edit";
            var editButton = document.querySelector(buttonElement);
            if (editButton && (editButton as EJ2Instance).ej2_instances) {
                ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
            }
            if (args.type === "QuickInfo") {
                if (
                    !args.element.querySelector(".status-row") &&
                    args.target.classList.contains("e-appointment")
                ) {
                    let variable = args.data.uuid;
                    if (this.canCreateSR) {
                        let row: HTMLElement = createElement("div", {
                            className: "status-row",
                            styles: "padding-top: 16px;font-size: 14px; display: flex",
                            innerHTML: "<a class='raiselink' style='color: #F59241'; >Raise Service Request</a>"
                        });
                        let contentElement: HTMLElement = <HTMLElement>(
                            args.element.querySelector(".e-popup-content")
                        );
                        contentElement.append(row);
                        let children = document.getElementsByClassName("raiselink");

                        for (let i = 0; i < children.length; i++) {
                            children[i].addEventListener("click", (event: Event) => {
                                console.log("args.data---> for create sr--->", args.data);
                                this.createSR(args.data);
                            });
                        }
                    }
                    else {
                        let row: HTMLElement = createElement("div", {
                            className: "status-row",
                            styles: "padding-top: 16px;font-size: 14px; display: flex",
                            innerHTML: "<a style='color: #cccccc'; >Raise Service Request</a>"
                        });
                        let contentElement: HTMLElement = <HTMLElement>(
                            args.element.querySelector(".e-popup-content")
                        );
                        contentElement.append(row);
                    }
                }
            }
        }
        else if (categoryColor == "#10B981") {
            args.target.style.backgroundColor = "#10B981";
            args.target.style.borderLeft = "5px solid #10B981";
            args.target.style.color = "#fff";
            var buttonElement = args.type === "QuickInfo" ? ".e-event-popup .e-edit" : ".e-schedule-dialog .e-event-edit";
            var editButton = document.querySelector(buttonElement);
            if (editButton && (editButton as EJ2Instance).ej2_instances) {
                ((editButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
            }
        }
        if (args.type === "QuickInfo") {
            if (args.element.querySelector('.e-popup-header')) {
                if (args.data.plan != null) {
                    let row2: HTMLElement = createElement("div", {
                        className: "name-row",
                        styles: "padding: 0px 25px; font-size: 12px; display: flex; font-weight: 500",
                        innerHTML: "<a>" + args.data.eventName + " (" + args.data.plan + ")" + "</a>"
                    });
                    let contentElement2: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement2.append(row2);
                }
                else {
                    let row2: HTMLElement = createElement("div", {
                        className: "name-row",
                        styles: "padding: 0px 25px; font-size: 12px; display: flex; font-weight: 500",
                        innerHTML: "<a>" + args.data.eventName + "</a>"
                    });
                    let contentElement2: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement2.append(row2);
                }

                if (args.data.ticketId != null) {
                    let row1: HTMLElement = createElement("div", {
                        className: "ticket-row",
                        styles: "padding: 5px 25px 0px; font-size: 12px; display: flex; font-weight: 500",
                        innerHTML: "<a style='text-transform: Capitalize;'>Ticket Details: </a>"
                    });
                    let contentElement1: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement1.append(row1);
                    let row: HTMLElement = createElement("div", {
                        className: "ticket-row",
                        styles: "padding: 0px 25px; font-size: 12px; display: flex;",
                        innerHTML: "<a style='text-transform: Capitalize;'>Identifier: " + args.data.ticketId + "</a>"
                    });
                    let contentElement: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement.append(row);
                    let row11: HTMLElement = createElement("div", {
                        className: "ticket-row",
                        styles: "padding: 0px 25px; font-size: 12px; display: flex;",
                        innerHTML: "<a style='text-transform: Capitalize;'>Status: " + args.data.ticketStatus + "</a>"
                    });
                    let contentElement11: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement11.append(row11);
                    let row12: HTMLElement = createElement("div", {
                        className: "ticket-row",
                        styles: "padding: 0px 25px 5px; font-size: 12px; display: flex;",
                        innerHTML: "<a style='text-transform: Capitalize;'>Type: " + args.data.ticketType + "</a>"
                    });
                    let contentElement12: HTMLElement = <HTMLElement>(
                        args.element.querySelector(".e-popup-header")
                    );
                    contentElement12.append(row12);
                }

                let row3: HTMLElement = createElement("div", {
                    className: "location-row",
                    styles: "padding: 5px 25px; font-size: 12px; display: flex; align-items: center;",
                    innerHTML: "<i class='fa fa-map-marker' style='padding: 3px 17px 3px 0px; color: #f39241; font-size: 22px; 'aria-hidden='true'></i>" + "<a>" + args.data.location + "</a>"
                });
                let contentElement3: HTMLElement = <HTMLElement>(
                    args.element.querySelector(".e-popup-header")
                );
                contentElement3.append(row3);
            }
            if (
                !args.element.querySelector(".status-row") &&
                args.target.classList.contains("e-appointment")
            ) {
                let variable = args.data.uuid;
                let row: HTMLElement = createElement("div", {
                    className: "status-row",
                    styles: "padding-top: 16px;font-size: 14px; display: flex",
                    innerHTML: "<a class='mylink' style='color: #f39241;'>View Service Request</a>"
                });
                let contentElement: HTMLElement = <HTMLElement>(
                    args.element.querySelector(".e-popup-content")
                );
                contentElement.append(row);
                let children = document.getElementsByClassName("mylink");

                for (let i = 0; i < children.length; i++) {
                    children[i].addEventListener("click", (event: Event) => {
                        this.openSRLog(args.data);
                    });
                }
            }
            this.popUpOpenId = args.data.id;
        }
        // if (args.type === 'Editor') {
        //     let el1: HTMLElement = args.element.querySelector('.e-all-day-time-zone-row');
        //     el1.style.display = 'none';
        //     let el2: HTMLElement = args.element.querySelector('.e-control .e-recurrenceeditor .e-lib');
        //     el2.style.display = 'none';
        //     let el3: HTMLElement = args.element.querySelector('.e-title-location-row');
        //     el3.style.display = 'none';
        //     let el5: HTMLElement = args.element.querySelector('.e-event-delete');
        //     el5.style.display = 'none';
        //     let el6: HTMLElement = args.element.querySelector('.e-description-row');
        //     el6.style.display = 'none';
        //     if (args.element.querySelector('.e-dlg-header')) {
        //         let el: HTMLElement = args.element.querySelector('.e-title-text');
        //         el.style.display = 'none';
        //         const customProp = document.querySelector('div#custom-prop.name-row');
        //         if (customProp) { return; }
        //         let row2: HTMLElement = createElement("div", {
        //             id: 'custom-prop',
        //             className: "name-row",
        //             styles: "padding-top: 16px;font-size: 12px; display: flex; text-transform: uppercase;",
        //             innerHTML: "<a style=' font-weight: 600;'>" + args.data.name + "</a>"
        //         });
        //         let contentElement2: HTMLElement = <HTMLElement>(
        //             args.element.querySelector(".e-dlg-header")
        //         );
        //         contentElement2.append(row2);
        //         if (args.data.plan != null) {
        //             let row: HTMLElement = createElement("div", {
        //                 className: "title-row",
        //                 styles: "padding-top: 5px ;font-size: 14px; display: flex;",
        //                 innerHTML: "<a style='color: #656564;' >" + args.data.eventName + " (" + args.data.plan + ")" + "</a>"
        //             });
        //             let contentElement: HTMLElement = <HTMLElement>(
        //                 args.element.querySelector(".e-dlg-header")
        //             );
        //             contentElement.append(row);
        //         }
        //         else {
        //             let row: HTMLElement = createElement("div", {
        //                 className: "title-row",
        //                 styles: "padding-top: 5px ;font-size: 14px; display: flex;",
        //                 innerHTML: "<a style='color: #656564;' >" + args.data.eventName + "</a>"
        //             });
        //             let contentElement: HTMLElement = <HTMLElement>(
        //                 args.element.querySelector(".e-dlg-header")
        //             );
        //             contentElement.append(row);
        //         }

        //         let row3: HTMLElement = createElement("div", {
        //             className: "location-row",
        //             styles: "padding-top: 5px; display: flex; text-transform: capitalize; align-items: center;",
        //             innerHTML: "<i class='fa fa-map-marker' style='padding: 3px 5px 3px 0px; color: #f39241;' aria-hidden='true'></i>" + "<a style='color: #656564;'>" + args.data.location + "</a>"
        //         });
        //         let contentElement3: HTMLElement = <HTMLElement>(
        //             args.element.querySelector(".e-dlg-header")
        //         );
        //         contentElement3.append(row3);
        //     }
        //     // if (document.getElementById("_dialog_wrapper")) {
        //         // console.log("startElement---->", document.getElementById("startTime"))
        //         let startElement: HTMLInputElement= document.getElementById("startTime") as HTMLInputElement;
        //         if (startElement.classList.contains('e-datetimepicker')) {
        //             console.log("startElement.value---->", startElement.value);
        //             console.log("startElement---->", startElement);
        //             // console.log("new DateTimePicker({ value: new Date(startElement.value) || new Date() }, startElement);---->", new DateTimePicker({ value: new Date(startElement.value) || new Date() }, startElement));x
        //             // new DateTimePicker({ value: new Date(startElement.value) || new Date() }, startElement);

        //         }
        //     // }

        // }
        this.prevPopUpData = args;
    }

    createSR(event: any) {
        let dialogRef = this.dialog.open(CreateSrModalComponent, {
            data: {
                property_uuid: event.uuid,
                cluster_uuid: event.cluster.uuid,
                label: 'Documents'
            }
        });
        dialogRef.componentInstance.status.subscribe(data => {
            if (data == 'success') {
                console.log("refreshing..");
                this.searchId = '';
                this.data = [];
                this.allData = [];
                this.confirmedData = [];
                this.nonConfirmedData = [];
                this.scheduleObj.eventSettings.dataSource = [];
                this.confirmed = false;
                this.nonConfirmed = false;
                this.lessThan10 = false;
                this.moreThan10 = false;
                let params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                };
                this.getData(params);
            }
        });
    }

    openSRLog(event: any) {
        let dialogRef = this.dialog.open(ManageSrComponent, {
            data: {
                path: '/' + event.eventName + '/amc/',
                property_uuid: event.uuid,
                ticket_uuid: event.ticketUuid,
                label: 'Documents',
                cluster: event.cluster,
                can_edit: this.canEditSR
            }
        });
        dialogRef.componentInstance.status.subscribe(data => {
            if (data == 'success') {
                this.searchId = '';
                this.data = [];
                this.allData = [];
                this.confirmedData = [];
                this.nonConfirmedData = [];
                this.scheduleObj.eventSettings.dataSource = [];
                this.confirmed = false;
                this.nonConfirmed = false;
                this.lessThan10 = false;
                this.moreThan10 = false;
                let params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                };
                this.getData(params);
            }
        });
    }

    onPopupClose(args) {
        if (args.data == undefined) {
            args.data = this.popUpData;
        }
        if (args.target == undefined) {
            args.target = this.popUpTarget;
        }
        if (args.data.name == "Add title") {
            args.data = this.popUpData;
            args.target = this.popUpTarget;
        }
        let categoryColor: string = args.data['categoryColor'] as string;
        if (categoryColor == "#F59241") {
            args.target.style.backgroundColor = "rgb(255 230 209)";
            args.target.style.borderLeft = "5px solid #f59241";
            args.target.style.color = "#B45309";
        }
        else if (categoryColor == "#10B981") {
            args.target.style.backgroundColor = "rgb(197 235 223)";
            args.target.style.borderLeft = "5px solid #10B981";
            args.target.style.color = "#047857";
        }
    }

    onCreated(): void {
        this.startDate = new Date(this.getTimestamp(1) * 1000);
        this.endDate = new Date(this.getTimestamp(1) * 1000 + 365 * 5 * 24 * 60 * 60 * 1000);
        console.log("start and end date", this.startDate, this.endDate);
        console.log(this.startDate);
        console.log(this.endDate);
    }

    getEventData(obj) {
        this.selectedValue = obj.startTime;
        this.scheduleObj.eventSettings.dataSource = this.data;
    }

    getClusterDetails() {
        let params = {};
        this.clusterService.getClusterList().subscribe((result: any) => {
            if (result && result.status) {
                console.log("cluster list result", result);
                result.payload.clusters.forEach(element => {
                    this.clusterList.push({
                        label: element.identifier,
                        value: element.uuid
                    });
                    this.clusters.push(element.uuid);
                });
            }
            if (this.user['user_type'] == 'amplus_ops_manager') {
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                    cluster_id_list: this.clusters
                };
            }
            else {
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                };
            }
            this.getData(params);
            this.getHolidays();
        });
    }

    clusterFilter() {
        this.searchId = '';
        this.data = [];
        this.allData = [];
        this.confirmedData = [];
        this.nonConfirmedData = [];
        this.scheduleObj.eventSettings.dataSource = [];
        let params = {};
        if (this.clusters.length > 0) {
            if (this.confirmed && !this.nonConfirmed) {
                this.confirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: true,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
            else if (!this.confirmed && this.nonConfirmed) {
                this.nonConfirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: false,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
            else {
                this.allData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
        }
        else {
            params = {
                start_date: this.getTimestamp(1),
                end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                is_scheduled: null,
            };
            this.getData(params);
        }
    }

    onActivate(event) {
        setTimeout(() => {
            window.open('/lead/view/amc?id=' + event);
        }, 300);
    }

    occupiedFilter() {
        this.data = [];
        if (this.lessThan10 && !this.moreThan10) {
            this.occupiedData = [];
            for (var i = 0; i < this.dates.length; i++) {
                let date = this.dates[i].dateCheck;
                if (this.dates[i].appointments.length <= 10) {
                    let appointment = this.dates[i].appointments;
                    let obj = {
                        date: this.dates[i].date,
                        dateCheck: this.dates[i].dateCheck
                    };

                    for (var j = 0; j < appointment.length; j++) {
                        obj['startTime'] = appointment[j].startTime;
                        obj['endTime'] = appointment[j].endTime;
                        obj['uuid'] = appointment[j].uuid;
                        obj['name'] = appointment[j].name;
                        obj['plan'] = appointment[j].plan;
                        obj['amc'] = appointment[j].amc;
                        obj['ticketId'] = appointment[j].ticketId;
                        obj['ticketUuid'] = appointment[j].ticketUuid;
                        obj['ticketStatus'] = appointment[j].ticketStatus;
                        obj['location'] = appointment[j].location;
                        obj['id'] = appointment[j].id;
                        obj['eventName'] = appointment[j].eventName;
                        obj['categoryColor'] = appointment[j].categoryColor;
                        obj['isScheduled'] = appointment[j].isScheduled;
                        this.occupiedData.push(obj);
                    }
                }
            }
        }
        else if (this.moreThan10 && !this.lessThan10) {
            this.occupiedData = [];
            for (var i = 0; i < this.dates.length; i++) {
                let date = this.dates[i].dateCheck;
                if (this.dates[i].appointments.length > 10) {
                    let appointment = this.dates[i].appointments;
                    let obj = {
                        date: this.dates[i].date,
                        dateCheck: this.dates[i].dateCheck
                    };
                    for (var j = 0; j < appointment.length; j++) {
                        obj['startTime'] = appointment[j].startTime;
                        obj['endTime'] = appointment[j].endTime;
                        obj['uuid'] = appointment[j].uuid;
                        obj['name'] = appointment[j].name;
                        obj['plan'] = appointment[j].plan;
                        obj['amc'] = appointment[j].amc;
                        obj['ticketId'] = appointment[j].ticketId;
                        obj['ticketUuid'] = appointment[j].ticketUuid;
                        obj['ticketStatus'] = appointment[j].ticketStatus;
                        obj['location'] = appointment[j].location;
                        obj['id'] = appointment[j].id;
                        obj['eventName'] = appointment[j].eventName;
                        obj['categoryColor'] = appointment[j].categoryColor;
                        obj['isScheduled'] = appointment[j].isScheduled;
                        this.occupiedData.push(obj);
                    }
                }
            }
        }
        else {
            this.occupiedData = [];
            this.allData = [];
        }
        console.log("this.lessThan10Data", this.occupiedData);
        let params = {
            start_date: this.getTimestamp(1),
            end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
            is_scheduled: null,
        };
        this.getData(params);
    }

    checkFilter() {
        this.searchId = '';
        this.data = [];
        this.selectedValue = new Date();
        this.selectedValue = new Date(this.datePipe.transform(this.selectedValue, 'yyyy, MM, dd'));
        console.log(this.selectedValue);
        let params = {};
        if (this.clusters.length > 0) {
            if (this.confirmed && !this.nonConfirmed) {
                this.confirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: true,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
            else if (!this.confirmed && this.nonConfirmed) {
                this.nonConfirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: false,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
            else {
                this.allData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                    cluster_id_list: this.clusters
                };
                this.getData(params);
            }
        }
        else {
            if (this.confirmed && !this.nonConfirmed) {
                this.confirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: true
                };
                this.getData(params);
            }
            else if (!this.confirmed && this.nonConfirmed) {
                this.nonConfirmedData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: false
                };
                this.getData(params);
            }
            else {
                this.allData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    is_scheduled: null,
                };
                this.getData(params);
            }
        }
    }

    getData(params) {
        let dataArray = [];
        let dateList = [];
        let dateArray = [];
        this.amcService.getAMCSchedular(params).subscribe((res: any) => {
            if (res && res.status) {
                if (res.payload.data.length > 0) {
                    let color = "#cccccc";
                    this.schedularData = res.payload.data;
                    for (let i = 0; i < this.schedularData.length; i++) {
                        if (this.schedularData[i].is_scheduled == true) {
                            color = "#10B981";
                        }
                        else {
                            color = "#F59241";
                        }
                        var obj = {
                            id: this.schedularData[i].uuid,
                            uuid: this.schedularData[i].lead_property.uuid,
                            plan: this.schedularData[i].property_amc ? this.schedularData[i].property_amc.plan.name : null,
                            amcUuid: this.schedularData[i].property_amc ? this.schedularData[i].property_amc.uuid : null,
                            name: this.schedularData[i].lead_property.lead.first_name + " " + this.schedularData[i].lead_property.lead.last_name,
                            location: this.schedularData[i].lead_property ? this.schedularData[i].lead_property.address : "",
                            cluster: this.schedularData[i].lead_property ? this.schedularData[i].lead_property.cluster : {},
                            eventName: this.schedularData[i].lead_property.identifier,
                            date: new Date(this.schedularData[i].start_time * 1000),
                            isTechnician: this.schedularData[i].is_technician_assigned,
                            ticketId: this.schedularData[i].ticket ? this.schedularData[i].ticket.identifier : null,
                            ticketUuid: this.schedularData[i].ticket ? this.schedularData[i].ticket.uuid : null,
                            ticketStatus: this.schedularData[i].ticket ? this.schedularData[i].ticket.status.replace('_', ' ') : null,
                            ticketType: this.schedularData[i].ticket ? this.schedularData[i].ticket.type.replace('_', ' ') : null,
                            startTime: new Date(this.schedularData[i].start_time * 1000),
                            endTime: new Date(this.schedularData[i].end_time * 1000),
                            categoryColor: color,
                            isScheduled: this.schedularData[i].is_scheduled
                        }
                        var dateObj = {
                            id: this.schedularData[i].uuid,
                            uuid: this.schedularData[i].lead_property.uuid,
                            plan: this.schedularData[i].property_amc ? this.schedularData[i].property_amc.plan.name : null,
                            amcUuid: this.schedularData[i].property_amc ? this.schedularData[i].property_amc.uuid : null,
                            name: this.schedularData[i].lead_property.lead.first_name + " " + this.schedularData[i].lead_property.lead.last_name,
                            location: this.schedularData[i].lead_property ? this.schedularData[i].lead_property.address : "",
                            cluster: this.schedularData[i].lead_property ? this.schedularData[i].lead_property.cluster : {},
                            eventName: this.schedularData[i].lead_property.identifier,
                            ticketId: this.schedularData[i].ticket ? this.schedularData[i].ticket.identifier : null,
                            ticketUuid: this.schedularData[i].ticket ? this.schedularData[i].ticket.uuid : null,
                            ticketStatus: this.schedularData[i].ticket ? this.schedularData[i].ticket.status.replace('_', ' ') : null,
                            ticketType: this.schedularData[i].ticket ? this.schedularData[i].ticket.type.replace('_', ' ') : null,
                            categoryColor: color,
                            isScheduled: this.schedularData[i].is_scheduled,
                            isTechnician: this.schedularData[i].is_technician_assigned,
                            dateCheck: this.datePipe.transform(this.schedularData[i].start_time * 1000, 'EEEE, MMMM d, y'),
                            date: this.datePipe.transform(this.schedularData[i].start_time * 1000, 'EEEE dd/MM/yyyy'),
                            startTime: this.schedularData[i].start_time * 1000,
                            endTime: this.schedularData[i].end_time * 1000
                        };
                        dateList.push(dateObj);
                        if (this.confirmed == true && this.nonConfirmed == false) {
                            this.confirmedData.push(obj);
                        }
                        else if (this.nonConfirmed == true && this.confirmed == false) {
                            this.nonConfirmedData.push(obj);
                        }
                        else {
                            this.allData.push(obj);
                            if (this.isTechnicianAssigned) {
                                let data = [];
                                let datelist = [];
                                for (var date = 0; date < dateList.length; date++) {
                                    if (dateList[date].isTechnician) {
                                        datelist.push(dateList[date]);
                                    }
                                }
                                console.log("isTechnicianAssigned=================-------------->", this.isTechnicianAssigned);
                                for (var item = 0; item < this.allData.length; item++) {
                                    if (this.allData[item].isTechnician) {
                                        data.push(this.allData[item]);
                                    }
                                }
                                dateList = datelist;
                                console.log("dateList------------->", dateList);
                                this.allData = data;
                                console.log("all=================-------------->", this.allData);
                            }
                        }

                    }
                    for (let i = 0; i < dateList.length; i++) {
                        let obj = {
                            date: dateList[i].date,
                            dateCheck: dateList[i].dateCheck,
                            appointments: [{
                                startTime: dateList[i].startTime,
                                endTime: dateList[i].endTime,
                                uuid: dateList[i].uuid,
                                name: dateList[i].name,
                                plan: dateList[i].plan,
                                amc: dateList[i].amc,
                                ticketId: dateList[i].ticketId,
                                ticketUuid: dateList[i].ticketUuid,
                                ticketStatus: dateList[i].ticketStatus,
                                ticketType: dateList[i].ticketType,
                                location: dateList[i].location,
                                cluster: dateList[i].cluster,
                                id: dateList[i].id,
                                eventName: dateList[i].eventName,
                                categoryColor: dateList[i].categoryColor,
                                isScheduled: dateList[i].isScheduled
                            }]
                        };
                        for (let j = 1; j < dateList.length; j++) {
                            if (dateList[i].date == dateList[j].date) {
                                let newObj = {
                                    startTime: dateList[j].startTime,
                                    endTime: dateList[j].endTime,
                                    uuid: dateList[j].uuid,
                                    name: dateList[j].name,
                                    plan: dateList[j].plan,
                                    amc: dateList[j].amc,
                                    ticketId: dateList[j].ticketId,
                                    ticketUuid: dateList[j].ticketUuid,
                                    ticketStatus: dateList[j].ticketStatus,
                                    ticketType: dateList[j].ticketType,
                                    location: dateList[j].location,
                                    cluster: dateList[j].cluster,
                                    id: dateList[j].id,
                                    eventName: dateList[j].eventName,
                                    categoryColor: dateList[j].categoryColor,
                                    isScheduled: dateList[j].isScheduled
                                }
                                obj.appointments.push(newObj);
                            }
                            obj.appointments = obj.appointments.filter((thing, index) => {
                                const _apo = JSON.stringify(thing);
                                return index === obj.appointments.findIndex(ob => {
                                    return JSON.stringify(ob) === _apo;
                                });
                            });
                        }
                        dateArray.push(obj);
                    }
                    this.dates = dateArray.filter((thing, index) => {
                        const _date = JSON.stringify(thing.date);
                        return index === dateArray.findIndex(obj => {
                            return JSON.stringify(obj.date) === _date;
                        });
                    });
                    this.datesLength = this.dates.length;
                    if (this.confirmed == true && this.nonConfirmed == false) {
                        this.data = this.confirmedData;
                    }
                    else if (this.nonConfirmed == true && this.confirmed == false) {
                        this.data = this.nonConfirmedData;
                    }
                    else if ((!this.lessThan10 && this.moreThan10) || (this.lessThan10 && !this.moreThan10)) {
                        this.data = this.occupiedData;
                        dateArray = [];
                        if (!this.lessThan10 && this.moreThan10) {
                            for (var i = 0; i < this.dates.length; i++) {
                                if (this.dates[i].appointments.length > 10) {
                                    dateArray.push(this.dates[i]);
                                }
                            }
                            this.dates = dateArray;
                            console.log("dateArray-->", dateArray);
                            console.log("dateArray-->", this.dates);
                        }
                        else if (this.lessThan10 && !this.moreThan10) {
                            for (var i = 0; i < this.dates.length; i++) {
                                if (this.dates[i].appointments.length < 11) {
                                    dateArray.push(this.dates[i]);
                                }
                            }
                            this.dates = dateArray;
                        }
                        this.datesLength = this.dates.length;
                        if (this.scheduleView == 'Year') {
                            document.getElementById("e-tbr-btn_5").click();
                            document.getElementById("e-tbr-btn_6").click();
                        }
                    }
                    else {
                        this.data = this.allData;
                        console.log("----------here--------------");
                        if (this.scheduleView == 'Year') {
                            document.getElementById("e-tbr-btn_5").click();
                            document.getElementById("e-tbr-btn_6").click();
                        }
                    }
                    console.log("...this.data", this.data);
                    this.scheduleObj.eventSettings.dataSource = [...this.data];
                    console.log("...this.scheduleObj.eventSettings.dataSource", this.scheduleObj.eventSettings.dataSource);
                    let today = this.datePipe.transform(new Date(), 'EEEE dd/MM/yyyy');
                    if (this.dates.length > 0 && this.dates[0].date == today) {
                        this.dates[0].date = "Today " + this.datePipe.transform(new Date(), 'dd/MM/yyyy');
                    }
                }
                else {
                    this.dates = [];
                    this.datesLength = this.dates.length;
                    this.scheduleObj.eventSettings.dataSource = [];
                }
            }
        });
    }

    //To get the start and end dates of current view and current Date range
    onActionComplete(args: EventRenderedArgs): void {
        console.log("args--->", args);
        if (args['requestType'] == 'eventChange') {
            let params = {
                schedule_uuid: args.data['id'],
                start_time: Math.floor(args.data['startTime'].getTime() / 1000)
            }
            this.amcService.updateAMCSchedular(params).subscribe(res => {
                if (res && res["status"]) {
                    this.toast.success("EVENT UPDATED SUCCESSFULLY");
                    this.checkFilter();
                }
            })
        }
    }

    getTimestamp(option) {
        let timestamp = 0;
        if (option == 1) {
            let current = this.form.get("day").value;
            current.setHours(0);
            current.setMinutes(0);
            current.setSeconds(0);
            current.setMilliseconds(0);
            timestamp = Math.floor(current.getTime() / 1000);
        } else if (option == 2) {
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("month").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("month").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
            console.log("final time object");
            console.log(timestamp);
        } else if (option == 3) {
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("year").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("year").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
        }
        return timestamp;
    }

    onActionBegin(args: ActionEventArgs): void {
        console.log("on action begins-->", args);
        if (args.requestType == 'eventChange') {
            let params = {
                schedule_uuid: args.data['id'],
                start_time: Math.floor(args.data['startTime'].getTime() / 1000)
            }
            this.amcService.updateAMCSchedular(params).subscribe(res => {
                if (res && res["status"]) {
                    this.toast.success("EVENT UPDATED SUCCESSFULLY");
                    this.checkFilter();
                }
            })
        }
    }

    search(event) {
        if (event.length > 2) {
            this.confirmed = false;
            this.nonConfirmed = false;
            let params = {};
            if (this.clusters.length > 0) {
                if (this.searchId) {
                    this.allData = [];
                    params = {
                        start_date: this.getTimestamp(1),
                        end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                        text: event,
                        cluster_id_list: this.clusters
                    };
                    this.getData(params);
                }
                else {
                    this.allData = [];
                    params = {
                        start_date: this.getTimestamp(1),
                        end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                        cluster_id_list: this.clusters
                    };
                    this.getData(params);
                }
            }
            else {
                this.allData = [];
                params = {
                    start_date: this.getTimestamp(1),
                    end_date: this.getTimestamp(1) + 365 * 5 * 24 * 60 * 60,
                    text: event
                };
                this.getData(params);
            }
        }
    }
}  