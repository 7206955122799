import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {
  @Output() option: EventEmitter<any> = new EventEmitter();
  form: any;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddDepartmentComponent>) { }

  ngOnInit() {
    this.form = this.fb.group({
      dep_name: ["", Validators.required]
    });
  }

  add() {
    this.option.emit({status: 'success', value: this.form.get('dep_name').value});
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

}
