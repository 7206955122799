import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AccountService } from "src/app/common/service/account/account.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";

@Component({
  selector: "app-partner-management",
  templateUrl: "./partner-management.component.html",
  styleUrls: ["./partner-management.component.scss"]
})
export class PartnerManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode =  false;
  isCollapsed = false;
  clusterList = [];
  partnerList = [];
  partnerListDetails = [];
  form: FormGroup;
  formValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      country_code: ["91"],
      phone_number: ["", Validators.required],
      pan_card: [""],
      is_sales: [true],
      is_epc: [false],
      aadhar_card: [""],
      bank_name: [""],
      bank_branch: [""],
      bank_account_type: ["Saving"],
      bank_account_number: [""],
      bank_account_ifsc: [""],
      company_name: [""],
      gst_number: [""],
      email_id: [""],
      city: [""],
      district: [""],
      pincode: [""],
      partner_code: [""],
      address: ""
    });
    // this.getPartnerDetails();

    this.form.valueChanges.subscribe(() => {
      this.formValid = <boolean>(this.form.valid && this.checkPartnerType());
    });
  }

  submit() {
    if (this.form.valid) {
      console.log('sending form data to new api');
      console.log(this.form.value);
      if (this.isEditMode) {
        this.accountService
          .updatePartnerUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("PARTNER UPDATED SUCCESSFULLY");
              // this.getUserDetails();
              // this.getPartnerDetails();
            }
          });
      } else {
        this.accountService
          .savePartnerUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("PARTNER CREATED SUCCESSFULLY");
              // this.getUserDetails();
              // this.getPartnerDetails();
            }
          });
      }
    }
  }

  editAccount(row) {
    console.log('printing the received address');
    console.log(row);
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      country_code: row.country_code,
      phone_number: row.phone_number,
      pan_card: row.pan_card,
      is_sales: row.is_sales,
      is_epc: row.is_epc,
      aadhar_card: row.aadhar_card,
      bank_name: row.bank_name,
      bank_branch: row.bank_branch,
      bank_account_type: row.bank_account_type,
      bank_account_number: row.bank_account_number,
      bank_account_ifsc: row.bank_account_ifsc,
      company_name: row.company_name,
      gst_number: row.gst_number,
      email_id: row.email_id,
      city: row.city,
      district: row.district,
      pincode: row.pincode,
      partner_code: row.partner_code,
      address: row.address
    });
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.accountService
      .deletePartnerUserAccount(row.uuid)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.reset();
          this.toast.success("PARTNER DELETED SUCCESSFULLY");
          // this.getUserDetails();
          // this.getPartnerDetails();
        }
      });
      }});
  }

  reset() {
    this.form.reset();
    this.form.patchValue({
      uuid: [null],
      name: "",
      country_code: "91",
      phone_number: "",
      pan_card: "",
      is_sales: true,
      is_epc: false,
      aadhar_card: "",
      bank_name: "",
      bank_branch: "",
      bank_account_type: "Saving",
      bank_account_number: "",
      bank_account_ifsc: "",
      company_name: "",
      gst_number: "",
      email_id: "",
      city: "",
      district: "",
      pincode: "",
      partner_code: "",
      address: ""
    });
    this.form.get("country_code").setValue("91");
    this.form.get("bank_account_type").setValue("Saving");

    //added this
    this.isEditMode = false;
  }

  // getPartnerDetails() {
  //   this.accountService.getPartnerAccountList().subscribe((result: any) => {
  //     if (result && result.status) {
  //       let data = result.payload.data;
  //       data.forEach(val => {
  //         val['partner_type'] = this.getPartnerType(val);
  //       });
  //       this.partnerList = data;
  //       this.partnerListDetails = data;
  //       console.log("counter of partner list");
  //       this.offset = 0;
  //       console.log(result.payload);
  //     }
  //   });
  // }

  onPage(event) {
    console.log(event);
    const start = event.offset * this.limit;
    const end = start + this.limit;
    const rows = [];
    this.offset = event.offset;
    let index = 0;
    for (let i = start; i < end; i++) {
      rows[index] = this.partnerListDetails[i];
      index++;
    }
    console.log(rows);

    this.partnerList = rows;
    //this.partnerList = [...this.partnerListDetails];
  }

  checkPartnerType(): boolean {
    if (
      this.form.get("is_sales").value == true ||
      this.form.get("is_epc").value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  getPartnerType(row) {
    let text = '';
    if(row.is_epc == 1) {
      text += 'EPC Partner';
    }
    if(row.is_sales == 1) {
      if(text != '') { text += ' , '}
      text += 'Sales Partner';
    }

    return text;
  }
}
