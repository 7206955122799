import { Component, OnInit, Input } from '@angular/core';
import { ActivityLogService } from '../../service/activity-log/activity-log.service';
import { MessageToastService } from '../../service/toast/message-toast.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  type = '';
  type_icon: any;
  created_on_date: any;
  scheduled_on_date: any;
  created_by_name: String;
  created_by_identifier: String = '';
  reminder_status: String;
  reminder_status_icon: String;
  reminder_type: String;
  reminder_priority: String = 'High';
  reminder_due_date: any;
  reminder_assigned_to: String = '';
  note: any;
  call_outcome: String = '';
  logged_on: any = '';
  log_date: any;
  log_time: any;

  call_outcome_map = {
    no_answer: 'No Answer',
    busy: 'Busy',
    wrong_number: 'Wrong Number',
    connected: 'Connected'
  };

  @Input() log_data: any;
  constructor(
    private activityLogService: ActivityLogService,
    private toast: MessageToastService
  ) {}

  ngOnInit() {
    console.log('data found out');
    console.log(this.log_data);
    this.type =
      this.log_data.scheduled_on == null
        ? this.log_data.log_type.toLowerCase()
        : 'reminder';
    if (this.type == 'log') {
      this.type = 'note';
    }

    console.log('type of this card ' + this.type);
    if (this.type == 'reminder') {
      this.reminder_type = this.log_data.log_type;
      if(this.reminder_type == 'log') {
        this.reminder_type = 'note';
      }
      this.reminder_status =
        this.log_data.is_completed == 1 ? 'Completed' : 'Pending';
      this.reminder_status_icon =
        this.reminder_status == 'Completed'
          ? 'assets/images/completed_icon.png'
          : 'assets/images/pending_icon.png';
      this.reminder_priority = this.getPriority(this.log_data.priority);
      this.reminder_due_date = new Date(this.log_data.scheduled_on * 1000);
      if (Object.keys(this.log_data.assigned_user_data).length != 0) {
        this.reminder_assigned_to = this.log_data.assigned_user_data.name;
        if (
          this.log_data.assigned_user_data.email &&
          this.log_data.assigned_user_data.email != ''
        ) {
          this.reminder_assigned_to +=
            ' (' + this.log_data.assigned_user_data.email + ')';
        } else if (
          this.log_data.assigned_user_data.phone_number &&
          this.log_data.assigned_user_data.phone_number != ''
        ) {
          this.reminder_assigned_to +=
            ' (' + this.log_data.assigned_user_data.phone_number + ')';
        }
      }
      this.getIcon(this.reminder_type);
    } else {
      this.getIcon(this.type);
      if (this.log_data.logged_on && this.log_data.logged_on != '') {
        this.logged_on = new Date(this.log_data.logged_on * 1000);
      }
    }

    this.created_on_date = new Date(this.log_data.created_on * 1000);
    this.scheduled_on_date = new Date(this.log_data.scheduled_on * 1000);
    if (Object.keys(this.log_data.user_data).length != 0) {
      this.created_by_name = this.log_data.user_data.name;
      if (
        this.log_data.user_data.email &&
        this.log_data.user_data.email != ''
      ) {
        this.created_by_identifier +=
          ' (' + this.log_data.user_data.email + ')';
      } else if (
        this.log_data.user_data.phone_number &&
        this.log_data.user_data.phone_number != ''
      ) {
        this.created_by_identifier +=
          ' (' + this.log_data.user_data.phone_number + ')';
      }
    }
    if (Object.keys(this.log_data.log_data).length != 0) {
      if (this.log_data.log_data.call_outcome) {
        this.call_outcome = this.call_outcome_map[
          this.log_data.log_data.call_outcome
        ];
      }
    }
    this.note = this.log_data.note;
  }

  getPriority(val) {
    switch (val) {
      case 0:
        return 'Low';
      case 1:
        return 'Medium';
      case 2:
        return 'High';
      default:
        return 'Medium';
    }
  }

  getIcon(type) {
    switch (type) {
      case 'whatsapp':
        this.type_icon = 'assets/images/whatsapp_icon.png';
        break;
      case 'meeting':
        this.type_icon = 'assets/images/meeting_icon.png';
        break;
      case 'email':
        this.type_icon = 'assets/images/email_icon.png';
        break;
      case 'log':
        this.type_icon = 'assets/images/log_icon.png';
        break;
      case 'note':
        this.type_icon = 'assets/images/log_icon.png';
        break;
      case 'call':
        this.type_icon = 'assets/images/phone_icon.png';
        break;
    }
  }

  changeReminderStatus() {
    if (this.reminder_status == 'Pending') {
      this.activityLogService
        .completeReminder({ uuid: this.log_data.uuid })
        .subscribe(val => {
          if (val && val['status']) {
            this.reminder_status = 'Completed';
            this.reminder_status_icon = 'assets/images/completed_icon.png';
            this.toast.success('SUCCESSFULLY UPDATED REMINDER STATUS');
          } else {
            this.toast.error('OPERATION FAILED');
          }
        });
    }
  }
}
