import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { DeleteModalComponent } from 'src/app/common/component/delete-modal/delete-modal.component';
import { AccountService } from 'src/app/common/service/account/account.service';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-discom-management',
  templateUrl: './discom-management.component.html',
  styleUrls: ['./discom-management.component.scss']
})
export class DiscomManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode = false;
  userList = [];
  isCollapsed = false;
  userListDetails = [];
  form: FormGroup;
  formValid: boolean = false;
  sidenavText: String = 'CREATE USER';
  discomOrganizationList = []

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService
  ) {
    console.log(accountService);
  }

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      organization: ["", Validators.required],
      password: ["", [Validators.required]],
      phone_number: ["", ],
    });
    this.getUserDetails();

    this.form.valueChanges.subscribe(() => {
      console.log("this.form", this.form);
      this.formValid = this.form.valid;
    });

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });
  }

  submit() {
    console.log("this.form.value", this.form.value)
    if (this.form.valid) {
      let params = this.form.value;
        params.phone_number = params.phone_number ? params.phone_number : "";
      if (this.isEditMode) {
        
        this.accountService
          .updateDiscomUserAccount(params)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error("ERROR OCCURED");
            }
          },
          (error: any) => {
            this.toast.error("BAD REQUEST OCCURED");
          }
          );
      } else {
        this.accountService
          .createDiscomUserAccount(params)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error(result.message.toUpperCase());
            }
          }, (error:any)=> {
            this.toast.error("ERROR OCCURED");
          });
      }
    }
  }

  editAccount(row) {
    this.sidenavBar.toggle();
    this.reset();
    this.sidenavText = 'EDIT USER';
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      organization: row.organization,
      password: row.password,
      phone_number: row.phone_number,
    });
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.accountService.deleteDiscomUserAccount(row.uuid).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("USER DELETED SUCCESSFULLY");
            this.getUserDetails();
          }
        });
      }});
  }

  reset() {
    this.form.reset();
    this.sidenavText = 'CREATE USER';
    this.form.patchValue({
      is_analytic: false,
      is_finance: false,
      is_business: false,
      is_technical: false,
      is_executive: false,
      is_designer: false,
      is_admin: false
    });
    // this.form.get("password").enable();
    this.form.get("email").enable();
    this.isEditMode = false;
  }

  getUserDetails() {
    this.accountService.getDiscomUserList().subscribe((result: any) => {
      if (result && result.status) {
        this.userList = result.payload.data;
        this.userListDetails = [...this.userList];
        this.offset = 0;
        this.discomOrganizationList = result.payload.dropdown_data.discom_organization_list;
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {

  }
}
