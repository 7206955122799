import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Output() option: EventEmitter<any> = new EventEmitter();

  titleText = 'CONFIRMATION';
  descText = 'Are you sure you want to delete this?';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
  ) { }

  ngOnInit() {
    if(this.data) {
      if(this.data.title) {
        this.titleText = this.data.title;
      }
      if(this.data.desc) {
        this.descText = this.data.desc;
      }
    }
  }

  option_yes() {
    this.option.emit('success');
    this.dialogRef.close();
  }

  option_no() {
    this.dialogRef.close();
  }

}
