import { Component, Inject ,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { ClusterService } from "../../service/cluster/cluster.service";


@Component({
  selector: 'app-assigning-sales-partner',
  templateUrl: './assigning-sales-partner.component.html',
  styleUrls: ['./assigning-sales-partner.component.scss']
})
export class AssigningSalesPartnerComponent implements OnInit {

  clusterList = [];
  salesPartnerList = [];
  selectedItems_uuid :any;
  lead_property_uuid: any;
  salesPartnerUuid: any;
  clusterID :any;
  salesPartnersName =[];
  epc_cluster_uuid : any;
  user_type : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clusterService: ClusterService,
    private dialogRef: MatDialogRef<AssigningSalesPartnerComponent> ,
    private toast: MessageToastService,
  ) {
    console.log('Received data:', data); // Access the array data here

    this.selectedItems_uuid =data.selectedItems
    
    this.clusterID =data.clusterId
    this.epc_cluster_uuid =data.epc_cluster_uuid
    this.user_type = data.user_type
  }


  

  ngOnInit() {

    if(this.user_type == "partner") {
      this.assigningEpcSalesPartner()
    }
    else{
      this.assigningSalesPartner()
    }
 
    
  }

  assigningSalesPartner() {
    
    this.clusterService.getAssigningSalesPartner(this.clusterID).subscribe((val: any) => {
      console.log("global cluster list found out");
      console.log(val);
      if (val && val["status"]) {
        this.salesPartnersName = [...val.payload.sales_partners];
        console.log("final cluster list");
      }
    });
  }


  assigningEpcSalesPartner() {
    
    this.clusterService.getAssigningEpcSalesPartner(this.epc_cluster_uuid , this.clusterID).subscribe((val: any) => {
      console.log("global cluster list found out");
      console.log(val);
      if (val && val["status"]) {
        this.salesPartnersName = [...val.payload.sales_partners];
        console.log("final cluster list");
      }
    });
  }

  dropdwnPartnerUpdate(event) {
    this.lead_property_uuid = event
    
    // this.clusterService.getSalesPartnerListUuid(event).subscribe((val: any) => {
    //   if (val && val["status"]) {
    //     this.salesPartnerList = val.payload.data;
    //   }
    // });
  
  }

  dropdwnPartnerName(event) {
    //alert(event);
    this.salesPartnerUuid = event;
    // this.clusterService.getSalesPartnerListUuid(event).subscribe((val: any) => {
    //   if (val && val["status"]) {
    //     this.salesPartnerList = val.payload.data;
    //   }
    // });
  
  }

  assignSalesPartner() {
    this.clusterService.postAssignSalesPartnerOnly(this.selectedItems_uuid,this.lead_property_uuid).subscribe((res: any) => {
      if (res.status) {
        this.dialogRef.close();
        this.toast.success(res.message);
        window.location.reload();

      }else {
        this.toast.error(res.message);
      }


    }) 
  }


  onCloseClick(): void {
    this.dialogRef.close();
  }


  
}

