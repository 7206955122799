import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatSidenav } from "@angular/material";
import { ManageSrComponent } from "src/app/common/component/sr-log/sr-log.component";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import * as FunnelGraph from "funnel-graph-js";
import { UserService } from 'src/app/common/service/user-service/user.service';
declare var $: any;

@Component({
  selector: 'app-global-service-requests',
  templateUrl: './global-service-requests.component.html',
  styleUrls: ['./global-service-requests.component.scss']
})
export class GlobalServiceRequestsComponent implements OnInit {

  srOffset = 0;
  srCount = 0;
  srLimit = 10;
  srList = [];
  ticketStatusDropdown = [{ label: 'Select', value: '' }];
  typeDropdown = [{ label: 'Select', value: '' }];
  paymentDropdown = [{ label: 'Select', value: '' }];
  hasEditAccess;
  isSalesPartner;
  isExecutive;
  canEditSR = false;
  form: any;
  technicianList: any;
  clusterUuid: any;
  funnelOptions: any;
  funnelValues: any = [1, 1];
  funnelLables: any = ["Select a Cluster", " "];
  funnelGraph: FunnelGraph;
  graph: any;
  _1vh: number;
  maxDate = new Date();


  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private menuToggle: MenuToggleService,
    private amcService: AMCService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private clusterService: ClusterService,

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      query_string: "",
      page: 1,
      status: '',
      type: '',
      payment_status: '',
      cluster_technician_id: '',
      created_on_to_date: 0,
      created_on_from_date: 0,
      cluster_independent: '',
      scheduled_on_to_date: 0,
      scheduled_on_from_date: 0
    });

    this._1vh = Math.round(window.innerHeight / 100);

    this.getFilterData();
    // this.form.get('type').valueChanges.subscribe((val) => {
    //   if (val) {
    //     let type = this.typeSubtypeData.filter(item => item.value == val)
    //     this.subtypeDropdown = type[0].subtype
    //   } else {
    //     this.subtypeDropdown = []
    //   }
    // });
    let user = this.authService.getUser();
    let userRoles = JSON.parse(user);
    this.canEditSR = this.userService.getEditSRRequest(userRoles);

    this.menuToggle.toggleMenu(false);
    this.clusterService.globalCluster.subscribe((val) => {
      if (val) {
        this.clusterUuid = val.uuid;
        this.getFilterData()
        this.getSRList();

      } else {
        this.clusterUuid = ""
      }

    })

    this.funnelOptions = {
      chart: {
        type: "funnel"
      },
      title: {
        text: "Tickets funnel"
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: true
          },
          center: ["40%", "50%"],
          neckWidth: "30%",
          neckHeight: "25%",
          width: "80%"
        }
      },
      legend: {
        enabled: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true
                  },
                  center: ["50%", "50%"],
                  width: "100%"
                }
              }
            }
          }
        ]
      }
    };
    this.fetchSRConfig();
  }

  createGraph() {
    $("#container").empty();

    this.graph = new FunnelGraph({
      container: ".funnel-container",
      gradientDirection: "horizontal",
      data: {
        labels: [...this.funnelLables],
        colors: ["#ffaf78", "#ff9180", "#ff4a8d"],
        values: [...this.funnelValues]
      },
      displayPercent: true,
      direction: "horizontal",
      height: this._1vh * 12,
      width: this.findWidth(this.funnelLables.length) / 1.25
    });

    this.graph.draw();
    this.correctGraphData();

  }
  cleanData(performanceData) {
    this.funnelLables = [];
    this.funnelValues = [];
    performanceData.forEach(val => {
      this.funnelValues.push(val.ticket_count);
      this.funnelLables.push(val.label.toUpperCase());
    });

  }

  findWidth(len): number {
    return len * 200;
  }
  getFilterData() {
    let params = { uuid: '' };
    if (this.form.value.cluster_independent) {
      params.uuid = '';
    } else {
      params.uuid = this.clusterUuid;
    }
    this.amcService.getGlobalServiceRequestFilterData(params).subscribe((res: any) => {
      if (res && res.status) {
        this.ticketStatusDropdown = [...res.payload.status];
        this.typeDropdown = [...res.payload.type];
        this.paymentDropdown = [...res.payload.payment_status];
        this.technicianList = [];
        res.payload.cluster_technician.forEach((ele: any) => {
          this.technicianList.push({ label: ele.name, value: ele.uuid });
        });
      }
    });
  }

  fetchSRConfig() {
    this.amcService.getSRConfig().subscribe((res: any) => {
      if(res && res.status) {
        console.log("config found in amc", res.payload);
        localStorage.setItem("sr-config", JSON.stringify(res.payload));
      }
    });
  }

  getSRList() {

    if (this.form.value.cluster_independent) {
      this.form.value.cluster_id = '';
    } else {
      this.form.value.cluster_id = this.clusterUuid;
    }
    let params = this.form.value;
    let dateFields = ['created_on_from_date', 'created_on_to_date', 'scheduled_on_to_date',
      'scheduled_on_from_date'];
    Object.keys(this.form.value).forEach(val => {
      if (dateFields.indexOf(val) >= 0 && this.form.get(val).value != 0 && this.form.get(val).value != null && this.form.get(val).value != '') {
        params[val] = Math.floor(this.form.get(val).value.getTime() / 1000);
      } else if (dateFields.indexOf(val) >= 0) {
        delete params[val];
      }
    });
    this.amcService.getGlobalServiceRequestList(this.form.value).subscribe((val: any) => {
      if (val && val.status) {
        this.sidenavBar.close();
        this.srList = val.payload.data;
        this.srCount = val.payload.count;
        this.srOffset = val.payload.current_page - 1;
        this.srLimit = val.payload.data_per_page;
        this.cleanData(val.payload.performance_data)
        this.createGraph();
      }
    });
  }
  correctGraphData() {
    Array.from(document.getElementsByClassName("label__percentage")).forEach(
      function (item) {
        if (item.innerHTML == "NaN%") item.innerHTML = "0%";
      }
    );
  }

  filter() {
    this.form.get("page").setValue(1);
    this.getSRList();
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  onActivate(event) {
    if (event.type == "click" && event.cell != '0') {
      this.openSRLog(event.row);
    }
  }

  openSRLog(event: any) {
    let dialogRef = this.dialog.open(ManageSrComponent, {
      data: {
        path: '/' + event.lead_property.identifier + '/amc/',
        property_uuid: event.lead_property.uuid,
        ticket_uuid: event.uuid,
        label: 'Documents',
        can_edit: this.canEditSR
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        this.getSRList();
      }
    });
  }

  reset() {
    this.form.patchValue({
      query_string: "",
      type: "",
      payment_status: "",
      status: "",
      created_on_to_date: 0,
      created_on_from_date: 0,
      cluster_technician_id: '',
      scheduled_on_to_date: 0,
      scheduled_on_from_date: 0
    });
  }

  onPage(event) {
    this.form.get("page").setValue(event.offset + 1);
    this.getSRList();
  }

  sidenavOpen() {
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  downloadList() {
    if (this.srList.length > 0) {
      console.log('request for downloading list');
      console.log(this.form.value);
      let downloadState = JSON.parse(JSON.stringify(this.form.value));
      delete downloadState['page'];
      console.log('current angular download object');
      console.log(downloadState);
      downloadState['download'] = 1;
      // this.currentState['download'] = 1;
      // this.getFilteredList();
      this.amcService.downloadListByUrl(this.amcService.globalServiceListUrl, downloadState, 'Global SR ');
      console.log("SR list downloaded!!!")
    }
    else {
      console.log("SR list empty!");
    }
  }

}
