import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { StageService } from "src/app/common/service/stage/stage.service";
import { MatDialog } from "@angular/material/dialog";
import { EditStageLinkComponent } from "../edit-stage-link/edit-stage-link.component";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';

@Component({
  selector: "app-create-stage-link",
  templateUrl: "./create-stage-link.component.html",
  styleUrls: ["./create-stage-link.component.scss"]
})
export class CreateStageLinkComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  clusters = [];
  selectedCities1 = [];
  isEditMode = false;
  isFilterCollapsed = false;
  isCollapsed = false;
  clusterList = [];
  filterApplied = false;
  stageList = [];
  stageLinkDataDetails = [];
  stageListDetails = [];
  stageLinkData = [];
  stageLinkList = [];
  stageLinkListDetails = [];
  nextStageList = [];
  form: FormGroup;
  filterForm: FormGroup;
  formValid: boolean = false;
  formValid1: boolean = false;
  selectedStage: string = "";

  accessList = [
    {
      label: "NA",
      value: "na"
    },
    {
      label: "View",
      value: "view"
    },
    {
      label: "Edit",
      value: "edit"
    }
  ];

  stageGroup = [
    {
      label: "Select Stage Group",
      value: ""
    },
    {
      label: "Acquisition",
      value: "acquisition"
    },
    {
      label: "Feasibility",
      value: "feasibility"
    },
    {
      label: "Execution",
      value: "execution"
    },
    {
      label: "QC",
      value: "qc"
    },
    {
      label: "Collection",
      value: "collection"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private stageService: StageService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      cluster_id: ["", Validators.required]
    });

    this.form = this.fb.group({
      stage_from_id: ["", Validators.required],
      stage_to_ids: ["", Validators.required]
    });
    this.clusters.push({
      label: "Select a cluster",
      value: null
    });

    this.getClusters();

    this.filterForm.valueChanges.subscribe(() => {
      this.formValid = this.filterForm.valid;
    });

    this.form.valueChanges.subscribe(() => {
      this.formValid1 = this.form.valid;
      this.selectedStage = this.stageList.find(
        x => x.value == this.form.controls["stage_from_id"].value
      );
      console.log("selected stage");
      console.log(this.selectedStage);
      if (this.selectedStage && this.selectedStage["label"]) {
        this.selectedStage = this.selectedStage["label"];
      }
    });
    
    // closing the sidemenu on page load
    this.menuToggle.toggleMenu(false);
    // resizing the content whenever menu is toggled
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    });

  }

  submit() {
    if (this.form.valid && this.filterForm.valid) {
      let data = this.form.value;
      this.stageService.saveStageLinkData(data).subscribe((result: any) => {
        if (result && result.status) {
          this.reset();
          this.toast.success("MAP STAGE LINKED SUCCESSFULLY");
          this.getStagesList();
        }
      });
    }
  }

  getStagesList() {
    if (this.filterForm.valid) {
      this.filterApplied = true;
      let queryParams = "uuid=" + this.filterForm.get("cluster_id").value;
      this.getStageDetails(queryParams);
      this.getStageLinkDetails();
    }
  }

  editAccount(row) {}

  deleteAccount(row) {
    this.stageService.deleteStageData(row.uuid).subscribe((result: any) => {
      if (result && result.status) {
        this.reset();
        this.toast.success("MAP STAGE LINK DELETED SUCCESSFULLY");
        this.getStagesList();
      }
    });
  }

  reset() {
    this.form.reset();
    this.form.patchValue({
      is_default: false
    });
    this.form.get("stage_from_id").setValue("");
    this.isEditMode = false;
  }

  getStageDetails(queryParam) {
    this.stageList = [
      {
        label: "Please select a stage",
        value: ""
      }
    ];
    this.stageService.getStageList(queryParam).subscribe((result: any) => {
      if (result && result.status) {
        let data = [];
        result.payload.stages.forEach(element => {
          data.push({
            label: element.display_name,
            value: element.uuid
          });
        });
        data.forEach(stage => {
          this.stageList.push(stage);
        });
        this.nextStageList = data;
        this.stageListDetails = data;
      }
    });
  }

  getStageLinkDetails() {
    let data = "uuid=" + this.filterForm.get("cluster_id").value;
    this.stageService.getStageLinkDetails(data).subscribe((result: any) => {
      result.payload.forEach(element => {
        let stageName = "";
        let nameAdded = false;
        element.next_stages.forEach(stage => {
          if (nameAdded) {
            stageName += ",";
          }
          stageName += stage.display_name;
          nameAdded = true;
        });
        element["stage_name"] = stageName;
      });
      this.stageLinkData = result.payload;
      this.stageLinkDataDetails = result.payload;
      this.offset = 0;
      // console.log('successfully fetched the link list');
      // console.log(this.stageLinkDataDetails);
      // console.log('data uuid');
      // console.log(data);
    });
  }

  getClusters() {
    this.clusterService.getClusterList().subscribe(res => {
      res["payload"].clusters.forEach(element => {
        this.clusters.push({
          label: element.identifier,
          value: element.uuid
        });
      });
    });
  }

  deleteStageLink(row) {
    console.log(row);
    let dialogRef = this.dialog.open(EditStageLinkComponent, {
      data: row
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == "success") {
        this.getStageLinkDetails();
        this.reset();
      }
    });
  }

  onPage(event) {
    // console.log(event);
    // this.offset = event.offset;
    // const start = this.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.stageLinkDataDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.stageLinkData = rows;
    // // this.partnerList = [...this.partnerListDetails];
  }
}
