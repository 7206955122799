import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DisaggregationService } from '../../service/disaggregation/disaggregation.service';

@Component({
  selector: 'app-disaggregation-label-modal',
  templateUrl: './disaggregation-label-modal.component.html',
  styleUrls: ['./disaggregation-label-modal.component.scss']
})
export class DisaggregationLabelModalComponent implements OnInit {
  @Output() label: EventEmitter<any> = new EventEmitter();
  
  form: any;
  filteredOptions: Observable<string[]>;
  equipmentList = []; //[{name: 'asd'}, {name: '12321'}, {name: 'sfda6'}, {name: 'as222'}, {name: "asd123"}];
  radioSelected = '';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DisaggregationLabelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private disaggregationService: DisaggregationService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      equipment: ["", Validators.required],
    });

    this.initFilterList();

    this.fetchEquipmentList();
  }

  initFilterList() {
    this.filteredOptions = this.form
          .get('equipment')
          .valueChanges.pipe(
            startWith(''),
            map(value => this._filter(<string>value))
          );
  }

  fetchEquipmentList() {
    this.disaggregationService.getGlobalEquipmentList().subscribe((res: any) => {
      if(res && res.status) {
        this.equipmentList = [...res.payload['equipment_list']];
        
        if(this.data && this.data.length) {
          this.equipmentList = this.equipmentList.filter( x => this.data.findIndex(y => y.label && x.name ? (y.label.toString().trim() == x.name.toString().trim()) : false ) < 0);
        }
        this.initFilterList();
        console.log("new lists ", this.data, this.equipmentList, res.payload);
      }
    });
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.equipmentList.filter((option) =>
      option['name'] ? option['name'].toLowerCase().includes(filterValue) : false
    );
  }

  addLabel() {
    if(this.form.valid) {
      const equipment = this.equipmentList.filter(e => e.name ? e.name == this.form.get('equipment').value : false);
      this.label.emit(equipment[0]);
      this.dialogRef.close();
    }
  }

}
