import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrls: ['./recommendation-item.component.scss']
})
export class RecommendationItemComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
    console.log("data found in item ", this.data);
  }

}
