import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TuyaService {

  tuyaDeviceAnalyticsUrl = 'v1/report/generation/dashboard/tuya';
  onsetDeviceAnalyticsUrl = 'v1/report/generation/dashboard/onset';
  disaggregationDashboardUrl = 'v1/report/disaggregation/dashboard';

  constructor(
    private http: HttpClient
  ) { }

  getTuyaAnalytics(params) {
    return this.http.get(environment.serverURl + this.tuyaDeviceAnalyticsUrl, { params: params});
  }

  getOnsetDeviceAnalytics(params) {
    return this.http.get(environment.serverURl + this.onsetDeviceAnalyticsUrl, { params: params });
  }

  getDisaggregationData(params) {
    return this.http.get(environment.serverURl + this.disaggregationDashboardUrl, { params: params });
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }

  downloadTuyaDataByUrl(url, data) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          'devices-consumption-report' + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.xls'
        );
      });
  }

}
