import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-amc-log-item',
  templateUrl: './amc-log-item.component.html',
  styleUrls: ['./amc-log-item.component.scss']
})
export class AmcLogListComponent implements OnInit {
  @Input() data: any;
  @Input() title: any;

  logs = [];
  field_map = {
    'no_of_visits_total': 'Total Number of Visits',
    'plant_warranty': 'Plant Warranty', 'cost_basic': 'Basic Cost', 'cost_gst': 'GST Cost', 'validity_start_date': 'Tenure Start Date', 'validity_end_date': 'Tenure End Date', 'system_checks': 'System Checks', 'spare_item': 'Spare Item', 'remote_analysis': 'Remote Analysis', 'insurance': 'Insurance', 'cleaning': 'Cleaning', 'import_export_monitoring': 'Import Export Monitoring', 'images': 'Images',
    'discount': 'Discount',
    'margin':'Margin',
    'max_capacity': 'Max Capacity',
    'max_inverter': 'Max Inverter',
    'terra_max_module': 'Terra Max Module',
    'aerial_max_module': 'Aerial Max Module',
    'atrium_max_module': 'Atrium Max Module',
    'flush_mount_max_module': 'Flush Mount Max Module',
    'amc_tenure_days': 'AMC Tenure',
    'status': 'Status',
    'plan_id': 'AMC Plan',
    'cable_tray': 'Cable Tray',
    'cable_tray_cost': 'Cable Tray Cost',
    'earthing': 'Earthing',
    'earthing_cost': 'Earthing Cost',
    'is_net_metering': 'Net metering',
    'net_metering_cost': 'Net metering cost',
    'is_homescape_app': 'HomeScape App',
    'homescape_app_cost': 'HomeScape App cost',
    'is_consumption_monitoring': 'Consumption Monitoring',
    'consumption_monitoring_cost': 'Consumption Monitoring cost',
    'amc_cost': 'AMC cost',
    'basic_price': 'Basic Cost',
    'cable_cost': 'Cable Cost',
  };
  new_images = [];
  old_images = [];


  constructor() { }

  ngOnInit() {
    console.log('data received in the item');
    console.log(this.data);
    console.log(this.data['log']);

    this.logs = JSON.parse(this.data['log']);
    console.log('log obtained after parsing');
    console.log(this.logs);

    if (this.logs) {
      this.logs.forEach((log: any) => {
        if (log.field == 'images') {
          this.new_images = JSON.parse(log.new_value);
          if (typeof this.new_images == 'string') {
            let images = [];
            images[0] = { "url": this.new_images };
            this.new_images = images;
          }
          // else {
          //   console.log("===--======================", JSON.parse(log.new_value))
          //   this.new_images = JSON.parse(log.new_value);
          // }
          this.old_images = JSON.parse(log.old_value);
        }
      });
    }
  }

}
