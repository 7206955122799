import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatDialog } from "@angular/material";
import { FinanceService } from "src/app/common/service/finance/finance.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { CreatePaymentPlanComponent } from '../add-payment-plan/add-payment-plan.component';
import { UserService } from 'src/app/common/service/user-service/user.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
declare var $: any;
@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent implements OnInit {

  isEditMode = false;
  count = 0;
  offset = 0;
  limit = 0;
  assetList = [];
  canAddPlan = false;

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private financeService: FinanceService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private userService: UserService,
    private authService: AuthService,
    private menuToggle: MenuToggleService
  ) { }
  ngOnInit() {
    this.getPlanList();
    this.canAddPlan = this.userService.getAddPaymentPlanAccess(JSON.parse(this.authService.getUser()));
    this.menuToggle.toggleMenu(false);

  }

  getPlanList() {
    this.financeService.getPaymentPlans().subscribe((val: any) => {
      console.log('plan list found out');
      console.log(val);
      if(val && val['status']) {
        this.assetList = [...val.payload.data];
        console.log('final asset list');
        console.log(this.assetList);
        console.log(this.assetList['plan_description'])
      }
    });
  }

  sidenavOpen() {
    console.log('sidenav opened');
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    console.log('sidenav closed');
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  onPage(event) {
    // this.offset = event.offset;
    this.getPlanList();
  }

  onActivate(event) {
    if(event.type ==  "click") {
      console.log("row clicked: ", event);
      let dialogRef = this.dialog.open(CreatePaymentPlanComponent, {
        data: event.row,
      });
    }
  }

  addPaymentPlan() {
    let dialogRef = this.dialog.open(CreatePaymentPlanComponent);
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == "success") {
        this.getPlanList();
      }
    });
  }

}