import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { DisaggregationService } from '../../service/disaggregation/disaggregation.service';
import { MessageToastService } from '../../service/toast/message-toast.service';

@Component({
  selector: 'app-global-equipment-modal',
  templateUrl: './global-equipment-modal.component.html',
  styleUrls: ['./global-equipment-modal.component.scss']
})
export class GlobalEquipmentModalComponent implements OnInit {
  form: any;
  filteredOptions: Observable<string[]>;
  consumptionCatList = [
    { key: 'high', value: 'High'},
    { key: 'low', value: 'Low'},
    { key: 'medium', value: 'Medium'},
  ];

  supplierTypeList = [
    { key: 'tuya', value: 'Tuya'},
    { key: 'aqi', value: 'AQI'},
    { key: 'raycon', value: 'Raycon'},
  ];
  equipmentType = [
    {
        "name": "Router",
        "value": "router"
    },
    {
        "name": "Microwave",
        "value": "microwave"
    },
    {
        "name": "AC",
        "value": "ac"
    },
    {
        "name": "Geyser",
        "value": "geyser"
    },
    {
        "name": "Exhaust Fan",
        "value": "exhaust_fan"
    },
    {
        "name": "Mobile Charge",
        "value": "mobile_charge"
    },
    {
        "name": "R.O",
        "value": "r.o"
    },
    {
        "name": "Washing Machine",
        "value": "washing_machine"
    },
    {
        "name": "Fridge",
        "value": "fridge"
    },
    {
        "name": "Unknown Device",
        "value": "unknown_device"
    },
    {
        "name": "NULL",
        "value": "null"
    },
    {
        "name": "ac",
        "value": "ac"
    },
    {
        "name": "Refrigerator",
        "value": "refrigerator"
    },
    {
        "name": "Speaker",
        "value": "speaker"
    },
    {
        "name": "Television",
        "value": "television"
    },
    {
        "name": "Dishwasher",
        "value": "dishwasher"
    },
    {
        "name": "Oven",
        "value": "oven"
    },
    {
        "name": "Cooler",
        "value": "cooler"
    },
    {
        "name": "Fan",
        "value": "fan"
    },
    {
        "name": "Tubelight",
        "value": "tubelight"
    },
    {
        "name": "Bulb",
        "value": "bulb"
    },
    {
        "name": "Electric Fan",
        "value": "electric_fan"
    },
    {
        "name": "Pool Pump",
        "value": "pool_pump"
    },
    {
        "name": "Electric Guitar",
        "value": "electric_guitar"
    },
    {
        "name": "Keyboard",
        "value": "keyboard"
    },
    {
        "name": "Water Purifier",
        "value": "water_purifier"
    },
    {
        "name": "Electric Kettle",
        "value": "electric_kettle"
    },
    {
        "name": "Iron",
        "value": "iron"
    },
    {
        "name": "Lantern",
        "value": "lantern"
    },
    {
        "name": "Lamp",
        "value": "lamp"
    },
    {
        "name": "Stove",
        "value": "stove"
    },
    {
        "name": "Toaster",
        "value": "toaster"
    },
    {
        "name": "Blender",
        "value": "blender"
    },
    {
        "name": "Laptop Charger",
        "value": "laptop_charger"
    },
    {
        "name": "Mobile Charger",
        "value": "mobile_charger"
    },
    {
        "name": "Heater",
        "value": "heater"
    }
];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<GlobalEquipmentModalComponent>,
    private disaggregationService: DisaggregationService,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      type: ["", Validators.required],
      description: ["", Validators.required],
      consumption_category: ["", Validators.required],
      supplier_type: ["", Validators.required],
      is_ml_available: [false]
    });

    this.initFilterList();
  }

  initFilterList() {
    this.filteredOptions = this.form
          .get('type')
          .valueChanges.pipe(
            startWith(''),
            map(value => this._filter(<string>value))
          );
  }

  createEquipment() {
    if(this.form.valid) {
      // create equipment
      let data = JSON.parse(JSON.stringify(this.form.value));
      data['type'] = data['type'].toLowerCase().replace(' ', '_');
      this.disaggregationService.addGlobalEquipmentList(data).subscribe((res: any) => {
        if(res && res.status) {
          this.toast.success('EQUIPMENT CREATED SUCCESSFULLY');
          this.dialogRef.close();
        }
      });
    }
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();

    return this.equipmentType.filter((option) =>
      option['name'] ? option['name'].toLowerCase().includes(filterValue) : false
    );
  }

}
