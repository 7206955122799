import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { ReportingService } from "src/app/common/service/reporting/reporting.service";
import { DatePipe } from "@angular/common";
import { MatSidenav } from "@angular/material/sidenav";
import { Subscription, BehaviorSubject } from "rxjs";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
declare var $: any;

@Component({
  selector: 'app-activity-dashboard',
  templateUrl: './activity-dashboard.component.html',
  styleUrls: ['./activity-dashboard.component.scss']
})
export class ActivityDashboardComponent implements OnInit {
  form: any;
  clusterList: any = [];
  formSubmitted: boolean = false;
  graphData: any;
  activeEntries: any;
  graphUserData: any;
  isCollapsed: boolean = false;
  showUserGraph: boolean = false;
  showDisaggregation: boolean = false;
  selectedUser: any;
  private subscription: Subscription = new Subscription();

  // graph parameters 
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  tooltipDisabled = false;
  xAxisLabel = "";
  showYAxisLabel = true;
  yAxisLabel = "";
  showGridLines = false;
  barPadding = 15;
  roundDomains = false;
  view: any = [1000, 400];
  userView: any = [500, 1000];
  startDate = "";
  endDate = "";

  colorScheme = {
    domain: ["#f59240"]
  };
  userColorScheme = {
    domain: ["#58b1f7"]
  };
  schemeType = "ordinal";
  activeIcon = "../../../assets/images/location_marker_active.png";
  inactiveIcon = "../../../assets/images/location_marker_inactive.png";
  call_busy: any;
  call: any;
  whatsapp: any;
  call_no_answer: any;
  call_connected: any;
  call_wrong_number: any;
  date_detail: any;
  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private clusterService: ClusterService,
    private reportingService: ReportingService,
    private datepipe: DatePipe,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) { }

  ngOnInit() {
    let cluster = "";
    let userDetails = JSON.parse(this.authService.getUser());

    this.form = this.fb.group({
      cluster_uuid: [cluster, Validators.required],
      from_date: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        Validators.required
      ],
      to_date: [new Date(), Validators.required],
      user_type: ["all", Validators.required],
      cluster_independent: "",
    });

    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          this.form.get("cluster_uuid").setValue(val.uuid);
          this.submit();
        }
      })
    );

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (100));
    });
  }

  submit() {
    let formData: any = {};
    if (this.form.value.cluster_independent) {
      formData["cluster_uuid"] = "";
    } else {
      formData["cluster_uuid"] = this.form.get("cluster_uuid").value;
    }
    formData["from_date"] = this.datepipe.transform(new Date(this.form.get("from_date").value.getTime()), "yyyy-MM-dd");
    formData["to_date"] = this.datepipe.transform(new Date(this.form.get("to_date").value.getTime()), "yyyy-MM-dd");
    formData["user_type"] = "associate";

    this.reportingService.getActivityLogDetails(formData).subscribe(val => {
      if (
        Object.keys(val["payload"]).length != 0 &&
        Object.keys(val["payload"].data).length != 0
      ) {
        let height = Object.keys(val["payload"].data).length * 10;

        this.graphData = this.correctData(val["payload"].data, this.form.get("user_type").value);
        this.startDate = this.datepipe.transform(new Date(this.form.get("from_date").value.getTime()), "MMMM d, y")
        this.endDate = this.datepipe.transform(new Date(this.form.get("to_date").value.getTime()), "MMMM d, y")
        this.formSubmitted = true;
        setTimeout(() => {
          let len = this.graphData.length;
          this.view = [1000, len * 30];
          this.showUserGraph = false;
          this.showDisaggregation = false;
          this.selectedUser = null;
        }, 300);
        this.toast.success("DATA FETCHED SUCCESSFULLY");
        this.sidenavBar.close();
      } else {
        this.formSubmitted = false;
        this.toast.error(val['message'].toUpperCase());
        this.sidenavBar.close();
      }
      $(".filter-header").click();
    });
  }

  onBarClick(params: Event) {
    console.log('data got on bar click');
    console.log(params);
    this.activeEntries = [params];
    let user_details = {
      user_type: params["extra"]["user_type"],
      uuid: params["extra"]["uuid"]
    }
    let formData: any = {};
    if (this.form.value.cluster_independent) {
      formData["cluster_uuid"] = "";
    } else {
      formData["cluster_uuid"] = this.form.get("cluster_uuid").value;
    }
    formData["from_date"] = this.datepipe.transform(new Date(this.form.get("from_date").value.getTime()), "yyyy-MM-dd");
    formData["to_date"] = this.datepipe.transform(new Date(this.form.get("to_date").value.getTime()), "yyyy-MM-dd");
    formData["user_type"] = params["extra"]["user_type"];
    formData["user_id"] = params["extra"]["uuid"];

    this.reportingService.getPerformenceDetails(formData).subscribe(val => {
      if (
        Object.keys(val["payload"]).length != 0 &&
        Object.keys(val["payload"].data).length != 0
      ) {
        setTimeout(() => {
          let len = Object.keys(val["payload"].data).length;
          this.userView = [len < 10 ? 500 : len * 40, 400];
          this.view = [550, this.view[1]];
          this.showUserGraph = true;
          this.showDisaggregation = false;
          this.selectedUser = params;
        }, 300);
        this.graphUserData = this.correctUserData(val["payload"].data, user_details);
        this.formSubmitted = true;
      } else {
        this.toast.error(val['message'].toUpperCase());
      }
    });
  }

  onPerformanceBarClick(params: Event) {
    console.log('data got on performance bar click======>');
    console.log(params);
    this.activeEntries = [params];
    let formData: any = {};
    if (this.form.value.cluster_independent) {
      formData["cluster_uuid"] = "";
    } else {
      formData["cluster_uuid"] = this.form.get("cluster_uuid").value;
    }
    // formData["from_date"] = this.datepipe.transform(new Date(this.form.get("from_date").value.getTime()), "yyyy-MM-dd");
    // formData["to_date"] = this.datepipe.transform(new Date(this.form.get("to_date").value.getTime()), "yyyy-MM-dd");
    formData["user_type"] = params["extra"]["user_type"];
    formData["user_id"] = params["extra"]["uuid"];
    formData["date"] = params["extra"]["date"];

    // this.datepipe.transform(new Date(key), "MMM d, yyyy")
    this.reportingService.getDatewiseDetails(formData).subscribe(val => {
      console.log("val date wise data=================>", val);
      if (
        Object.keys(val["payload"]).length != 0
      ) {
        setTimeout(() => {
          let value = val["payload"]['data'];
          this.showDisaggregation = true;
          this.date_detail = this.datepipe.transform(new Date(params["extra"]["date"]), "MMM d, yyyy");
          this.call = value.call;
          this.whatsapp = value.whatsapp;
          this.call_busy = value.call_busy;
          this.call_connected = value.call_connected;
          this.call_no_answer = value.call_no_answer;
          this.call_wrong_number = value.call_wrong_number;
        }, 300);
        this.formSubmitted = true;
      } else {
        this.toast.error(val['message'].toUpperCase());
      }
    });
  }


  correctData(data, userType): any {
    let new_data = [];
    data.forEach((element) => {
      if (userType == "all" || userType == element.user_type) {
        new_data.push({
          name: element.name,
          value: element.count,
          extra: {
            uuid: element.uuid,
            user_type: element.user_type
          }
        });
      }
    });
    return new_data;
  }

  correctUserData(data, userDetails): any {
    let new_data = [];
    console.log(userDetails);
    Object.keys(data).map((key: any, index) => {
      var point = data[key] ? data[key] : 0;
      new_data.push({
        name: this.datepipe.transform(new Date(key), "MMM d"),
        value: point,
        extra: {
          user_type: userDetails.user_type,
          uuid: userDetails.uuid,
          date: key,
        }
      });
    });
    return new_data;
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
