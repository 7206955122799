import { EventEmitter, Output } from "@angular/core";
import { Inject } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LeadService } from "../../service/lead/lead.service";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: 'app-edit-recommendation-modal',
  templateUrl: './edit-recommendation-modal.component.html',
  styleUrls: ['./edit-recommendation-modal.component.scss']
})
export class EditRecommendationModalComponent implements OnInit {
  recommendationList = [];
  form: any;
  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private leadService: LeadService,
    private toast: MessageToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditRecommendationModalComponent>,
  ) { }

  ngOnInit() {
    this.getRecommendationList();

    this.form = this.fb.group({
      recommendation: ['', Validators.required],
    });
  }

  getRecommendationList() {
    this.leadService.getNoClusterFilterData().subscribe((val) => {
      this.recommendationList = [];
      val['payload'].capacity.forEach((cap) => {
        val['payload'].structure_type.forEach((s) => {
          this.recommendationList.push({key: cap.toString() + '_' + s, value: cap.toString() + 'kW ' + s.toUpperCase()});
        });
      });

      console.log("current lists");
      console.log(this.recommendationList);
    });
  }

  submit() {
    this.form.get("recommendation").markAsTouched();
    if(this.form.valid) {
      this.leadService.updateRecommendation({
        recommended_capacity: parseInt(this.form.get('recommendation').value.split('_')[0]),
          recommended_structure_type: this.form.get('recommendation').value.split('_')[1],
      }, { uuid: this.data }).subscribe((val: any) => {
        if(val && val.status) {
          this.toast.success('PROPERTY UPDATED SUCCESSFULLY');
            this.leadService.refreshNewPropertyList.next(true);
            this.dialogRef.close();
            this.status.emit(this.form.get("recommendation").value);
        }
      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
