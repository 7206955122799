import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take } from 'rxjs/operators';
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { FinanceService } from 'src/app/common/service/finance/finance.service';
import { StageService } from 'src/app/common/service/stage/stage.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-add-payment-plan',
  templateUrl: './add-payment-plan.component.html',
  styleUrls: ['./add-payment-plan.component.scss']
})
export class CreatePaymentPlanComponent implements OnInit {

  form: any;
  // stagesList = [];
  stageList = ['lead_generation', 'communication', 'sales_partner_assignment', 'site_survey', 'proposal', 'final_commercial', 'customer_kyc',
  'kyc_verification', 'net_metering_application', 'design_and_material_planning', 'discom_noc_received', 'final_proposal', 'subsidy_claim', 
  'installation_partner_allocation', 'material_dispatch', 'material_received_at_site', 'technical_assessment', 
  'installation', 'site_qc', 'meter_installation', 'logger_data_config', 'site_commissioned'];
  currentStageList = this.stageList;
  filteredStageList = [];
  emptyStage = {stage_type:'', percentage: null, amount: null};
  formDisabled = false;
  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    private financeService:  FinanceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatePaymentPlanComponent>,
    private fb: FormBuilder,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      plan_type: ["", Validators.required],
      stage_details: this.fb.array([])
    });
    if(this.data) {
      this.fillData();
    } else {
      this.form.setValidators([this.customValidator.bind(this)]);
      this.addStageForm();
    }
    console.log("form objects: ", this.form.controls, this.form.controls.stage_details, this.form.controls.stage_details.controls);
  }

  fillData() {
    console.log("data found in modal: ", this.data);
    this.form.get('plan_type').setValue(this.data['plan_type']);
    let cummulative_percent = 0, cummulative_amount = 0;
    this.data['stage_list'].forEach((stage) => {
      const item = {stage_type: stage.stage_type, percentage: stage.cummulative_percentage ? stage.cummulative_percentage - cummulative_percent : 0, 
        amount: stage.cummulative_amount ? stage.cummulative_amount - cummulative_amount: 0};
      cummulative_percent = stage.cummulative_percentage ? stage.cummulative_percentage : 0;
      cummulative_amount = stage.cummulative_amount ? stage.cummulative_amount : 0;
      this.addStageForm(item);
    });
    this.form.disable();
    this.formDisabled = true;
  }

  get milestones() {
    return this.form.controls.stage_details as FormArray;
  }

  addStageForm(item?, idx?) {
    if(item == null) item = this.emptyStage;
    const stageForm = this.fb.group({
      stage_type: [item.stage_type, Validators.required],
      percentage: [item.percentage],
      amount: [item.amount]
    });

    // adding the item at the end by default
    if(idx == null) { idx = this.milestones.length; }
    if(idx > this.milestones.length + 1) return;

    if(idx > 0) {
      const lastStageVal = this.milestones.controls[idx-1].get('stage_type').value;
      if(lastStageVal == null || lastStageVal == '') {
        this.toast.error("PLEASE FILL THE PREVIOUS STAGE");
        return;
      }
    }

    if(idx == this.milestones.length) {
      this.updateStageList(idx-1);
    }
    this.milestones.insert(idx, stageForm);
    
  }

  customValidator(control) {
    if(control.value.plan_type == null || control.value.plan_type == '') return {'mandatory_field': 'please fill mandatory fields'};
    // console.log('customer validator: ', control, control.controls, control.value);
    if(control.controls.stage_details.controls.some((form) => !form.valid)) return {'mandatory_field': 'please fill mandatory fields'};
    
    // validates if all stage has > 0 payment amount
    if(control.value.stage_details.some(i => (i.percentage == null || i.percentage == 0) && (i.amount == null || i.amount == 0))) 
    {return {'zero_amount': 'stage payment amount should be greater than zero'};}
    
    // validates that percentages total to 100
    let total = 0;
    control.value.stage_details.forEach((stage) => total+= (stage.percentage? stage.percentage : 0));
    return total == 100 ? null : {'invalid_percentages': 'all percentages should sum to 100'};
  }

  // remove stages already selected earlier and add them back if un-selected
  updateStageList(lastStageIdx) {
    if(lastStageIdx < 0) { this.currentStageList = this.stageList; return;}
    const lastStage = this.milestones.controls[lastStageIdx].get('stage_type').value;
    this.currentStageList = [];
    const pos = this.stageList.indexOf(lastStage);
    console.log("pos: ", pos, " lastStage: ", lastStage, " lastStageIndex: ", lastStageIdx);
    this.stageList.forEach((stage, idx) => {
      if(idx > pos) {
        this.currentStageList.push(stage);
      }
    });
  }

  getStageList(idx) {
    if(idx == this.milestones.length - 1) { return this.currentStageList;}
    return this.stageList;
  }

  stageFilter(value) {
    console.log("filter value obtained: ", value);
    const filterValue = value.toLowerCase();
    let items = JSON.parse(JSON.stringify(this.stageList));
    this.filteredStageList =  items.filter(option => option.toLowerCase().includes(filterValue));
  }

  deleteStage(index: number) {
    if(this.milestones.length > 1) {
      this.updateStageList(index-2);
      this.milestones.removeAt(index);
    } else {
      this.addStageForm({name: '', capacity: '', category: 'inverters', uuid: ''})
    }
  }

  validateForm() {
    if(this.form.valid) { return true; }
    console.log('form errors: ', this.form.errors, ' form valid: ', this.form.valid, ' form obj: ', this.form);
    if(this.form.errors) {
      Object.keys(this.form.errors).forEach((key) => {
        this.toast.error(this.form.errors[key].toUpperCase());
      });
    }
  }

  submit() {
    console.log("submitting the form: ", this.form.value);
    if(this.validateForm()) {
      let data = JSON.parse(JSON.stringify(this.form.value));
      data.stage_details.forEach((form) => {
        Object.keys(form).forEach((key) => {
          if(form[key] == null || form[key] == '') {
            delete form[key];
          }
        });
      })
      this.financeService.addPaymentPlan(data).subscribe((val: any) => {
        if(val && val.status) {
          this.toast.success("PLAN ADDED SUCCESSFULLY");
          this.status.emit("success");
          this.dialogRef.close();
        }
      })
    }
  }

}
