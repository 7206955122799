import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/common/service/account/account.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ManageAmcPlanComponent } from './manage-amc-plan/manage-amc-plan.component';
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { CreateSrModalComponent } from "src/app/common/component/create-sr-modal/create-sr-modal/create-sr-modal.component";
import { ManageSrComponent } from "src/app/common/component/sr-log/sr-log.component";
import { ManageAssetComponent } from "src/app/common/component/create-asset/create-asset.component";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { DatePipe } from "@angular/common";
import { UserService } from 'src/app/common/service/user-service/user.service';

@Component({
  selector: 'app-amc',
  templateUrl: './amc.component.html',
  styleUrls: ['./amc.component.scss']
})
export class AmcComponent implements OnInit {

  propertyData: any = { 'activation_status': false };
  lead_uuid: any;
  cardCollapsed = true;
  amcDetailsForm: any;
  selected_tab: number = 0;
  boolean_dropdown = [{ key: "Yes", value: "yes" }, { key: "No", value: "no" }];
  amc_pack_dropdown = [{ key: "AMC Gold", value: "amc_gold" }, { key: "AMC Silver", value: "amc_silver" }, { key: "AMC Platinum", value: "amc_platinum" }, { key: "AMC Basic", value: "amc_basic" }, { key: "AMC Diamond", value: "amc_diamond" }];
  offset = 0;
  limit = 10;
  count = 100;
  active_index = 0;
  warranty_modules = ['Modules', 'Inverters', 'Structure', 'BOS'];
  asset_list = [];
  filtered_asset_list = [];
  img_arrays = [];
  isOpsManager = false;

  srOffset = 0;
  srCount = 0;
  srLimit = 0;
  srList = [];
  dummy_amc_uuid = "584532a8-8a95-4306-8402-c0105918be6c";

  amc_active = false;
  amc_details_array = [];
  hasEditAccess = false;
  hasAMCDetailsAccess = false;
  hasCreateSRAccess = false;
  hasEditSRAccess = false;
  hasWarrantyModulesAccess = false;
  isExecutive = false;
  isSalesPartner = false;
  isAdmin = false;
  canEditSR = false;

  @ViewChild(DatatableComponent, { static: false })      // import {DatatableComponent} from '@swimlane/ngx-datatable';
  private readonly table: DatatableComponent;

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService,
    private amcService: AMCService,
    private toast: MessageToastService,
    private authService: AuthService,
    private userService: UserService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.menuToggle.toggleMenu(false);

    this.amcDetailsForm = this.fb.group({

    });
    this.route.queryParams.subscribe(params => {
      this.lead_uuid = params['id'];
      this.getPropertyData({ property_id: this.lead_uuid });
    });
    this.fetchTabData();
    let user = this.authService.getUser();
    let userRoles = JSON.parse(user);
    this.hasAMCDetailsAccess = this.userService.getAMCDetailsAccess(userRoles);
    this.hasCreateSRAccess = this.userService.getCreateSRAccess(userRoles);
    this.hasEditSRAccess = this.userService.getEditSRRequest(userRoles);
    this.hasWarrantyModulesAccess = this.userService.getWarrantyModuleAccess(userRoles);


    this.amcService.refreshNotifier.subscribe((module) => {
      this.fetchTabData()
    });

    this.amcService.updateSRList.subscribe((res: any) => {
      if (res) {
        this.fetchSRDetails();
      }
    });

    this.fetchSRConfig();
  }

  fetchSRConfig() {
    this.amcService.getSRConfig().subscribe((res: any) => {
      if (res && res.status) {
        console.log("config found in amc", res.payload);
        localStorage.setItem("sr-config", JSON.stringify(res.payload));
      }
    });
  }

  fetchAMCDetails() {
    this.amcService.getAMCDetails(this.lead_uuid).subscribe((val: any) => {

      if (val && val['status']) {
        this.amc_active = true;
        this.amc_details_array = val["payload"].data;
        console.log("amc details array ", this.amc_details_array);
        for (var i = 0; i < this.amc_details_array.length; i++) {
          if (this.amc_details_array[i].status=='active' && this.amc_details_array[i].cost_balance > 0) {
            this.amc_details_array[i].status = "processing";
          }
        }
        this.img_arrays = [];
        this.amc_details_array.forEach((amc_pack: any) => {
          this.img_arrays.push(JSON.parse(amc_pack['images']));
        });
      }
    });
  }

  getRowIndex(row: any): number {
    return this.table.bodyComponent.getRowIndex(row);   // row being data object passed into the template
  }

  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe(res => {
      if (res && res['status']) {
        console.log('property details found out');
        console.log(res);
        this.propertyData = res['payload'];
      }
    });
  }

  openAMCPlanDialog(index?: number) {
    let dialogRef = this.dialog.open(ManageAmcPlanComponent, {
      data: {
        path: '/' + this.propertyData.identifier + '/amc/',
        property_uuid: this.lead_uuid,
        label: 'Documents',
        can_edit: this.hasAMCDetailsAccess,
        amc_details: index != null ? JSON.parse(JSON.stringify(this.amc_details_array[index])) : {}
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        console.log("success...");
        this.fetchAMCDetails();
      }
    });
  }

  checkInactive(index: number) {
    if (this.amc_details_array[index]['status'] == 'active' || this.amc_details_array[index]['status'] == 'processing') {
      return false;
    }
    return true;
  }

  getAMCDate(date, idx) {
    // if(this.amc_details_array[idx]['status'] == 'active' || this.amc_details_array[idx]['status'] == 'expired') {
    return date ? this.datepipe.transform(new Date(date), 'dd MMM, yyyy').toString() : '--';
    // }
    // return '--';
  }

  getAMCDesc(idx) {
    switch (this.amc_details_array[idx]['status']) {
      case 'active': return 'This plant has active ' + this.amc_details_array[idx]['plan']['name'] + ' plan';
      case 'expired': return this.amc_details_array[idx]['plan']['name'] + ' plan expired';
      case 'processing': return this.amc_details_array[idx]['plan']['name'] + ' plan processing';
      case 'cancelled': return this.amc_details_array[idx]['plan']['name'] + ' plan cancelled';
      default: return this.amc_details_array[idx]['plan']['name'] + ' plan selected';
    }
  }

  deleteAsset(event) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(val => {
      if (val == "success") {
        console.log("delete confirmation ***************");
        console.log(event);
        this.amcService.deleteAsset({ 'assets_uuid': event.uuid }).subscribe(val => {
          if (val && val['status']) {
            this.toast.success("SUCCESSFULLY DELETED THE ASSET");
            this.fetchTabData();
          }
        });
      }
    });
  }

  createAsset(row?) {
    if (row == null) row = {};
    let dialogRef = this.dialog.open(ManageAssetComponent, {
      data: {
        property_uuid: this.lead_uuid,
        row: row
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == "success") {
        console.log("success...");
        this.fetchTabData();
      }
    });
  }

  openSRLog(uuid: string) {
    let dialogRef = this.dialog.open(ManageSrComponent, {
      data: {
        path: '/' + this.propertyData.identifier + '/amc/',
        property_uuid: this.lead_uuid,
        ticket_uuid: uuid,
        label: 'Documents',
        can_edit: this.hasEditSRAccess
      }
    });

    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        this.fetchTabData();
      }
    });
  }

  createSR() {
    let dialogRef = this.dialog.open(CreateSrModalComponent, {
      data: {
        path: '/' + this.propertyData.identifier + '/src/',
        property_uuid: this.lead_uuid,
        property_amc_uuid: this.dummy_amc_uuid,
        cluster_uuid: this.propertyData.cluster.uuid,
        label: 'Documents'
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        console.log("refreshing..");
        this.fetchTabData();
      }
    });
  }

  switchActiveTab(i) {
    this.active_index = i;
    this.getFilteredList();
  }

  activateAMC() {
    this.amc_active = !this.amc_active;
  }

  fetchTabData() {
    if (this.selected_tab == 0) {
      this.fetchAMCDetails();
    } else if (this.selected_tab == 1) {
      this.fetchSRDetails();
    } else if (this.selected_tab == 2) {
      this.fetchAssetDetails();
    }
  }

  fetchSRDetails() {
    this.amcService.getServiceRequestList({ property_uuid: this.lead_uuid, page: this.srOffset + 1 }).subscribe((val: any) => {
      if (val && val.status) {

        this.srList = val.payload.data;
        this.srCount = val.payload.count;
        this.srOffset = val.payload.current_page - 1;
        this.srLimit = val.payload.data_per_page;
      }
    });
  }

  getFilteredList() {
    console.log("getting filtered list");
    let category = "";
    switch (this.active_index) {
      case 0: category = "Modules"; break;
      case 1: category = "Inverters"; break;
      case 2: category = "Structure"; break;
      case 3: category = "BOS"; break;
    }

    this.filtered_asset_list = [];
    let dummy_list = [];
    this.asset_list.forEach((asset: any) => {
      if (asset.warranty_module && asset.warranty_module.category.toLowerCase() == category.toLowerCase()) {
        dummy_list.push(asset);
      }
    });

    this.filtered_asset_list = dummy_list;
    console.log("final filtered list");
    console.log(this.filtered_asset_list);
  }

  fetchAssetDetails() {
    this.amcService.getAssetList({ 'property_uuid': this.lead_uuid }).subscribe((val: any) => {
      if (val && val['status']) {
        console.log('result from asset details');
        console.log(val);
        this.asset_list = val['payload'].data;
        console.log(this.asset_list);
        this.getFilteredList();
      }
    });
  }

  onActivate(event) {
    if (event.type == "click" && event.cell != '0') {
      console.log('row clicked');
      console.log(event.row.uuid);
      this.openSRLog(event.row.uuid);
    }
  }

  deleteAMC(idx?) {
    console.log("delete event ", idx);
    if (idx != null) {
      let dialogRef = this.dialog.open(
        DeleteModalComponent,
        { data: { 'title': 'Delete AMC Plan', 'desc': 'Are you sure you want to delete this plan?' } }
      );

      dialogRef.componentInstance.option.subscribe((val) => {
        if (val == 'success') {
          console.log("deleting");
          // call delete amc api
          this.amcService.deletePropertyAMC({ property_amc_uuid: this.amc_details_array[idx]['uuid'] }).subscribe((res: any) => {
            if (res && res.status) {
              this.fetchTabData();
            }
          });
        }
      });
    }
  }

  onPage(event) {
    this.srOffset = event.offset;
    this.fetchSRDetails();
  }

  editAsset() {

  }

  onTabChange(event) {
    this.selected_tab = event.index;
    this.fetchTabData();
  }
}
