import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth-service/auth.service";
import { MessageToastService } from "../toast/message-toast.service";
import { MenuService } from "../sidebar/menu.service";

@Injectable({
  providedIn: "root"
})
export class LoginGuard implements CanActivate {
  accessiblUrl: string;
  loop: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private toast: MessageToastService,
    private menu: MenuService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.getAccessToken() != null && this.auth.getUser() != null) {
      this.firstAccessibleURL();
      this.toast.success("ALREADY LOGGED IN");
      return false;
    } else {
      return true;
    }
  }

  firstAccessibleURL() {
    console.log('navigating to first url');
    let access: string;
    let userInfo = JSON.parse(this.auth.getUser());
    if (userInfo.user_type == "amplus" || userInfo.user_type == "partner") {
      access = userInfo.user_roles[0];
    } else {
      access = userInfo.user_type;
    }

    this.loop = true;
    this.menu.getAll().forEach(group => {
      this.accessiblUrl = "/" + group.state;
      if (this.loop) {
        group.children.forEach(item => {
          if (this.loop) {
            if (item["roles"].indexOf(access) >= 0 && (!item['visible'] || item['visible']!='false')) {
              this.accessiblUrl = this.accessiblUrl + "/" + item.state;
              console.log('navigating to ');
              console.log(this.accessiblUrl);
              this.router.navigate([this.accessiblUrl]);
              this.loop = false;
            }
          }
        });
      }
    });
  }
}
