import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSidenav } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { MenuToggleService } from '../../service/menu-toggle/menu-toggle.service';
import { TicketService } from '../../service/ticket/ticket.service';
import { TicketLogComponent } from '../ticket-log/ticket-log.component';
import * as FunnelGraph from "funnel-graph-js";
import { FormBuilder } from '@angular/forms';
import { AccountService } from '../../service/account/account.service';
declare var $: any;

@Component({
  selector: 'app-ticket-dashboard',
  templateUrl: './ticket-dashboard.component.html',
  styleUrls: ['./ticket-dashboard.component.scss']
})
export class TicketDashboardComponent implements OnInit {

  ticketList = [];
  ticketCount = 1;
  ticketOffset = 0;
  ticketLimit = 10;
  funnelOptions: any;
  funnelValues: any = [1, 1];
  funnelLables: any = ["Select a Cluster", " "];
  funnelGraph: FunnelGraph;
  graph: any;
  _1vh: number;
  userList = [];
  leadPropertyId = null;
  stageFilter = 'all';
  statusFilter = 'all';
  stageFilterOptions = [
    { label: 'All Ticket Status', value: '' },
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' }
  ];
  statusFilterOptions = [
    { label: 'All Statuses', value: '' },
    { label: 'Red', value: 'red' },
    { label: 'Yellow', value: 'yellow' },
    { label: 'Green', value: 'green' },
  ];
  offset = 0;
  form: any;
  formData: any = { page: 1 };
  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private menuToggle: MenuToggleService,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private titleCasePipe: TitleCasePipe,
  ) { }

  ngOnInit() {

    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });

    this.getTicketList();
    this.getUsersDropdown();
    this.form = this.fb.group({
      page: 1,
      status: '',
      flag: '',
      assigned_to_id: '',
      assigned_to_type: '',
      stage_type: ''
    });
    this._1vh = Math.round(window.innerHeight / 100);

    this.funnelOptions = {
      chart: {
        type: "funnel"
      },
      title: {
        text: "Tickets funnel"
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: true
          },
          center: ["40%", "50%"],
          neckWidth: "30%",
          neckHeight: "25%",
          width: "80%"
        }
      },
      legend: {
        enabled: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true
                  },
                  center: ["50%", "50%"],
                  width: "100%"
                }
              }
            }
          }
        ]
      }
    };
  }

  getUsersDropdown() {
    this.accountService.getAllUsersList().subscribe((val: any) => {
      if (val && val.status) {
        console.log("users list found out: ", val.payload);
        Object.keys(val.payload).forEach((type) => {
          val.payload[type].forEach((user) => {
            this.userList.push({ 'label': user.name + ' (' + this.titleCasePipe.transform(user.type) + ')' + ' - ' + this.getDetail(user), 'value': user.uuid + '**' + user.type });
          });
        });
      }
    });
  }

  reset() {
    this.form.patchValue({
      page: 1,
      status: '',
      flag: '',
      assigned_to_id: '',
      assigned_to_type: '',
      stage_type: ''
    });
  }

  getDetail(user) {
    if (user.email) { return user.email; }
    return user.phone_number;
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  sidenavOpen() {
    $('app-ticket-dashboard').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    $('app-ticket-dashboard').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  filter() {
    console.log("here in filter()-->")
    this.form.get("page").setValue(1);
    this.formData = JSON.parse(JSON.stringify(this.form.value));
    if (this.form.get('assigned_to_id').value != '' && this.form.get('assigned_to_id').value != undefined) {
      this.formData.assigned_to_id = this.form.get('assigned_to_id').value.split('**')[0];
      this.formData.assigned_to_type = this.form.get('assigned_to_id').value.split('**')[1];
    }
    this.getFilteredList();
  }

  createGraph() {
    $("#container").empty();

    this.graph = new FunnelGraph({
      container: ".funnel-container",
      gradientDirection: "horizontal",
      data: {
        labels: [...this.funnelLables],
        colors: ["#ffaf78", "#ff9180", "#ff4a8d"],
        values: [...this.funnelValues]
      },
      displayPercent: true,
      direction: "horizontal",
      height: this._1vh * 12,
      width: this.findWidth(this.funnelLables.length) / 1.25
    });

    this.graph.draw();
    this.correctGraphData();
    setTimeout(() => {
      let funnelDiv = document.getElementsByClassName("svg-funnel-js__labels");
      for (var i = 0; i < funnelDiv.length; i++) {
        funnelDiv[i].addEventListener("click", event => {
          console.log("funnel div clicked");
          let division = (<HTMLInputElement>(
            (<HTMLInputElement>event["srcElement"]).childNodes[1]
          )).innerText;
          console.log("division--->__", this.originalType(division), "__");
          if (this.originalType(division) == "  ") {
            delete this.formData['stage_type'];
            if (this.form.get('assigned_to_id').value != '' && this.form.get('assigned_to_id').value != undefined) {
              this.formData.assigned_to_id = this.form.get('assigned_to_id').value.split('**')[0];
              this.formData.assigned_to_type = this.form.get('assigned_to_id').value.split('**')[1];
            }
            this.getFilteredList();
          }
          else {
            this.form.get("stage_type").setValue(this.originalType(division));
            this.formData = JSON.parse(JSON.stringify(this.form.value));
            if (this.form.get('assigned_to_id').value != '' && this.form.get('assigned_to_id').value != undefined) {
              this.formData.assigned_to_id = this.form.get('assigned_to_id').value.split('**')[0];
              this.formData.assigned_to_type = this.form.get('assigned_to_id').value.split('**')[1];
            }
            this.getFilteredList();
          }
        });
        if (i == 0) {
          let division = funnelDiv[i].childNodes[0].childNodes[1].textContent;
          if (division) {
            console.log("division--(division)->", this.originalType(division));
          }
        }
      }
    }, 100);
  }

  cleanData(performanceData, state) {
    this.funnelLables = [];
    this.funnelValues = [];
    performanceData.forEach(val => {
      if (state == "open") {
        this.funnelValues.push(val.ticket_open);
      }
      else if (state == "closed") {
        this.funnelValues.push(val.ticket_closed);
      }
      else {
        this.funnelValues.push(val.ticket_count);
      }
      this.funnelLables.push(this.formatType(val.label));
    });

  }

  correctGraphData() {
    Array.from(document.getElementsByClassName("label__percentage")).forEach(
      function (item) {
        if (item.innerHTML == "NaN%") item.innerHTML = "0%";
      }
    );
  }
  findWidth(len): number {
    return len * 200;
  }

  getTicketList() {
    this.ticketService.getGlobalTicketList().subscribe((val: any) => {
      if (val && val.status) {
        this.ticketList = val.payload.data;
        this.ticketCount = val.payload.count;
        this.ticketOffset = val.payload.current_page - 1;
        this.ticketLimit = val.payload.data_per_page;
        this.cleanData(val.payload.performance_data, "all");
        this.createGraph();
        console.log("ticket list found  ", val.payload.data);
      }
    });
  }

  getFilteredList() {
    this.ticketService.getTicketList(this.formData).subscribe((val: any) => {
      if (val && val.status) {
        this.ticketList = val.payload.data;
        this.ticketCount = val.payload.count;
        this.ticketOffset = val.payload.current_page - 1;
        this.ticketLimit = val.payload.data_per_page;
        this.cleanData(val.payload.performance_data, this.form.get('status').value);
        this.createGraph();

        console.log("ticket list found  ", val.payload.data);
      }
    });
    this.sidenavBar.close();
  }

  formatType(type) {
    return this.titleCasePipe.transform(type.split('_').join(' ')).toUpperCase();
  }

  originalType(type) {
    return this.titleCasePipe.transform(type.split(' ').join('_')).toLowerCase();
  }

  onActivate(event) {
    if (event.type == "click" && event.cell != '0') {
      console.log('row clicked: ', event);
      this.leadPropertyId = event.row.lead_property.uuid;
      event.row['property_uuid'] = this.leadPropertyId;
      console.log("leadPropertyID=================", this.leadPropertyId);
      let dialogRef = this.dialog.open(TicketLogComponent, {
        data: event.row,
      });

      dialogRef.componentInstance.status.subscribe(data => {
        if (data == 'success') {
          console.log("refreshing..");
          this.getFilteredList();
        }
      });
    }
  }

  onPage(event) {
    console.log("page changed: ", event);
    this.formData['page'] = event.offset + 1;
    this.offset = event.offset;
    this.getFilteredList();
  }

  // onFilterChange(event, type) {
  //   console.log("filter changed: ", event);
  //   if (event == 'all') { delete this.currentState[type]; }
  //   else { this.currentState[type] = event; }
  //   this.getFilteredList();
  // }

  downloadList() {
    if (this.ticketList.length > 0) {
      console.log('request for downloading list');
      let downloadState = JSON.parse(JSON.stringify(this.formData));
      delete downloadState['page'];
      console.log('current angular download object');
      console.log(downloadState);
      downloadState['download'] = 1;
      // this.currentState['download'] = 1;
      // this.getFilteredList();
      this.ticketService.downloadListByUrl(this.ticketService.ticketListUrl, downloadState);
      console.log("ticket list downloaded!!!")
    }
    else {
      console.log("ticket list empty!");
    }
  }

}
