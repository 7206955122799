import { Component, OnInit } from "@angular/core";
import { FieldConfig } from "../field.interface";
import { FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
declare var $: any;

@Component({
  selector: "app-date-time",
  template: `
    <mat-form-field class="demo-full-width margin-top" [formGroup]="group">
      <input
        matInput
        [min]='min'
        [max]='max'
        class="form-control lead-stage-date"
        [formControlName]="field.name"
        [owlDateTime]="dt6"
        [owlDateTimeTrigger]="dt6"
        [placeholder]="field.label"
        autocomplete="random"
      />
      <owl-date-time (afterPickerOpen)='changeField()' (afterPickerClosed)='changeField()' #dt6></owl-date-time>
      <mat-hint></mat-hint>
      <mat-error *ngIf="group.get(field.name).hasError('required')">{{field.label}} is required</mat-error>
  <mat-error *ngIf="group.get(field.name).hasError('owlDateTimeMax')"
        >{{ field.label }} exceeds the maximum value of {{datepipe.transform(group.get(field.name).errors.owlDateTimeMax['max'], 'MM-dd-yyyy h:mm a')}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('owlDateTimeMin')"
      >{{ field.label }} is less than the minimum value of {{datepipe.transform(group.get(field.name).errors.owlDateTimeMin['min'], 'MM-dd-yyyy h:mm a')}}</mat-error
    >
    </mat-form-field>
    <br />
  `,
  styleUrls: ["./date-time.component.scss"]
})
export class DateTimeComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  value: any;
  
  min: any = '';
  max: any = '';

  pickerOpen: boolean = false;

  constructor(private datepipe: DatePipe) {}
  ngOnInit() {
    if(this.field.value && this.field.value != 0)
    {this.group.get(this.field.name).setValue(new Date(this.field.value * 1000));}
    console.log('extra validation found in datetime field');
    console.log(this.validation);

    this.validation.forEach(val => {
      if(val['start_time'] && val['start_time'] != 0) {
        console.log('printing start time');
        this.min = new Date(val['start_time']*1000);
        console.log(this.min);
        console.log(val['start_time']);
      }
      if(val['end_time'] && val['end_time'] != 0) {
        console.log('printing end time');
        this.max = new Date(val['end_time']*1000);
        console.log(this.max);
      }
    });
  }
  

  changeField() {
    this.pickerOpen = !this.pickerOpen;
    console.log(this.pickerOpen);
  }
}
