import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BaseService } from "../base.service";
import { MessageToastService } from "../toast/message-toast.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ClusterService {
  clusterURL = "v1/cluster/info?";
  junkClusterListURL = "v1/property/cluster/list";
  getClusterListURL = "v1/cluster/list";
  salesPartnerListURL = "v1/account/salespartner/list";
  assignClusterURL = "v1/property/unassigned/cluster";
  clusterAdminURL = "v1/account/clusteradmin?";
  clusterAdminList = "v1/account/clusteradmin/list";
  multiPropertyURL = "v1/property/unassigned/cluster/multiple";
  clusterTechnicianURL = "v1/account/clustertechnician?";
  clusterTechnicianListURL = "v1/account/clustertechnician/list";
  channelPartnerURL = "v1/account/channelpartner";
  downloadChannelPartnerURL = "v1/account/channelpartner/list?download=true";
  opsManagerListURL = "v1/account/opsmanager/list";
  opsManagerURL = "v1/account/opsmanager";
  reassignClusterUrl = 'v1/property/astral/reassign/cluster';
  getAssigningEpcSalesPartnerURL ="v1/account/epcpartner/list";

 // salesPartnerListUuidURL = "/v1/account/salespartner/list";
  postAssignSalesPartnerURL = "v1/account/channelpartner/bulk_assignment";
  postAssignSalesPartnerOnlyURL= "v1/property/bulk_assignment"; 
  getAssigningSalesPartnerURL ="v1/report/analytics/lead/filter/data";

  globalCluster = new BehaviorSubject(null);
  curPropertyCluster = new BehaviorSubject(null);
  refereshNotifer = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, "", messageService);
  }

  setGlobalCluster(cluster) {
    console.log("setting global cluster in service");
    this.globalCluster.next(cluster);
  }

  setLocalCluster(cluster) {
    this.curPropertyCluster.next(cluster);
  }

  saveClusterData(data) {
    return this.http.post(environment.serverURl + this.clusterURL, data);
  }

  updateClusterData(data) {
    return this.http.put(environment.serverURl + this.clusterURL, data);
  }

  deleteClusterData(uuid) {
    return this.http.delete(
      environment.serverURl + this.clusterURL + "uuid=" + uuid
    );
  }

  getClusterList() {
    return this.http.get(environment.serverURl + this.getClusterListURL);
  }

  getAssigningSalesPartner(clusterID) {
    return this.http.get(environment.serverURl + this.getAssigningSalesPartnerURL+"?cluster_id=" +clusterID);
  }

  getAssigningEpcSalesPartner(epc_cluster_uuid,clusterID) {
    return this.http.get(environment.serverURl + this.getAssigningEpcSalesPartnerURL+"?cluster_uuid=" +epc_cluster_uuid +"&sales_partner_uuid=" +clusterID);;
  }



  getJunkClusterList() {
    return this.http.get(environment.serverURl + this.junkClusterListURL);
  }

  assignCluster(prop_id, cluster_id) {
    return this.http.put(environment.serverURl + this.assignClusterURL, {
      property_id: prop_id,
      cluster_id: cluster_id,
    });
  }

  reassignCluster(cluster_id, prop_id) {
    return this.http.put(environment.serverURl + this.reassignClusterUrl, {
      cluster_id: cluster_id,
      lead_property_uuid: prop_id,
    });
  }

  saveClusterAdminData(data) {
    return this.http.post(environment.serverURl + this.clusterAdminURL, data);
  }

  updateClusterAdminData(data) {
    return this.http.put(environment.serverURl + this.clusterAdminURL, data);
  }

  deleteClusterAdminData(uuid) {
    return this.http.delete(
      environment.serverURl + this.clusterAdminURL + "uuid=" + uuid
    );
  }

  getClusterAdminDetails() {
    return this.http.get(environment.serverURl + this.clusterAdminList);
  }

  multiplePropertyAssignment(data) {
    return this.http.put(environment.serverURl + this.multiPropertyURL, data);
  }

  getClusterTechnicians(cluster_id) {
    return this.http.get(
      environment.serverURl + this.clusterTechnicianListURL,
      {
        params: {
          cluster_uuid: cluster_id,
        },
      }
    );
  }

  createClusterTechnician(data) {
    return this.http.post(
      environment.serverURl + this.clusterTechnicianURL,
      data
    );
  }

  updateClusterTechnician(data) {
    return this.http.put(
      environment.serverURl + this.clusterTechnicianURL,
      data
    );
  }

  deleteClusterTechnician(uuid) {
    return this.http.delete(
      environment.serverURl + this.clusterTechnicianURL + "uuid=" + uuid
    );
  }

  getChannelPartners(data) {
    return this.http.get(
      environment.serverURl + this.channelPartnerURL + '/list', {
      params: data
    }
    );
  }

  createChannelPartner(data) {
    return this.http.post(
      environment.serverURl + this.channelPartnerURL,
      data
    );
  }

  updateChannelPartner(data) {
    return this.http.put(
      environment.serverURl + this.channelPartnerURL,
      data
    );
  }

  deleteChannelPartner(uuid) {
    return this.http.delete(
      environment.serverURl + this.channelPartnerURL + "?uuid=" + uuid
    );
  }

  convertLeadToChannelPartner(uuid) {
    return this.http.post(
      environment.serverURl + this.channelPartnerURL + "/lead", uuid
    );
  }

  getSalesPartnerListUuid(uuid) {
    return this.http.get(
      environment.serverURl + this.salesPartnerListURL+"?cluster_uuid="+ uuid,
      {
        params: {
         // cluster_uuid: uuid,
        },
      }
      
    );
 
  }


  postAssignSalesPartner(selectedItems_uuid,salesPartnerUuid,lead_propertyUuid) {

    return this.http.post(
      environment.serverURl + this.postAssignSalesPartnerURL,
      {
        
          "sales_partner_uuid": salesPartnerUuid,
          "cluster_uuid": lead_propertyUuid,      
          "lead_property_uuid": selectedItems_uuid//["ea1ccaa8-412d-456c-bef8-788369d68a76", "ea1ccaa8-412d-456c-bef8-788369d68a78"],
        
      }
      
    );
 
  }


  postAssignSalesPartnerOnly(selectedItems_uuid,lead_propertyUuid) {

    return this.http.post(
      environment.serverURl + this.postAssignSalesPartnerOnlyURL,
      {
        
          "sales_partner_uuid": lead_propertyUuid,      
          "lead_property_uuid": selectedItems_uuid//["ea1ccaa8-412d-456c-bef8-788369d68a76", "ea1ccaa8-412d-456c-bef8-788369d68a78"],
        
      }
      
    );
 
  }



  getSalesPartnerList(params) {
    return this.http.get(
      environment.serverURl + this.salesPartnerListURL,
      {
        params: {
          cluster_uuid: params,
        },
      }
    );
  }

  getAllSalesPartnerList() {
    return this.http.get(environment.serverURl + this.salesPartnerListURL);
  }

  downloadListByUrl(url, data) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: "arraybuffer",
      })
      .subscribe((res) => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          "application/vnd.mx-excel",
          "channel-partners" +
          "_" +
          timeObject.getDate() +
          "/" +
          timeObject.getMonth() +
          "/" +
          timeObject.getFullYear() +
          "__" +
          timeObject.getHours() +
          "-" +
          timeObject.getMinutes() +
          "-" +
          timeObject.getSeconds() +
          ".xls"
        );
      });
  }
  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement("a");
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = "_blank";
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }

  getOpsManagerList() {
    return this.http.get(environment.serverURl + this.opsManagerListURL);
  }

  createOpsManager(data) {
    return this.http.post(
      environment.serverURl + this.opsManagerURL,
      data
    );
  }

  updateOpsManager(data) {
    return this.http.put(
      environment.serverURl + this.opsManagerURL,
      data
    );
  }

  deleteOpsManager(uuid) {
    return this.http.delete(
      environment.serverURl + this.opsManagerURL + "?uuid=" + uuid
    );
  }

}
