import { Injectable } from "@angular/core";
import { Observable, from, throwError } from "rxjs";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "../auth-service/auth.service";
import { LoaderService } from "../loader/loader.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
  networkCalls = 0;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    this.networkCalls++;
    if (this.networkCalls > 0) {
      this.showLoader();
    }
    return next
      .handle(this.addAuthenticationToken(request))
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.networkCalls--;
              if (this.networkCalls == 0) {
                this.hideLoader();
              }
            }
          },
          error => {
            this.networkCalls--;
            if (this.networkCalls == 0) {
              this.hideLoader();
            }
            if (error.status == 401 || error.status == 403) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              this.router.navigate(["/login"]);
            }
          }
        ),
        catchError(error => {
          return throwError(error)
          // return Observable.throw(error);
        })
      )
      .toPromise();
  }

  addAuthenticationToken(request) {
    const accessToken = this.authService.getAccessToken();
    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: this.authService.getAccessToken()
      }
    });
  }

  showLoader() {
    this.loaderService.show();
  }

  hideLoader() {
    this.loaderService.hide();
  }
}
