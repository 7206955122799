import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadLogicService {
  commonUpload = "v1/common/upload";
  businessHeadUrl: any;
  approvalOfBusinessImgUrl :any;
  cancelledChequeImgUrl : any;
  imgType: any;
  constructor(private http: HttpClient) { }
  cancelledCheque = (event: Event) => {
    this.imgType = "cancelledChequeImg";
  }

  approvalOfBusiness= (event: Event) => {
    this.imgType = "approvalOfBusinessImg";
  }

  fileURLInitiateTransfer(fileToUpload: File) {
    if (fileToUpload) {
      const formData = new FormData();
      formData.append('file', fileToUpload);
      this.http.post(environment.serverURl + this.commonUpload, formData).subscribe(
        response => {
          // Handle the response from the API
          console.log('File uploaded successfully', response);
          let data = response['payload'];
          if(this.imgType ==="approvalOfBusinessImg"){
              this.approvalOfBusinessImgUrl = data.url;
          }else if(this.imgType ==="cancelledChequeImg"){
            this.cancelledChequeImgUrl = data.url;
          }else{
            this.businessHeadUrl = data.url;
          }
        },
        error => {
          console.error('File upload failed', error);
        }
      );
    }
  }
}
