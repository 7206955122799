import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSidenav, MatDialog } from "@angular/material";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { LoaderService } from 'src/app/common/service/loader/loader.service';
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { UserService } from 'src/app/common/service/user-service/user.service';
declare var $: any;

@Component({
    selector: 'app-cost-sheet',
    templateUrl: './cost-sheet.component.html',
    styleUrls: ['./cost-sheet.component.scss']
})
export class CostSheetComponent implements OnInit {
    form: any;
    isEditMode = false;
    count = 0;
    offset = 0;
    limit = 0;
    clusterList = [];
    editBenchmarkAccess = false;
    @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
    constructor(
        private fb: FormBuilder,
        private clusterService: ClusterService,
        private toast: MessageToastService,
        private authService: AuthService,
        private dialog: MatDialog,
        private loaderService: LoaderService,
        private menuToggle: MenuToggleService,
        private userService: UserService
    ) { }

    ngOnInit() {

        let user = JSON.parse(this.authService.getUser());
        this.editBenchmarkAccess = this.userService.benchmarkEditAccess(user);
        this.form = this.fb.group({
            uuid: [null],
            identifier: ["", Validators.required],
            benchmark_cost: ["", Validators.required],
        });

        this.getClusterList();
        this.menuToggle.toggleMenu(false);
    }

    getClusterList() {
        this.clusterService.getClusterList().subscribe((val: any) => {
            console.log('global cluster list found out');
            console.log(val);
            if (val && val['status']) {
                this.clusterList = [...val.payload.clusters];
                console.log('final cluster list');
                console.log(this.clusterList);
            }
        });
    }

    sidenavOpen() {
        console.log('sidenav opened');
        $('app-cost-sheet').css({ height: '100%' });
        $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
    }

    sidenavClose() {
        this.reset();
        console.log('sidenav closed');
        $('app-cost-sheet').css({ height: 'auto' });
        $('.funnel-filter-container').css({ 'padding-bottom': '' });
    }

    sidenavToggle() {
        this.sidenavBar.open();
    }

    editCluster(row) {
        this.sidenavBar.open();
        this.reset();
        this.isEditMode = true;
        this.form.patchValue({
            uuid: row.uuid,
            identifier: row.identifier,
            benchmark_cost: row.benchmark_cost,
        });
    }

    reset() {
        this.form.patchValue({
            uuid: null,
            identifier: "",
            benchmark_cost: ""
        });
        this.isEditMode = false;
    }

    onPage(event) {
        this.offset = event.offset;
        this.getClusterList();
    }

    deleteCluster(row) {
        let dialogRef = this.dialog.open(DeleteModalComponent);

        dialogRef.componentInstance.option.subscribe(data => {
            if (data == 'success') {
                this.clusterService.deleteClusterData(row.uuid).subscribe((result: any) => {
                    if (result && result.status) {
                        this.reset();
                        this.toast.success("CLUSTER DELETED SUCCESSFULLY");
                        this.getClusterList();
                    }
                });
            }
        });

    }

    submit() {
        if (this.isEditMode) {
            this.clusterService
                .updateClusterData(this.form.value).subscribe((val: any) => {
                    if (val && val['status']) {
                        this.toast.success('CLUSTER UPDATED SUCCESSFULLY');
                        this.getClusterList();
                    } else {
                        this.toast.error('OPERATION FAILED');
                    }
                    this.sidenavBar.close();
                    this.sidenavClose();
                });
        }
    }
}
