import { Component, EventEmitter, Output, Input, Renderer } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { LoginService } from "../../service/login/login.service";
import { AuthService } from "../../service/auth-service/auth.service";
import { SignOutComponent } from "../sign-out/sign-out.component";
import { ClusterService } from "../../service/cluster/cluster.service";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { MenuToggleService } from "../../service/menu-toggle/menu-toggle.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";
import { UserInfoComponent } from "../user-info/user-info.component";
import { UserService } from "../../service/user-service/user.service";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  userName: string;
  userAbbr: string;
  userRole = "";
  clusterList: any = [];
  selectedCluster: any;
  selectedBenchMark: any;
  curBenchmark: any;
  curPropertyCluster: any;
  isClusterAdmin: boolean = false;
  isBusinessUser: boolean = false;
  hasAddAccess: boolean = false;
  isPropertyView: boolean = false;
  isDiscomUser: boolean = false;
  hideClusterInfo: boolean = false;
  authorizationToken : any;
  userUuid : any;

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private authService: AuthService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private renderer: Renderer

  ) {
    // to close popup if we click anywhere else on the screen
    renderer.listenGlobal("document", "click", event => {
      let requestPopup = document.getElementById("mark-channel-partner-popup");
      if (
        !event.target.classList.contains("popup-action-btn") &&
        requestPopup
      ) {
        requestPopup.remove();
      }
    });
  }

  @Input()
  heading: string;
  // @Output()
  // toggleSidenav = new EventEmitter<void>();

  mode = "DARK MODE";
  options = {
    lang: "en",
    dark: false,
    settings: false,
    docked: false,
    boxed: false,
    opened: false,
  };

  ngOnInit() {


    this.setUserDetails();
    this.getClusterList();
    this.isPropertyView = location.pathname.indexOf("lead/view/") >= 0;

    this.clusterService.curPropertyCluster.subscribe((cluster: any) => {
      console.log(cluster);
      if (cluster) {
        this.curPropertyCluster = cluster.identifier;
        this.curBenchmark = cluster.benchmark_cost;
      } else {
        this.curPropertyCluster = "";
        this.curBenchmark = "";
      }
    });
    console.log("location.pathname", location.pathname);
    let userDetails = JSON.parse(this.authService.getUser());
    

    this.hideClusterInfo = location.pathname.indexOf("lead/pre-sales-lead") >= 0 || userDetails["user_type"] == "discom_user" || location.pathname.indexOf("lead/ha-lead") >= 0 || location.pathname.indexOf("maintenance/amc-scheduler") >= 0;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        console.log("header subscribe ...", data);
        this.isPropertyView = data.url.indexOf("lead/view/") >= 0;
        this.hideClusterInfo = data.url.indexOf("lead/pre-sales-lead") >= 0 || userDetails["user_type"] == "discom_user" || data.url.indexOf("lead/ha-lead") >= 0 || data.url.indexOf("maintenance/amc-scheduler") >= 0;
      });

    this.route.queryParams.subscribe((params) => {
      this.isPropertyView =
        params["id"] == "create" ? false : this.isPropertyView;
    });

    this.userUuid = JSON.parse(this.authService.getUser()).user_details.uuid;
    this.authorizationToken = localStorage.getItem("token")
  }

  getClusterList() {
    let userDetails = JSON.parse(this.authService.getUser());
    console.log("user is " + userDetails["user_type"]);
    console.log(userDetails);

    if (["discom_user"].indexOf(userDetails["user_type"]) != -1) {
      console.log("cluster details found out");
      console.log(userDetails["cluster_details"]);
      this.selectedCluster = userDetails["cluster_details"].identifier;
      this.clusterService.setGlobalCluster(userDetails["cluster_details"]);
    } else {
      this.clusterService.getClusterList().subscribe((val) => {
        console.log("value from header cluster list");
        console.log(val["payload"].clusters);
        this.clusterList = val["payload"].clusters;
        this.selectedCluster = this.clusterList[0].identifier;
        this.selectedBenchMark = this.clusterList[0].benchmark_cost;
        this.clusterService.setGlobalCluster(this.clusterList[0]);
        $("#header-cluster").val("");
        let element = document.getElementById("header-cluster-scroll");
        console.log("elemet found out for scrolling");
        console.log(element);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
        // setTimeout(()=>{element.animate({ scrollTop: 1000 }, 1000);},2000);
      });
    }

    this.hasAddAccess = this.userService.getClusterCreationAccess(userDetails);
  }

  resetPassword() {
    let dialogRef = this.dialog.open(ResetPasswordComponent);
    console.log("resetting");
  }

  userDetails() {
    let dialogRef = this.dialog.open(UserInfoComponent);
    console.log("user details");
  }

  setUserDetails() {
    this.userName = JSON.parse(this.authService.getUser()).user_details.name;

    this.userAbbr = "";
    this.userName.split(" ").forEach((word) => {
      if (word) {
        this.userAbbr += word[0];
      }
    });
  }

  signout() {
    let dialogRef = this.dialog.open(SignOutComponent);
  }

  // edit(cluster) {
  //   console.log('edit button clicked');
  //   console.log(cluster);
  //   // this.selectedCluster = cluster.identifier;
  // }

  addCluster() {
    console.log("adding cluster ");
    console.log($("#header-cluster").val());
    let data = { identifier: $("#header-cluster").val() };

    if (data.identifier != "") {
      this.clusterService.saveClusterData(data).subscribe((val) => {
        if (val && val["status"]) {
          this.toast.success("CLUSTER ADDED SUCCESSFULLY");
          this.getClusterList();
        } else {
          this.toast.error(val["message"].toUpperCase());
        }
      });
    } else {
      this.toast.error("PLEASE ENTER A CLUSTER NAME");
    }
  }

  selectCluster(cluster) {
    console.log("selected cluster clicked");
    console.log(cluster);
    this.selectedCluster = cluster.identifier;
    this.selectedBenchMark = cluster.benchmark_cost;
    this.clusterService.setGlobalCluster(cluster);
  }

  deleteCluster(cluster) {
    console.log("delete button clicked");
    console.log(cluster);
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe((data) => {
      if (data == "success") {
        this.clusterService
          .deleteClusterData(cluster.uuid)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("CLUSTER DELETED SUCCESSFULLY");
              this.getClusterList();
            }
          });
      }
    });
  }

  toggleSidemenu() {
    this.menuToggle.toggleMenu();
  }
}
