import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss']
})
export class EditRoomComponent implements OnInit {
  form: any;
  @Output() room_val: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditRoomComponent>,
    private fb: FormBuilder,
    private toast: MessageToastService,
  ) { }

  ngOnInit() {
    console.log("data----> edit department:::", this.data);
    this.form = this.fb.group({
      name: [this.data['name'], Validators.required],
      description: [this.data['description'], Validators.required]
    });
  }

  submit() {
    if (this.form.valid) {
      this.room_val.emit(this.form.value);
      this.dialogRef.close();
    }
    else {
      this.toast.error('PLEASE ENTER A ROOM NAME AND DESCRIPTION');
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
