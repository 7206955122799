import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { ActivityLogsComponent } from "./activity-logs/activity-logs.component";
import { CreateLogComponent } from "./create-log/create-log.component";

@Component({
    selector: "app-channel-partner-logs",
    templateUrl: "./channel-partner-logs.component.html",
    styleUrls: ["./channel-partner-logs.component.scss"],
})
export class ChannelPartnerLogsComponent implements OnInit {
    curComponent: any = ActivityLogsComponent;
    tabCompMap = {
        "log-list": ActivityLogsComponent,
        "create-log": CreateLogComponent,
    };

    @ViewChild("btnGroupWrapper", { static: false }) btnGroup: ElementRef;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private renderer: Renderer2
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.renderer.listen(this.btnGroup.nativeElement, "click", (event) => {
            if (event && event.target.className == "modal-btn") {
                console.log("btn clicked ", this.btnGroup.nativeElement);

                Array.from(this.btnGroup.nativeElement.children).forEach((btn) => {
                    this.renderer.removeClass(btn, "active-modal-btn");
                });

                this.renderer.addClass(event.target, "active-modal-btn");
                this.curComponent = this.tabCompMap[event.target.id];
            }
        });
    }
}
