import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { FieldConfig } from "src/app/common/component/dynamic-form/field.interface";
import { DynamicFormComponent } from "src/app/common/component/dynamic-form/dynamic-form/dynamic-form.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DatePipe } from "@angular/common";
import { BehaviorSubject, Subject } from "rxjs";
import { AccountService } from "src/app/common/service/account/account.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/common/component/confirmation-dialog/confirmation-dialog.component";
// import { ManageProposalComponent } from "src/app/common/component/manage-proposal/manage-proposal.component";
import { AddPaymentPlanComponent } from "src/app/common/component/add-payment-plan/add-payment-plan.component";
import { FinanceService } from "src/app/common/service/finance/finance.service";
import { AddPaymentModalComponent } from "src/app/common/component/finance/add-payment/add-payment.component";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { TatUpdateComponent } from "src/app/common/component/tat-update/tat-update.component";
import { SolarProductComponent } from "src/app/common/component/solar-product/solar-product.component";
import { UserService } from "src/app/common/service/user-service/user.service";
import { ManageAssetComponent } from "src/app/common/component/create-asset/create-asset.component";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { ViewAssetComponent } from "src/app/common/component/view-asset/view-asset.component";
import { CreateActivityLogComponent } from 'src/app/common/component/create-activity-log/create-activity-log.component';
import { $ } from "protractor";
import { EMarketPlaceComponent } from "src/app/common/component/e-market-place/e-market-place.component";
import { FileLikeObject, FileUploader } from "ng2-file-upload";
import { environment } from "src/environments/environment";
import { LoaderService } from "src/app/common/service/loader/loader.service";
import { ProposalComponent } from "src/app/common/component/proposal/proposal.component";

@Component({
  selector: "app-stage-view",
  templateUrl: "./stage-view.component.html",
  styleUrls: ["./stage-view.component.scss"],
})
export class StageViewComponent implements OnInit {
  @ViewChild(DynamicFormComponent, { static: false })
  form: DynamicFormComponent;

  para: string;
  lead_uuid: string;
  stage_uuid: string;
  stage_type: string;
  regConfig: FieldConfig[];
  access: string;
  stageType: string;
  completedOn: string;
  proposalStatus: string;
  valid: boolean;
  mapCheck: boolean = false;
  stage_name: string = "";
  isCompleted: boolean = false;
  payment_plan_status: boolean = false;
  is_emarket: boolean = false;
  cardCollapsed = true;
  propertyData: any;
  skipAccess: boolean = false;
  addPaymentAccess: boolean = false;
  pendingAmount = 0;
  addPlanAccess = false;
  lastPaymentStage = false;
  pendingAmountIgnoreLimit = 0;
  communicationCount = 0;
  communication_status = false;
  finalcomm_status: any;
  active_index = 0;
  warranty_modules = ['Modules', 'Inverters', 'Structure', 'BOS'];
  asset_list = [];
  filtered_asset_list = [];
  testDate: any;
  communincationAttempt: any;
  CommuncationCompleted: any;
  benchmark_cost: any;
  total_selling_price: any;
  final_system_capacity: any;
  subsidy: any;
  customer_share: any;
  billing_milestone_1: any;
  billing_milestone_2: any;
  subsidy_applicable: any;
  is_payment: any;
  customerShare: any;
  userType:any;
  canUpdatePrice : any;
  eMarketEditAccessDesigner: boolean = false;
  serverUrl: string = environment.serverURl;
  uploadUrl: string = "v1/emarket/bom_kit/upload";
  network_calls = 0;
  stageSubmitted: Subject<void> = new Subject<void>();
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  uploader: FileUploader = new FileUploader({
    url: this.serverUrl + this.uploadUrl,
    isHTML5: true,
    method: "POST",
    itemAlias: "file",
    authTokenHeader: "authorization",
    authToken: this.authService.getAccessToken(),
    maxFileSize: 10 * 1024 * 1024,
  });

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    private toast: MessageToastService,
    private accountService: AccountService,
    private datepipe: DatePipe,
    private menuToggle: MenuToggleService,
    private loaderService: LoaderService,
    private clusterService: ClusterService,
    private dialog: MatDialog,
    private userService: UserService,
    private financeService: FinanceService,
    private authService: AuthService,
    private amcService: AMCService
  ) { }

  ngOnInit() {

    this.userType = JSON.parse(this.authService.getUser());
    this.canUpdatePrice = this.userService.getClusterAdminEditAccess(this.userType);
    if(this.canUpdatePrice){
      this.canUpdatePrice = "cluster_admin";
    }
    
    this.route.queryParams.subscribe((params) => {
      this.lead_uuid = params["id"];
      if (this.lead_uuid === "create") {
        this.para = "creating new lead";
      } else {
        this.para = "updating existing lead";
        this.getPropertyData({ property_id: this.lead_uuid });
      }
    });

    let user = JSON.parse(this.authService.getUser());
    this.addPaymentAccess = this.userService.getAddPaymentAccess(user);
    this.addPlanAccess = this.userService.getAddPaymentPlanAccess(user);
      this.eMarketEditAccessDesigner = this.userService.getEmarketEditAccessDesigner(user);
      
    this.menuToggle.toggleMenu(false);
    // resizing the content whenever menu is toggled
    this.menuToggle.menuToggle.subscribe((val) => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200);
    });
    this.amcService.gettestUrl(this.lead_uuid).subscribe((data) => {
      this.communincationAttempt = data["payload"];
    });

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('lead_property_uuid', this.lead_uuid); //note comma separating key and value
    };
    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter, options);

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.loaderService.show();
      this.network_calls++;
      console.log("showing the input file size");
      console.log(file.file.size);
      if (file.file.size < 10485760) {
        file.upload();
      }
    };

    this.uploader.onCompleteItem = async (val) => {
      console.log(this.uploader.queue);
      this.network_calls--;
      if (this.network_calls == 0) {
        this.loaderService.hide();
      }
    };

    this.uploader.onSuccessItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);
    this.uploader.onErrorItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);
  }


  uploadItems() {
    this.fileInput.nativeElement.click();
  }

  manageResponse(item, response, status, header) {
    let data = JSON.parse(response);
    if (data && data["status"]) {
      this.toast.success(data.message);
    } else {
      this.toast.error(data.message.toUpperCase());
    }
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case "fileSize":
        console.log("file with the error");
        console.log(item);
        this.toast.error(
          "ERROR UPLOADING -" +
          this.cutName(item.name) +
          " IS GREATER THAN 10MB"
        );
        break;
      case "mimeType":
        break;
      default:
    }
  }

  cutName(name) {
    return name.substring(0, 6) + "....";
  }



  createActivityLog(id) {
    let dialogRef = this.dialog.open(CreateActivityLogComponent, {
      data: {
        lead: {
          uuid: this.propertyData.uuid
        },
        uuid: this.propertyData.property_uuid,
        is_profile: true,
        communicationStage: true
      }
    });
    dialogRef.componentInstance.status.subscribe(res => {
      if (res == 'success') {
        this.communicationAttemptPut(id)
      }
    });
  }

  communicationAttemptPut(id) {
    this.amcService.communicationAttemptPut(id, this.lead_uuid).subscribe((res) => {
      this.communincationAttempt = res["payload"];
      if (!this.communincationAttempt.status) {
        this.toast.error(res['message']);
      }
    });
  }


  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe((res) => {
      if (res && res["status"]) {
        console.log("property details found out");
        console.log(res);
        this.propertyData = res["payload"];

        this.payment_plan_status = this.propertyData.data.payment_plan_status;
      }
    });
  }

  valid_check(event) {
    console.log("received value");
    console.log(event);
    this.valid = event;

    console.log("valid value");
    console.log(this.valid);
  }

  onStageChange(event) {
    this.stage_uuid = event.stage_uuid;
    this.stage_name = event.stage_name;
    this.stage_type = event.stage_type;
    this.proposalStatus = event.proposal_status;
    this.isCompleted = event.stage_on != "" ? true : false;
    this.payment_plan_status = event.payment_plan_status;
    this.is_emarket = event.is_emarket;
    this.communication_status = event.communication_status;
    this.finalcomm_status = event.finalCommStatus;
    this.regConfig = null;
    console.log("stage change event");
    console.log(event);
    //console.log('setting stage uuid as '+ event);
    if (this.lead_uuid != "create") {
      console.log("inside update");
      this.para = event;
      this.leadService
        .getPropertyStage(this.lead_uuid, this.stage_uuid)
        .subscribe((val) => {
          console.log(val["payload"]);
          console.log("printing the cleaned value--------------->");
          console.log(val);
          if (val["payload"].stage) {
            this.access = val["payload"].stage.access;
            this.regConfig = [...val["payload"].stage.fields];
            this.stageType = val["payload"].stage.type;
            this.completedOn = val["payload"].stage.completed_on;
            this.customerShare = val["payload"].stage.customer_share;
            this.is_payment = val["payload"].stage.is_payment;
            this.lastPaymentStage = val["payload"].stage.last_payment_milestone;
            this.pendingAmount = val["payload"].stage.total_amount_pending
              ? val["payload"].stage.total_amount_pending.toFixed(1)
              : 0;
            this.pendingAmountIgnoreLimit = this.lastPaymentStage ? 495 : 0;
            if (this.pendingAmount > 5) {
              this.skipAccess = val["payload"].stage.skip_access
                ? val["payload"].stage.skip_access
                : false;
            }
            else {
              this.skipAccess = false;
            }
            if (this.stageType == 'installation') {
              this.fetchAssetDetails();
            }
            if (this.stageType == 'communication') {
              this.CommuncationCompleted = val["payload"].stage.completed_on ? true : false;
            }
          }
          this.checkData();
          // , this.stageType== 'installation' && this.asset_list.length == 0
          console.log("disabled key: ", !this.valid, this.skipAccess, (!this.payment_plan_status && this.stageType == 'final_commercial'), !this.skipAccess && (this.pendingAmount - this.pendingAmountIgnoreLimit) > 5, (!this.is_emarket && this.stageType == 'technical_assessment'));
          console.log(this.regConfig);
          this.regConfig.forEach(field => {
            if (field.type === "file") {
              let value = field.value;
              let substring1 = "https://storage.googleapis.com/";
              let substring2 = "https://firebasestorage.googleapis.com/";
              value.forEach((val, index) => {
                if (val.includes(substring1) == false && val.includes(substring2) == true) {
                  // value.splice(index, 1);
                  console.log(value, "<===========value1")
                }
                else if (val.includes(substring2) == true && val.includes(substring2) == false) {
                  // value.splice(index, 1);
                  console.log(value, "<===========value2")
                }
                else if (val.includes(substring1) == false && val.includes(substring2) == false) {
                  value.splice(index, 1);
                  console.log(value, "<===========value3")
                }
              });
            }
          });
        });
    } else {
      this.clusterService.globalCluster.subscribe((val) => {
        if (val) {
          this.leadService.getDefaultStage(val.uuid).subscribe((val) => {
            console.log(val);
            console.log("inside create default stage");
            this.access = val["payload"].data.current_stage.access;
            this.regConfig = [...val["payload"].data.current_stage.fields];
            this.stageType = val["payload"].stage.type;
            console.log(this.regConfig, this.access);
            this.regConfig.forEach(field => {
              if (field.type === "file") {
                let value = field.value;
                let substring1 = "https://storage.googleapis.com/";
                let substring2 = "https://firebasestorage.googleapis.com/";
                value.forEach((val, index) => {
                  if (val.includes(substring1) == false && val.includes(substring2) == true) {
                    // value.splice(index, 1);
                    console.log(value, "<===========value1")
                  }
                  else if (val.includes(substring2) == true && val.includes(substring2) == false) {
                    // value.splice(index, 1);
                    console.log(value, "<===========value2")
                  }
                  else if (val.includes(substring1) == false && val.includes(substring2) == false) {
                    value.splice(index, 1);
                    console.log(value, "<===========value3")
                  }
                });
              }
            });
          });
        }
      });
    }
  }

  checkData() {
    let checkMap: boolean = true; // to check if all the fields are map-type

    if (this.regConfig && this.regConfig.length != 0) {
      this.regConfig.forEach((field) => {
        if (field.type != "map") {
          checkMap = false;
        }
      });
    } else {
      checkMap = false;
    }

    this.mapCheck = checkMap;
  }
  hasEditAccess() {
    return this.access && this.access.indexOf("edit") > -1;
  }
  hasViewAccess() {
    return this.access && this.access.indexOf("view") > -1;
  }

  createAsset(row?) {
    if (row == null) row = {};
    let dialogRef = this.dialog.open(ManageAssetComponent, {
      data: {
        property_uuid: this.lead_uuid,
        row: row
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == "success") {
        console.log("success...");
        this.fetchAssetDetails();
      }
    });
  }

  fetchAssetDetails() {
    this.amcService.getAssetList({ 'property_uuid': this.lead_uuid }).subscribe((val: any) => {
      if (val && val['status']) {
        console.log('result from asset details');
        console.log(val);
        this.asset_list = val['payload'].data;
        console.log(this.asset_list);
      }
    });
  }

  viewWarrantyItems() {
    let dialogRef = this.dialog.open(ViewAssetComponent, {
      data: {
        asset_list: this.asset_list,
        lead_uuid: this.lead_uuid
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        console.log("refreshing..");
      }
    });
  }

  _confirm(formData) {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "CONFIRMATION",
        desc: "Are you sure you want to complete this stage? As soon as you will complete this stage, system will automatically share credits with referrer & referee.",
      },
    });
    dialogRef.componentInstance.option.subscribe((data) => {
      if (data == "success") {
        this._updateStage(formData);
      }
    });
  }

  clear() {
    this.form.clear();
  }

  submit(event) {
    let typeList = {};
    let formData = {}; //JSON.parse(JSON.stringify(event));
    this.regConfig.forEach(field => {
      if (field.type === "file") {
        let value = field.value;
        let substring1 = "https://storage.googleapis.com/";
        let substring2 = "https://firebasestorage.googleapis.com/";
        value.forEach((val, index) => {
          if (val.includes(substring1) == false && val.includes(substring2) == true) {
            // value.splice(index, 1);
            console.log(value, "<===========value1")
          }
          else if (val.includes(substring2) == true && val.includes(substring2) == false) {
            // value.splice(index, 1);
            console.log(value, "<===========value2")
          }
          else if (val.includes(substring1) == false && val.includes(substring2) == false) {
            value.splice(index, 1);
            console.log(value, "<===========value3")
          }
        });
      }
    });
    this.regConfig.forEach((val) => {
      typeList[val.name] = val.type;
    });

    Object.keys(event).forEach((ele) => {
      console.log(event, ele, typeList, "<+++++++++++++++++++++++++++++++++++++++++")
      if (ele.includes("lead_property_image")) {
        console.log("---->", event[ele].split(','));
        let imageList = event[ele].split(',');
        let substring1 = "https://storage.googleapis.com/";
        let substring2 = "https://firebasestorage.googleapis.com/";
        imageList.forEach((val, index) => {
          if (val.includes(substring1) == false && val.includes(substring2) == true) {
            // value.splice(index, 1);
            console.log(imageList, "<===========value1")
          }
          else if (val.includes(substring2) == true && val.includes(substring2) == false) {
            // value.splice(index, 1);
            console.log(imageList, "<===========value2")
          }
          else if (val.includes(substring1) == false && val.includes(substring2) == false) {
            imageList.splice(index, 1);
            console.log(imageList, "<===========value3")
          }
        });
       event[ele] = imageList.join(",");
       console.log("---->", event[ele]);
      }
      if (typeList[ele] == "date") {
        if (event[ele]) {
          formData[ele] = this.datepipe.transform(event[ele], "yyyy-MM-dd");
          console.log("date changed");
        }
      } else if (typeList[ele] == "datetime") {
        console.log("form element right now");
        console.log(event[ele]);
        if (event[ele]) {
          formData[ele] = Math.floor(event[ele].getTime() / 1000);
        } // - 19800;
      } else {
        formData[ele] = event[ele];
      }
    });

    if (this.lead_uuid == "create") {
      formData["stage_id"] = this.stage_uuid;
      console.log("stage_id set as " + this.stage_uuid);
      this.leadService.addFirstStage(formData).subscribe((val) => {
        console.log("printing response from creating the lead");
        console.log(val);
        if (val && val["status"]) {
          this.toast.success("PROPERTY CREATED SUCCESSFULLY");
          this.form.clear();
          this.stageSubmitted.next();
          //window.location.reload();
        }
        else if (val && !val["status"]) {
          let msg = val['message'];
          this.toast.error(msg);
        }
        else {
          this.toast.error("OPERATION UNSUCCESSFUL");
        }
      });
    } else {
      formData["stage_id"] = this.stage_uuid;
      formData["property_id"] = this.lead_uuid;
      if (!this.isCompleted && this.stage_type == "site_commissioned") {
        this._confirm(formData);
      } else {
        this._updateStage(formData);
      }
    }
  }

  _updateStage(formData) {
    console.log("========form data================", formData);
    this.leadService.updateStage(formData).subscribe((val) => {
      if (val && val["status"]) {
        this.toast.success("PROPERTY UPDATED SUCCESSFULLY");
        if (this.regConfig.length == 0) {
          window.location.reload();
        } else {
          this.stageSubmitted.next();
          this.getPropertyData({ property_id: this.lead_uuid });
          this.leadService.updatePropertyNotifier.next("reload");
        }
      } else {
        this.toast.error(val['message'].toUpperCase());
      }
    });
  }

  skipPayment() {
    if (this.pendingAmount > 0) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "300px",
        data: {
          title: "CONFIRMATION",
          desc: "Are you sure you want to skip payment for this stage?",
        },
      });
      dialogRef.componentInstance.option.subscribe((data) => {
        if (data == "success") {
          const data = {
            lead_property_uuid: this.lead_uuid,
            stage_uuid: this.stage_uuid,
            payment_medium: "cheque",
            payment_amount: this.pendingAmount,
            remarks: "stage payment skip",
            approval_status: "pending_skip",
          };
          this.financeService.addPayment(data).subscribe((val: any) => {
            if (val && val.status) {
              this.toast.success("PAYMENT SKIPPED SUCCESSFULLY ");
              this.stageSubmitted.next();
            } else {
              this.toast.error(val.message.toUpperCase());
            }
          });
        }
      });
    }
  }

  addPayment() {
    let dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        path: '/' + this.lead_uuid + '/src/',
        property_uuid: this.lead_uuid,
        label: 'stage_payment'
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        this.stageSubmitted.next();
      }
    });
  }

  openProposalModal() {
    let dialogRef = this.dialog.open(ProposalComponent, {
      data: {
        uuid: this.lead_uuid,
        hasEditAccess: this.hasEditAccess(),
        label: "Proposal"
      },
    });

    dialogRef.componentInstance.status.subscribe((val) => {
      console.log("componentInstance", val);
      if (val && val.length > 0) {
        this.stageSubmitted.next();
      }
    });
  }

  openTATUpdateModal() {
    let dialogRef = this.dialog.open(TatUpdateComponent, {
      data: {
        uuid: this.lead_uuid,
        stage_uuid: this.stage_uuid,
        communication_status: this.communication_status
      },
    });

    dialogRef.componentInstance.status.subscribe((val) => {
      console.log("componentInstance", val);
      if (val) {
        this.stageSubmitted.next();
      }
    });
  }

  openPaymentPlanModal() {

    let dialogRef = this.dialog.open(AddPaymentPlanComponent, {
      data: {
        uuid: this.lead_uuid,
        hasEditAccess: this.hasEditAccess(),
        viewOnly: this.is_payment != null ? true : false
      },
    });
  }

  addSolarProduct() {
    let dialogRef = this.dialog.open(SolarProductComponent, {
      data: {
        uuid: this.lead_uuid,
        hasEditAccess: this.hasEditAccess(),
      },
    });
  }
  downloadCertificate() {
    let uuid = { uuid: this.lead_uuid };
    console.log(uuid);
    this.amcService.downloadCertificateByUrl(this.amcService.downloadCertificateUrl, uuid, 'certificate');
    console.log("Certificate Downloaded!!!!");
  }

  downloadProposalPdf() {
    let uuid = { uuid: this.lead_uuid };
    console.log(uuid);
    this.amcService.downloadCertificateByUrl(this.amcService.downloadProposalUrl, uuid, 'proposal pdf');
    console.log("Pdf Downloaded!!!!");
  }

  eMarketPlace() {
    
    if(this.canUpdatePrice == "cluster_admin" && !this.is_emarket){
      this.toast.error("Dear Cluster Admin, Bom Kit Not Added Yet!")
    }else{
    console.log("inside emarketplace");
    // let status = false;
    // this.finalcomm_status.forEach((stage: any) => {
    //   if (stage['type'] == 'final_commercial') { status = true; }
    // });
    let dialogRef = this.dialog.open(EMarketPlaceComponent, {
      data: {
        uuid: this.lead_uuid,
        hasEditAccess: this.hasEditAccess(),
        finalcomm_status: this.finalcomm_status
      },
    });
    dialogRef.componentInstance.status.subscribe((val) => {
      console.log("componentInstance", val);
      if (val && val.length > 0) {
        this.stageSubmitted.next();
      }
    });
  }
  }
}