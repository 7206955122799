import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "../base.service";
import { environment } from "src/environments/environment";
import { MessageToastService } from "../toast/message-toast.service";

@Injectable({
  providedIn: "root"
})
export class AccountService {
  accountURL = "v1/account/amplus?";
  getAccountListURL = "v1/account/amplus/list";
  partnerAccountURL = "v1/account/partner";
  partnerAccountListURL = "v1/account/partner/list";
  propertyDetailsURL = 'v2/account/customer';
  discomUserUrl = 'v1/account/discomuser';
  stageTimetakenUrl = 'v1/property/pre-sales/ticket/property';
  allUsersUrl = 'v1/account/users/list';
  iotRoomListUrl = 'v1/property/iot/room/list';
  iotUpdateRoomUrl ='v1/property/iot/room';
  iotDeviceConsumptionUrl = 'v1/property/iot/consumption';
  iotSendDeviceInstructionUrl = 'v1/property/iot/device/instructions';

  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, " ", messageService);
    console.log("obj created");
  }

  saveUserAccount(data) {
    return this.http.post(environment.serverURl + this.accountURL, data);
  }

  updateUserAccount(data) {
    return this.http.put(environment.serverURl + this.accountURL, data);
  }

  deleteUserAccount(uuid) {
    return this.http.delete(
      environment.serverURl + this.accountURL + "uuid=" + uuid
    );
  }

  getAccountList() {
    return this.http.get(environment.serverURl + this.getAccountListURL);
  }

  savePartnerUserAccount(data) {
    return this.http.post(environment.serverURl + this.partnerAccountURL, data);
  }

  updatePartnerUserAccount(data) {
    return this.http.put(environment.serverURl + this.partnerAccountURL, data);
  }

  deletePartnerUserAccount(data) {
    return this.http.delete(environment.serverURl + this.partnerAccountURL, {
      params: data
    });
  }

  getPartnerAccountList(data) {
    return this.http.get(environment.serverURl + this.partnerAccountListURL, {
      params: data
    });
  }

  getPropertyDetails(data) {
    return this.http.get(environment.serverURl + this.propertyDetailsURL, {
      params: data
    });
  }

  getDiscomUserList() {
    return this.http.get(environment.serverURl + this.discomUserUrl + '/list');
  }

  createDiscomUserAccount(data) {
    return this.http.post(environment.serverURl + this.discomUserUrl, data);
  }

  updateDiscomUserAccount(data) {
    return this.http.put(environment.serverURl + this.discomUserUrl, data);
  }

  deleteDiscomUserAccount(uuid) {
    return this.http.delete(environment.serverURl + this.discomUserUrl, {
      params: {uuid: uuid}
    });
  }

  getAllUsersList() {
    return this.http.get(environment.serverURl + this.allUsersUrl);
  }
  
  getIOTRoomList(data) {
    return this.http.get(environment.serverURl + this.iotRoomListUrl, {
      params: data
    });
  }

  updateIOTRoom(data){
    return this.http.put(environment.serverURl + this.iotUpdateRoomUrl, data);
  }

  getIOTDeviceConsumption(params){
    return this.http.get(environment.serverURl + this.iotDeviceConsumptionUrl, {
      params: params
    });
  }

  sendDeviceInstructions(data){
   return this.http.post(environment.serverURl + this.iotSendDeviceInstructionUrl, data);
  }
  getStageTimeTakenDeadline(params){
    return this.http.get(environment.serverURl + this.stageTimetakenUrl, {
      params: params
    });
  }


}
