import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { FinanceService } from 'src/app/common/service/finance/finance.service';
import { LeadService } from 'src/app/common/service/lead/lead.service';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { UserService } from 'src/app/common/service/user-service/user.service';
import { AddERPCodeModalComponent } from '../add-erp-code/add-erp-code.component';
import { AddPaymentModalComponent } from '../add-payment/add-payment.component';
import { InitiateRefundComponent } from '../initiate-refund/initiate-refund.component';
import { InitiateTransferComponent } from '../initiate-transfer/initiate-transfer.component';

@Component({
  selector: 'app-property-payment-log',
  templateUrl: './property-payment-log.component.html',
  styleUrls: ['./property-payment-log.component.scss']
})
export class PropertyPaymentLogComponent implements OnInit {

  logs:any;
  formGroup: FormGroup
  payment_mode = [{ key: "Cash", value: "cash" }, { key: "Card", value: "card" }, { key: "Cheque", value: "cheque" }]; // , { key: "Refund", value: "refund"}
  leaduuid;
  totalPaidAmount = 0;
  totalPendingAmount = 0;
  totalExpectedAmount = 0;
  data = {};
  uploadingFiles: boolean = false;
  addPaymentAccess: boolean = false;
  initiateTransferRefundAccess: boolean = false;
  actionableCTA:any;


  constructor(
    private formBuilder: FormBuilder,
    private leadeService: LeadService,
    private financeService: FinanceService,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      payment_medium: ["", Validators.required],
      payment_amount: ["", Validators.compose([Validators.required, Validators.max(99999999), Validators.maxLength(8)])],
      remarks: ["", Validators.required],
      approval_status: "pending_finance",
      documents: [[]]
    });
    let user = JSON.parse(this.authService.getUser());
    this.addPaymentAccess = this.userService.getAddPaymentAccess(user);
    this.initiateTransferRefundAccess = this.userService.getInitiateTransferRefundAccess(user);

    this.route.queryParams.subscribe(params => {
      this.leaduuid = params['id'];
      this.data = {
        path: '/' + this.leaduuid + '/src/',
        property_uuid: this.leaduuid,
        label: 'Receipts'
      }
      this.getPaymentLogs();
    });
    
    // closing the sidemenu on page load
    this.menuToggle.toggleMenu(false);
    this.financeService.refreshNotifier.subscribe((v) => {
      if(v == 'refresh') {
        this.getPaymentLogs();
      }
    });
  }

  addPayment() {
    if(this.formGroup.valid) {
      let data = JSON.parse(JSON.stringify(this.formGroup.value));
      data.uuid = this.leaduuid;
      this.leadeService.addPaymentLog(data).subscribe(
        (data: any) => {
          if(data) {
            if(data.status) {
              this.toast.success("Payment log added!");
              this.formGroup.reset();
              this.getPaymentLogs();
               
            } else {
              this.toast.error(data.message.toUpperCase());
            }
          }
        },
        (error: any) => {
          this.toast.error("OPERATION FAILED");
        }
      );
    } else {
      this.toast.error("Please fill the valid input");
      this.formGroup.markAllAsTouched();
      this.formGroup.get("payment_medium").markAsDirty();;
      this.formGroup.get("payment_amount").markAsDirty();
      this.formGroup.get("remarks").markAsDirty();
    }
    
  }

  getPaymentLogs() {
    this.financeService.getPaymentLogs(this.leaduuid).subscribe(
      (response: any) => {
        this.logs = [];
        response.payload.data.payments.forEach(element => {
          element.images = element.documents ? JSON.parse(element.documents) : [];
          if(element.remarks == "stage payment skip" || element.approval_status == "pending_skip"){
            element.payment_medium = "-";
          }


               // Check file extensions and update URLs if needed
      if (element.business_approval_url) {
        const businessExtension = element.business_approval_url.split('.').pop().toLowerCase();
        if (businessExtension.toLowerCase() === 'jpg' || businessExtension.toLowerCase() === 'png') {
          element.business_approval_url_1 = element.business_approval_url;
        }else{
          element.business_approval_url_1 = '../../../../../assets/images/copy_clip.webp';
        }
      }

      if (element.cancelled_cheque_url) {
        const chequeExtension = element.cancelled_cheque_url.split('.').pop().toLowerCase();
        
        if (chequeExtension.toLowerCase() === 'jpg' || chequeExtension.toLowerCase() === 'png') {
          element.cancelled_cheque_url_1 = element.cancelled_cheque_url;          
        }else{
          element.cancelled_cheque_url_1 = '../../../../../assets/images/copy_clip.webp';
        }
      }

          this.logs.push(element);
        
        });
        console.log("logs--------->", this.logs);
        this.totalPaidAmount = response.payload.data.total_paid_amount
        this.totalExpectedAmount = response.payload.data.total_expected_amount
        this.totalPendingAmount = response.payload.data.total_pending_amount
        this.actionableCTA = response.payload.data.dropdown_options;
      },
      (error: any) => {
        
      }
    )
  }

  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  openInitiateRefundDialog(){
    let dialogRef = this.dialog.open(InitiateRefundComponent, {
      data: {
        path: '/' + this.leaduuid + '/src/',
        property_uuid: this.leaduuid
      }
    });
    console.log("inside add erp dialogue modal--------", dialogRef );
  }

  openInitiateTransferDialog(){
    let dialogRef = this.dialog.open(InitiateTransferComponent, {
      data: {
        path: '/' + this.leaduuid + '/src/',
        property_uuid: this.leaduuid
      }
    });
    console.log("inside add erp dialogue modal--------", dialogRef );
  }

  openAddErpDialog(){
    let dialogRef = this.dialog.open(AddERPCodeModalComponent, {
      data: {
        path: '/' + this.leaduuid + '/src/',
        property_uuid: this.leaduuid
      }
    });
    console.log("inside add erp dialogue modal--------", dialogRef );
  }

  openAddPaymentDialog() {
    let dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        path: '/' + this.leaduuid + '/src/',
        property_uuid: this.leaduuid,
        label: 'stage_payment'
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if( data == 'success') {
        this.getPaymentLogs()
      }
    });
  }

    
/* To copy any Text */
copyText(val: string){
  let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

  this.toast.success("Copied Successfully!")
  }
}
