import { Component, enableProdMode } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>"
})
export class AppComponent {
  constructor(translate: TranslateService) {
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    this.disableConsole();
  }

  disableConsole() {
    if (environment.production) {
      window.console.log = function() {}; // disable any console.log debugging statements in production mode
      // window.console.error = function () { };
    }
  }
}
