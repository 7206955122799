import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return value ? Number(value.toFixed(6).slice(0,-4)) : value;
  }

}
