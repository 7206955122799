import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatSidenav, MatDialog } from "@angular/material";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { environment } from "src/environments/environment";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { LoaderService } from 'src/app/common/service/loader/loader.service';
declare var $: any;

@Component({
  selector: 'app-spare-items',
  templateUrl: './spare-items.component.html',
  styleUrls: ['./spare-items.component.scss']
})
export class SpareItemsComponent implements OnInit {
  form: any;
  isEditMode = false;
  count = 0;
  offset = 0;
  limit = 0;
  type_options = [{label: 'Select', value: ''}, {label: 'Electrical', value: 'electrical'}, {label: 'Structural', value: 'structural'}];
  assetList = [];
  serverUrl: string = environment.serverURl;
  uploadUrl: string = "v2/amc/asset/upload";
  network_calls = 0;

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;

  uploader: FileUploader = new FileUploader({
    url: this.serverUrl + this.uploadUrl,
    isHTML5: true,
    method: "POST",
    itemAlias: "file",
    authTokenHeader: "authorization",
    authToken: this.authService.getAccessToken(),
    maxFileSize: 10 * 1024 * 1024,
  });
  constructor(
    private fb: FormBuilder,
    private amcService: AMCService,
    private toast: MessageToastService,
    private authService: AuthService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private menuToggle: MenuToggleService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      type: ["", Validators.required],
      description: ["", Validators.required],
      erp_code: ["", Validators.required],
      unit_price: ["", Validators.required],
      unit_of_measurement: ["", Validators.required],
      gst_percent: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
      remarks: ["", Validators.required]
    });

    this.getAssetList();
    this.menuToggle.toggleMenu(false);

    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter, options);

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.loaderService.show();
      this.network_calls++;
      console.log("showing the input file size");
      console.log(file.file.size);
      if (file.file.size < 10485760) {
        file.upload();
      }
    };

    this.uploader.onCompleteItem = async (val) => {
      console.log(this.uploader.queue);
      this.network_calls--;
      if (this.network_calls == 0) {
        this.loaderService.hide();
      }
    };

    this.uploader.onSuccessItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);
    this.uploader.onErrorItem = (item, response, status, header) =>
      this.manageResponse(item, response, status, header);

      this.amcService.refreshNewAssetList.subscribe((val) => {
        if (val) {
          this.getAssetList();
        }
      });
  }

  getAssetList() {
    this.amcService.getGlobalAssetList({page: this.offset + 1}).subscribe((val: any) => {
      console.log('global asset list found out');
      console.log(val);
      if(val && val['status']) {
        this.count = val.payload.count;
        this.offset = val.payload.current_page - 1;
        this.limit = val.payload.data_per_page;
        this.assetList = [...val.payload.data];
        console.log('final asset list');
        console.log(this.assetList);
        console.log(this.count + '  ' + this.offset + ' ' + this.limit);
      }
    });
  }

  uploadItems(){
    this.fileInput.nativeElement.click();
  }

  manageResponse(item, response, status, header) {
    let data = JSON.parse(response);
    if (data && data["status"]) {
      this.toast.success("FILE UPLOADED SUCCESSFULLY");
      this.getAssetList();
    } else {
      this.toast.error(data.message.toUpperCase());
    }
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case "fileSize":
        console.log("file with the error");
        console.log(item);
        this.toast.error(
          "ERROR UPLOADING -" +
          this.cutName(item.name) +
          " IS GREATER THAN 10MB"
        );
        break;
      case "mimeType":
        break;
      default:
    }
  }

  cutName(name) {
    return name.substring(0, 6) + "....";
  }

  sidenavOpen() {
    console.log('sidenav opened');
    $('app-spare-item').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    this.reset();
    console.log('sidenav closed');
    $('app-spare-item').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  editAsset(row) {
    this.sidenavBar.open();
    this.reset();
    this.isEditMode = true;
    this.form.patchValue({
      uuid: row.uuid,
      type: row.type,
      description: row.description,
      erp_code: row.erp_code,
      unit_price: row.unit_price,
      gst_percent: row.gst_percent,
      remarks: row.remarks,
      unit_of_measurement: row.unit_of_measurement
    });
  }

  reset() {
    this.form.patchValue({
      uuid: null,
      type: "",
      description: "",
      erp_code: "",
      unit_price: "",
      gst_percent: "",
      remarks: "",
      unit_of_measurement: ""
    });
    this.isEditMode = false;
  }

  onPage(event) {
    this.offset = event.offset;
    this.getAssetList();
  }

  deleteAsset(row) {
    console.log('deleting *****');
    console.log(row);

    let dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        this.amcService.deleteGlobalAsset({uuid: row.uuid}).subscribe((val: any) => {
          if(val && val['status']) {
            this.toast.success('ASSET DELETED SUCCESSFULLY');
            this.getAssetList();
          } else {
            this.toast.error('OPERATION FAILED');
          }
        });
      }
    });
  }

  submit() {
    // if(this.isEditMode) {
    //   console.log('editting');
    // }else {
    //   console.log('creating');
    // }
    if(this.isEditMode) {
      this.amcService.updateGlobalAsset(this.form.value).subscribe((val: any) => {
        if(val && val['status']) {
          this.toast.success('ASSET UPDATED SUCCESSFULLY');
          this.getAssetList();
        } else {
          this.toast.error('OPERATION FAILED');
        }
        this.sidenavBar.close();
        this.sidenavClose();
      });
    } else {
      this.amcService.createGlobalAsset(this.form.value).subscribe((val: any) => {
        if(val && val['status']) {
          this.toast.success('ASSET CREATED SUCCESSFULLY');
          this.getAssetList();
        } else {
          this.toast.error('OPERATION FAILED');
        }
        this.sidenavBar.close();
        this.sidenavClose();
      });
    }
  }

}
