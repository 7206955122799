import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";

@Component({
  selector: 'app-recommended-list-view',
  templateUrl: './recommended-list-view.component.html',
  styleUrls: ['./recommended-list-view.component.scss']
})
export class RecommendedListViewComponent implements OnInit {

  recommendedList;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log("data in recommended list ", this.data);
    this.recommendedList = this.data.recommendation_data;
  }

}
