import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  baseURL: any;
  partnerListUrl = "v1/link/partner/list";
  partnerLinkedListUrl = "v1/link/partner/next";
  createLink = "v1/link/partner";
  deleteLink = "v1/link/partner";

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  getPartnerList(data) {
    return this.http.get(this.baseURL + this.partnerListUrl, {
      params: data
    });
  }

  getLinkedPartnerList(data) {
    return this.http.get(this.baseURL + this.partnerLinkedListUrl, {
      params: data
    });
  }

  createPartnerLink(data) {
    return this.http.post(this.baseURL + this.createLink, data);
  }

  deletePartnerLink(data) {
    return this.http.delete(this.baseURL + this.deleteLink, {
      params: data
    });
  }

}
