import { Component, OnInit, Input } from '@angular/core';
import { AMCService } from "src/app/common/service/amc/amc.service";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-sr-spare-parts',
  templateUrl: './sr-spare-parts.component.html',
  styleUrls: ['./sr-spare-parts.component.scss']
})
export class SrSparePartsComponent implements OnInit {
  @Input() data: any;
  @Input() update: number;
  @Input() canEdit: boolean;

  form: any;

  typeList = [{'label':'Electrical', 'value':'electrical'}, {'label':'Structural', 'value':'structural'}];
  descList = [];
  itemList = [];
  totalItemsPrice = 0;
  originalItemsPrice = 0;

  constructor(
    private amcService: AMCService,
    private fb: FormBuilder,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      ticket_id: [this.data.ticket_id, Validators.required],
      type: ["", Validators.required],
      asset_id: ["", Validators.required],
      quantity: [1, Validators.required],
      property_uuid: ["", Validators.required]
    });

    console.log("finding spare items for "+this.data.ticket_id);
    this.getItemList();
    this.form.get("type").valueChanges.subscribe(value => {
      this.getAssetList();
    });
  }

  ngOnChanges() {
    if(this.data && this.data.ticket_id) {
      this.form.get('ticket_id').setValue(this.data.ticket_id);
      this.form.get('property_uuid').setValue(this.data.property_uuid);
    }
    this.getItemList();
    this.getAssetList();
  }

  getItemList() {
    if(this.data && Object.keys(this.data).length > 0 ) {
      console.log("fetching list ", this.data);
      this.amcService.getSpareItemList({ticket_uuid: this.data.ticket_id}).subscribe((res: any) => {
        console.log('response found out in spare parts');
        console.log(res);
        this.itemList = [];
        this.totalItemsPrice = 0;
        this.originalItemsPrice = 0;
        this.originalItemsPrice;
  
        res.payload.data.forEach((item: any) => {
          this.itemList.push(item);
          this.totalItemsPrice += item.cost + item.cost_gst;
          this.originalItemsPrice += item.original_cost + item.original_cost_gst;
        });
        console.log(">>>>>>>>>>>>>>>>>>", this.itemList);
      });
    }
  }

  getAssetList() {
    if(this.data && this.data.ticket_id && this.data.property_uuid && this.form.get("type").value && this.form.get("type").value != '')
    {this.amcService.searchAsset({ticket_uuid: this.data.ticket_id, property_uuid: this.data.property_uuid, type: this.form.get("type").value}).subscribe((response: any) => {
      this.descList = []
      console.log("response asset ", response);
      if(response.payload && response.payload.data)
      {response.payload.data.forEach((item) => {
        this.descList.push({label: item.description, value: item.uuid});
      });
      this.descList = [...this.descList];}
      
    });}
  }



  addSpareItem() {
    if(this.form.valid) {
      this.amcService.addSpareItem(this.form.value).subscribe(
        (response: any) => {
            this.toast.success("Spare item added!");
            this.form.reset({
              quantity: 1,
              ticket_id: this.data.ticket_id
            });
            this.getItemList();
            this.amcService.refreshNotifier.next("");
        },
        (error: any) => {
            this.toast.error("OPERATION FAILED");
        }
      )
    } else {
      console.log(this.form);
      this.toast.error("INVALID FORM DATA");
    }
  }

  deleteSpareItem(uuid) {
    this.amcService.deleteSpareItem({ uuid: uuid }).subscribe(
      (response: any) => {
          this.toast.success("Spare item deleted!");
          this.form.reset({
            quantity: 1,
            ticket_id: this.data.ticket_id
          });
          this.getItemList();
          this.amcService.refreshNotifier.next("");
      },
      (error: any) => {
          this.toast.error("OPERATION FAILED");
      }
    )
  }

}
