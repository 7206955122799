import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2 } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { Observable, Subscription } from "rxjs";
import { ListItemDirective } from "src/app/common/directive/list-select/list-item.directive";
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MatDialog } from "@angular/material";
import { HoldStageComponent } from "./hold-stage/hold-stage.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
declare var $: any;

@Component({
  selector: "app-stage-bar",
  templateUrl: "./stage-bar.component.html",
  styleUrls: ["./stage-bar.component.scss"]
})
export class StageBarComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;
  @Input() uuid: string;
  @Input() events: Observable<void>;
  @Output() stageDetails: EventEmitter<any> = new EventEmitter();

  list_data;
  next_stage = [];
  next_stage_hold = false;
  selectedItem;
  default_uuid;
  viewInit = false;
  holdToggle = false;
  classList = ['green-milestone', 'red-milestone', 'blue-milestone'];
  private eventsSubscription: Subscription;
  proposalStatus: string;
  paymentPlanStatus: boolean;
  communicationStagePresent: boolean;
  finalComStagePresent: any;
  is_emarket: boolean;
  finalCommCheck: boolean = false;
  constructor(private leadService: LeadService,
    private clusterService: ClusterService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.next_stage_hold = false;
    this.inputSideNav.toggle();
    if (this.uuid != "create") {
      this.getPropertyDetails();
    } else {
      this.clusterService.globalCluster.subscribe((val) => {
        if (val) {
          this.leadService.getDefaultStage(val.uuid).subscribe(val => {
            console.log("stage bar create val[payload].");
            console.log(val["payload"].data.current_stage);
            console.log(val, val["payload"].data.current_stage);
            this.list_data = val;
            this.is_emarket = val["payload"].data.is_emarket;
            this.onClick(
              val["payload"].data.current_stage.name,
              val["payload"].data.current_stage.uuid,
              val["payload"].data.current_stage.completed_on,
              val["payload"].data.current_stage.type
            );
            console.log("=====================================================================");
            console.log("=====================================================================");
            console.log(this.is_emarket);
            console.log("=====================================================================");
            console.log("=====================================================================");
            console.log("=====================================================================");
          });
        }
      });
    }
  }

  ngAfterViewInit() {
    this.eventsSubscription = this.events.subscribe(() => {
      if (this.uuid != "create") {
        console.log('refreshing the stage bar');
        this.getPropertyDetails();
      }
    });
  }

  colorTimeline() {
    setTimeout(() => {
      // color previous stages
      this.list_data.payload.data.previous_stage.forEach((stage, idx) => {
        const ele = document.getElementsByClassName('is-completed')[idx];
        if (stage.deadline != null && stage.deadline < stage.completed_on) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'green-milestone');
        }
      });

      // color current stages
      const curStage = this.list_data.payload.data.current_stage;
      const ele = document.getElementsByClassName('is-current')[0];
      if (curStage.completed_on != null) {
        if (curStage.deadline < curStage.completed_on) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'green-milestone');
        }

      } else {
        if (curStage.deadline < Math.floor((new Date()).getTime() / 1000)) {
          this.addClass(ele, 'red-milestone');
        } else {
          this.addClass(ele, 'blue-milestone');
        }
      }
    }, 300);
  }

  addClass(ele, className) {
    this.classList.forEach((color) => {
      ele.classList.remove(color);
    });

    ele.classList.add(className);
  }

  communicationCheck(data): boolean {
    if (data['previous_stage'] && data['previous_stage'].length > 0) {
      data['previous_stage'].forEach((stage: any) => {
        if (stage['type'] == 'communication') { return true; }
      });
    } // returns undefined evrytime

    if (data['current_stage'] && data['current_stage']['type'] == 'communication') { return true; }

    return false;
  }

  finalCommercialCheck(data) {

    if (data['current_stage'] && data['current_stage']['type'] == 'final_commercial') { this.finalCommCheck = true; }

    else if (data['previous_stage'] && data['previous_stage'].length > 0) {
      data['previous_stage'].forEach((stage: any) => {
        if (stage['type'] == 'final_commercial') { this.finalCommCheck = true; }
      });
    }
    
    else { this.finalCommCheck = false; } // correct version to check stages

  }

  getPropertyDetails() {
    this.next_stage_hold = false;
    this.leadService.getPropertyDetails(this.uuid).subscribe(val => {
      console.log("not create, list data");
      console.log(val);
      this.list_data = val;
      this.is_emarket = val["payload"].data.is_emarket;
      this.next_stage = [];
      for (var stage = 0; stage < this.list_data.payload.data.next_stage.length; stage++) {
        if (this.list_data.payload.data.next_stage[stage].on_hold) {
          this.next_stage_hold = true;
          this.next_stage.push(this.list_data.payload.data.next_stage[stage]);

        }
      }
      console.log(val, val["payload"].data.current_stage);
      this.finalCommercialCheck(val['payload'].data)
      this.proposalStatus = val["payload"].data.proposal_status;
      this.paymentPlanStatus = val["payload"].data.payment_plan_status;
      this.communicationStagePresent = this.communicationCheck(val['payload'].data);
      this.finalComStagePresent = this.finalCommCheck;
      this.stageDetails.emit({
        stage_uuid: val["payload"].data.current_stage.uuid,
        stage_name: val["payload"].data.current_stage.name,
        stage_completed: val["payload"].data.current_stage.completed_on,
        stage_type: val["payload"].data.current_stage.type,
        proposal_status: this.proposalStatus,
        is_emarket: val["payload"].data.is_emarket,
        payment_plan_status: this.paymentPlanStatus,
        communication_status: this.communicationStagePresent,
        finalCommStatus: this.finalComStagePresent
      });
      this.markActiveItem();
      this.colorTimeline();
    });

  }

  markActiveItem() {
    setTimeout(() => {
      $('.active-item').click();
    }, 300);
  }

  onClick(name, uuid, completed_on, type, listDirective = null) {
    console.log("clicked stage bar item");
    console.log(name);
    console.log("uuid of item clicked type", type);
    console.log(uuid);
    console.log(this.is_emarket);
    this.stageDetails.emit({
      stage_uuid: uuid,
      stage_name: name,
      stage_on: completed_on,
      stage_type: type,
      proposal_status: this.proposalStatus,
      payment_plan_status: this.paymentPlanStatus,
      communication_status: this.communicationStagePresent,
      is_emarket: this.is_emarket,
      finalCommStatus: this.finalComStagePresent
    });
    console.log("stage details-->", this.stageDetails);
    if (listDirective) {
      (<ListItemDirective>listDirective).toggle();
    }
  }

  openModal(event) {
    console.log("event in open Modal--------->", event);
    if (!event.on_hold) {
      let dialogRef = this.dialog.open(HoldStageComponent, {
        data: {
          on_hold: !event.on_hold,
          status: "On",
          property_uuid: this.uuid,
          stage_uuid: event.uuid,
          state: "toggle"
        }
      });
      dialogRef.componentInstance.status.subscribe(data => {
        console.log(data, ",=====> data in open modal");
        if (!data) {
          event.on_hold = !event.on_hold;
        }
        else if (data) {
          console.log("refreshing.. yey=====.");
          this.getPropertyDetails();
        }
      });
    }
    else {
      let data = {
        hold_remark: "hold off",
        on_hold: false,
        property_uuid: this.uuid,
        stage_uuid: event.uuid
      }
      this.leadService.holdStage(data).subscribe(val => {
        if (val && val['status']) {
          this.toast.success(val['message']);
        }
        else {
          this.toast.error(val['message']);
        }
      });
    }
   // this.getPropertyDetails();
  }

  openInfo(event) {
    console.log("event in open Info---------->", event);
    let dialogRef = this.dialog.open(HoldStageComponent, {
      data: {
        on_hold: event.on_hold,
        status: event.on_hold ? "On" : "Off",
        property_uuid: this.uuid,
        stage_uuid: event.uuid,
        hold_started_on: event.hold_started_on,
        hold_end_on: event.hold_end_on,
        hold_remark: event.hold_remark,
        total_days: event.hold_days_count,
        state: "info"
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        console.log("refreshing... yey :)");
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
