import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from '../../service/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SignOutComponent>,
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  option_yes() {
    this.loginService.logOut().subscribe(result => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("auth_payload");
      this.router.navigate(["/login"]);
    });
    this.dialogRef.close();
  }

  option_no() {
    this.dialogRef.close();
  }
}
