import 'zone.js';
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators, FormGroup, AbstractControl } from "@angular/forms";
import { environment } from "src/environments/environment";
import {
  FileUploader,
  FileUploaderOptions,
  FileItem,
  FileLikeObject
} from "ng2-file-upload/ng2-file-upload";
import { FieldConfig } from "../field.interface";
import { LoaderService } from "src/app/common/service/loader/loader.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { Observable, fromEvent } from "rxjs";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"]
})
export class FileUploadComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  touched: boolean = false;
  newGroup: FormGroup;
  serverUrl: string = environment.serverURl;
  uploadUrl: string = "v1/common/upload";
  dict = [];
  network_calls = 0;
  MAX_FILE_SIZE = 250 * 1024 * 1024;

  isConnected: boolean;
  private uploader: FileUploader = new FileUploader({
    url: this.serverUrl + this.uploadUrl,
    isHTML5: true,
    method: "POST",
    itemAlias: "file",
    authTokenHeader: "authorization",
    authToken: localStorage.getItem("token"),
    maxFileSize: this.MAX_FILE_SIZE
  });

  constructor(
    private loaderService: LoaderService,
    private toast: MessageToastService,

  ) {
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.log('----------here when failed');
      this.onWhenAddingFileFailed(item, filter, options);
    }
  }

  ngOnInit() {
    // clearing current field
    this.isConnected = navigator.onLine;
    this.group.controls[this.field.name].setValue("");

    console.log("displaying the file field", this.group.controls[this.field.name]);

    // listening to value change in this field
    // if it is null then creating new uploader instance and clearing the dictionary
    this.group.valueChanges.subscribe(data => {
      if (data[this.field.name] == null) {
        this.clear();
      }
    });

    // function running when the files are selected for upload
    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
      this.loaderService.show();
      this.network_calls++;
      let response;
      console.log("showing the input file size");
      console.log(file.file.size);
      if (file.file.size < this.MAX_FILE_SIZE) {
        file.upload();
      }
    };

    // making image objects out of links in the field value
    // pushing objects in the uploader and also in the field again (since it was emptied at the beginning)
    // from now on all the input file objects will be pushed in the uploader
    console.log(fromEvent(window, 'offline'), "-----------------------------")
    this.initializeUploader(this.field);

    // when the files are uploaded
    // set value of the input field and hide the loader service
    this.uploader.onCompleteItem = async () => {
      console.log(this.uploader.queue);
      await this.group.get(this.field.name).setValue(this.getUploaderImages());
      console.log(this.group.value);
      Object.keys(this.group.value).forEach((ele) => {
        if (ele.includes("lead_property_image")) {
          console.log("---->", this.group.value[ele].split(','));
          let imageList = this.group.value[ele].split(',');
          let substring1 = "https://storage.googleapis.com/";
          let substring2 = "https://firebasestorage.googleapis.com/";
          imageList.forEach((val, index) => {
            if (val.includes(substring1) == false && val.includes(substring2) == true) {
              // value.splice(index, 1);
              console.log(imageList, "<===========value1")
            }
            else if (val.includes(substring2) == true && val.includes(substring2) == false) {
              // value.splice(index, 1);
              console.log(imageList, "<===========value2")
            }
            else if (val.includes(substring1) == false && val.includes(substring2) == false) {
              imageList.splice(index, 1);
              console.log(imageList, "<===========value3")
            }
          });
          this.group.value[ele] = imageList.join(",");
         console.log("---->", this.group.value[ele]);
        }
      })
    
      this.network_calls--;
      if (this.network_calls == 0) { this.loaderService.hide(); }
    };
  }

  get validator() {
    try {
      const validator = this.group.controls[this.field.name].validator({} as AbstractControl);
      console.log(validator);
      if (validator && validator.required) {
        return true;
      }
    }
    catch (e) {
      return false;
    }
  }

  initializeUploader(data) {
    console.log("displaying input ele data");
    let imageData: FileUploaderOptions;
    if (data && data.value && data.value.length) {
      data.value.forEach(url => {
        let blob = [];
        let option: FilePropertyBag = {};
        let fileItem = new FileItem(
          this.uploader,
          new File(blob, url, option),
          imageData
        );
        if(!this.isConnected){
          alert(!this.isConnected);
        }
        this.uploader.queue.push(fileItem);
      });

      this.group.get(this.field.name).setValue(this.getUploaderImages());
    }
  }

  getUploaderImages() {
    let images = "";
    this.dict = [];
    if (this.uploader && this.uploader.queue.length) {
      this.uploader.queue.forEach((item, idx) => {
        let url = this.getImageURL(item);
        if(url.includes("https://")){
          console.log(url, "<===========================url");
          this.dict.push({ url: url, type: this.getType(url) });
          if (idx != this.uploader.queue.length - 1) {
            images = images + url + ",";
          } else {
            images = images + url;
          }
        }
        else {
          this.toast.warning("CHECK YOUR INTERNET CONNECTION")
        }
      });
    }
    return images;
  }

  getType(url) {
    let ext = url.split(".").pop();
    if (
      ext == "jpg" ||
      ext == "png" ||
      ext == "jpeg" ||
      ext == "JPG" ||
      ext == "PNG" ||
      ext == "JPEG"
    ) {
      return "image";
    } else if (ext == "pdf") {
      return "pdf";
    } else {
      return "video";
    }
  }

  getImageURL(obj) {
    console.log("returning images for " + this.field.name);
    if (obj) {
      let xhr = obj._xhr;
      // let response = null;
      console.log(xhr);
      if (xhr && xhr.response != "") {
        let response = JSON.parse(xhr.response);
        if (response.status) {
          console.log("returning the url found");
          console.log(response.payload.url);
          return response.payload.url;
        }
      } else {
        console.log("returning object " + obj.file.name);
        console.log(obj.file.name);
        return obj.file.name;
      }
    } else {
      console.log("returning empty string");
      return "";
    }
  }

  clear() {
    this.uploader = new FileUploader({
      url: this.serverUrl + this.uploadUrl,
      isHTML5: true,
      method: "POST",
      itemAlias: "file",
      authTokenHeader: "authorization",
      authToken: localStorage.getItem("token")
    });

    this.dict = [];
  }

  fileList() {
    console.log("items in the queue");
    this.uploader.queue.forEach(val => {
      console.log(val);
    });
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case "fileSize":
        console.log("file with the error");
        console.log(item);
        this.toast.error(
          "ERROR UPLOADING -" +
          this.cutName(item.name) +
          " IS GREATER THAN 10MB"
        );
        break;
      case "mimeType":
        break;
      default:
    }
  }

  cutName(name) {
    return name.substring(0, 6) + "....";
  }

  deleteItem(item) {
    for (let i = 0; i < this.dict.length; i++) {
      if (this.dict[i] == item) {
        this.dict.splice(i, 1);
      }
    }

    this.uploader.queue = [];

    let blob = [];
    let option: FilePropertyBag = {};
    let imageData: FileUploaderOptions;
    this.dict.forEach(dictItem => {
      let fileItem = new FileItem(
        this.uploader,
        new File(blob, dictItem.url, option),
        imageData
      );
      this.uploader.queue.push(fileItem);
    });

    this.group.get(this.field.name).setValue(this.getUploaderImages());
  }
}
