import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { AMCService } from "../../service/amc/amc.service";
import { ClusterService } from "../../service/cluster/cluster.service";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { now } from "moment";
import { parse } from "querystring";
import { AuthService } from "../../service/auth-service/auth.service";
import { UserService } from "../../service/user-service/user.service";

@Component({
  selector: "app-sr-log",
  templateUrl: "./sr-log.component.html",
  styleUrls: ["./sr-log.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageSrComponent implements OnInit {
  @ViewChild("payments", { static: false })
  private myScrollContainer: ElementRef;

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  paymentForm: any;
  commentForm: any;

  compData = {};
  updateServices = 0;
  prevCashAmount = 0;
  type_options = [];
  types = [];
  holidayCheck: any;
  timeForm: any;
  holidayList = [];
  // type_options = [{'label':'Troubleshooting', 'value':'troubleshooting'}, {'label':'Maintenance', 'value':'maintenance'}, {'label':'Repair', 'value':'repair'}, {'label':'Relocation', 'value':'relocation'}];
  // types = [
  //           {'type': 'troubleshooting', 'subtype': [{'label':'Plant is not working', 'value':'plant_not_working'}]},
  //           {'type': 'maintenance', 'subtype': [{'label':'Panel Cleaning', 'value':'panel_cleaning'}, {'label':'Plant Regular Checkup', 'value':'plant_checkup'}, { 'label': 'Others', 'value': 'others'}]},
  //           {'type': 'repair', 'subtype': [{'label':'Mounting Structure', 'value':'mounting_structure'},{'label':'Solar Panels', 'value':'solar_panels'},{'label':'Inverters', 'value':'inverters'},{'label':'Meters', 'value':'meters'}, {'label':'Others', 'value':'others'}]},
  //           {'type': 'relocation', 'subtype': [{'label':'Need help with moving plant', 'value':'need_help_moving'}, { 'label': 'Others', 'value': 'others'}]}];
  subtype_options = [{ label: "", value: "" }];
  subtype = "";

  status_options = [];
  // status_options = [
  //   {'label':'Open','value': 'open'},
  //   {'label':'Visitation Scheduled','value': 'visitation_scheduled'},
  //   {'label':'Visitation Completed', 'value':'visitation_completed'},
  //   {'label':'Material Order Raised', 'value':'material_order_raised'},
  //   {'label':'Material Reached Tentative', 'value':'material_reached_tentative'},
  //   {'label':'Material Reached Confirmed', 'value':'material_reached_confirmed'},
  //   {'label':'In - Progress', 'value':'inprogress'},
  //   {'label':'Completed', 'value':'completed'},
  //   {'label':'Rejected', 'value':'rejected'}
  // ];
  time_slots = [
    { hour: 8, label: "8:00 - 10:00", value: 28800000 }, // milliseconds from 12 midnight
    { hour: 10, label: "10:00 - 12:00", value: 36000000 },
    { hour: 12, label: "12:00 - 14:00", value: 43200000 },
    { hour: 14, label: "14:00 - 16:00", value: 50400000 },
    { hour: 16, label: "16:00 - 18:00", value: 57600000 },
  ];
  global_time_slots = [
    { hour: 8, label: "8:00 - 10:00", value: 28800000 }, // milliseconds from 12 midnight
    { hour: 10, label: "10:00 - 12:00", value: 36000000 },
    { hour: 12, label: "12:00 - 14:00", value: 43200000 },
    { hour: 14, label: "14:00 - 16:00", value: 50400000 },
    { hour: 16, label: "16:00 - 18:00", value: 57600000 },
  ];
  type;
  maintainance_type;
  timeVisibility = true;
  typeDisabled = true;
  payment_options = [
    { label: "Cash", value: "Cash" },
    { label: "Credit Card", value: "Credit Card" },
  ];
  mode_options = [
    { label: "Prepaid", value: "Prepaid" },
    { label: "Postpaid", value: "Postpaid" },
  ];
  payment_status_options = [
    { label: "Paid", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
  ];
  randomList = [
    { value: "a", label: "b" },
    { value: "ad", label: "sb" },
    { value: "add", label: "ssb" },
    { value: "asdss", label: "ssdsb" },
  ];
  log_status = "pending";
  adding_payment = false;
  timeSlot = 28800000;
  payment_mode = "view";
  payment_sum = 0;
  ticket_details: any;
  technicianList: any;
  uploadingFiles: boolean = false;
  commentData: any;
  notifier: Subject<void> = new Subject<void>();
  isActiveAttachment = false;
  canEditSR = false;
  slotPresent = false;
  propId = "";
  minDate = new Date();
  canUpdatePaymentStatus: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toast: MessageToastService,
    private authService: AuthService,
    private userService: UserService,
    private amcService: AMCService,
    public dialogRef: MatDialogRef<ManageSrComponent>,
    private clusterService: ClusterService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    const config = JSON.parse(localStorage.getItem("sr-config"));
    console.log("config found ", config);
    this.canUpdatePaymentStatus = this.userService.paymentStatusAccess(JSON.parse(this.authService.getUser()));

    this.type_options = [];
    this.types = [];

    Object.keys(config.ticket_type).forEach((key: any) => {
      this.type_options.push({
        label: config.ticket_type[key]["title"],
        value: key,
      });
      let subtype = [];
      Object.keys(config.ticket_type[key]["subtype"]).forEach((sKey: any) => {
        subtype.push({
          label: config.ticket_type[key]["subtype"][sKey],
          value: sKey,
        });
      });
      this.types.push({ type: key, subtype: subtype });
    });

    this.status_options = [];
    Object.keys(config.ticket_status).forEach((key: any) => {
      this.status_options.push({
        label: key,
        value: config.ticket_status[key],
      });
    });

    this.timeForm = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()],
    });

    let now = new Date().getHours();
    this.time_slots = [];
    let time;
    for (var i = 0; i < this.global_time_slots.length; i++) {
      if (this.global_time_slots[i].hour >= now)
        this.time_slots.push(this.global_time_slots[i]);
    }
    if (this.time_slots.length == 0) {
      this.minDate.setDate(this.minDate.getDate() + 1);
      if (new Date() < this.minDate && this.maintainance_type == 'breakdown_maintainance') {
        this.time_slots = [];
      }
      else {
        this.time_slots = this.global_time_slots;
      }
    }
    time = this.time_slots[0].value;
    console.log("time slots-->", this.time_slots);

    console.log("time----> in on init----->", time);
    this.form = this.fb.group({
      type: ["", Validators.required],
      // subtype: [""],
      payment_status: ["", Validators.required],
      technician_uuid: [""],
      description: [""],
      scheduled_on: [
        new Date(new Date(this.minDate.setHours(0, 0, 0, 0)).getTime() + time),
        Validators.required,
      ],
      time_slot: [time, Validators.required],
      status: [this.log_status, Validators.required],
      images: [[]],
      visitation_charge: [0, Validators.required],
      cleaning_charge: [0, Validators.required],
      spare_item_charge: [0, Validators.required],
      gst: [0, Validators.required],
    });

    console.log("this.form ------->", this.form.value);
    this.paymentForm = this.fb.group({
      visitation_charge: [500, Validators.required],
      cleaning_charge: [800, Validators.required],
      spare_item_charge: [200, Validators.required],
      gst: [200, Validators.required],
    });

    this.commentForm = this.fb.group({
      description: ["", Validators.required],
      images: [[]],
    });

    this.form.get("scheduled_on").valueChanges.subscribe((res: any) => {
      this.holidayCheck = this.datepipe.transform(res, "EEEE, MMMM d, y");
      this.getHolidays();
    });

    this.commentData = this.data;
    this.commentData.label = null;
    this.canEditSR = this.data.can_edit;
    let cluster;
    if (this.data.cluster) {
      cluster = this.data.cluster;
    } else if (this.clusterService.curPropertyCluster.value != null) {
      cluster = this.clusterService.curPropertyCluster.value;
    } else {
      cluster = this.clusterService.globalCluster.value;
    }
    this.getTechnicians(cluster);
    this.fetchServiceRequest({ ticket_uuid: this.data.ticket_uuid });
    this.amcService.refreshNotifier.subscribe(() => {
      this.fetchServiceRequest({
        ticket_uuid: this.data.ticket_uuid,
        patch: true,
      });
    });
  }

  getHolidays() {
    let year = new Date().getFullYear();
    let stDate = year.toString() + ", 1, 1";
    let params = {
      start_date: Math.floor(new Date(stDate).getTime() / 1000),
      end_date: this.getTimestamp(1) + 365 * 24 * 60 * 60,
    };
    this.amcService.getHolidayList(params).subscribe((result) => {
      let res = result["payload"]["data"];
      for (var i = 0; i < res.length; i++) {
        let date = new Date(res[i].date * 1000);
        res[i].date = this.datepipe.transform(date, "EEEE, MMMM d, y");
      }
      this.holidayList = res;
      for (var j = 0; j < this.holidayList.length; j++) {
        if (this.holidayList[j].date == this.holidayCheck) {
          this.toast.warning(
            "Selected Day is a Holiday (" + this.holidayList[j].name + ")"
          );
        }
      }
    });
  }

  getTimestamp(option) {
    let timestamp = 0;
    if (option == 1) {
      timestamp = Math.floor(this.timeForm.get("day").value.getTime() / 1000);
    }
    return timestamp;
  }

  getTechnicians(cluster) {
    console.log(
      "this.clusterService.curPropertyCluster.value.uuid",
      this.clusterService.curPropertyCluster.value
    );
    console.log(
      "this.clusterService.globalCluster.value.uuid",
      this.clusterService.globalCluster.value
    );
    console.log("cluster", cluster);
    this.clusterService
      .getClusterTechnicians(cluster.uuid)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.technicianList = [{ label: "Select a technician", value: "" }];
          res.payload.data.forEach((ele: any) => {
            this.technicianList.push({ label: ele.name, value: ele.uuid });
          });
        }
      });
  }

  selectHandler(val) {
    const timestamp = Math.floor(this.ticket_details.scheduled_on * 1000);
    if (val == 'breakdown_maintenance') {
      this.minDate.setDate(this.minDate.getDate() + 1);
      this.minDate = new Date(this.minDate.setHours(0, 0, 0, 0));
      let hour = this.timeSlot / 3600000; // to get exact hour from midnight
      let slot: any = {};
      slot.hour = hour;
      slot.label = hour.toString() + ":00 - " + (hour + 2).toString() + ":00";
      slot.value = this.timeSlot;
      this.jsonComparator(this.time_slots, slot);
      let schdate = new Date(Math.floor(this.ticket_details.scheduled_on * 1000));
      let nowTime = new Date();
      console.log("scheduled date---->", schdate < nowTime);
      let nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + 1);
      console.log("hors minutes----")
      console.log(nextDay.setMinutes(nextDay.getMinutes() + 30), nextDay); // setting 1/2 hour buffer
      console.log(
        "timestamp < nowTime.getTime()",
        timestamp < nowTime.getTime(),
        timestamp,
        nowTime.getTime()
      );
      console.log("nowtime-->", nowTime.toLocaleDateString());
      console.log("schDate-->", schdate.toLocaleDateString());
      console.log("next day-->", nextDay.toLocaleDateString());
      if (!this.slotPresent && schdate.toLocaleDateString() < nextDay.toLocaleDateString()) {
        this.time_slots.push({
          hour: hour,
          label: hour.toString() + ":00 - " + (hour + 2).toString() + ":00",
          value: this.timeSlot,
        });
      } else if (schdate.toLocaleDateString() > nowTime.toLocaleDateString() && schdate.toLocaleDateString() <= nextDay.toLocaleDateString()) {
        let now = new Date().getHours();
        this.time_slots = [];
        for (var i = 0; i < this.global_time_slots.length; i++) {
          if (this.global_time_slots[i].hour >= now)
            this.time_slots.push(this.global_time_slots[i]);
        }
        if (this.time_slots.length == 0) {
          this.minDate.setDate(nextDay.getDate() + 1);
          // if (new Date() < this.minDate) {
          //   this.time_slots = [];
          // }
          // else {
          this.time_slots = this.global_time_slots;
          // }
        }
      } else if (schdate > nextDay) {
        this.time_slots = this.global_time_slots;
      }
    }
    else if (val == "regular_maintenance") {
      console.log("regular maintainance", val);
      this.minDate = new Date();
      this.minDate = new Date(this.minDate.setHours(0, 0, 0, 0));
      this.time_slots = this.global_time_slots;
    }

    this.form.get("scheduled_on").valueChanges.subscribe((res: any) => {
      this.holidayCheck = this.datepipe.transform(res, "EEEE, MMMM d, y");
      this.getHolidays();
      // this.timeVisibility = true;
      let nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + 1);
      if (val == "breakdown_maintenance") {
        let dt = new Date(res.setHours(0, 0, 0, 0));
        if (dt > this.minDate) {
          this.time_slots = this.global_time_slots;
          res = new Date(
            new Date(res.setHours(0, 0, 0, 0)).getTime() +
            parseInt(this.form.get("time_slot").value)
          );
        } else {
          let now = new Date().getHours();
          this.time_slots = [];
          for (var i = 0; i < this.global_time_slots.length; i++) {
            if (this.global_time_slots[i].hour >= now) {
              this.time_slots.push(this.global_time_slots[i]);
            }
          }
          if (this.time_slots.length == 0) {
            this.minDate.setDate(nextDay.getDate() + 1);
            // if (new Date() < this.minDate) {
            //   this.time_slots = [];
            // }
            // else {
            this.time_slots = this.global_time_slots;
            // }
          }
          console.log("time slots---->", this.time_slots);
          this.form.patchValue({
            time_slot: this.time_slots[0].value,
          });
          res = new Date(
            new Date(res.setHours(0, 0, 0, 0)).getTime() +
            this.time_slots[0].value
          );
        }
      }
      else if (val == 'regular_maintenance') {
        console.log('regular-->', val);
        this.time_slots = this.global_time_slots;
        console.log(res, "res in regular handle");
        res = new Date(new Date(res.setHours(0, 0, 0, 0)).getTime() + 28800000);
        console.log(res, "res in regular handle");
      }
      console.log("time slot value --->", this.form.get("time_slot").value);
      console.log("this.timeSlot", this.timeSlot);
      console.log("res scheduled on------>", res);
    });
  }

  fetchServiceRequest(params) {
    this.amcService.getServiceRequestDetails(params).subscribe(
      (response: any) => {
        this.ticket_details = response.payload.data;
        this.log_status = this.ticket_details.status;
        this.prevCashAmount = this.ticket_details.payment_cash;
        this.updateDataPackets();
        if (!params.patch) this._patchValue();
        console.log(">>>>>>>>>>>>", this.ticket_details);
      },
      (error: any) => { }
    );
  }

  updateDataPackets() {
    this.compData["sr_type"] = this.ticket_details.type;
    this.compData["ticket_id"] = this.ticket_details.uuid;
    this.compData["property_uuid"] = this.data.property_uuid;
    this.updateServices++;
  }

  _patchValue() {
    let imagesParams = [];
    if (
      this.ticket_details.images != null &&
      this.ticket_details.images != ""
    ) {
      let images = JSON.parse(this.ticket_details.images);
      images.forEach((image) => {
        imagesParams.push(image["url"]);
      });

      console.log("imagesParams  ", imagesParams);
    }
    const timestamp = Math.floor(this.ticket_details.scheduled_on * 1000);
    const midnightObj = new Date(timestamp).setHours(0, 0, 0, 0);
    this.timeSlot = timestamp - new Date(midnightObj).getTime();
    console.log(
      "time slot found ",
      Math.floor(this.ticket_details.scheduled_on * 1000),
      this.timeSlot,
      new Date(timestamp).getDay(),
      new Date(timestamp).getHours()
    );
    console.log("time slot found ---->", this.timeSlot);
    this.maintainance_type = this.ticket_details.type
    this.selectHandler(this.ticket_details.type);
    console.log("maintainance type=>", this.maintainance_type);
    if (this.status_options.findIndex((x) => x.value == this.log_status) < 0) {
      console.log(
        "resolving mismatch 1 ",
        this.status_options,
        this.log_status
      );
      this.status_options.push({
        label: this.log_status.replace("_", " "),
        value: this.log_status,
      });
    }

    if (
      this.type_options.findIndex((x) => x.value == this.ticket_details.type) <
      0
    ) {
      console.log(
        "resolving mismatch 2 ",
        this.type_options,
        this.ticket_details.type
      );
      this.type_options.push({
        label: this.ticket_details.type.replace("_", " "),
        value: this.ticket_details.type,
      });
      this.types.push({
        type: this.ticket_details.type,
        subtype: [
          {
            label: this.ticket_details.subtype.replace("_", " "),
            value: this.ticket_details.subtype,
          },
        ],
      });
    } else if (
      this.types.findIndex((x) => x.type == this.ticket_details.type) >= 0 &&
      this.types[
        this.types.findIndex((x) => x.type == this.ticket_details.type)
      ]["subtype"].findIndex((x) => x.value == this.ticket_details.subtype) < 0
    ) {
      console.log("resolving mismatch");
      this.types[
        this.types.findIndex((x) => x.type == this.ticket_details.type)
      ]["subtype"].push({
        label: this.ticket_details.subtype.replace("_", " "),
        value: this.ticket_details.subtype,
      });
    }
    console.log("time slots-=-=-=-=-=-==-=-=-=-=-=", this.time_slots);
    this.form = this.fb.group({
      type: [this.ticket_details.type, Validators.required],
      // subtype: [this.ticket_details.subtype],
      technician_uuid: [
        this.ticket_details.technician && this.ticket_details.technician.uuid
          ? this.ticket_details.technician.uuid
          : "",
      ],
      payment_status: [this.ticket_details.payment_status, Validators.required],
      images: [imagesParams],
      status: [this.log_status, Validators.required],
      description: [this.ticket_details.description],
      scheduled_on: [
        new Date(Math.floor(this.ticket_details.scheduled_on * 1000)),
        Validators.required,
      ],
      time_slot: [this.timeSlot, Validators.required],
      cost_visitation: [
        this.ticket_details.cost_visitation,
        Validators.required,
      ],
      cost_spare: [this.ticket_details.cost_spare, Validators.required],
      cost_service: [this.ticket_details.cost_service, Validators.required],
    });

    this.form.get("time_slot").valueChanges.subscribe((val) => {
      // this.dateTimeChange();
      console.log(val, "<-----");
    });

    this.form.get("type").valueChanges.subscribe((val) => {
      this.maintainance_type = val;
      this.selectHandler(val);
    });

    this.form.get("scheduled_on").valueChanges.subscribe((res: any) => {
      this.holidayCheck = this.datepipe.transform(res, "EEEE, MMMM d, y");
      this.getHolidays();
      // this.timeVisibility = true;
      if (this.maintainance_type == "breakdown_maintenance") {
        let dt = new Date(res.setHours(0, 0, 0, 0));
        if (dt > this.minDate) {
          this.time_slots = this.global_time_slots;
          res = new Date(
            new Date(res.setHours(0, 0, 0, 0)).getTime() +
            parseInt(this.form.get("time_slot").value)
          );
        } else {
          let now = new Date().getHours();
          this.time_slots = [];
          for (var i = 0; i < this.global_time_slots.length; i++) {
            if (this.global_time_slots[i].hour >= now) {
              this.time_slots.push(this.global_time_slots[i]);
            }
          }
          if (this.time_slots.length == 0) {
            this.minDate.setDate(this.minDate.getDate() + 1);
            // if (new Date < this.minDate) {
            //   this.time_slots = [];
            // }
            // else {
            this.time_slots = this.global_time_slots;
          }
          this.form.patchValue({
            time_slot: this.time_slots[0].value,
          });
          res = new Date(
            new Date(res.setHours(0, 0, 0, 0)).getTime() +
            this.time_slots[0].value
          );
        }
      }
      else if (this.maintainance_type == 'regular_maintenance') {
        console.log('regular-->', this.maintainance_type);
        this.time_slots = this.global_time_slots;
        res = new Date(new Date(res.setHours(0, 0, 0, 0)).getTime() + 28800000);
        console.log(res, "res in regular handle");
      }
      console.log("time slot value --->", this.form.get("time_slot").value);
      console.log("this.timeSlot", this.timeSlot);
      console.log("res scheduled on------>", res);
    });

    this.paymentForm = this.fb.group({
      cost_visitation: [
        this.ticket_details.cost_visitation,
        Validators.required,
      ],
      cost_service: [this.ticket_details.cost_service, Validators.required],
      cost_spare: [this.ticket_details.cost_spare, Validators.required],
      payment_cash: [this.ticket_details.payment_cash, Validators.required],
      gst: [200, Validators.required],
    });
    console.log("payment status cash value ", this.ticket_details.payment_cash);
    this.prevCashAmount =
      this.ticket_details.payment_cash != null
        ? this.ticket_details.payment_cash
        : 0;

    this.paymentForm.valueChanges.subscribe((val: any) => {
      console.log("new value of the form");
      console.log(this.paymentForm.value);
      console.log(this.paymentForm);
      this.payment_sum = 0;
      this.ticket_details.cost_visitation =
        this.paymentForm.get("cost_visitation").value;
      this.ticket_details.cost_service =
        this.paymentForm.get("cost_service").value;
      this.ticket_details.cost_spare = this.paymentForm.get("cost_spare").value;
    });
  }
  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  onStatusChange(event) {
    if (event != null && event.target != null) {
      this.log_status = event.target.value;
    }
  }

  jsonComparator(array, obj) {
    for (var i = 0; i < array.length; i++) {
      if (JSON.stringify(array[i]) == JSON.stringify(obj)) {
        this.slotPresent = true;
      }
    }
  }

  addPayment() {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    }, 100);
    this.adding_payment = true;
  }

  submitPayment() {
    if (
      this.paymentForm.get("payment_cash").value == null ||
      this.paymentForm.get("payment_cash").value == ""
    ) {
      this.adding_payment = false;
      return;
    }
    const extraPayment =
      this.paymentForm.get("payment_cash").value - this.prevCashAmount;
    const pendingAmount =
      (this.ticket_details && this.ticket_details.pending_amount
        ? this.ticket_details.pending_amount
        : 0) - extraPayment;
    console.log(
      "payment status ",
      this.paymentForm.controls.payment_cash.value,
      this.prevCashAmount,
      extraPayment,
      pendingAmount,
      this.ticket_details.pending_amount
    );
    if (pendingAmount == 0 || pendingAmount >= 1) {
      this.adding_payment = false;
      this.ticket_details.payment_cash =
        this.paymentForm.get("payment_cash").value;
      this.submit(false);
    } else if (0 < pendingAmount && pendingAmount < 1) {
      this.toast.error("PENDING AMOUNT CANNOT BE LESS THAN Rs.1");
    } else if (pendingAmount < 0) {
      this.toast.error("PAYMENT GREATER THAN PENDING AMOUNT IS NOT ALLOWED");
    }
  }

  // editPayment() {
  //   if(this.payment_mode == 'view') { this.payment_mode = 'edit'; }
  //   else { this.payment_mode = 'view'; }
  // }

  validForm() {
    Object.keys(this.form.controls).forEach((key) => {
      // console.log("controls ", this.form.get(key));
      if (this.form.get(key).errors && this.form.get(key).errors["required"]) {
        return false;
      }
    });
    return true;
  }

  submit(hideWindow) {
    if (!this.canEditSR) {
      return;
    }
    if (this.validForm()) {
      if (this.uploadingFiles) {
        this.toast.error("File Uploading Underprocess...");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.form.value));
      console.log("data==========", data);
      console.log(
        "this.form.get('scheduled_on').value.getTime()--->",
        new Date(this.form.get("scheduled_on").value.getTime()).setHours(
          0,
          0,
          0,
          0
        )
      );
      console.log("data==========", data);
      let imageList = [];
      data.images.forEach((image) => {
        imageList.push({
          url: image,
        });
      });
      data.ticket_uuid = this.data.ticket_uuid;
      data.images = JSON.stringify(imageList);

      if (data.technician_uuid != null && data.technician_uuid == "") {
        console.log("deleting technician key");
        delete data.technician_uuid;
      }
      let midnightObj = new Date(
        this.form.get("scheduled_on").value.getTime()
      ).setHours(0, 0, 0, 0);
      data.cost_service = this.ticket_details.cost_service;
      data.cost_spare = this.ticket_details.cost_spare;
      data.cost_visitation = this.ticket_details.cost_visitation;
      data.payment_cash = this.ticket_details.payment_cash;
      data.scheduled_on = Math.floor(
        (new Date(midnightObj).getTime() +
          parseInt(this.form.get("time_slot").value)) /
        1000
      );
      // data.scheduled_on = Math.floor(
      //   (this.form.get("scheduled_on").value.getTime() +
      //     parseInt(this.form.get("time_slot").value)) /
      //     1000
      // );
      data["property_uuid"] = this.data.property_uuid;
      data["service_list"] = [];

      console.log(data.scheduled_on, "<-------data.scheduled_on");
      this.amcService.updateServiceRequest(data).subscribe((val: any) => {
        if (val && val.status) {
          this.status.emit("success");
          if (hideWindow) {
            this.toast.success("REQUEST UPDATED SUCCESSFULLY");
            this.amcService.updateSRLists();
            this.dialogRef.close();
          } else {
            this.fetchServiceRequest({
              ticket_uuid: this.data.ticket_uuid,
              patch: true,
            });
            this.notifier.next();
          }
        } else {
          this.toast.error(val.message);
        }
      });
    } else {
      this.toast.error("PLEASE FILL MANDATORY FIELDS");
      console.log(this.form);
    }
  }

  postComment() {
    if (this.commentForm.valid) {
      let data = this.commentForm.value;
      data.ticket_id = this.data.ticket_uuid;

      data.images = JSON.stringify(data.images);
      console.log(" >>>>>>>>", data.images);

      this.amcService.postComment(data).subscribe(
        (response: any) => {
          this.notifier.next();
          this.resetCommentBox();
        },
        (error: any) => {
          console.log("api comment error");
        }
      );
    }
  }

  // dateTimeChange() {
  //   console.log("time slot", this.form.get("time_slot").value);
  //   const midnightObj = new Date(this.form.get("scheduled_on").value).setHours(
  //     0,
  //     0,
  //     0,
  //     0
  //   );
  //   const dateObj = new Date(
  //     new Date(midnightObj).getTime() +
  //       parseInt(this.form.get("time_slot").value)
  //   );
  //   console.log(
  //     "time slot changed ",
  //     midnightObj,
  //     new Date(midnightObj),
  //     new Date(midnightObj).getTime(),
  //     dateObj.getTime()
  //   );
  //   this.form.get("scheduled_on").setValue(dateObj);
  // }

  onAttachmentClick() {
    this.isActiveAttachment = !this.isActiveAttachment;
  }
  resetCommentBox() {
    this.commentForm = this.fb.group({
      description: ["", Validators.required],
      images: [[]],
    });
    this.isActiveAttachment = false;
  }
}

