import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AccountService } from '../../service/account/account.service';
import { AuthService } from '../../service/auth-service/auth.service';
import { TicketService } from '../../service/ticket/ticket.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { UserService } from '../../service/user-service/user.service';

@Component({
  selector: 'app-ticket-log',
  templateUrl: './ticket-log.component.html',
  styleUrls: ['./ticket-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketLogComponent implements OnInit {

  notifier: Subject<void> = new Subject<void>();
  @Output() status: EventEmitter<any> = new EventEmitter();
  log_color = 'yellow';
  ticketDetails = Object({});
  form: any;
  typeOptions = [];
  subTypeOptions = [];
  stageData = [];
  userList = [];
  canEditSR = true;
  minDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  statusOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' }
  ];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private titleCasePipe: TitleCasePipe,
    private ticketService: TicketService,
    private authService: AuthService,
    private userService: UserService,
    private toastService: MessageToastService,
    public dialogRef: MatDialogRef<TicketLogComponent>,
  ) { }

  ngOnInit() {
    this.canEditSR = this.userService.ticketEditAccess(JSON.parse(this.authService.getUser()));
    console.log("data in ticket log: ", this.data);
    this.ticketDetails = this.data;

    this.typeOptions = Object.keys(this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES).map(key => { return { 'label': key.split('_').join(' ').toUpperCase(), 'value': key } });

    this.form = this.fb.group({
      uuid: [this.data.uuid],
      property_uuid: [this.data.property_uuid],
      type: [{ value: this.data.type, disabled: !this.canEditSR }],
      subtype: [{ value: this.data.subtype, disabled: !this.canEditSR }],
      assigned_to_uuid: [{ value: "", disabled: !this.canEditSR }],
      assigned_to_type: [""],
      deadline_on: [new Date(this.data.deadline_on * 1000)],
      status: [this.data.status ? this.data.status : "open"],
      description: [this.data.description ? this.data.description : ""],
    });

    this.getStage(this.data.property_uuid);

    this.selectHandler(this.data.type);
    this.form.get('subtype').setValue(this.data.subtype);

    this.getUserList();
    this.form.get("type").valueChanges.subscribe(val => {
      this.selectHandler(val);
    });

  }

  // get validForm() {
  //   let data = JSON.parse(JSON.stringify(this.form.value));
  //      console.log("Math.floor(this.form.get('deadline_on').value.getTime() / 1000)---->", Math.floor(this.form.get("deadline_on").value.getTime() / 1000));
  //     data.deadline_on = Math.floor(this.form.get("deadline_on").value.getTime() / 1000);
  //     data.assigned_to_uuid = this.form.get('assigned_to_uuid').value.split('**')[0];
  //     data.assigned_to_type = this.form.get('assigned_to_uuid').value.split('**')[1];
  //     console.log("data------------->", data);
  //     this.form.value = data;
  //     console.log("data------------->", this.form.value);
  //     console.log("form value--->", this.form.valid, this.form.value);
  //   if (!this.form.valid) return false;
  //   if ((this.form.get('assigned_to_uuid').value && !this.form.get('assigned_to_type').value) ||
  //     (!this.form.get('assigned_to_uuid').value && this.form.get('assigned_to_type').value)) { return false; }
  //   return true;
  // }

  selectHandler(val) {
    console.log('value found in the type... ');
    console.log(val);
    this.typeOptions.forEach(option => {
      if (option.value.toLowerCase() == val) {
        console.log('changing value to...');
        console.log(option);
        this.subTypeOptions = [];
        this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES[option.value].forEach((optn) => this.subTypeOptions.push({ 'label': optn.split('_').join(' ').toUpperCase(), 'value': optn }));
        console.log('current value of subtype: ' + this.subTypeOptions, this.ticketService.SERVICE_REQUEST_PRE_COMMISSION_TYPES[option.value]);
      }
    });
  }

  getUserList() {
    this.accountService.getAllUsersList().subscribe((val: any) => {
      if (val && val.status) {
        console.log("users list found out: ", val.payload);
        Object.keys(val.payload).forEach((type) => {
          val.payload[type].forEach((user) => {
            this.userList.push({ 'label': user.name + ' (' + this.titleCasePipe.transform(user.type) + ')' + ' - ' + this.getDetail(user), 'value': user.uuid + '**' + user.type });
          });
        });
        this.addAssignee();
      }
    });
  }

  addAssignee() {
    this.form.get("assigned_to_uuid").setValue(this.data.assignee.uuid + '**' + this.data.assignee.type);
    this.form.get("assigned_to_type").setValue(this.data.assignee.type);
  }

  getDetail(user) {
    if (user.email) { return user.email; }
    return user.phone_number;
  }

  onStatusChange(event) {
    if (event != null && event.target != null) {
      this.form.get('status').setValue(event.target.value);
    }
  }

  dateTimeChange() {

  }

  getStage(uuid) {
    this.accountService.getStageTimeTakenDeadline({ uuid: uuid }).subscribe((res) => {
      this.stageData = res['payload']['data'];
    })
  }

  submit() {
    console.log(this.form.valid);
    // if (this.form.valid) {
      console.log("submitting form: ", this.form.value);
      let data = JSON.parse(JSON.stringify(this.form.value));
       console.log("Math.floor(this.form.get('deadline_on').value.getTime() / 1000)---->", Math.floor(this.form.get("deadline_on").value.getTime() / 1000));
      data.deadline_on = Math.floor(this.form.get("deadline_on").value.getTime() / 1000);
      data.assigned_to_uuid = this.form.get('assigned_to_uuid').value.split('**')[0];
      data.assigned_to_type = this.form.get('assigned_to_uuid').value.split('**')[1];
      console.log("data------------->", data);
      this.ticketService.updateTicket(data).subscribe((val: any) => {
        if (val && val.status) {
          this.toastService.success('TICKET UPDATED');
          this.dialogRef.close();
          this.status.emit('success');
        } else {
          this.toastService.error(val.message.toUpperCase());
        }
      });
    // }
    // else {
    //   this.toastService.error("INVALID FORM");
    // }
  }

}
