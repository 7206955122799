import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { LeadService } from '../../service/lead/lead.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.scss']
})
export class EditPropertyComponent implements OnInit {
  
  form: any;
  administrativeName = '';
  city_geo_point = "";
  filteredStateList = [];
  filteredSourceList = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditPropertyComponent>,
    private leadService: LeadService,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    console.log("data received in the modal: ", this.data);

    this.form = this.fb.group({
      lead_uuid: [this.data.lead.uuid],
      lead_property_uuid: [this.data.uuid],
      first_name: [this.data.lead.first_name],
      last_name: [this.data.lead.last_name],
      email: [this.data.lead.email],
      phone_number_1: [this.data.lead.phone_number_1],
      address: [this.data.address],
      city: [this.data.city],
      state: [this.data.state],
      lead_source: [this.data.source]
    });

    this.filteredStateList = this.form
          .get('state')
          .valueChanges.pipe(
            startWith(''),
            map(value => this.stateFilter(<string>value))
          );

    this.filteredSourceList = this.form.get('lead_source').valueChanges.pipe(startWith(''), map(value => this.sourceFilter(value)));
    console.log("fiteredsourcelist----------", this.filteredSourceList);
  }

  ngAfterViewInit() {
    let input = document.getElementById("city-input");
    var restrictOptions = {
      componentRestrictions: { country: "in" },
      fields: ["ALL"],
      types: ["(cities)"],
    };
    var autoComplete = new google.maps.places.Autocomplete(
      input,
      restrictOptions
    );
    google.maps.event.addListener(autoComplete, "place_changed", () => {
      let place = autoComplete.getPlace();
      this.city_geo_point = $("#city-input")[0].value;
      console.log("place object found out");
      console.log(place);
      place.address_components.forEach((item) => {
        console.log(
          item.types.includes("administrative_area_level_1"),
          this.administrativeName
        );
        if (item.types.includes("administrative_area_level_1")) {
          this.administrativeName = item.short_name;
        }
      });
      console.log('selected state: ', this.administrativeName);
      // this.administrativeName =
      //   place.address_components[
      //     place.address_components.length - 2
      //   ].short_name;
      this.form.get("city").setValue(this.city_geo_point);
      this.data.city = this.form.get("city").value;
      // $('#pac-input').val(this.display_text);
      window.dispatchEvent(new Event("resize"));
    });
    // this.setAdministrativeLevel(this.data.city);
    setTimeout(() => {
      this.form
        .get("city")
        .setValue(this.data.city != null ? this.data.city : "");
    }, 300);
  }

  private stateFilter(value) {
    const filterValue = value.toLowerCase();

    return this.data.state_dropdown.filter(option =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  private sourceFilter(value) {
    const filterValue = value.toLowerCase();

    return this.data.source_dropdown.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    // update new property 
    if(this.form.valid) {
      let data = JSON.parse(JSON.stringify(this.form.value));
      this.leadService.updateNewPropertyData(data).subscribe((val: any) =>{
        if(val && val.status) {
          this.toast.success("LEAD UPDATED SUCCESSFULLY");
          this.leadService.refreshNewPropertyList.next(true);
        }
        else {
          this.toast.error(val.message.toUpperCase());
        }
      });
    } else {
      this.toast.error("INVALID FORM DATA");
    }
  }

}
