import { Component, OnInit, ViewChild ,ElementRef, Renderer2 } from '@angular/core';
import { LeadService } from 'src/app/common/service/lead/lead.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { FormBuilder } from '@angular/forms';
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { LoggerService } from 'src/app/common/service/logger/logger.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { LeadActivationModalComponent } from 'src/app/common/component/lead-activation-modal/lead-activation-modal.component';
import { Subscription } from 'rxjs';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { DeleteModalComponent } from 'src/app/common/component/delete-modal/delete-modal.component';
import { AddClusterComponent } from 'src/app/common/component/add-cluster/add-cluster.component';
import { CreateActivityLogComponent } from 'src/app/common/component/create-activity-log/create-activity-log.component';
import { SetReminderComponent } from 'src/app/common/component/set-reminder/set-reminder.component';
import { ActivityLogListComponent } from '../activity-log-list/activity-log-list.component';
import { UserService } from 'src/app/common/service/user-service/user.service';
import { AssigningSalesPartnerComponent } from "src/app/common/component/assigning-sales-partner/assigning-sales-partner.component";

declare var $: any;

const colMap = {
  Name: 'lead__first_name',
  'Phone Number': 'lead__phone_number_1',
  Address: 'address',
  Identifier: 'identifier',
  'Current Stage': 'current_stage',
  'Last Updated On': 'updated_on',
  Source: 'lead__lead_source',
  // 'Lead Status': 'lms_status',
  'Last Updated Stage': 'current_stage',
  'Deadline(days)': 'deadlineDays',
};

@Component({
  selector: "app-lead-list-view",
  templateUrl: "./lead-list-view.component.html",
  styleUrls: ["./lead-list-view.component.scss"],
})
export class LeadListViewComponent implements OnInit {
  items: string[] = [];
  selectedItems: string[] = [];
  selectAllCheckbox: boolean = false;
  checkboxVisibility: any;
  anyCheckboxChecked: any;
  isShowAlert: boolean = false;
  rows = [];
  count = 0;
  offset = 0;
  limit: number;
  leadList = [];
  listSize: number;
  // defaultAccess = 'view';
  form: any;
  stageList: any = [];
  clusterList: any = [];
  search_text: any = [];
  salesAgent: any = [];
  epcAgent: any = [];
  formData: any = {};
  isCollapsed: boolean = false;
  isTechnicalUser: boolean = false;
  clusterView: boolean = false;
  selectedCluster: any;
  sortData: any;
  queueDropdown: any = [];
  isBusinessUser = false;
  isPartner: boolean = false;
  reassignCluster = false;
  isAdminUser = false;
  hasAllLeadsAccess = false;
  hasChangeLeadStatusAccess = true;
  hasChangeAssignedExecutive = false;
  hasChangeDesignerAccess = false;
  hasAssignedLeadAccess = false;
  canAddProperty: Boolean = false;
  random_exp = -1;
  lmsStatusList: any = [];
  executiveList: any = [];
  designerList: any = [];
  discomeUserList: any = [];
  cityList: any = [];
  leadSourceList = [];
  pageCount = 0;
  subscriptionList = new Subscription();
  isFormValid = false;
  maxDate = new Date();
  utmSourceList = [];
  utmCampaignList = [];
  utmMediumList = [];
  leadCategoryList = [];
  formNameList = [];
  isPageActive: boolean = true;
  clusterIdForApi: any;
  user_type: any;
  showFilterDropdown: boolean = false;
  checkIsPartner :boolean = false;
  sales_partner_uuid : any;
  epc_cluster_uuid :any;
  partner_sales_user : string[];

  // this will store data of filter, sort, cluster_id and page change

  currentState = { cluster_id: "", page: 1, column_name: "", sort_order: "" };

  queueStatusMap = [
    { key: 1, value: "Running" },
    { key: 0, value: "Idle" },
  ];

  accessList = [
    { name: "Select an access level", value: "" },
    { name: "View", value: "view" },
    { name: "Edit", value: "edit" },
  ];

  preSalesStatusOptions = [
    { name: "Select", value: "" },
    { name: "Open Tickets", value: "open" },
    { name: "Close Tickets", value: "close" },
  ];

  preSalesFlagOptions = [
    { name: "Select", value: "" },
    { name: "Red", value: "red" },
    { name: "Green", value: "green" },
    { name: "Yellow", value: "yellow" },
  ];
  processingAttempts = [1, 2, 3];

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private leadService: LeadService,
    private authService: AuthService,
    private fb: FormBuilder,
    private clusterService: ClusterService,
    private dialog: MatDialog,
    private loggerService: LoggerService,
    private userService: UserService,
    private toast: MessageToastService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe((val) => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    });

    let user = JSON.parse(this.authService.getUser());
    this.sales_partner_uuid = user.user_details.uuid;
    this.epc_cluster_uuid = user.cluster_details.uuid;
    this.user_type = user.user_type;
    this.partner_sales_user = user.user_roles;
    const found_partner_sales = this.partner_sales_user.includes("partner_sales");

    if (this.user_type == "partner") {
      this.isPartner= true;
        if(found_partner_sales){
           this.checkIsPartner = true;
        }
    }
    const rolesToCheck = ["cluster_admin_business", "amplus_business", "amplus_admin", "amplus_sales_manager"];
    const found = rolesToCheck.some(role => this.partner_sales_user.includes(role));
    if (found) {
      this.showFilterDropdown = true;
    }
    this.hasChangeLeadStatusAccess = this.userService.getLeadStatusAccess(user);
    this.hasChangeAssignedExecutive =
      this.userService.getAssignedExecutiveAccess(user);
    this.hasChangeDesignerAccess =
      this.userService.getAssignedDesignerAccess(user);
    this.canAddProperty = this.userService.getAddPropertyAccess(user);

    this.form = this.fb.group({
      cluster_id: "",
      prev_stage_id: "",
      cur_stage_id: "",
      access: "",
      text: "",
      sales_agent_id: "",
      epc_agent_id: "",
      active_status: false,
      lms_status: "",
      executive_id: "",
      source: "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      form_name: "",
      blanket_category: "",
      cluster_independent: false,
      date_from: 0,
      date_to: 0,
      recommended: false,
      priority_level: 0,
      last_activity_log_date_to: 0,
      last_activity_log_date_from: 0,
      first_activity_log_date_to: 0,
      first_activity_log_date_from: 0,
      designer_id: "",
      discom_user_id: "",
      city: "",
      pre_sales_ticket_flag: "",
      pre_sales_ticket_status: "",
      sales_partner: "",
    });

    this.subscriptionList.add(
      this.clusterService.globalCluster.subscribe((val) => {
        if (val) {
          this.selectedCluster = val;
          this.form.get("cluster_id").setValue(this.selectedCluster.uuid);

          this.currentState["cluster_id"] = this.selectedCluster.uuid;

          this.getFilterData(this.currentState["cluster_id"]);
          this.getLeadDetails(this.currentState);

          $(".funnel-sidenav").click();
          this.submit("cluster_change");
        }
      })
    );
  }

  highlightRows() {
    Array.from(document.getElementsByTagName("datatable-body-row")).forEach(
      (row, idx) => {
        if (
          this.leadList[idx].is_recommended &&
          !row.classList.contains("highlight-row")
        ) {
          row.classList.add("highlight-row");
        } else if (
          !this.leadList[idx].is_recommended &&
          row.classList.contains("highlight-row")
        ) {
          row.classList.remove("highlight-row");
        }
      }
    );
  }

  getFilterData(cluster_id) {
    this.leadService.getFilterData(cluster_id).subscribe((val) => {
      this.stageList = [];
      this.stageList.push({ uuid: "", display_name: "Select a stage" });
      this.stageList.push(...val["payload"].stages);

      this.epcAgent = [];
      this.epcAgent.push({ uuid: "", name: "Select an epc agent" });
      this.epcAgent.push(...val["payload"].epc_partners);

      this.salesAgent = [];
      this.salesAgent.push({ uuid: "", name: "Select a sales agent" });
      this.salesAgent.push(...val["payload"].sales_partners);

      this.lmsStatusList = [];
      this.lmsStatusList.push({ uuid: "", value: "Select" });
      val["payload"].lms_status.forEach((status) => {
        this.lmsStatusList.push({ uuid: status, value: status });
      });

      this.executiveList = [];
      this.executiveList.push({ uuid: "", name: "Select" });
      this.executiveList.push(...val["payload"].executives);

      this.designerList = [];
      this.designerList.push({ uuid: "", name: "Select" });
      this.designerList.push(...val["payload"].designers);

      this.discomeUserList = [];
      this.discomeUserList.push({ uuid: "", name: "Select" });
      this.discomeUserList.push(...val["payload"].discom_users);

      this.cityList = [];
      this.cityList.push({ label: "Select a city", value: "" });
      val["payload"]["cities"].forEach((ele: any) => {
        this.cityList.push({ label: ele, value: ele });
      });
      this.leadSourceList = [];
      this.leadSourceList.push(...val["payload"].lead_sources);

      this.utmSourceList = [];
      this.utmSourceList.push({ label: "Select a Source", value: "" });
      val["payload"]["utm_sources"].forEach((ele: any) => {
        this.utmSourceList.push({ label: ele, value: ele });
      });

      this.utmCampaignList = [];
      this.utmCampaignList.push({ label: "Select a Campaign", value: "" });
      val["payload"]["utm_campaigns"].forEach((ele: any) => {
        this.utmCampaignList.push({ label: ele, value: ele });
      });

      this.utmMediumList = [];
      this.utmMediumList.push({ label: "Select a Medium", value: "" });
      val["payload"]["utm_mediums"].forEach((ele: any) => {
        this.utmMediumList.push({ label: ele, value: ele });
      });

      this.leadCategoryList = [];
      this.leadCategoryList.push({ label: "Select a Category ", value: "" });
      val["payload"]["blanket_category"].forEach((ele: any) => {
        this.leadCategoryList.push({ label: ele, value: ele });
      });

      this.formNameList = [];
      this.formNameList.push({ label: "Select a Name", value: "" });
      val["payload"]["form_names"].forEach((ele: any) => {
        this.formNameList.push({ label: ele, value: ele });
      });

      this.clearForm();
    });
  }

  combineNameAndNumber(data) {
    let option_text: string;
    if (data.number) {
      option_text = data.name + " (" + <string>data.number + ")";
    } else {
      option_text = data.name;
    }
    return option_text;
  }

  onActivate(event) {
    console.log("event.cellIndex", event.cellIndex);
    const clickedColumnName = event.column.name;

    if (
      event.type === "click" &&
      ![
        "Executive",
        "Designer",
        "Last Updated Stage",
        "Medium",
        "Select all",
        "Checkbox",
      ].includes(clickedColumnName)
    ) {
      // if (
      //   event.type == "click" &&
      //   event.cellIndex != 14 &&
      //   event.cellIndex != 15 &&
      //   event.cellIndex != 16 &&
      //   event.cellIndex != 18 &&
      //   event.cellIndex != 10
      // )

      console.log("**** event found out");
      console.log(event);
      setTimeout(() => {
        window.open("lead/view/stage-view?id=" + event.row.uuid);
      }, 300);
    }
  }

  getLeadDetails(data) {
    console.log("data sending for getting list");
    console.log(data);

    this.clusterIdForApi = data.cluster_id;
    this.leadService.getLeadList(data).subscribe((result: any) => {
      if (result && result.status) {
        this.checkboxVisibility = result.payload.checkbox;

        this.items = result["payload"].data.map((item) => item.uuid);

        this.pageCount = result["payload"].data.length;
        result.payload.data.forEach((val) => {
          val["full_name"] =
            val.lead.first_name +
            (val.lead.last_name != "" ? " " + val.lead.last_name : "");

          if (Object.keys(val["executive"]).length > 0) {
            val["executive"].name =
              val["executive"].name && val["executive"].name != ""
                ? val["executive"].name
                : "";
          } else {
            val["executive"] = { uuid: "", name: "" };
          }

          val["lms_status"] =
            val["lms_status"] && val["lms_status"] != ""
              ? val["lms_status"]
              : "";
          val["phone_number"] =
            val.lead.phone_number_1 +
            (val.lead.phone_number2 ? " , " + val.lead.phone_number2 : "");

          val["current_stage"] = val.current_stage.display_name;
        });
        this.queueDropdown = result.payload.queue_status_drop_down;
        this.leadList = [...result.payload.data];
        this.limit = result.payload.data_per_page;
        this.count = result.payload.count;
        this.offset = data["page"] - 1;
        setTimeout(() => {
          this.highlightRows();
        }, 300);
      }
    });
  }

  onPage(event) {
    // const currentPageNumber = this.currentState["page"];
    // //  $('li.pages').removeClass("active");
    // //  $('li.pages[aria-label="page "'+currentPageNumber+']').addClass('active');
    // const liElement = this.elementRef.nativeElement.querySelector("li.pages");
    
    // this.renderer.removeClass(liElement, "active");
    // //this.renderer.removeClass(liElement, 'active');
    // // Add the 'active' class if the page is active
    // if (this.isPageActive) {
    //   this.renderer.addClass(liElement, "xxxxxxxxxxxxxxxxxxxxxxxx");
    // }
    // // Set the aria-label attribute value
    // this.renderer.setAttribute(liElement, "aria-label", "page 4");
     if (this.isShowAlert) {
      this.anyCheckboxChecked = false;
      // this.checked = false
      this.toggleSelectAll(false)
       alert(
           "NOTE! once you move to the next page selected leads! will be unchecked."
      //   "Please assign the sales partner for selected leads! before moving to next page."
       );
    } 
      this.currentState["page"] = event.offset + 1;

      this.offset = event.offset;

      this.getLeadDetails(this.currentState);
    
  }

  editLead(event) {
    setTimeout(() => {
      window.open("lead/view/stage-view?id=" + event.uuid);
    }, 300);
  }

  propertyGeneration(event) {
    setTimeout(() => {
      window.open("lead/view/property-generation?id=" + event.uuid);
    }, 300);
  }

  configureIot(event) {
    setTimeout(() => {
      window.open("lead/view/configure-iot?id=" + event.uuid);
    }, 300);
  }
  configureAMC(event) {
    setTimeout(() => {
      window.open("lead/view/amc?id=" + event.uuid);
    }, 300);
  }

  activateProperty(event) {
    if (this.isTechnicalUser) {
      let dialogRef = this.dialog.open(LeadActivationModalComponent, {
        data: { activation_status: event.activation_status, uuid: event.uuid },
      });

      dialogRef.componentInstance.status.subscribe((data) => {
        if (data == "success") {
          this.getLeadDetails(this.currentState);
        }
      });
    }
  }

  openMap(event) {
    window.open("lead/map-view/" + event.uuid);
  }

  addProperty() {
    window.open("lead/view/stage-view?id=create");
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  onSort(event) {
    console.log(event);

    // if clicked column is not in the column list, we return
    if (Object.keys(colMap).indexOf(event["column"].name) < 0) {
      return;
    }

    this.currentState["column_name"] = colMap[event["column"].name];
    this.currentState["sort_order"] = event["newValue"];

    // ngx tables by default sends you to 1st page after sorting
    this.offset = 0;
    this.currentState["page"] = 1;

    this.getLeadDetails(this.currentState);
  }

  checkFormValidity() {
    let validity = true;
    Object.keys(this.form.controls).forEach((key) => {
      // console.log("checking ", key, "errortype ", ( this.form.get(key).errors != null && this.form.get(key).errors.length > 0));
      if (
        key != "date_from" &&
        key != "date_to" &&
        this.form.get(key).errors != null &&
        this.form.get(key).errors.length > 0
      ) {
        validity = false;
      }
    });
    this.isFormValid = validity;
  }

  // submit function
  submit(mode = "normal") {
    this.checkFormValidity();
    const clusterEnabled = !this.form.get("cluster_independent").value;
    console.log("filter form ", this.form);
    let priority = this.form.get("priority_level").value;
    this.form.get("priority_level").setValue(priority ? 1 : 0);
    // when filter, sorting or page change occurs
    if (mode != "cluster_change") {
      let dateFieldsFrom = [
        "last_activity_log_date_from",
        "first_activity_log_date_from",
      ];
      let dateFieldsTo = [
        "last_activity_log_date_to",
        "first_activity_log_date_to",
      ];
      Object.keys(this.form.value).forEach((val) => {
        console.log(val);
        if (
          dateFieldsTo.indexOf(val) >= 0 &&
          this.form.get(val).value != 0 &&
          this.form.get(val).value != null &&
          this.form.get(val).value != ""
        ) {
          console.log(new Date(this.form.get(val).value.setHours(23, 59, 59)));
          this.currentState[val] = Math.floor(
            this.form.get(val).value.getTime() / 1000
          );
        } else if (
          dateFieldsFrom.indexOf(val) >= 0 &&
          this.form.get(val).value != 0 &&
          this.form.get(val).value != null &&
          this.form.get(val).value != ""
        ) {
          this.currentState[val] = Math.floor(
            this.form.get(val).value.getTime() / 1000
          );
        } else if (this.form.value[val] != "" && this.form.value[val] != null) {
          this.currentState[val] = this.form.value[val];
        } else {
          delete this.currentState[val];
        }
      });
      if (
        !clusterEnabled &&
        Object.keys(this.currentState).indexOf("cluster_id") >= 0
      ) {
        delete this.currentState["cluster_id"];
      } else if (
        clusterEnabled &&
        Object.keys(this.currentState).indexOf("cluster_id") < 0
      ) {
        this.currentState["cluster_id"] = this.selectedCluster.uuid;
      }
      this.getLeadDetails(this.currentState);
    }
    // when cluster change occurs
    else {
      this.clearForm();
    }
    this.clearState();
    this.sidenavBar.close();
  }

  clearForm() {
    // resetting form data
    this.resetForm();
    // removing form data from state, only keeping cluster_id, pagenum and sort data
    let newState = {
      cluster_id: this.selectedCluster.uuid,
      page: this.currentState.page,
      column_name: this.currentState.column_name,
      sort_order: this.currentState.sort_order,
    };
    this.currentState = newState;
  }

  // clear sorting, and page data
  clearState() {
    this.currentState["page"] = 1;
    this.currentState["sort_order"] = "";
    this.currentState["column_name"] = "";
  }

  resetForm() {
    this.form.patchValue({
      prev_stage_id: "",
      cur_stage_id: "",
      access: "",
      text: "",
      sales_agent_id: "",
      epc_agent_id: "",
      active_status: false,
      lms_status: "",
      executive_id: "",
      source: "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      blanket_category: "",
      form_name: "",
      date_from: 0,
      date_to: 0,
      cluster_independent: false,
      recommended: false,
      priority_level: 0,
      last_activity_log_date_to: 0,
      last_activity_log_date_from: 0,
      first_activity_log_date_to: 0,
      first_activity_log_date_from: 0,
      designer_id: "",
      discom_user_id: "",
      city: "",
      pre_sales_ticket_status: "",
      pre_sales_ticket_flag: "",
      sales_partner: "",
    });
  }

  optionSelect(key, row) {
    let data = { is_logging: key, property_uuid: row.uuid };
    this.loggerService.manageQueue(data).subscribe((val) => {
      if (val && !val["status"]) {
        this.toast.error("OPERATION FAILED");
      }
      if (val && val["status"]) {
        this.toast.success("QUEUE STATUS UPDATED SUCCESSFULLY");
      }
      this.getLeadDetails(this.currentState);
    });
  }

  disableLead(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe((data) => {
      if (data == "success") {
        this.leadService
          .disableLead({ property_id: row.uuid })
          .subscribe((val) => {
            if (val && val["status"]) {
              if (this.count == 1 && this.currentState.page > 1) {
                this.currentState["page"] = this.currentState["page"] - 1;
              }
              this.getLeadDetails(this.currentState);
              this.toast.success("LEAD DELETED SUCCESSFULLY");
            } else {
              this.toast.error("OPERATION FAILED");
            }
          });
      }
    });
  }

  downloadList(type) {
    console.log("request for downloading list");
    let downloadState = JSON.parse(JSON.stringify(this.currentState));
    delete downloadState["page"];
    console.log("current angular download object");
    console.log(downloadState);
    downloadState["download"] = 1;
    switch (type) {
      case "lead-list":
        this.leadService.getLeadList(downloadState).subscribe((res) => {
          this.toast.success(res["message"]);
        });
        break;
      case "employee-list":
        this.leadService.downloadListByUrl(
          type,
          this.leadService.downloadEmployeeUrl,
          downloadState
        );
        break;
    }
  }

  clusterReassign(row) {
    this.updateModal([row], true);
  }

  updateModal(body, multi) {
    let dialogRef = this.dialog.open(AddClusterComponent, {
      data: { data: body, multi: multi },
    });

    dialogRef.componentInstance.status.subscribe((data) => {
      if (data == "success") {
        this.currentState["page"] =
          this.pageCount == 1 && this.currentState["page"] > 1
            ? this.currentState["page"] - 1
            : this.currentState["page"];
        this.getLeadDetails(this.currentState);
      }
    });
  }

  sidenavOpen() {
    console.log("sidenav opened");
    $("app-lead-list-view").css({ height: "100%" });
    $(".funnel-filter-container").css({ "padding-bottom": "0px" });
  }

  sidenavClose() {
    console.log("sidenav closed");
    $("app-lead-list-view").css({ height: "auto" });
    $(".funnel-filter-container").css({ "padding-bottom": "" });
  }

  executiveChange(uuid, row) {
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      executive_uuid: uuid,
    };
    this.leadService.updateExecutive(data).subscribe((val) => {
      if (val && val["status"]) {
        this.getLeadDetails(this.currentState);
        this.toast.success("PROPERTY UPDATED SUCCESSFULLY");
      } else {
        this.toast.error("OPERATION FAILED");
      }
    });
  }

  onDesignerChange(row) {
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      designer_uuid: row.designer.uuid,
    };
    this.leadService.updateDesigner(data).subscribe((val) => {
      if (val && val["status"]) {
        this.getLeadDetails(this.currentState);
        this.toast.success("DESIGNER UPDATED SUCCESSFULLY");
      } else {
        this.toast.error("OPERATION FAILED");
      }
    });
  }

  onDiscomUserChange(row) {
    let data = {
      lead_property_id: row.uuid ? row.uuid : null,
      discom_user_id: row.discom_user.uuid,
    };
    this.leadService.updateDiscomUser(data).subscribe((val) => {
      if (val && val["status"]) {
        this.getLeadDetails(this.currentState);
        this.toast.success("DISCOM USER UPDATED SUCCESSFULLY");
      } else {
        this.toast.error("OPERATION FAILED");
      }
    });
  }

  lmsStatusChange(event, value, row) {
    console.log("status change: ", event);
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      lms_status: value,
    };
    console.log(data);

    this.leadService.updateLmsStatus(data).subscribe((val) => {
      if (val && val["status"]) {
        this.getLeadDetails(this.currentState);
        this.toast.success("PROPERTY UPDATED SUCCESSFULLY");
      } else {
        this.toast.error("OPERATION FAILED");
        if (row.processing_attempts > 0 && row.processing_attempts < 3) {
          row.lms_status = "processing";
        }
      }
    });
  }

  //to get checkbox Uuid function here

  

  toggleSelectAll(checked: boolean) {

    if (checked) {
      this.selectedItems = this.items.slice();
      console.log("selectedItems All checked" + this.selectedItems);
    } else {
      this.selectedItems = [];
    }
    this.updateSelectAllCheckbox();
    console.log("selectedItems all unchecked" + this.selectedItems);
  }

  toggleSelectOne(item: any, checked: boolean) {
    if (checked) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      }
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.updateSelectAllCheckbox();
  }

  updateSelectAllCheckbox() {
    this.selectAllCheckbox = this.items.every((item) =>
      this.selectedItems.includes(item)
    );
    this.isShowAlert = true;
    console.log("selectedItems one" + this.selectedItems);
    const selectedItems_uuid = this.selectedItems;

    if (selectedItems_uuid.length == 0) {
      this.anyCheckboxChecked = false;
    } else {
      this.anyCheckboxChecked = true;
    }

    this.anyCheckboxChecked; //= this.selectedItems
  }

  //end here

  // assigningSalesPartner dialog
  assigningSalesPartner() {
   // alert(this.clusterIdForApi);

    let dialogRef = this.dialog.open(AssigningSalesPartnerComponent, {
      data: {
        selectedItems: this.selectedItems,
        clusterId: this.clusterIdForApi,
      },
    });
  }
  //end here

  //

  assigningSalesPartnerEpc(){
  let dialogRef = this.dialog.open(AssigningSalesPartnerComponent, {
      data: {
        selectedItems: this.selectedItems,
        clusterId: this.sales_partner_uuid,
        epc_cluster_uuid : this.epc_cluster_uuid,
        user_type : this.user_type,
      },
    });  
  }
  
  viewActivityLog(row) {
    let dialogRef = this.dialog.open(ActivityLogListComponent, {
      data: row,
    });
  }

  createActivityLog(row) {
    let dialogRef = this.dialog.open(CreateActivityLogComponent, {
      data: row,
    });
  }

  setReminder(row) {
    let dialogRef = this.dialog.open(SetReminderComponent, {
      data: row,
    });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
