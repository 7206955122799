import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddonsItem, ModuleItem } from '../../component/manage-proposal/models/module-item';

@Injectable({
  providedIn: 'root'
})
export class ManageProposalService {

  baseURL: any;
  // proposalCrudUrl = "v1/proposal/property";
  proposalLogsUrl = "v1/proposal/property/log";
  proposalCrudUrl = "v3/proposal/property";

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

   
  getProposal(data) {
    return this.http.get(this.baseURL + this.proposalCrudUrl, {
      params: data
    });
  }
  getProposalLogs(uuid) {
    return this.http.get(this.baseURL + this.proposalLogsUrl, {
      params: {uuid: uuid}
    });
  }

  createProposal(data, lead_uuid) {
    return this.http.post(this.baseURL + this.proposalCrudUrl, data, {
      params: {uuid: lead_uuid}
    });
  }

  updateProposal(data, lead_uuid) {
    return this.http.put(this.baseURL + this.proposalCrudUrl, data, {
      params: {uuid: lead_uuid}
    });
  }
  getModules() {
    let modules: ModuleItem[] = [{
        uuid: "terra",
        quantity: 2,
        epc_cost: 1200,
        name: "Terra",
        capacity: 2.1,
        category: "module"
    },
    {
        uuid: "aerial",
        quantity: 1,
        epc_cost: 3200,
        name: "Aerial",
        capacity: 4.1,
        category: "module"
    },
    {
        uuid: "atrium",
        quantity: 0,
        epc_cost: 0,
        name: "Atrium",
        capacity: 0,
        category: "module"
    },
    {
        uuid: "fush-mount",
        quantity: 0,
        epc_cost: 0,
        name: "Fush Mount",
        capacity: 0,
        category: "module"
    }
    ] 
    return modules;
}

getAddons() {
  let addons: AddonsItem[] = [{
      uuid: "cable",
      name: "Cable Trays",
      price: 2901,
      category: "addons",
      enabled: true
  },
  {
    uuid: "earthing",
    name: "Earthing",
    price: 3801,
    category: "addons",
    enabled: true
  },
  {
    uuid: "net-metering",
    name: "Net Metering",
    price: 2450,
    category: "addons",
    enabled: true
  },
  {
    uuid: "app",
    name: "HomeScape App",
    price: 8190,
    category: "addons",
    enabled: true
  },
  {
    uuid: "consumption-metering",
    name: "Consumption Metering",
    price: 5710,
    category: "addons",
    enabled: true
  },
  ] 
  return addons;
}
   

}
