import { Input } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { AMCService } from "../../service/amc/amc.service";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: 'app-sr-services',
  templateUrl: './sr-services.component.html',
  styleUrls: ['./sr-services.component.scss']
})
export class SrServicesComponent implements OnInit {
  @Input() data;
  @Input() update;
  @Input() canEdit;

  totalServicePrice = 0;
  discountedPrice = 0;
  dropdownOpts = [];
  filteredOpts;
  serviceList = [];
  selectedService;
  ticketId;

  form: any;

  constructor(
    private fb: FormBuilder,
    private amcService: AMCService,
    private toastService: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      service: [""]
    });

    this.filteredOpts = this.form.get("service").valueChanges.pipe(
      startWith(''),
        map(service => service ? this._filterOpts(service) : this.dropdownOpts.slice())
    );
  }

  ngOnChanges() {
    if(this.data) {
      if(this.data.sr_type && this.data.sr_type != '') {
        this.amcService.getDropdownServiceList({ category: this.data.sr_type, property_uuid: this.data.property_uuid }).subscribe((val: any) => {
          if(val && val.status) {
            console.log("service list", val.payload);
            this.dropdownOpts = val.payload.data;
  
            this.form.get("service").setValue("");
          }
        });
      }

      if(this.data.ticket_id && this.data.ticket_id != '') {
        this.ticketId = this.data.ticket_id;
        this.fetchServiceList();
      }
    }
  }

  _filterOpts(value) {
    const filterValue = value.toLowerCase();
    return this.dropdownOpts.filter(service => service.title.toLowerCase().indexOf(filterValue) === 0);
  }

  setService(uuid) {
    this.selectedService = uuid;
    console.log('selected service is: ', uuid);
  }

  addService() {
    this.amcService.addTicketService({ ticket_id: this.data.ticket_id, service_list: [ this.selectedService ], property_uuid: this.data.property_uuid}).subscribe((val: any) => {
      if(val && val.status) {
        console.log("success", val);
        this.toastService.success("SERVICE ADDED SUCCESSFULLY");
        this.form.get("service").setValue("");

        this.fetchServiceList();
      } else {
        this.toastService.error(val.message.toUpperCase());
      }
    });
  }

  deleteService(uuid) {
    this.amcService.deleteTicketService({ uuid: uuid }).subscribe((val: any) => {
      if( val && val.status ) {
        this.toastService.success("SERVICE DELETED SUCCESSFULLY");
        this.fetchServiceList();
      }
    });
  }

  fetchServiceList() {
    this.amcService.getAddedServiceList({ ticket_uuid: this.ticketId }).subscribe((val: any) => {
      if(val && val.status) {
        console.log("service list found out:", val.payload);
        this.serviceList = val.payload.data;

        this.totalServicePrice = 0;
        this.discountedPrice = 0;
        this.serviceList.forEach((service: any) => {
          this.totalServicePrice += (service.original_cost ? service.original_cost : 0) +  (service.original_cost * service.cost_gst) / 100;
          this.discountedPrice += (service.cost ? service.cost : 0) +  (service.cost * service.cost_gst) / 100;
        });
      }
    });
  }

}
