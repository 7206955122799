import { Component, OnInit, EventEmitter, Output, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Output() option: EventEmitter<any> = new EventEmitter();
  titleText: string = "CONFIRMATION";
  descText: string = "Are you sure you want to delete?";


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) { }

  ngOnInit() {
    if(this.data) {
      if(this.data.title) {
        this.titleText = this.data.title;
      }
      if(this.data.desc) {
        this.descText = this.data.desc;
      }
    }
  }

  option_yes() {
    this.option.emit('success');
    this.dialogRef.close();
  }

  option_no() {
    this.dialogRef.close();
  }


}
