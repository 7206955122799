import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AMCService } from '../../service/amc/amc.service';

@Component({
    selector: 'app-view-asset',
    templateUrl: './view-asset.component.html',
    styleUrls: ['./view-asset.component.scss']
})
export class ViewAssetComponent implements OnInit {

    @Output() status: EventEmitter<any> = new EventEmitter();
    offset = 0;
    limit = 5;
    count = 100;
    active_index = 0;
    warranty_modules = ['Modules', 'Inverters', 'Structure', 'BOS'];
    asset_list = [];
    filtered_asset_list = [];

    constructor(
        private amcService: AMCService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ViewAssetComponent>,
    ) { }

    ngOnInit() {
        this.getFilteredList();
    }


    switchActiveTab(i) {
        this.active_index = i;
        this.getFilteredList();
    }

    fetchAssetDetails() {
        this.amcService.getAssetList({ 'property_uuid': this.data.lead_uuid }).subscribe((val: any) => {
            if (val && val['status']) {
                console.log('result from asset details');
                console.log(val);
                this.asset_list = val['payload'].data;
                console.log(this.asset_list);
                this.getFilteredList();
            }
        });
    }

    getFilteredList() {
        console.log("getting filtered list");
        let category = "";
        switch (this.active_index) {
            case 0: category = "Modules"; break;
            case 1: category = "Inverters"; break;
            case 2: category = "Structure"; break;
            case 3: category = "BOS"; break;
        }

        this.filtered_asset_list = [];
        let dummy_list = [];
        this.data.asset_list.forEach((asset: any) => {
            if (asset.warranty_module && asset.warranty_module.category.toLowerCase() == category.toLowerCase()) {
                dummy_list.push(asset);
            }
        });

        this.filtered_asset_list = dummy_list;
        console.log("final filtered list");
        console.log(this.filtered_asset_list);
    }
}
