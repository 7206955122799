import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'statusTextTransform'})
export class StatusTextTransform implements PipeTransform {
  transform(value: any): string {
    let re = /\_/gi;
    let val = value ? `${value}` : ""
    let newStr = val.replace(re, " ");
    return newStr;
  }
}