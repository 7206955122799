import {
    Component,
    OnInit,
    Inject,
    EventEmitter,
    Output,
    Renderer2,
    Renderer,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ClusterService } from "../../service/cluster/cluster.service";
import { LeadService } from "../../service/lead/lead.service";
declare var google: any;
declare var $: any;

@Component({
    selector: "app-ivr-logs",
    templateUrl: "./ivr-logs.component.html",
    styleUrls: ["./ivr-logs.component.scss"],
})
export class IvrLogsComponent implements OnInit {

    selected_tab = 0;
    pendingLogs = [];
    resolvedLogs = [];
    pendingLogsLen = 0;
    resolvedLogsLen = 0;

    @Output() status: EventEmitter<any> = new EventEmitter();
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<IvrLogsComponent>,
        private toast: MessageToastService,
        private clusterService: ClusterService,
        private leadService: LeadService,
        private renderer: Renderer
    ) {
        // to close popup if we click anywhere else on the screen
        renderer.listenGlobal("document", "click", (event) => {
            let requestPopup = document.getElementById("mark-channel-partner-popup");
            if (
                !event.target.classList.contains("popup-action-btn") &&
                requestPopup
            ) {
                requestPopup.remove();
            }
        });
    }

    ngOnInit() {
        console.log("data received in this modal");
        this.getPendingIVRLogs();
    }

    ngAfterViewInit() { }

    onTabChange(event) {
        this.selected_tab = event.index;
        console.log(" this.selected_tab  ivr-->", this.selected_tab);
        if (this.selected_tab == 0) {
            this.getPendingIVRLogs();
        }
        else if (this.selected_tab == 1) {
            this.getResolvedIVRLogs();
        }
    }

    getPendingIVRLogs() {
        this.leadService.getIvrLogs({ is_answered: false }).subscribe(res => {
            console.log("res----- ivr----->", res);
            if (res && res['status']) {
                this.pendingLogs = res['payload']['data'];
                this.pendingLogsLen = this.pendingLogs.length;
            }
            else {
                console.log("no pending logs available...");
            }
        });
    }

    getResolvedIVRLogs() {
        this.leadService.getIvrLogs({ is_answered: true }).subscribe(res => {
            if (res && res['status']) {
                this.resolvedLogs = res['payload']['data'];
                this.resolvedLogsLen = this.resolvedLogs.length;
            }
            else {
                console.log("no resolved logs available...");
            }
        });
    }

    updateLog(uuid) {
        let data = {
            uuid: uuid
        }
        this.leadService.updateIvrlogs(data).subscribe(res => {
            if (res && res['status']) {
                this.toast.success("Log Resolved Successfully");
            }
            else {
                this.toast.error("Please Try again!!!");
            }
        });
        this.getPendingIVRLogs();
    }
    submit() { }

    cancel() {
        this.dialogRef.close();
    }
}
