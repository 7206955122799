import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, matFormFieldAnimations, MAT_DIALOG_DATA } from '@angular/material';
import { debounceTime } from 'rxjs/operators';
import { AMCService } from '../../service/amc/amc.service';
import { ManageProposalService } from '../../service/proposal/manage-proposal.service';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { isArray } from 'util';
// import { ModuleItemData } from './models/module-item';

@Component({
    selector: 'app-proposal',
    templateUrl: './proposal.component.html',
    styleUrls: ['./proposal.component.scss'],
})
export class ProposalComponent implements OnInit {

    moduleItemList: any = {
        'inverters': [],
        'modules': [],
        'structure': [],
    };
    filteredOptions: any = {
        'inverters': [],
        'modules': [],
        'structure': [],
    };
    uploadingFiles: boolean = false;
    form: any;
    displayedColumns = ['name', 'price'];
    panelOpenState = false;
    currentProposal: any;
    showEditable = false;
    consumerTypeDropdown = [];
    stateDropdown = [];
    amcTypeDropdown = [];
    proposalStatusDropdown = [];
    structureTypeDropdown = [];
    is_subsidy = false;
    total_selling_price = 0;
    phaseDropdown = [
        { key: "1 PH", value: "1ph" },
        { key: "3 PH", value: "3ph" }
    ];
    logs = [
        {
            "log": "Proposal Created",
            "submitter_name": "Aryan",
            "submitter_id": 1,
            "submitter_type": "cluster_admin",
            "submitter_email": "asif.iqbal@amplussolar.com",
            "log_type": "create",
            "created_on": 1621506984
        }
    ];
    inverterTotalInfo = {
        price: 0,
        capacity: 0
    };

    structureTotalInfo = {
        price: 0,
        quantity: 0
    };

    moduleTotalInfo = {
        price: 0,
        capacity: 0
    };
    structureTotalCount = 0;
    addonsTotalPrice = 0;
    servicesTotalPrice = 0;
    @Output() status: EventEmitter<any> = new EventEmitter();
    readonly = true;
    dropdownData: any;
    imageView: any;
    selectedTabIndex = 0;
    maxInverters: any = 0;
    quantityCom: any = 0;
    mFormQuantity: any = 0;

    constructor(
        private fb: FormBuilder,
        private toast: MessageToastService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ProposalComponent>,
        private amcService: AMCService,
        private proposalService: ManageProposalService

    ) { }

    ngOnInit() {
        console.log(this.data);
        this.form = this.fb.group({
            monthly_bill: [0, Validators.required],
            state: ['', Validators.required],
            consumer_type: ['', Validators.required],
            basic_price: ['100', Validators.required],
            gst_percentage: ['18', Validators.required],
            amc_tenure_days: ['365', Validators.required],
            status: ['admin_proposed', Validators.required],
            plan_id: ['', Validators.required],
            discount: [0, Validators.required],
            national_subsidy: [0, Validators.required],
            structures: this.fb.array([]),
            addons: this.fb.array([]),
            cable_tray: ['', Validators.required],
            cable_cost: [0, Validators.required],
            ie_meter_cost: [0],
            is_subsidy: [false],
            earthing: ['', Validators.required],
            earthing_cost: [0, Validators.required],
            is_net_metering: [true, Validators.required],
            net_metering_cost: [0, Validators.required],
            is_homescape_app: [true, Validators.required],
            homescape_app_cost: [0, Validators.required],
            is_consumption_monitoring: [true, Validators.required],
            consumption_monitoring_cost: [0, Validators.required],
            amc_cost: [0, Validators.required],
            phase_type: ["1ph"],
            bom_cost: [0, Validators.required],
            business_margin: [0, Validators.required],
            i_and_c: [0, Validators.required],
            regulatory: [0, Validators.required],
            images: [[], Validators.required],
        });
        this.initDefaultsAndPrefields();

    }

    get structures() {
        return this.form.controls.structures as FormArray;
    }

    get addons() {
        return this.form.controls.addons as FormArray;
    }

    initDefaultsAndPrefields() {
        this.getProposalDetails();
        // this.getWarrantyItemList('');

        this.addons.valueChanges.pipe(debounceTime(400)).
            subscribe(data => {
                console.log("data addon-->", data);
                this.addonsTotalPrice = 0
                data.forEach(form => {
                    this.addonsTotalPrice += parseInt(form.enabled && form.price != '' ? form.price : 0)
                });
            });

        this.form.get('cable_tray').valueChanges.subscribe((value) => {

            this.dropdownData.cable_tray.forEach((option) => {
                if (option.value == value && !this.showEditable) {
                    this.form.get('cable_cost').setValue(option.cost);
                }
            });
        });
        this.form.get('earthing').valueChanges.subscribe((value) => {
            this.dropdownData.earthing.forEach((option) => {
                if (option.value == value && !this.showEditable) {
                    this.form.get('earthing_cost').setValue(option.cost);
                }
            });
        });
        this.form.get('is_consumption_monitoring').valueChanges.subscribe((value) => {
            this.dropdownData.consumption_monitoring.forEach((option) => {
                if (option.value == value && !this.showEditable) {
                    this.form.get('consumption_monitoring_cost').setValue(option.cost);
                }
            });
        });
        this.form.get('is_homescape_app').valueChanges.subscribe((value) => {
            this.dropdownData.homescape_app.forEach((option) => {
                if (option.value == value && !this.showEditable) {
                    this.form.get('homescape_app_cost').setValue(option.cost);
                }
            });
        });
        this.form.get('is_net_metering').valueChanges.subscribe((value) => {
            this.dropdownData.net_metering.forEach((option) => {
                if (option.value == value && !this.showEditable) {
                    this.form.get('net_metering_cost').setValue(option.cost);
                }
            });
        });
        this.form.get('net_metering_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getAddonsTotalPrice();
        });
        this.form.get('homescape_app_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getAddonsTotalPrice();
        });
        this.form.get('cable_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getAddonsTotalPrice();
        });
        this.form.get('earthing_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getAddonsTotalPrice();
        });
        this.form.get('consumption_monitoring_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getAddonsTotalPrice();
        });
        this.form.get('is_subsidy').valueChanges.subscribe((value) => {
            this.is_subsidy = value;
        });
        this.form.get('business_margin').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getServicesTotalPrice();
        });
        this.form.get('i_and_c').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getServicesTotalPrice();
        });
        this.form.get('regulatory').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getServicesTotalPrice();
        });
        this.form.get('amc_cost').valueChanges.pipe(debounceTime(400)).subscribe((_) => {
            this.getServicesTotalPrice();
        });
    }

    getProposalDetails() {
        this.proposalService.getProposal({ uuid: this.data.uuid }).subscribe(
            (response: any) => {
                this.currentProposal = response.payload.data;
                this.total_selling_price = response.payload.total_selling_price;
                this.form.get('bom_cost').setValue(this.total_selling_price);
                this.getServicesTotalPrice();
                this.showEditable = this.currentProposal != null;
                this.dropdownData = response.payload.dropdown_data;
                this.dropdownData.proposal_status.forEach(item => {
                    this.proposalStatusDropdown.push({
                        key: item.label,
                        value: item.value
                    });
                });
                this.proposalStatusDropdown = [...this.proposalStatusDropdown]
                this.dropdownData.amc_type.forEach(item => {
                    this.amcTypeDropdown.push({
                        key: item.label,
                        value: item.value
                    });
                });
                this.amcTypeDropdown = [...this.amcTypeDropdown]

                this.dropdownData.customer_type.forEach(item => {
                    this.consumerTypeDropdown.push({
                        key: item.label,
                        value: item.value
                    });
                });
                this.consumerTypeDropdown = [...this.consumerTypeDropdown]

                this.dropdownData.state.forEach(item => {
                    this.stateDropdown.push({
                        key: item.label,
                        value: item.value
                    });
                });
                this.stateDropdown = [...this.stateDropdown]

                if (this.currentProposal) {
                    this.getProposalLog();
                    this.setCurrentProposalData();
                    this.form.disable();
                } else {
                    this.form.patchValue(this.dropdownData.default_value);

                }
                this.getAddonsTotalPrice();
                console.log("wew", this.structureTypeDropdown)

            }
        );
    }

    setCurrentProposalData() {
        if (this.currentProposal == null) {
            return;
        }
        this.imageView = JSON.parse(this.currentProposal.images);

        console.log("===--======================", this.imageView);
        if (this.imageView[0] == null) {
            // this.imageView[0]['url'] = '';
            this.imageView = [];
            console.log("===--======================", this.imageView);
        }


        // else {
        //     this.data['path'] = { "path": this.currentProposal.images[0].url }
        // }
        this.form.patchValue({
            business_margin: this.currentProposal.business_margin,
            i_and_c: this.currentProposal.i_and_c,
            regulatory: this.currentProposal.regulatory,
            is_net_metering: this.currentProposal.is_net_metering,
            net_metering_cost: this.currentProposal.net_metering_cost,
            monthly_bill: this.currentProposal.monthly_bill,
            state: this.currentProposal.state,
            consumer_type: this.currentProposal.customer_type,
            plan_id: this.currentProposal.plan.uuid,
            amc_tenure_days: this.currentProposal.amc_tenure_days,
            cable_tray: this.currentProposal.cable_tray,
            cable_cost: this.currentProposal.cable_cost,
            earthing: this.currentProposal.earthing,
            earthing_cost: this.currentProposal.earthing_cost,
            is_homescape_app: this.currentProposal.is_homescape_app,
            homescape_app_cost: this.currentProposal.homescape_app_cost,
            is_consumption_monitoring: this.currentProposal.is_consumption_monitoring,
            consumption_monitoring_cost: this.currentProposal.consumption_monitoring_cost,
            discount: 0,//this.currentProposal.discount,
            national_subsidy: this.currentProposal.national_subsidy,
            amc_cost: this.currentProposal.amc_cost,
            status: this.currentProposal.status,
            phase_type: this.currentProposal.phase_type,
            is_subsidy: this.currentProposal.lead_property.subsidy_applicable.toLowerCase() == 'yes' ? true : false,
            images: this.currentProposal.images ? this.imageView : []
        });
    }

    getAddonsTotalPrice() {
        this.addonsTotalPrice = 0;
        let formValue = this.form.value;
        this.addonsTotalPrice += parseInt(formValue.net_metering_cost ? formValue.net_metering_cost : 0);
        this.addonsTotalPrice += parseInt(formValue.homescape_app_cost ? formValue.homescape_app_cost : 0);
        this.addonsTotalPrice += parseInt(formValue.cable_cost ? formValue.cable_cost : 0);
        this.addonsTotalPrice += parseInt(formValue.earthing_cost ? formValue.earthing_cost : 0);
        this.addonsTotalPrice += parseInt(formValue.ie_meter_cost ? formValue.ie_meter_cost : 0);
        this.addonsTotalPrice += parseInt(formValue.consumption_monitoring_cost ? formValue.consumption_monitoring_cost : 0);
    }

    getServicesTotalPrice() {
        this.servicesTotalPrice = this.total_selling_price;
        this.servicesTotalPrice += parseFloat(this.form.get('business_margin').value ? this.form.get('business_margin').value : 0)
        this.servicesTotalPrice += parseFloat(this.form.get('i_and_c').value ? this.form.get('i_and_c').value : 0)
        this.servicesTotalPrice += parseFloat(this.form.get('regulatory').value ? this.form.get('regulatory').value : 0);
        this.servicesTotalPrice += parseFloat(this.form.get('amc_cost').value ? this.form.get('amc_cost').value : 0)
    }

    // getTotalCostAfterDiscount() {
    //     let totalCost = (this.addonsTotalPrice + this.servicesTotalPrice)
    //         - parseFloat(this.form.get('discount').value ? this.form.get('discount').value - 0);
    //     return (totalCost + totalCost * 0.138).toFixed(1);
    // }

    getTotalCostAfterDiscount() {
        this.form.get('discount').setValue(0); // Ensure discount value is always 0
        let totalCost = this.addonsTotalPrice + this.servicesTotalPrice - 0; // Discount is always 0
        return (totalCost + totalCost * 0.138).toFixed(1);
    }
    

    getTotalCost() {
        let total = this.addonsTotalPrice + this.servicesTotalPrice
        return (total + total * 0.138).toFixed(1);
    }

    onViewEdit() {
        this.form.enable();
        this.showEditable = false;
    }

    onAddingFile() {
        console.log(this.form.value);
        let params = JSON.parse(JSON.stringify(this.form.value));
        let imageList = [];
        params.images.forEach(image => {
            if (typeof image == 'string') {
                imageList.push({
                    url: image,
                });
            }
        });
        let latestImage = [];
        latestImage.push(imageList[imageList.length - 1]);
        console.log(latestImage);
        params.images = JSON.stringify(imageList);
    }
    submit() {
        console.log(this.form.value);

        // if (this.validForm()) {
            let params = JSON.parse(JSON.stringify(this.form.value));
            if (params.images != null && params.images.length > 1) {
                let imageList = [];
                params.images.forEach(image => {
                    if (typeof image == 'string') {
                        imageList.push({
                            url: image,
                        });
                    }
                });
                let latestImage = [];
                latestImage.push(imageList[imageList.length - 1]);
                console.log(latestImage);
                params.images = JSON.stringify(latestImage);
            }
            else if (params.images != null && params.images.length == 1) {
                if (typeof params.images[0] == 'object') {
                    params.images = JSON.stringify(params.images);
                    console.log("params.images", params.images);
                }
                else if (typeof params.images[0] == 'string') {
                    let image = params.images[0];
                    let imageList = [];
                    imageList.push({
                        url: image
                    });
                    params.images = JSON.stringify(imageList);
                    console.log("params.images", params.images);
                }
               console.log("params.images", params.images);
            }
            else {
                this.toast.error('Please upload Logo');
            }

            if (this.currentProposal != null) {
                this.proposalService.updateProposal(params, this.currentProposal.uuid).subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.toast.success("Proposal updated successfully");
                            this.currentProposal = response.payload.data;
                            this.setCurrentProposalData();
                            this.disableForm();
                            this.getProposalLog();
                            this.status.emit("reload");
                            this.dialogRef.close();
                        } else {
                            this.toast.error(response.message);
                        }
                    },
                    (error: any) => {
                        this.toast.error("Please fill all the mandatory Fields!!!");
                        console.log("error->", error.error);
                    }
                )
            } else {
                this.proposalService.createProposal(params, this.data.uuid).subscribe(
                    (response: any) => {
                        if (response.status) {
                            this.toast.success("Proposal created successfully");
                            this.currentProposal = response.payload.data;
                            this.setCurrentProposalData();
                            this.disableForm();
                            this.getProposalLog();
                            this.status.emit("reload");
                            this.dialogRef.close();
                        } else {
                            this.toast.error(response.message);
                        }
                    },
                    (error: any) => {
                        this.toast.error("Please fill all the mandatory Fields!!!")
                        console.log("error->", error.error);
                    }
                )
            }
        // } else {
        //     this.toast.error("PLEASE FILL MANDATORY FIELDS");
        //     console.log(this.form);
        // }
    }


    updateUploadStatus(event) {
        console.log("uploading======== event);=====>", event);
        this.uploadingFiles = event;
        console.log("uploading=======event); files======>", this.uploadingFiles);
        this.toast.warning("Only latest image uploaded by you will be considered!!")
    }

    validForm() {
        Object.keys(this.form.controls).forEach((key) => {
            // console.log("controls ", this.form.get(key));
            if (this.form.get(key).errors && this.form.get(key).errors['required']) {
                return false;
            }
        });
        return true;
    }

    disableForm() {
        this.showEditable = true;
        this.form.disable();
    }
    cancel() {
        if (this.currentProposal != null) {
            this.setCurrentProposalData();
            this.disableForm()
        } else {
            this.dialogRef.close();
        }

    }

    onTabChange(event) {
        this.selectedTabIndex = event.index;
    }

    getProposalLog() {
        this.proposalService.getProposalLogs(this.currentProposal.uuid).subscribe(
            (response: any) => {
                console.log(response);
                this.logs = response.payload.data;
            }
        );

    }

    subsidy() {
        console.log('is subsidy', this.is_subsidy);
        this.is_subsidy = !this.is_subsidy;
        console.log('is subsidy', this.is_subsidy);
    }

}