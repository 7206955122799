import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/common/service/account/account.service';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicFileUploadComponent } from '../../dynamic-form/dynamic-file-upload/dynamic-file-upload.component';
import { FileUploadLogicService } from '../../dynamic-form/file-upload-logic.service';

@Component({
  selector: 'app-initiate-refund',
  templateUrl: './initiate-refund.component.html',
  styleUrls: ['./initiate-refund.component.scss']
})
export class InitiateRefundComponent implements OnInit {
  leadPropertyUUID: any;
  identifier :any;
  form: FormGroup;
  responseText:string;
  uuid:any;
  amountRefund : number;
  accountHolderName :any;
  accountNumber : number
  ifscCode : any;
  refundAmount:any;
  approvalOfBusinessImg :any;
  businessHeadUrl:any;
  cancelledChequeUrl:any;
  checkImgUrl:boolean =true;

  

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toast :MessageToastService,
    public fileUploadLogicService: FileUploadLogicService,
    private dynamicFileUpload :DynamicFileUploadComponent,  
    public dialogRef: MatDialogRef<InitiateRefundComponent>
  ) { 
    this.form = this.fb.group({
      // Add form controls for your input fields
      refundAmount: ['', Validators.required],
      //refundAmount: ['', [Validators.required, this.validateMaxDigits]],
      accountHolderName: ['', Validators.required],
      ifscCode:['', Validators.required],
     accountNumber: ['', Validators.required],
    });
  }
  

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.leadPropertyUUID = params['id'];
      this.getPropertyDataIn({ property_id: this.leadPropertyUUID });
      this.uuid = this.leadPropertyUUID;
    });

    this.fileUploadLogicService.approvalOfBusinessImgUrl ='';
    this.fileUploadLogicService.cancelledChequeImgUrl ='';
  
    
  }
  onSubmit() {
      this.amountRefund = this.form.value.refundAmount;
      this.accountHolderName = this.form.value.accountHolderName;
      this.accountNumber = this.form.value.accountNumber;
      this.ifscCode = this.form.value.ifscCode;
      this.businessHeadUrl ="";
      this.cancelledChequeUrl ="";
      this.refund();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
  getPropertyDataIn(data) {
    this.accountService.getPropertyDetails(data).subscribe((res) => {
      if (res && res['status']) {
        console.log('property data found : ', res['payload']);
        this.identifier =res['payload'].identifier;      
       // alert(res['payload'].cluster.uuid);
      }
    });
  }



  refund() {
    this.businessHeadUrl =this.fileUploadLogicService.approvalOfBusinessImgUrl;
    this.cancelledChequeUrl =this.fileUploadLogicService.cancelledChequeImgUrl;
    this.checkImgUrl = (this.businessHeadUrl === '' || this.cancelledChequeUrl === '')  ? false : true;

    if (this.form.valid && this.checkImgUrl) {
      this.businessHeadUrl =this.fileUploadLogicService.approvalOfBusinessImgUrl;
      this.cancelledChequeUrl =this.fileUploadLogicService.cancelledChequeImgUrl;
      this.dynamicFileUpload.refundPayment(this.uuid,this.amountRefund,this.accountHolderName,this.accountNumber,this.ifscCode,this.businessHeadUrl,this.cancelledChequeUrl).subscribe(
      (response: any) => {
        if (response.status === true) {
          this.responseText = response;
          console.log(response);
          this.closeDialog();
          this.toast.success(response.message)
          window.location.reload();
        }else{
          this.toast.error(response.message);
        }
      },
      (error) => {
        this.toast.error(error.statusText);    
      }
    );
  }else{
    this.toast.error("PLEASE FILL MANDATORY FIELDS");
  }
 
  }
}
