import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth-service/auth.service';
import { MenuToggleService } from '../../service/menu-toggle/menu-toggle.service';
import { TicketService } from '../../service/ticket/ticket.service';
import { UserService } from '../../service/user-service/user.service';
import { CreateSrModalComponent } from '../create-sr-modal/create-sr-modal/create-sr-modal.component';
import { CreateTicketModalComponent } from '../create-ticket-modal/create-ticket-modal.component';
import { TicketLogComponent } from '../ticket-log/ticket-log.component';

@Component({
  selector: 'app-service-tickets',
  templateUrl: './service-tickets.component.html',
  styleUrls: ['./service-tickets.component.scss']
})
export class ServiceTicketsComponent implements OnInit {

  ticketList = [];
  ticketCount = 1;
  ticketOffset = 0;
  ticketLimit = 10;

  leadPropertyId = null;
  stageFilter = 'all';
  statusFilter = 'all';
  canEditTicket = false;
  stageFilterOptions = [
    {label: 'All Ticket Status', value: 'all'},
    {label: 'Open', value: 'open'},
    {label: 'Closed', value: 'closed'}
  ];
  statusFilterOptions = [
    {label: 'All Statuses', value: 'all'},
    {label: 'Red', value: 'red'},
    {label: 'Yellow', value: 'yellow'},
    {label: 'Green', value: 'green'},
  ];
  offset = 0;
  currentState = {page: 1};
  constructor(
    private menuToggle: MenuToggleService,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private authService: AuthService,
    private titleCasePipe: TitleCasePipe,
  ) { }

  ngOnInit() {
    
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });

    let user = JSON.parse(this.authService.getUser());
    this.canEditTicket = this.userService.ticketEditAccess(user);

    this.route.queryParams.subscribe(params => {
      this.leadPropertyId = params['id'];
      this.currentState['uuid'] = this.leadPropertyId;
      this.getTicketList();
    });
  }
  
  getTicketList() {
    this.ticketService.getTicketList(this.currentState).subscribe((val: any) => {
      if(val && val.status) {
        this.ticketList = val.payload.data;
        this.ticketCount = val.payload.count;
        this.ticketOffset = val.payload.current_page - 1;
        this.ticketLimit = val.payload.data_per_page;

        console.log("ticket list found  ", val.payload.data);
      }
    });
  }

  formatType(type)  { 
    return this.titleCasePipe.transform(type.split('_').join(' '));
  }

  createSR() {
    let dialogRef = this.dialog.open(CreateTicketModalComponent, {
      data: {
        property_uuid: this.leadPropertyId,
        label: 'Documents'
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if( data == 'success') {
        console.log("refreshing..");
        this.getTicketList();
      }
    });
  }

  onActivate(event) {
    if(event.type == "click" && event.cell != '0') {
      console.log('row clicked: ', event);
      event.row['property_uuid'] = this.leadPropertyId;
      let dialogRef = this.dialog.open(TicketLogComponent, {
        data: event.row,
      });
      
      dialogRef.componentInstance.status.subscribe(data => {
        if( data == 'success') {
          console.log("refreshing..");
          this.getTicketList();
        }
      });
    }
  }

  onPage(event) {
    console.log("page changed: ", event);
    this.currentState['page'] = event.offset + 1;
    this.offset = event.offset;
    this.getTicketList();
  }

  onFilterChange(event, type) {
    console.log("filter changed: ", event);
    if(event == 'all') { delete this.currentState[type]; }
    else { this.currentState[type] = event; }
    this.getTicketList();
  }

}
