import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  constructor(private http: HttpClient) {}

  setSession(obj) {
    localStorage.setItem(
      "user",
      JSON.stringify({
        user_type: obj.user_type,
        user_roles: obj.user_roles,
        user_details: obj.user_details,
        cluster_details: obj.cluster_details,
        default_access: obj.default_access
      })
    );
    localStorage.setItem("token", obj.token);
  }

  setAuthPayload(payload) {
    localStorage.setItem(
      "auth_payload",
      JSON.stringify(payload)
    );
  }

  getAuthPayload() {
    return localStorage.getItem("auth_payload");
  }

  getAccessToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    return localStorage.getItem('user');
  }
}
