import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadFilesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() extras: any;

  @Output() uploadStatus = new EventEmitter<boolean>();

  files: File[] = [];
  storagePath: string;
  canManage = false;
  addManage = false;

  constructor() { }

  ngOnInit() {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    console.log(userDetails);
    this.storagePath = this.extras.path;
    this.addManage = this.extras.can_edit != null ? this.extras.can_edit : true;
    console.log('printing storage path ', this.extras);
    console.log('images', this.form.get('images').value);
    if (this.form.get('images').value.length == 1) {
      this.canManage = false;
    }
    else{
      this.canManage = true;
    }
  }

  ngOnChanges() {
    let parts = [
      new Blob([''], { type: 'text/plain' }),
      '',
      new Uint16Array([33])
    ];

    console.log('############### inside upload files component');
    console.log(this.form.value);
    console.log(this.form.get('images').value);
    console.log(this.form.get('images').value.length);

    if (this.form.get('images').value.length > 0) {
      console.log('**************** adding dummy files');
      this.form.get('images').value.forEach((img, index) => {
        let file = new File(parts, 'random_' + index.toString() + '.png');
        this.files.push(file);
      });
      console.log('length after addition ' + this.files.length);
      this.uploadStatus.emit(false);
    }
  }

  uploadFiles(files: FileList) {
    this.canManage = true;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
    this.uploadStatus.emit(true);
  }

  updateFileList(event) {
    let curValue = this.form.get('images').value;
    if (curValue == '') {
      curValue = [];
      curValue.push(event);
    }
    else {
      curValue.push(event);
    }
    this.canManage = true;
    this.form.get('images').setValue(curValue);
    console.log('printing updated file list****');
    console.log(this.form.get('images').value);
    if (curValue.length == this.files.length) {
      this.uploadStatus.emit(false);
    }
  }

  deleteFile(event, file) {
    let curValue = this.form.get('images').value;
    let index = curValue.indexOf(event, 0);
    if (index > -1) {
      curValue.splice(index, 1);
    }
    index = this.files.indexOf(file, 0);
    if (index > -1) {
      this.files.splice(index, 1);
    }
    console.log('printing form value and file list');
    console.log(this.form.get('images').value);
    console.log(this.files);
  }

}
