import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, Form, FormGroup } from '@angular/forms';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { Subscription } from "rxjs";
import { DatePipe } from '@angular/common';
import { AuthService } from "src/app/common/service/auth-service/auth.service";
declare var $: any;

@Component({
  selector: 'app-manage-amc-plan',
  templateUrl: './manage-amc-plan.component.html',
  styleUrls: ['./manage-amc-plan.component.scss']
})
export class ManageAmcPlanComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  amcDetailsForm: FormGroup;
  boolean_dropdown = [{ key: "Yes", value: true }, { key: "No", value: false }];
  amc_pack_dropdown = [];
  selectedPlan = {}
  // amc_pack_dropdown = [ { key: "AMC Gold", value: "amc_gold"}, { key: "AMC Silver", value: "amc_silver"}, { key: "AMC Platinum", value: "amc_platinum"}];
  amcPlanList: any
  uploadingFiles: boolean = false;
  update = false;
  selected_pack = '';
  selected_tab = 0;
  property_amc_uuid = '';
  isAdmin = false;
  canEditSR = false;
  isDisabled = false;

  statusOpts = [{ label: 'Processing', value: 'processing' }, { label: 'Active', value: 'active' }, { label: 'Expired', value: 'expired' }, { label: 'Cancelled', value: 'cancelled' }];
  warrantySupportOpts = [{ key: 'Partial', value: 'partial' }, { key: 'Full', value: 'full' }];
  structTypeOpts = [{ key: 'Select a Structure Type', value: "" }, { key: 'Atrium', value: 'atrium' }, { key: 'Arial', value: 'arial' }, { key: 'Terra', value: 'terra' }];
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ManageAmcPlanComponent>,
    private toast: MessageToastService,
    private amcService: AMCService,
    private datepipe: DatePipe,
    private authService: AuthService
  ) { }

  ngOnInit() {
    console.log("************** initializing manage amc");
    console.log(this.data);
    this.amcDetailsForm = this.fb.group({
      plan_uuid: ["", Validators.required],
      status: ["processing", Validators.required],
      cost_basic: [0, Validators.required],
      cost_gst: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      no_of_breakdown_visit: [0, Validators.required],
      no_of_regular_visit: [0, Validators.required],
      regular_visit_post_limit_discount: [0, [Validators.required, Validators.max(100), Validators.min(0)]],
      cleaning_freq_yearly: [0, Validators.required],
      consumable_limit: [0, Validators.required],
      consumable_post_limit_discount: [0, [Validators.required, Validators.max(100), Validators.min(0)]],
      priority_support: [false, Validators.required],
      warranty_support_coverage: ["partial", Validators.required],
      sim_recharge: [false, Validators.required],
      wait_time_for_item_replacement_days: [0, Validators.required],
      canopy_painting_freq_yearly: [0, Validators.required],
      aluminium_paint_freq_yearly: [0, Validators.required],
      structure_varnish_freq_yearly: [0, Validators.required],
      is_subsidised: [false, Validators.required],
      validity_start_date: new Date(),
      validity_end_date: new Date(),
      plant_warranty: [false, Validators.required],
      system_checks: [false, Validators.required],
      remote_analysis: [false, Validators.required],
      insurance: [false, Validators.required],
      cleaning: [false, Validators.required],
      is_active: [true, Validators.required],
      import_export_monitoring: [false, Validators.required],
      property_amc_uuid: [""],
      images: [[]]
    });
    console.log("dom modal data", this.data.amc_details);
    this.canEditSR = this.data.can_edit;
    console.log("can edit amc--->", this.canEditSR);
      this.isDisabled = this.canEditSR ? false :true;
    // getting all the dropdown options of amc plan
    this.getFormDetails();

    this.getUserRole();

    this.subscription.add(this.amcDetailsForm.get('plan_uuid').valueChanges.subscribe(val => {
      console.log('/////// form field changed');
      console.log(val);
      if (val != null && val != '') {
        this.amc_pack_dropdown.forEach((option: any) => {
          if (option.value == val) {
            this.selected_pack = option.key;
            console.log("selected pack", this.selected_pack);
          }
        });
      }
      console.log('------- field value set');
      // updating the contents of the form only when we are creating it
      if (!this.update) { this.onPlanChange({ value: val }); }
      else {
        console.log('not triggering onchange');
      }
    }));
  }

  getUserRole() {
    this.isAdmin = JSON.parse(this.authService.getUser())["user_roles"].indexOf('amplus_admin') >= 0;
  }

  getFormDetails() {
    this.amcService.getAMCPlans({ property_uuid: this.data.property_uuid }).subscribe(
      (response: any) => {
        console.log('***** response found out in amc plans');
        console.log(response);
        if (response && response['status']) {
          this.amcPlanList = response.payload.data;
          this.amcPlanList.forEach(plan => {
            this.amc_pack_dropdown.push({
              key: plan.name,
              value: plan.uuid
            });
          });
        }
        else {
          this.toast.error(response.message);
        }
        // if this is edit form, patch value
        if (this.data.amc_details.plan) {
          this.update = true;
          console.log("setting update");
          if (this.data.amc_details.images != null && this.data.amc_details.images != "") {
            let imagesParams = [];
            let images = JSON.parse(this.data.amc_details.images);
            console.log("final images array found out");
            console.log(images);
            images.forEach(image => {
              imagesParams.push(image['url']);
            });
            this.data.amc_details.images = imagesParams;
            console.log("imagesParams  ", imagesParams);
          }
          this.property_amc_uuid = this.data.amc_details.uuid;
          this.amcPlanList = [this.data.amc_details];
          this._patchValue(this.data.amc_details);
        }
        // if this is create form only patch the pack
        else {
          this.amcDetailsForm.get('plan_uuid').setValue(this.amc_pack_dropdown[0].value);
          this.amcDetailsForm.get('status').setValue('processing');
        }
      }
    );


  }

  ngAfterViewInit() {
    console.log("building view");
  }

  onPlanChange(data) {
    console.log('onchange triggered ');
    console.log(data.value);
    console.log('current value of the plan ' + this.amcDetailsForm.get('plan_uuid').value);

    console.log("######## plan changed");
    this.amcPlanList.forEach(plan => {
      if (plan.uuid == data.value) {
        this.selectedPlan = plan
        plan['plan'] = { 'uuid': plan.uuid };
        plan['images'] = [];
        this._patchValue(plan);
        this.amcDetailsForm.get("status").setValue("processing");
      }
    });

  }

  _patchValue(plan) {
    console.log("_patchValue", plan);

    this.amcDetailsForm = this.fb.group({
      plan_uuid: [plan.plan.uuid, Validators.required],
      cost_basic: [plan.cost_basic, Validators.required],
      status: [plan.status, Validators.required],
      cost_gst: [plan.cost_gst, [Validators.required, Validators.min(0), Validators.max(100)]],
      no_of_breakdown_visit: [plan.no_of_breakdown_visit, Validators.required],
      no_of_regular_visit: [plan.no_of_regular_visit, Validators.required],
      regular_visit_post_limit_discount: [plan.regular_visit_post_limit_discount, Validators.required],
      cleaning_freq_yearly: [plan.cleaning_freq_yearly, Validators.required],
      consumable_limit: [plan.consumable_limit, Validators.required],
      consumable_post_limit_discount: [plan.consumable_post_limit_discount, Validators.required],
      priority_support: [plan.priority_support, Validators.required],
      warranty_support_coverage: [plan.warranty_support_coverage, Validators.required],
      sim_recharge: [plan.sim_recharge, Validators.required],
      wait_time_for_item_replacement_days: [plan.wait_time_for_item_replacement_days, Validators.required],
      canopy_painting_freq_yearly: [plan.canopy_painting_freq_yearly, Validators.required],
      aluminium_paint_freq_yearly: [plan.aluminium_paint_freq_yearly, Validators.required],
      structure_varnish_freq_yearly: [plan.structure_varnish_freq_yearly, Validators.required],
      is_subsidised: [plan.is_subsidised, Validators.required],
      validity_end_date: [plan.validity_end_date ? plan.validity_end_date : ''],
      validity_start_date: [plan.validity_start_date ? plan.validity_start_date : ''],
      plant_warranty: [plan.plant_warranty, Validators.required],
      system_checks: [plan.system_checks, Validators.required],
      spare_item: [plan.spare_item, Validators.required],
      remote_analysis: [plan.remote_analysis, Validators.required],
      insurance: [plan.insurance, Validators.required],
      cleaning: [plan.cleaning, Validators.required],
      is_active: [plan.is_active == null ? true : plan.is_active, Validators.required],
      import_export_monitoring: [plan.import_export_monitoring, Validators.required],
      images: [plan.images],
      property_amc_uuid: plan.uuid
    });

    this.amc_pack_dropdown.forEach((option: any) => {
      if (option.value == this.amcDetailsForm.get('plan_uuid').value) {
        this.selected_pack = option.key;
        console.log("selected pack 2", this.selected_pack);
      }
    });

    this.subscription.add(this.amcDetailsForm.get('plan_uuid').valueChanges.subscribe(val => {
      console.log('/////// form field changed');
      console.log(val);
      if (val != null && val != '') {
        this.amc_pack_dropdown.forEach((option: any) => {
          if (option.value == val) {
            this.selected_pack = option.key;
            console.log("selected pack 3", this.selected_pack);
          }
        });
      }
      console.log('------- field value set');
      // updating the contents of the form only when we are creating it
      if (!this.update) { this.onPlanChange({ value: val }); }
      else {
        console.log('not triggering onchange');
      }
    }));

    console.log("final form value");
    console.log(this.amcDetailsForm);
  }

  onSubmit() {
    console.log("this.amcDetailsForm.value", this.amcDetailsForm.value);
    console.log("this.amcDetailsForm.value", this.amcDetailsForm);

    if (this.amcDetailsForm.valid) {
      let formData = this.amcDetailsForm.value;
      console.log(formData);
      let startDate = this.datepipe.transform(formData.validity_start_date, 'yyyy/MM/dd');
      let endDate = this.datepipe.transform(formData.validity_end_date, 'yyyy/MM/dd');
      console.log("startDate ", startDate, endDate, " endDate < startDate -> ", (endDate < startDate));

      if (endDate < startDate) {
        this.toast.error("Tenure to date must be greater than from date");
        return;
      }

      let params = JSON.parse(JSON.stringify(this.amcDetailsForm.value));
      console.log("params details ")
      params['validity_start_date'] = params['validity_start_date'] && params['validity_start_date'] != '' ? Math.floor((new Date(params['validity_start_date'])).getTime() / 1000) : 0;
      params['validity_end_date'] = params['validity_end_date'] && params['validity_end_date'] != '' ? Math.floor((new Date(params['validity_end_date'])).getTime() / 1000) : 0;
      // params.images = JSON.parse(params.images);
      params.property_uuid = this.data.property_uuid;
      // params.property_amc_uuid = params.uuid;
      let imageList = [];
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      let todayStr = yyyy + '-' + mm + '-' + dd;
      console.log('-------- current value of params');
      console.log(params);
      params.images.forEach(image => {
        imageList.push({
          url: image,
          type: "default",
          fileType: image.substr(image.lastIndexOf(".") + 1),
          date: todayStr
        });
      });
      params.images = JSON.stringify(imageList);

      Object.keys(params).forEach(key => {
        if (params[key] == "false") params[key] = false;
        else if (params[key] == "true") params[key] = true;
      });

      console.log('submitting form..');
      console.log(params);
      if (this.update) {
        this.amcService.updateAMCDetails(params).subscribe(
          (response: any) => {
            if (response.status) {
              this.toast.success("AMC UPDATE SUCCESSFULLY");
              this.status.emit('success');
              this.amcService.refreshNotifier.next("all");
              this.cancel();
            } else {
              this.toast.error(response.message);
            }
          },
          (err: any) => {
            console.log("erroe----", err);
            if(err.error.payload.consumable_post_limit_discount){
              this.toast.error("limit discount: " + err.error.payload.consumable_post_limit_discount[0]);
            }
            else if( err.error.payload.regular_visit_post_limit_discount){
              this.toast.error("limit discount: " + err.error.payload.regular_visit_post_limit_discount[0]);
            }
            else{
              this.toast.error("OPERATION FAILED");
            }
          }
        );
      }
      else {
        this.amcService.createAMC(params).subscribe(
          (response: any) => {
            if (response.status) {
              this.toast.success("AMC DETAILS SUBMITTED SUCCESSFULLY");
              this.status.emit('success');
              this.cancel();
            } else {
              this.toast.error(response.message);
            }

          },
          (error: any) => {
            this.toast.error("OPERATION FAILED");
          }
        );
      }
    } else {
      console.log(this.amcDetailsForm);
      this.toast.error("INVALID FORM");
    }

  }

  onTabChange(event) {
    console.log('//////////// modal tab changed');
    console.log(event);
    if (event.index == 1) {
      this.fetchAMCLogs();
    }
  }

  fetchAMCLogs() {
    this.amcService.getAMCLogs({ property_amc_uuid: this })
  }

  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
