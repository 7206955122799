
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/common/service/account/account.service';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUploadLogicService } from '../../dynamic-form/file-upload-logic.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-initiate-transfer',
  templateUrl: './initiate-transfer.component.html',
  styleUrls: ['./initiate-transfer.component.scss']
})
export class InitiateTransferComponent implements OnInit {
  getDropDown="v1/finance/payment/transfer?cluster_uuid="
  paymentTransfer="v1/finance/payment/transfer";
  commonUpload = "v1/common/upload";
  leadPropertyUUID: any;
  identifier :any;
  form: FormGroup;
  responseText:string;
  businessHeadUrl :any;
  filteredOptions: any[] = [];
  searchText = '';
  searchControl = new FormControl();
  displayText :any;
  transferTo :any;
  clusterUuid :any;
  options: any[];
  uuid: any;
  selectedOption: any = '';
  remarks :any;
  checkImgUrl:boolean =true;
  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toast :MessageToastService,
    public dialogRef: MatDialogRef<InitiateTransferComponent>,
    private http: HttpClient,
    public fileUploadLogicService: FileUploadLogicService
 

    ) { 
    this.form = this.fb.group({
      refundAmount: [null, Validators.required], // Create a FormControl for refundAmount
      searchInput: this.searchControl
      
    });  
  }

  ngOnInit() {
    this.fileUploadLogicService.businessHeadUrl="";
    
    this.route.queryParams.subscribe((params) => {
      this.leadPropertyUUID = params['id'];
      this.getPropertyDataIn({ property_id: this.leadPropertyUUID });
      this.uuid = this.leadPropertyUUID;
    });

 
    // Subscribe to businessHeadUrl changes
   // Access the businessHeadUrl property directly
  
  }


  onSearchInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value.toLowerCase();
  
    console.log('Search value changed:', searchText);
    this.filterOptions();
  }

  filterOptions() {
    const searchText = this.searchControl.value ? this.searchControl.value.toLowerCase() : '';
  
    this.filteredOptions = this.options.filter((option) =>
      this.optionContainsText(option, searchText)
    );
  }
  
  optionContainsText(option: any, searchText: string): boolean {
    const identifier = (option.identifier || '').toLowerCase();
    const firstName = (option.lead.first_name || '').toLowerCase();
    const lastName = (option.lead.last_name || '').toLowerCase();
  
    const optionText = `${identifier} ${firstName} ${lastName}`;
    return optionText.includes(searchText);
  }

  

  updateSearchControl(uiid) {
     this.selectedOption = uiid//this.form.get('selectedOption').value;
    
    const selectedOptionObject = this.options.find(option => option.uuid === this.selectedOption);
  
    if (selectedOptionObject) {
       this.displayText = `${selectedOptionObject.identifier} - ${selectedOptionObject.lead.first_name} ${selectedOptionObject.lead.last_name}`;
      this.searchControl.setValue(this.displayText);
      this.transferTo =`${selectedOptionObject.identifier}`;
    }
  }
  
  

  makeHttpRequest(clusterUuid) {
    const url = environment.serverURl + this.getDropDown+clusterUuid;
    this.http.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': localStorage.getItem("token"),
      }
    }).subscribe((data: any) => {      
      console.log(data);
      this.options = data.payload.data;
      this.filteredOptions= data.payload.data;
      this.searchControl.valueChanges.subscribe(() => {
        this.filterOptions();
      });

    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
  getPropertyDataIn(data) {
    this.accountService.getPropertyDetails(data).subscribe((res) => {
      if (res && res['status']) {
        console.log('property data found : ', res['payload']);
        this.identifier =res['payload'].identifier;
        this.clusterUuid=res['payload'].cluster.uuid;

        this.makeHttpRequest(this.clusterUuid);
      }
    });
  }

  transfer(){
    this.businessHeadUrl = this.fileUploadLogicService.businessHeadUrl;
   
   this.checkImgUrl = (this.businessHeadUrl === "" || this.transferTo === undefined) ? false : true;




    if (this.form.valid && this.checkImgUrl) { 
      const url = environment.serverURl + this.paymentTransfer;
        const data = {
        lead_property_to_uuid: this.selectedOption,//this.form.get('selectedOption').value,
        lead_property_from_uuid: this.uuid,
        business_approval_url: this.businessHeadUrl,
        remarks: this.identifier + " to " + this.transferTo,
        payment_amount: this.form.get('refundAmount').value,
        approval_status: "pending_finance"
      };
  
      const headers = new HttpHeaders({
        'Accept': 'application/json',      
        'Content-Type': 'application/json',
      });
  
      this.http.post(url, data, { headers, responseType: 'text' }).subscribe(
        (response) => {
            const jsonResponse = JSON.parse(response);
            if (jsonResponse['status']) {
                this.toast.success(jsonResponse['message']);
                window.location.reload();
            } else {
                this.toast.error(jsonResponse['message']);
            }
        },
        (error) => {
            //console.log('Error:', error);
            // Handle error (e.g., show a generic error message)
           // this.toast.error(JSON.stringify(error) +'----- An error occurred during the request.');
        }
    )     
    }else{
       this.toast.error("PLEASE FILL MANDATORY FIELDS");
    }
    }

    fileURLInitiateTransfer(fileToUpload){

      if (fileToUpload) {
        const formData = new FormData();
        formData.append('file', fileToUpload);
        //environment.serverURl + this.commonUpload = "v1/common/upload";
        this.http.post(environment.serverURl + this.commonUpload, formData).subscribe(
          response => {
            // Handle the response from the API          
            console.log('File uploaded successfully', response);
            let data = response['payload'];
                this.businessHeadUrl=data.url;
          },
          error => {
            console.error('File upload failed', error);
          }
        );  
      }
    } 
}
