import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-referral-detail',
  templateUrl: './referral-detail.component.html',
  styleUrls: ['./referral-detail.component.scss']
})
export class ReferralDetailComponent implements OnInit {

  stageList = [
    {
      step: 'Signed Up Successfully',
      reward: 100
    },
    {
      step: 'Get HomeScaped',
      reward: 100
    },
    {
      step: 'Plant Activated',
      reward: 100
    },
  ];

  log: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReferralDetailComponent>,
  ) { }

  ngOnInit() {
    console.log("data found in modal: ", this.data);
    this.log = this.data.log;
  }

}
