import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ClusterService } from "../cluster/cluster.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LeadService {
  baseURL: any;
  noPropertyURL = "v1/property/customer/unassigned";
  leadListURL = "v1/property/list";
  noClusterURL = "v1/property/unassigned/cluster";
  propertyDetails = "/v1/property/info";
  propertyStage = "v1/property/stage";
  updateProperty = "v1/property/new";
  updatePropertyData = "v1/property/customer/data";
  defaultStage = "v1/property/stage/default";
  firstStage = "v1/property/info/astral";
  updateStageUrl = "v1/property/info";
  mapJsonUrl = "v1/property/map";
  filterData = "v1/property/filter/data";
  noClusterFilterData = "v1/property/filter/data/all";
  disableLeadUrl = "v1/property/info/astral";
  updateStatus = "v1/property/lmsstatus";
  updateExecutives = "/v1/property/executive";
  updateDesignerUrl = "/v1/property/designer";
  updateRecommendationUrl = "v1/recommendation/solar/property";
  recommendationListUrl = "v1/recommendation/solar/list";
  paymentLogsUrl = "v1/property/payment";
  downloadEmployeeUrl = "v1/report/referral/employee";
  updateDiscomUserUrl = "v1/property/assign/discom";
  preSalesLeadListUrl = "v1/property/pre-sales/list";
  preSalesLeadFilterDataUrl = "v1/property/pre-sales/filter/data";
  automationPropertyListUrl = "v1/property/automation/list";
  propertyListUrl = "api/customer/property/list";
  processingAttemptUrl = "v1/property/processing-attempt";
  propertyTATUrl = "v1/property/stage/deadline";
  solarProductUrl = "v1/property/solar-product";
  solarProductListUrl = "v1/property/solar-product/list";
  disaggregationPropertyListUrl = "v1/property/disaggregation/list";
  aqiPropertyListURL = "v1/property/aqi/list";
  ivrLogsUrl = "v1/property/servetel/log";
  holdStageURL = "v1/property/hold";

  refreshNewPropertyList = new Subject();
  updatePropertyNotifier = new Subject();

  constructor(
    private http: HttpClient,
    private clusterService: ClusterService
  ) {
    this.baseURL = environment.serverURl;
  }

  getLeadRecommendationList(params) {
    return this.http.get(this.baseURL + this.recommendationListUrl, {
      params: params,
    });
  }

  updateNewProperty(data) {
    return this.http.put(this.baseURL + this.updateProperty, data);
  }

  updateNewPropertyData(data) {
    return this.http.put(this.baseURL + this.updatePropertyData, data);
  }

  updateRecommendation(data, params) {
    return this.http.put(this.baseURL + this.updateRecommendationUrl, data, {
      params: params,
    });
  }

  getLeadPropertyList(params) {
    return this.http.get(this.baseURL + this.propertyListUrl, {
      params: params,
    });
  }

  getNoPropertyLeads(data) {
    return this.http.get(this.baseURL + this.noPropertyURL, {
      // headers: new HttpHeaders({
      //   Authorization: localStorage.getItem("token")
      // }),
      params: data,
    });
  }

  getLeadList(data) {
    return this.http.get(this.baseURL + this.leadListURL, {
      params: data,
    });
  }

  getNoClusterProperty(data) {
    return this.http.get(this.baseURL + this.noClusterURL, {
      // headers: new HttpHeaders({
      //   Authorization: localStorage.getItem("token")
      // }),
      params: data,
    });
  }

  getPropertyDetails(uuid) {
    return this.http.get(this.baseURL + this.propertyDetails, {
      params: { property_id: uuid },
    });
  }

  getPropertyStage(prop_uuid, stage_uuid) {
    return this.http.get(this.baseURL + this.propertyStage, {
      params: { stage_id: stage_uuid, property_id: prop_uuid },
    });
  }

  getDefaultStage(uuid) {
    return this.http.get(this.baseURL + this.defaultStage, {
      params: { uuid: uuid },
    });
  }

  addFirstStage(data) {
    return this.http.post(this.baseURL + this.defaultStage, data);
  }

  updateStage(data) {
    return this.http.put(this.baseURL + this.updateStageUrl, data);
  }

  getMapJson(data) {
    return this.http.get(this.baseURL + this.mapJsonUrl, {
      params: { uuid: data },
    });
  }

  getFilterData(data) {
    if (data != "") {
      return this.http.get(this.baseURL + this.filterData, {
        params: { uuid: data },
      });
    } else {
      return this.http.get(this.baseURL + this.filterData);
    }
  }

  getNoClusterFilterData() {
    return this.http.get(this.baseURL + this.noClusterFilterData);
  }

  disableLead(data) {
    return this.http.delete(this.baseURL + this.disableLeadUrl, {
      params: data,
    });
  }

  updateLmsStatus(data) {
    return this.http.put(this.baseURL + this.updateStatus, data);
  }

  updateExecutive(data) {
    return this.http.put(this.baseURL + this.updateExecutives, data);
  }
  updateDesigner(data) {
    return this.http.put(this.baseURL + this.updateDesignerUrl, data);
  }

  updateDiscomUser(data) {
    return this.http.post(this.baseURL + this.updateDiscomUserUrl, data);
  }

  downloadAutomationListByUrl(url, data) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          'automation-properties' + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.xls'
        );
      });
  }

  downloadListByUrl(type, url, data) {
    this.http
      .get(this.baseURL + url, {
        params: data,
        responseType: "arraybuffer",
      })
      .subscribe((res) => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          "application/vnd.mx-excel",
          type +
            "_" +
            timeObject.getDate() +
            "/" +
            timeObject.getMonth() +
            "/" +
            timeObject.getFullYear() +
            "__" +
            timeObject.getHours() +
            "-" +
            timeObject.getMinutes() +
            "-" +
            timeObject.getSeconds() +
            ".xls"
        );
      });
  }

  downloadPropertyList(data) {
    this.http
      .get(this.baseURL + this.noClusterURL, {
        params: data,
        responseType: "arraybuffer",
      })
      .subscribe((res) => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          "application/vnd.mx-excel",
          "property_list_" +
            timeObject.getDate() +
            "/" +
            timeObject.getMonth() +
            "/" +
            timeObject.getFullYear() +
            "__" +
            timeObject.getHours() +
            "-" +
            timeObject.getMinutes() +
            "-" +
            timeObject.getSeconds() +
            ".xls"
        );
      });
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement("a");
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = "_blank";
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }

  getPaymentLogs(params) {
    return this.http.get(environment.serverURl + this.paymentLogsUrl, {
      params: params,
    });
  }

  addPaymentLog(data) {
    return this.http.post(
      environment.serverURl + this.paymentLogsUrl + "?uuid=" + data.uuid,
      data
    );
  }

  getPreSalesLeadList(data) {
    return this.http.get(this.baseURL + this.preSalesLeadListUrl, {
      params: data,
    });
  }
  getPreSalesFilterData() {
    return this.http.get(this.baseURL + this.preSalesLeadFilterDataUrl);
  }

  getAutomationPropertyList(data) {
    return this.http.get(this.baseURL + this.automationPropertyListUrl, {
      params: data,
    });
  }

  getDisaggregationPropertyList(data) {
    return this.http.get(this.baseURL + this.disaggregationPropertyListUrl, {
      params: data,
    });
  }

  getAQIPropertyList(data) {
    return this.http.get(this.baseURL + this.aqiPropertyListURL, {
      params: data,
    });
  }

  updateProcessingAttempt(data) {
    return this.http.put(
      environment.serverURl + this.processingAttemptUrl,
      data
    );
  }

  getPropertyTATdata(params) {
    return this.http.get(environment.serverURl + this.propertyTATUrl, {
      params: params,
    });
  }

  updatePropertyTATdata(data) {
    return this.http.put(environment.serverURl + this.propertyTATUrl, data);
  }

  addSolarProduct(data) {
    return this.http.post(this.baseURL + this.solarProductUrl, data);
  }

  updateSolarProduct(data) {
    return this.http.put(this.baseURL + this.solarProductUrl, data);
  }

  deleteSolarProduct(params) {
    return this.http.delete(this.baseURL + this.solarProductUrl, {
      params: params,
    });
  }

  getSolarProduct(params) {
    return this.http.get(this.baseURL + this.solarProductUrl, {
      params: params
    });
  }
  
  getSolarProductList(params) {
    return this.http.get(this.baseURL + this.solarProductListUrl, {
      params: params,
    });
  }

  getIvrLogs(params) {
    return this.http.get(this.baseURL + this.ivrLogsUrl, {
      params: params,
    });
  }

  updateIvrlogs(data){
  return this.http.put(this.baseURL + this.ivrLogsUrl, data );
  }

  holdStage(data) {
    return this.http.post(this.baseURL + this.holdStageURL, data);
  }
}
