import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FinanceService } from 'src/app/common/service/finance/finance.service';
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";

@Component({
  selector: 'app-add-erp-code',
  templateUrl: './add-erp-code.component.html',
  styleUrls: ['./add-erp-code.component.scss']
})
export class AddERPCodeModalComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;

  constructor(
    private fb: FormBuilder,
    private financeService: FinanceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddERPCodeModalComponent>,
    private toast: MessageToastService) { }


  ngOnInit() {
    console.log("inside add-erp-code");
    console.log(this.data);
    this.form = this.fb.group({
      property_id: [this.data.property_uuid, Validators.required],
      customer_erp_code: ["", Validators.required]
    });
  }

  submit() {
    if (this.form.valid) {
      let data = JSON.parse(JSON.stringify(this.form.value));
      this.financeService.upadteErpCode(data).subscribe((val: any) => {
        if (val && val.status) {
          this.toast.success("ERP CODE UPDATED SUCCESSFULLY");
          this.status.emit("success");
          this.dialogRef.close();
          window.location.reload();
        }
      });

    } else {
      this.toast.error("PLEASE FILL MANDATORY FIELDS");
      console.log(this.form);
    }
  }

}