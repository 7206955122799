import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  baseURL: any;
  deviceURL = "v1/logger/device";
  loggerURL = "v1/logger/info";
  queueStatus = 'v1/logger/manage/queue';
  loggerActivate = 'v1/logger/activate';
  tuyaURL = 'v1/logger/tuya';
  tuyaDeviceURL = 'v1/logger/tuya/device';
  tuyaLoggerActivate = 'v1/logger/tuya/manage/queue';
  cleanSolarIotDataURL = 'v1/logger/solar/clean';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  getLoggerActivationStatus(params) {
    return this.http.get(this.baseURL + this.loggerActivate, {params: params});
  }

  getLoggerDetails(data) {
    return this.http.get(this.baseURL + this.loggerURL, {
      params: {uuid: data}
    });
  }

  getTuyaLoggerDetails(data) {
    return this.http.get(this.baseURL + this.tuyaURL, {
      params: {uuid: data}
    });
  }

  addLogger(data, uuid) {
    return this.http.post(this.baseURL + this.loggerURL, data, {
      params: {property_uuid: uuid}
    });
  }

  addTuyaDetails(data, uuid) {
    return this.http.post(this.baseURL + this.tuyaURL, data, {
      params: {property_uuid : uuid}
    });
  }

  updateTuyaDetails(data, uuid) {
    return this.http.put(this.baseURL + this.tuyaURL, data, {
      params: {property_uuid: uuid}
    });
  }

  addTuyaDevice(data, uuid) {
    return this.http.post(this.baseURL + this.tuyaDeviceURL, data, {
      params: {property_uuid: uuid}
    });
  }
  
  updateTuyaDevice(data, uuid) {
    return this.http.put(this.baseURL + this.tuyaDeviceURL, data, {
      params: {device_uuid: uuid}
    });
  }

  updateLogger(data, uuid) {
    return this.http.put(this.baseURL + this.loggerURL, data, {
      params: {property_uuid: uuid}
    });
  }

  updateDevice(data, uuid) {
    return this.http.put(this.baseURL + this.deviceURL, data, {
      params: {property_uuid: uuid}
    });
  }

  addDevice(data, uuid) {
    return this.http.post(this.baseURL + this.deviceURL, data, {
      params: {property_uuid: uuid}
    });
  }

  manageQueue(data) {
    return this.http.put(this.baseURL + this.queueStatus, data);
  }

  changeActivationStatus(data) {
    return this.http.put(this.baseURL + this.loggerActivate, data);
  }

  changeTuyaQueueStatus(data) {
    return this.http.put(this.baseURL + this.tuyaLoggerActivate, data);
  }

  cleanSolarIotData(data) {
    return this.http.post(this.baseURL + this.cleanSolarIotDataURL, data);
  }

}
