import { Component, OnInit, Inject, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { LoggerService } from "../../service/logger/logger.service";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-tuya-device-modal",
  templateUrl: "./tuya-device-modal.component.html",
  styleUrls: ["./tuya-device-modal.component.scss"]
})
export class TuyaDeviceModalComponent implements OnInit {
  tuya_device_form: any;
  device_id: any;
  lead_uuid: any;
  device_data: any;

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TuyaDeviceModalComponent>,
    private fb: FormBuilder,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.device_data = this.data.device_data;
    this.lead_uuid = this.data.uuid;

    this.tuya_device_form = this.fb.group({
      device_id: ["", Validators.required],
      status: ["", Validators.required],
      last_logged_on: ["", Validators.required],
      installed_on: ["", Validators.required],
      removed_on: ""
    });

    this.tuya_device_form.valueChanges.subscribe(val => {
      console.log("value changed in the form");
      console.log(this.tuya_device_form);
    });
  }

  submit() {
    let formData = {};

    Object.keys(this.tuya_device_form.value).forEach(key => {
      formData[key] = this.tuya_device_form.value[key];
    });

    console.log("printing the form data");
    console.log(formData);

    console.log("removed_on :" , formData['removed_on']);

    if(formData['removed_on'] == ""){
      formData['removed_on'] = new Date(2000, 0, 1);
    }
    
    console.log("after  as upadtex", formData['removed_on']);
    
    this.loggerService.addTuyaDevice(formData, this.lead_uuid).subscribe(res => {
      console.log('tuya device add', res);
      if (res && res["status"]) {
        // this.dialogRef.close();
        this.toast.success("SUCCESSFULLY ADDED THE DEVICE");
        this.reset();
        setTimeout(() => {
          this.status.emit(true);
          this.dialogRef.close();
        }, 300);
      }
    });
  }

  reset() {
    this.tuya_device_form.patchValue({
      device_id: ["", Validators.required],
      status: ["", Validators.required],
      last_logged_on: ["", Validators.required],
      installed_on: ["", Validators.required],
      removed_on: ""
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
