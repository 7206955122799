import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { BaseService } from "../base.service";
import { MessageToastService } from "../toast/message-toast.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DepartmentService {
  departmentURL = "v1/cluster/department?";
  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, "", messageService);
  }

  saveDepartmentData(data) {
    return this.http.post(environment.serverURl + this.departmentURL, data);
  }

  updateDepartmentData(data) {
    return this.http.put(environment.serverURl + this.departmentURL, data);
  }

  deleteDepartmentData(uuid) {
    return this.http.delete(
      environment.serverURl + this.departmentURL + "uuid=" + uuid
    );
  }

  getDepartmentList(queryParams = "") {
    return this.http.get(
      environment.serverURl + this.departmentURL + queryParams
    );
  }
}
