import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";

@Component({
  selector: "app-cluster-technician-management",
  templateUrl: "./cluster-technician-management.component.html",
  styleUrls: ["./cluster-technician-management.component.scss"]
})
export class ClusterTechnicianManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  clusterList = [];
  isCollapsed = false;
  isEditMode = false;
  clusterAdminList = [];
  clusterAdminDetails = [];
  form: FormGroup;
  formValid: boolean = false;
  sidenavText: String = 'CREATE TECHNICIAN';
  clusterId:any

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      // password: ["", Validators.required],
      phone_number: ["", Validators.required],
      country_code: ["+91", Validators.required],
      cluster_id: [""]
    });
    this.clusterList.push({
      label: "Select a cluster",
      value: null
    });
    this.clusterService.globalCluster.subscribe(cluster => {
      if (cluster) {
        this.clusterId = cluster.uuid;
        this.getClusterTechnicians();
      }
    });
    
    this.getClusterDetails();

    this.form.valueChanges.subscribe(() => {
      this.formValid = this.form.valid;
    });

    this.menuToggle.toggleMenu(false)
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });
  }

  submit() {
    this.form.get('cluster_id').setValue(this.clusterId);
    if (this.form.valid) {
      if (this.isEditMode) {
        this.clusterService
          .updateClusterTechnician(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER TECHNICIAN UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = 'CREATE TECHNICIAN';
              this.getClusterTechnicians();
            }
          });
      } else {
        this.clusterService
          .createClusterTechnician(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CLUSTER TECHNICIAN CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = 'CREATE TECHNICIAN';
              this.getClusterTechnicians();
            }
          });
      }
    }
  }

  editAccount(row) {

    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      // password: 'random',
      phone_number: row.phone_number,
      country_code: "+91",
      cluster_id: row.cluster.uuid
    });
    
    this.sidenavBar.toggle();
    // this.form.get("password").disable();
    this.sidenavText = 'EDIT TECHNICIAN';
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.clusterService
      .deleteClusterTechnician(row.uuid)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.toast.success("CLUSTER TECHNICIAN DELETED SUCCESSFULLY");
          this.reset();
          this.getClusterTechnicians();
        }
        else {
          this.toast.error('OEPRATION FAILED');
        }
      });
      }});
  }

  reset() {
    this.form.reset();
    this.sidenavText = 'CREATE TECHNICIAN';
    // this.form.get("password").enable();
    this.form.get("cluster_id").setValue("");
    this.form.get("country_code").setValue("+91");
    this.isEditMode = false;
  }

  getClusterTechnicians() {
    this.clusterService.getClusterTechnicians(this.clusterId).subscribe((result: any) => {
      if (result && result.status) {
        this.clusterAdminList = result.payload.data;
        this.clusterAdminDetails = [...this.clusterAdminList];
        this.offset = 0;
      }
    });
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.clusters.forEach(element => {
          this.clusterList.push({
            label: element.identifier,
            value: element.uuid
          });
        });
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {
   
  }
}
