import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { FieldConfig, Validator } from "../field.interface";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";

@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  templateUrl: `./dynamic-form.html`,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];
  @Input() access: string;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() valid_check: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(FileUploadComponent, { static: false }) uploadField: FileUploadComponent;
  form: FormGroup;
  validationList: any = [];


  get value() {
    return this.form.value;
  }

  clear() {
    this.form.reset();
    if (document.getElementById('lead_property-location')) {
      (<HTMLInputElement>(document.getElementById('lead_property-location'))).value = "";
      //TODO NEED TO RESET GEOFIELD HINT(LAT, LNG)
      //  document.getElementById('mat-hint-0').innerHTML = "(lat: , lng: )"
    }
    //this.uploadField.clear();
  }

  get check_valid(): boolean {
    return this.form.valid;
  }
  constructor(private fb: FormBuilder,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.createControl();
    if (this.access == "view") {
      this.form.disable();
    }
    console.log("dynamic form comp");
    console.log(this.form);

    console.log('fields array view');
    console.log(this.fields);
    this.fields.forEach(field => {
      if (field.type === "file") {
        let value = field.value;
        let substring1 = "https://storage.googleapis.com/";
        let substring2 = "https://firebasestorage.googleapis.com/";
        value.forEach((val, index) => {
          if (val.includes(substring1) == false && val.includes(substring2) == true) {
            // value.splice(index, 1);
            console.log(value, "<===========value1")
          }
          else if (val.includes(substring2) == true && val.includes(substring2) == false) {
            // value.splice(index, 1);
            console.log(value, "<===========value2")
          }
          else if (val.includes(substring1) == false && val.includes(substring2) == false) {
            value.splice(index, 1);
            // this.toast.warning("File not uploaded.")
            console.log(value, "<===========value3")
          }
        });
      }
    });
    // this.valid_check.emit(this.form.valid);


  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log('emitting value');
      console.log(this.form.valid);
      console.log(this.form);
      this.valid_check.emit(this.form.valid);
    }, 100);

    this.form.statusChanges.subscribe(() => {
      this.valid_check.emit(this.form.valid);
      console.log('emitting value');
      console.log(this.form.valid);
      console.log(this.form);
    });
  }

  ngAfterContentInit() {
    (<any>Object).values(this.form.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.fields.forEach(field => {
      if (field.type === "file") {
        let value = field.value;
        let substring1 = "https://storage.googleapis.com/";
        let substring2 = "https://firebasestorage.googleapis.com/";
        value.forEach((val, index) => {
          if (val.includes(substring1) == false && val.includes(substring2) == true) {
            // value.splice(index, 1);
            console.log(value, "<===========value1")
          }
          else if (val.includes(substring2) == true && val.includes(substring2) == false) {
            // value.splice(index, 1);
            console.log(value, "<===========value2")
          }
          else if (val.includes(substring1) == false && val.includes(substring2) == false) {
            value.splice(index, 1);
            // this.toast.warning("File not uploaded.")
            console.log(value, "<===========value3")
          }
        });
      }
    });
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field.validations || [], field.required)
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any, required) {
    const validList = [];
    let extraValidations = [];
    extraValidations.push({ 'empty': '' });
    if (required) { validList.push(Validators.required); }

    let obj = JSON.parse(validations);
    Object.keys(obj).forEach(val => {
      console.log('printing field validation keys');
      console.log(val);
      if (val == 'min_len') {
        validList.push(Validators.minLength(obj[val]));
      }
      else if (val == 'max_len') {
        validList.push(Validators.maxLength(obj[val]));
      }
      else if (val == 'geo_point') {
        extraValidations.push({ 'geo_point': obj[val] });
      }
      else if (val == 'start_date') {
        extraValidations.push({ 'start_date': obj[val] });
      }
      else if (val == 'end_date') {
        extraValidations.push({ 'end_date': obj[val] });
      }
      else if (val == 'start_time') {
        extraValidations.push({ 'start_time': obj[val] });
      }
      else if (val == 'end_time') {
        extraValidations.push({ 'end_time': obj[val] });
      }
      else if (val == 'min_value') {
        validList.push(Validators.min(obj[val]));
      }
      else if (val == 'max_value') {
        validList.push(Validators.max(obj[val]));
      }
    });
    this.validationList.push(extraValidations);
    return Validators.compose(validList);
    // if (validations.length > 0) {

    //   validations.forEach(valid => {
    //     validList.push(valid.validator);
    //   });
    //   return Validators.compose(validList);
    // }
    // return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
