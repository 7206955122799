import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OwlDateTimeComponent } from "ng-pick-datetime";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeFormats
} from "ng-pick-datetime";
import { MomentDateTimeAdapter } from "ng-pick-datetime-moment";
import * as _moment from "moment";
import { Moment } from "moment";
declare var $: any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseInput: "MM/YYYY",
  fullPickerInput: "l LT",
  datePickerInput: "MMM YYYY",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY"
};

@Component({
  selector: 'app-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  providers: [
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE]
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS }
  ]
})
export class MonthSelectorComponent implements OnInit {
  @Input() form: any;
  @Input() maxDate: any;
  @Input() minDate: any;
  @Output() dateSelected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.form.get('month').setValue(moment());
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.get("month").value;
    ctrlValue.year(normalizedYear.year());
    this.form.get("month").setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: OwlDateTimeComponent<Moment>
  ) {
    console.log('month chosen');
    const ctrlValue = this.form.get("month").value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    this.form.get("month").setValue(ctrlValue);
    this.dateSelected.emit([]);
    datepicker.close();
  }

  disableViewChange() {
    setTimeout(()=>{
      $('button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button').css("pointer-events","none");
    },300);
  }

}

