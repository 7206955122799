import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AqiService {

  aqiDeviceDataUrl = 'v1/report/generation/dashboard/aqi';

  constructor(
    private http: HttpClient
  ) { }

  getAQIData(params) {
    return this.http.get(environment.serverURl + this.aqiDeviceDataUrl, { params: params });
  }

  downloadAQIData(params) {
    return this.http.get(environment.serverURl + this.aqiDeviceDataUrl, { params: params, responseType: 'arraybuffer'});
  }
}
