import {
    Component,
    OnInit,
    Inject,
    EventEmitter,
    Output,
    Renderer2,
    Renderer,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ClusterService } from "../../service/cluster/cluster.service";
import { LeadService } from "../../service/lead/lead.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
declare var google: any;
declare var $: any;

@Component({
    selector: "app-reassign-cluster",
    templateUrl: "./reassign-cluster.component.html",
    styleUrls: ["./reassign-cluster.component.scss"],
})
export class ReassignClusterComponent implements OnInit {
    form: any;
    clusterList = [];


    @Output() status: EventEmitter<any> = new EventEmitter();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ReassignClusterComponent>,
        private toast: MessageToastService,
        private dialog: MatDialog,
        private clusterService: ClusterService,
        private leadService: LeadService,
        private renderer: Renderer
    ) {
    }

    ngOnInit() {
        console.log("data received in this modal");
        console.log(this.data);
        this.clusterService.getClusterList().subscribe((result: any) => {
            if (result && result.status) {
                result.payload.clusters.forEach(element => {
                    this.clusterList.push({
                        label: element.identifier,
                        value: element.uuid
                    });
                });
            }
        })
        this.form = this.fb.group({
            cluster_id: this.data.cluster.uuid,
            lead_property_uuid: [this.data.uuid]
        });

    }

    submit() {
        if (this.form.valid) {
            let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: "300px",
                data: {
                    title: "CONFIRMATION",
                    desc: "Are you sure you want to proceed with cluster re-assignment, there may be data losses?",
                },
            });
            dialogRef.componentInstance.option.subscribe((data) => {
                if (data == "success") {
                    this.clusterService.reassignCluster(this.form.value.cluster_id, [this.form.value.lead_property_uuid]).subscribe((res: any) => {
                        console.log(res);
                        if (res && res.status) {
                            this.dialogRef.close();
                            this.status.emit("success");
                            this.toast.success(res.message);
                            window.location.reload();
                        } else {
                            this.toast.error(res.message);
                        }
                    });
                }
            });

        }
    }

    cancel() {
        this.dialogRef.close();
    }
}
