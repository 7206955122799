import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { LoggerService } from "../../service/logger/logger.service";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-tuya-device",
  templateUrl: "./tuya-device.component.html",
  styleUrls: ["./tuya-device.component.scss"]
})
export class TuyaDeviceComponent implements OnInit {
  @Input() device_data: any;
  @Output() update: EventEmitter<any> = new EventEmitter();

  // isCollapsed: boolean = false;
  form: any;
  device_uuid: number;
  lead_uuid: string;

  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.device_uuid = this.device_data.uuid;

    this.route.params.subscribe(val => {
      this.lead_uuid = val.id;
    });


    console.log("showing data got");
    console.log(this.device_data);

    this.form = this.fb.group({
      device_id: [this.device_data.device_id, Validators.required],
      status: [this.device_data.status, Validators.required],
      installed_on: [this.device_data.installed_on, Validators.required], 
      removed_on: [this.device_data.removed_on],
      last_logged_on: [this.device_data.last_logged_on, Validators.required],
    });
  }

  ngAfterViewInit() {
    console.log('printing device form value');
    console.log(this.form);
  }

  update_device() {
    console.log("printing value of device form");

    let formData = {};

    Object.keys(this.form.value).forEach(key => {
      formData[key] = this.form.value[key];
    });

    console.log("installed date");
    console.log(formData["installed_on"]);

    console.log(formData);
    this.loggerService.updateTuyaDevice(formData, this.device_uuid).subscribe(res => {
      console.log('update tuya device', res);
      if (res && res["status"]) {
        this.toast.success("SUCCESSFULLY UPDATED THE DEVICE");
        this.update.emit(true);
      }
    });
  }
 
  undo() {
    this.form.patchValue({
      device_id: this.device_data.device_id,
      status: this.device_data.status,
      installed_on: this.device_data.installed_on,
      removed_on: this.device_data.removed_on
    });
  }
}
