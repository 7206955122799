import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadService } from 'src/app/common/service/lead/lead.service';
import { FormBuilder } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

declare var $: any;

const colMap = {
  Name: 'lead__first_name',
  'Phone Number': 'lead__phone_number_1',
  Address: 'address',
  Identifier: 'identifier',
  'Current Stage': 'current_stage',
  'Last Updated On': 'updated_on',
  Source: 'lead__lead_source',
  'Lead Status': 'lms_status',
  'Last Updated Stage': 'current_stage',
  'Total Rooms': 'room_count',
  'Total Devices': 'device_count'
};
@Component({
  selector: 'app-ha-properties',
  templateUrl: './ha-properties.component.html',
  styleUrls: ['./ha-properties.component.scss']
})
export class HaPropertiesComponent implements OnInit {

  rows = [];
  count = 0;
  offset = 0;
  limit: number;
  leadList = [];
  listSize: number;
  defaultAccess = 'view';
  form: any;
  clusterList: any = [];
  search_text: any = [];
  formData: any = {};
  selectedCluster: any;
  sortData: any;
  cityList: any = [];
  leadSourceList = [];
  pageCount = 0;
  isFormValid = false;


  // this will store data of filter, sort, cluster_id and page change
  currentState = { page: 1, column_name: '', sort_order: ''};

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private leadService: LeadService,
    private fb: FormBuilder,
    private menuToggle: MenuToggleService,
    private toast: MessageToastService
  ) {}

  ngOnInit() {
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });

    this.form = this.fb.group({
      text: '',
      active: ''
    });
    this.getLeadDetails(this.currentState);  
  }

  highlightRows() {
    Array.from(document.getElementsByTagName("datatable-body-row")).forEach(
      (row, idx) => {
        if (
          this.leadList[idx].is_recommended &&
          !row.classList.contains("highlight-row")
        ) {
          row.classList.add("highlight-row");
        } else if (
          !this.leadList[idx].is_recommended &&
          row.classList.contains("highlight-row")
        ) {
          row.classList.remove("highlight-row");
        }
      }
    );
  }

  combineNameAndNumber(data) {
    let option_text: string;
    if (data.number) {
      option_text = data.name + ' (' + <string>data.number + ')';
    } else {
      option_text = data.name;
    }
    return option_text;
  }

  onActivate(event) {
    if (event.type == "click" && event.cellIndex != 7 && event.cellIndex != 8 && event.cellIndex != 9 && event.cellIndex != 13 && event.cellIndex != 10) {
      setTimeout(() => {
        window.open('lead/view/home-automation?id=' + event.row.uuid);
      }, 300);
    }
  }

  getLeadDetails(data) {
    console.log('data sending for getting list');
    console.log(data);
    this.leadService.getAutomationPropertyList(data).subscribe((result: any) => {
      if (result && result.status) {
        this.pageCount = result['payload'].data.length;
        result.payload.data.forEach(val => {
          val['full_name'] =
            val.lead.first_name +
            (val.lead.last_name != '' ? ' ' + val.lead.last_name : '');

          val['phone_number'] =
            val.lead.phone_number_1 +
            (val.lead.phone_number2 ? ' , ' + val.lead.phone_number2 : '');

        });
        this.leadList = [...result.payload.data];
        this.limit = result.payload.data_per_page;
        this.count = result.payload.count;
        this.offset = data['page'] - 1;
        setTimeout(() => {
          this.highlightRows();
        }, 300);
      }
    });
  }

  downloadList() {
    if (this.leadList.length > 0) {
      console.log('request for downloading list');
    let downloadState = JSON.parse(JSON.stringify(this.currentState));
    delete downloadState['page'];
    console.log('current angular download object');
    console.log(downloadState);
    downloadState['download'] = 1;
      // this.currentState['download'] = 1;
      // this.getFilteredList();
      this.leadService.downloadAutomationListByUrl(this.leadService.automationPropertyListUrl, downloadState);
      console.log("automation properties list downloaded!!!")
    }
    else {
      console.log("automation properties list empty!");
    }
  }

  onPage(event) {
    this.currentState['page'] = event.offset + 1;

    this.offset = event.offset;

    this.getLeadDetails(this.currentState);
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  onSort(event) {
    console.log(event);

    // if clicked column is not in the column list, we return
    if (Object.keys(colMap).indexOf(event['column'].name) < 0) {
      return;
    }

    this.currentState['column_name'] = colMap[event['column'].name];
    this.currentState['sort_order'] = event['newValue'];

    // ngx tables by default sends you to 1st page after sorting
    this.offset = 0;
    this.currentState['page'] = 1;

    this.getLeadDetails(this.currentState);
  }

  checkFormValidity() {
    let validity = true;
    Object.keys(this.form.controls).forEach((key) => {
      console.log("checking ", key, "errortype ", ( this.form.get(key).errors != null && this.form.get(key).errors.length > 0));
      // if(key != 'date_from' && key != 'date_to' && ( this.form.get(key).errors != null && this.form.get(key).errors.length > 0)) {
      //   validity = false;
      // }
    });
    this.isFormValid = validity;
  }

  // submit function
  submit(mode = 'normal') {
    console.log("form value---------->", this.form.value);
    this.checkFormValidity();
    // when filter, sorting or page change occurs
    if (mode != 'cluster_change') {
      Object.keys(this.form.value).forEach(val => {
        console.log(val);
        if (this.form.value[val] != '' && this.form.value[val] != null) {
          this.currentState[val] = this.form.value[val];
        } 
        else {
          delete this.currentState[val];
        }
      });
       
    }
    // when cluster change occurs
    else {
      this.clearForm();
    }

    this.clearState();
    this.getLeadDetails(this.currentState);
    this.sidenavBar.close();
  }

  clearForm() {
    // resetting form data
    this.resetForm();
    console.log("clear form------->");
    // removing form data from state, only keeping cluster_id, pagenum and sort data
    let newState = {
       page: this.currentState.page,
      column_name: this.currentState.column_name,
      sort_order: this.currentState.sort_order
    };
    this.currentState = newState;
    this.getLeadDetails(this.currentState);
  }

  // clear sorting, and page data
  clearState() {
    this.currentState['page'] = 1;
    this.currentState['sort_order'] = '';
    this.currentState['column_name'] = '';
  }

  resetForm() {
    this.form.patchValue({
      text: '',
      active: ''
    });
    console.log("value--->", this.form.value);
  }

  sidenavOpen() {
    console.log('sidenav opened');
    $('app-lead-list-view').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    console.log('sidenav closed');
    $('app-lead-list-view').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }


}
