import { Component, Input, OnInit } from '@angular/core';

declare var $: any;

const colMap = {
    Name: 'lead__first_name',
    'Phone Number': 'lead__phone_number_1',
    Address: 'address',
    Identifier: 'identifier',
    'Current Stage': 'current_stage',
    'Last Updated On': 'updated_on',
    Source: 'lead__lead_source',
    'Lead Status': 'lms_status',
    'Last Updated Stage': 'current_stage'
};

@Component({
    selector: 'app-property-list',
    templateUrl: './property-list.component.html',
    styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent implements OnInit {
    @Input() data: any;
    propertyCount = 0;
    propertyOffset = 0;
    propertyLimit = 10;
    currentState = { cluster_id: '', page: 1, column_name: '', sort_order: '' };

    constructor(
    ) { }

    ngOnInit() {
    }

    onPage(event) {
    }

}
