import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { StageService } from "src/app/common/service/stage/stage.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { DatePipe } from "@angular/common";
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
declare var $: any;

@Component({
  selector: "app-create-stage-field",
  templateUrl: "./create-stage-field.component.html",
  styleUrls: ["./create-stage-field.component.scss"]
})
export class CreateStageFieldComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  clusters = [];
  selectedCities1 = [];
  isEditMode = false;
  clusterList = [];
  filterApplied = false;
  isFilterCollapsed = false;
  isCollapsed = false;
  tableColumnAllowed = false;
  stagesList = [];
  stagesListDetails = [];
  tableData = [];
  tableNameList = [];
  columnNameList = [];
  stagesFieldList = [];
  isfileColumn = false;
  stagesFieldListDetails = [];
  form: FormGroup;
  editData: any;
  filterForm: FormGroup;
  formValid: boolean = false;
  formValid1: boolean = false;
  meta_text: string = "Meta Data";
  validationList = [];

  fieldTypeList = {
    text: "Text",
    text_area: "Text Area",
    file: "File",
    integer: "Integer",
    dropdown_partner_epc: "EPC Partner Dropdown",
    dropdown_partner_sales: "Sales Partner Dropdown",
    dropdown_generic: "Generic Dropdown",
    dropdown_designer: "Designer Dropdown",
    float: "Float",
    geo_point: "Geo Point",
    datetime: "Date Time",
    date: "Date"
  };

  validationListMap = {
    text_area: ["min_len", "max_len"],
    geo_point: ["geo_point"],
    datetime: ["start_time", "end_time"],
    date: ["start_date", "end_date"],
    text: ["min_len", "max_len"],
    float: ["min_len", "max_len", "min_value", "max_value"],
    integer: ["min_len", "max_len", "min_value", "max_value"],
    file: [],
    map: [],
    dropdown_partner_epc: [],
    dropdown_partner_sales: [],
    dropdown_generic: [],
    dropdown_designer: []
  };

  fieldType = [
    {
      label: "Select a field type",
      value: "",
      tableColumnAllowed: false,
      fileColumn: false
    },
    {
      label: "Text",
      value: "text",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "Text Area",
      value: "text_area",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "File",
      value: "file",
      tableColumnAllowed: true,
      fileColumn: true
    },
    {
      label: "Integer",
      value: "integer",
      tableColumnAllowed: true,
      fileColumn: false
    },
    // {
    //   label: "Map",
    //   value: "map",
    //   tableColumnAllowed: false,
    //   fileColumn: false
    // },
    {
      label: "EPC Partner Dropdown",
      value: "dropdown_partner_epc",
      tableColumnAllowed: false,
      fileColumn: false
    },
    {
      label: "Sales Partner Dropdown",
      value: "dropdown_partner_sales",
      tableColumnAllowed: false,
      fileColumn: false
    },
    {
      label: "Generic Dropdown",
      value: "dropdown_generic",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "Designer Dropdown",
      value: "dropdown_designer",
      tableColumnAllowed: false,
      fileColumn: false
    },
    {
      label: "Geo Point",
      value: "geo_point",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "Date",
      value: "date",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "Date Time",
      value: "datetime",
      tableColumnAllowed: true,
      fileColumn: false
    },
    {
      label: "Float",
      value: "float",
      tableColumnAllowed: true,
      fileColumn: false
    }
  ];
  accessList = [
    {
      label: "NA",
      value: "na"
    },
    {
      label: "View",
      value: "view"
    },
    {
      label: "Edit",
      value: "edit"
    }
  ];

  stageGroup = [
    {
      label: "Select Stage Group",
      value: ""
    },
    {
      label: "Acquisition",
      value: "acquisition"
    },
    {
      label: "Feasibility",
      value: "feasibility"
    },
    {
      label: "Execution",
      value: "execution"
    },
    {
      label: "QC",
      value: "qc"
    },
    {
      label: "Collection",
      value: "collection"
    }
  ];

  constructor(
    private fb: FormBuilder,
    private stageService: StageService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      cluster_id: ["", Validators.required],
      stage_id: ["", Validators.required]
    });

    this.form = this.fb.group({
      uuid: [null],
      type: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(50)])
      ],
      table_name: [null, Validators.compose([Validators.maxLength(30)])],
      column_name: [null, Validators.compose([Validators.maxLength(50)])],
      hint: [null, Validators.compose([Validators.maxLength(50)])],
      display_name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ],
      is_required: false,
      meta: [null, Validators.compose([Validators.maxLength(30)])],
      min_len: null,
      max_len: null,
      geo_point: "",
      start_date: null,
      end_date: null,
      start_time: 0,
      end_time: 0,
      min_value: null,
      max_value: null
    });

    this.form.get("type").valueChanges.subscribe(data => {
      //console.log("***type name value set");
      this.createTableColumn();

      this.validationList = this.validationListMap[data];
      //console.log("printing dropdown selection");
      //console.log(data);
      if (data == "dropdown_generic") {
        this.form.controls["meta"].setValidators([Validators.required]);
        this.meta_text = "Meta Data*";
      } else {
        this.form.controls["meta"].setValidators(null);
        this.meta_text = "Meta Data";
      }
    });

    this.filterForm.get("cluster_id").valueChanges.subscribe(data => {
      this.getStagesList();
    });

    this.form.get("table_name").valueChanges.subscribe(data => {
      if (data != "" && data) {
        this.columnNameList = [];
        let typeData = this.tableData[this.form.get("type").value];

        let tableDetails = typeData.find(x => x.table_details.key == data);
        this.columnNameList = [];
        this.columnNameList.push({
          key: "",
          value: "Please select a column name"
        });
        if (tableDetails && tableDetails.column_list) {
          tableDetails.column_list.forEach(element => {
            this.columnNameList.push(element);
          });
        }

        this.form.get("column_name").setValidators([Validators.required]);
        this.form.get("column_name").setValue("");
        this.form.get("meta").updateValueAndValidity();
        this.form.updateValueAndValidity();
        if (this.isEditMode) {
          setTimeout(() => {
            let found = false;
            this.columnNameList.forEach(val => {
              if (val.key == this.editData.column_name.key) {
                found = true;
              }
            });
            if (!found) {
              this.columnNameList.push({
                key: this.editData.column_name.key,
                value: this.editData.column_name.value
              });
            }
            this.form
              .get("column_name")
              .setValue(this.editData.column_name.key);
          }, 300);
        }
        //console.log("data", tableDetails);
      }
    });

    this.clusters.push({
      label: "Select a cluster",
      value: null
    });
    this.stagesList.push({
      label: "Select a stage",
      value: null
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.formValid = this.filterForm.valid;
    });

    this.getClusters();

   // closing the sidemenu on page load
   this.menuToggle.toggleMenu(false);
   // resizing the content whenever menu is toggled
   this.menuToggle.menuToggle.subscribe(val => {
     setTimeout(() => {
       window.dispatchEvent(new Event('resize'));
     }, 100);
   });
  
  }

  createTableColumn() {
    this.tableColumnAllowed = false;
    if (!this.isEditMode) {
      this.formReset();
    }
    let type = this.form.get("type").value;
    let field = this.fieldType.find(x => x.value == type);
    if (field && field.tableColumnAllowed) {
      this.tableColumnAllowed = true;

      this.form.controls["table_name"].setValidators([Validators.required]);
      // this.getColumnListData();
      //console.log("Table Name");
      let typeData = this.tableData[type];
      this.tableNameList = [];
      this.tableNameList.push({
        key: "",
        value: "Please select a table name"
      });
      typeData.forEach(element => {
        this.tableNameList.push(element.table_details);
      });
      this.columnNameList = [];
      //console.log(this.tableNameList);
      this.form.get("table_name").setValue("");

      if (this.isEditMode) {
        setTimeout(() => {
          let found = false;
          this.tableNameList.forEach(val => {
            if (val.key == this.editData.table_name.key) {
              found = true;
            }
          });
          if (!found) {
            this.tableNameList.push({
              key: this.editData.table_name.key,
              value: this.editData.table_name.value
            });
          }
          this.form.get("table_name").setValue(this.editData.table_name.key);
        }, 300);
      }
      //console.log("Type Data", typeData);
      //console.log(this.form.value);
      // this.form.controls["table_name"].setErrors({ incorrect: false });
      // this.form.controls["table_name"].clearValidators();
      // this.form.controls["table_name"].updateValueAndValidity();
      // this.form.controls["column_name"].setErrors({ incorrect: false });
      // this.form.controls["column_name"].clearValidators();
      // this.form.controls["column_name"].updateValueAndValidity();
      this.isfileColumn = false;
    }
  }

  submit() {
    //console.log("form value submitting");
    //console.log(this.form.value);
    let formData = {};
    let formValue = JSON.parse(JSON.stringify(this.form.value));
    formData["uuid"] = formValue["uuid"];
    formData["type"] = formValue["type"];
    formData["table_name"] = formValue["table_name"];
    formData["column_name"] = formValue["column_name"];
    formData["hint"] = formValue["hint"];
    formData["display_name"] = formValue["display_name"];
    formData["is_required"] = formValue["is_required"];
    formData["meta"] = formValue["meta"];
    //console.log('initial value check');
    //console.log(formValue['is_required']);
    //console.log(formData['is_required']);
    //console.log(formData);

    let validations = {};

    if (formValue["min_len"] != null) {
      validations["min_len"] = formValue["min_len"];
    }
    if (formValue["max_len"] != null) {
      validations["max_len"] = formValue["max_len"];
    }
    if (formValue["geo_point"] != "") {
      validations["geo_point"] = formValue["geo_point"];
    }
    if (formValue["start_date"] != null) {
      validations["start_date"] = this.datepipe.transform(
        formValue["start_date"],
        "yyyy-MM-dd"
      );
    }
    if (formValue["end_date"] != null) {
      validations["end_date"] = this.datepipe.transform(
        formValue["end_date"],
        "yyyy-MM-dd"
      );
    }
    if (formValue["start_time"] != null) {
      //console.log('value is not null');
      //console.log(formValue['start_time']);
      validations["start_time"] = Math.floor(
        new Date(formValue["start_time"]).getTime() / 1000
      );
    }
    if (formValue["end_time"] != null) {
      validations["end_time"] = Math.floor(
        new Date(formValue["end_time"]).getTime() / 1000
      );
    }
    if (formValue["min_value"] != null) {
      validations["min_value"] = formValue["min_value"];
    }
    if (formValue["max_value"] != null) {
      validations["max_value"] = formValue["max_value"];
    }

    formData["validations"] = JSON.stringify(validations);
    //console.log('final value check');
    //console.log(formValue['is_required']);
    //console.log(formData['is_required']);
    //console.log(formData);

    if (this.form.valid && this.filterForm.valid) {
      let data = formData;
      //console.log("data that we are submitting");
      //console.log(data);
      Object.keys(data).forEach(key => {
        //console.log("current on key");
        //console.log(key);
        //console.log("with value");
        //console.log(data[key]);
        if (
          data[key] != null &&
          data[key] == "" && typeof data[key] != "boolean"
        ) {
          data[key] = null;
        }
      });
      data["stage_id"] = this.filterForm.get("stage_id").value;
      if (this.isEditMode) {
        this.stageService
          .updateStageFieldData(formData)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("STAGE FIELD UPDATED SUCCESSFULLY");
              this.getStageFieldList();
              this.getColumnListData();
            }
          });
      } else {
        this.stageService
          .saveStageFieldData(formData)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.getColumnListData();
              this.toast.success("STAGE FIELD CREATED SUCCESSFULLY");
              this.getStageFieldList();
            }
          });
      }
    }
  }

  filterAdded() {
    this.getColumnListData();
    this.columnNameList = [];
    this.tableNameList = [];
    this.tableColumnAllowed = false;
    this.isEditMode = false;
    $("#typeSelect").prop("disabled", false);
    this.form.reset();
    //this.form.get('type').setValue('');
    if (this.filterForm.valid) {
      // this.columnNameList.push({
      //   key: "",
      //   value: "Please select a column name"
      // });
      // this.tableNameList.push({
      //   key: "",
      //   value: "Please select a table name"
      // });
      this.form.get("type").setValue("");
      this.form.get("table_name").setValue("");
      this.form.get("column_name").setValue("");
      this.filterApplied = true;
      this.getStageFieldList();
    }
  }

  getStagesList() {
    this.form.get("table_name").reset();
    this.form.get("column_name").reset();
    this.form.get("table_name").enable();
    let queryParams = "uuid=" + this.filterForm.get("cluster_id").value;
    this.getStageDetails(queryParams);
  }

  getStageFieldList() {
    let queryParams = "uuid=" + this.filterForm.get("stage_id").value;
    this.stageService
      .getStageFieldList(queryParams)
      .subscribe((result: any) => {
        if (result.status) {
          //console.log("API response");
          //console.log(result);
          result.payload.stage_fields.forEach(val => {
            val["field_type"] = this.fieldTypeList[
              result.payload.stage_fields.type
            ];
          });
          this.stagesFieldList = [...result.payload.stage_fields];
          this.stagesFieldListDetails = result.payload.stage_fields;
          this.offset = 0;
        }
      });
  }

  editAccount(row) {
    //this.getColumnListData();
    this.isEditMode = true;
    $("#typeSelect").prop("disabled", true);
    this.editData = row;
    //console.log("edit row data found");
    //console.log(row);
    //console.log(row.table_name.value);
    //console.log(row.column_name.value);
    //console.log(JSON.parse(row.validations).start_time);
    //console.log("end");
    this.form.get("type").setValue(row.type);
    //console.log("");
    let validationObject = JSON.parse(row.validations);
    this.form.patchValue({
      uuid: row.uuid,
      hint: row.hint,
      display_name: row.display_name,
      meta: row.meta,
      is_required: row.is_required,
      min_len:
        validationObject.min_len != null ? validationObject.min_len : null,
      max_len:
        validationObject.max_len != null ? validationObject.max_len : null,
      geo_point: validationObject.geo_point ? validationObject.geo_point : null,
      start_date: validationObject.start_date
        ? validationObject.start_date
        : null,
      end_date: validationObject.end_date ? validationObject.end_date : null,
      start_time: validationObject.start_time
        ? validationObject.start_time
        : null,
      end_time: validationObject.end_time ? validationObject.end_time : null,
      min_value:
        validationObject.min_value != null ? validationObject.min_value : null,
      max_value:
        validationObject.max_value != null ? validationObject.max_value : null
    });

    this.filterForm.patchValue({
      cluster_id: row.stage.cluster.uuid
    });
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        this.stageService
          .deleteStageFieldData(row.uuid)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.reset();
              this.getColumnListData();
              this.toast.success("STAGE FIELD DELETED SUCCESSFULLY");
              this.getStageFieldList();
            }
          });
      }
    });
  }

  reset() {
    this.form.reset();
    this.form.get("type").setValue("");
    this.form.patchValue({
      is_default: false,
      type: ""
    });
    this.isEditMode = false;
    this.form.get("table_name").clearValidators();
    this.form.get("column_name").clearValidators();
    $("#typeSelect").prop("disabled", false);
  }

  formReset() {
    this.form.get("table_name").reset();
    this.form.get("table_name").enable();
    this.form.get("column_name").reset();
    this.form.get("column_name").enable();
    this.form.get("display_name").reset();
    this.form.get("display_name").enable();
    this.form.get("hint").reset();
    this.form.get("hint").enable();
    this.form.get("meta").setValue(null);

    this.form.get("min_len").setValue(null);
    this.form.get("max_len").setValue(null);
    this.form.get("geo_point").setValue(null);
    this.form.get("start_date").setValue(null);
    this.form.get("end_date").setValue(null);
    this.form.get("start_time").setValue(null);
    this.form.get("end_time").setValue(null);
    this.form.get("min_value").setValue(null);
    this.form.get("max_value").setValue(null);
    this.form.get("is_required").setValue(false);
  }

  getStageDetails(queryParam) {
    this.stagesList = [];
    this.stagesList.push({
      label: "Select a stage",
      value: null
    });
    this.stageService.getStageList(queryParam).subscribe((result: any) => {
      if (result && result.status) {
        result.payload.stages.forEach(element => {
          this.stagesList.push({
            label: element.display_name,
            value: element.uuid
          });
        });
      }
    });
  }

  getColumnListData() {
    let data = this.filterForm.get("stage_id").value;
    //console.log("value of uuid set");
    //console.log(data);
    this.stageService.getColumnNameDetails(data).subscribe((result: any) => {
      if (result.status) {
        this.tableData = result.payload;

        let data = Object.keys(this.tableData);
        this.tableNameList = [];
      }
    });
  }

  getStageData(data) {
    let queryParams = "uuid=" + data.uuid;
    this.stageService.getStageData(queryParams).subscribe((result: any) => {});
  }

  getClusters() {
    this.clusterService.getClusterList().subscribe(res => {
      res["payload"].clusters.forEach(element => {
        this.clusters.push({
          label: element.identifier,
          value: element.uuid
        });
      });
    });
  }

  onPage(event) {
    // const start = event.offset * this.limit;
    // const end =
    //   this.stagesFieldListDetails.length >= start + this.limit
    //     ? start + this.limit
    //     : this.stagesFieldListDetails.length;
    // const rows = [];
    // this.offset = event.offset;
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.stagesFieldListDetails[i];
    //   index++;
    // }
    // //console.log("row", rows);
    // this.stagesFieldList = rows;
    // this.stagesFieldList = [...this.stagesFieldList];
  }
}
