import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { LoginService } from "../../service/login/login.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private toast: MessageToastService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      old_password: ["", Validators.required],
      new_password: ["", Validators.required],
      re_new_password: ["", Validators.required]
    });
  }

  submit() {
    if (
      this.form.controls["new_password"].value ==
      this.form.controls["re_new_password"].value
    ) {
      this.loginService
        .resetPassword(
          this.form.controls["old_password"].value,
          this.form.controls["new_password"].value
        )
        .subscribe(result => {
          if (result['status']) {
            this.dialogRef.close();
            this.toast.success("PASSWORD CHANGED SUCCESSFULLY");
          }
          else {
            this.toast.error('INCORRECT PASSWORD ENTERED');
          }
        });
    } else {
      this.toast.error("PASSWORDS DO NOT MATCH");
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
