import {
  Component,
  OnInit
} from "@angular/core";
import { LoaderService } from "src/app/common/service/loader/loader.service";
import { ActivatedRoute } from "@angular/router";
import { LeadService } from "src/app/common/service/lead/lead.service";
declare var google: any;
declare var $: any;
declare var jQuery: any;

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.scss"]
})
export class MapViewComponent implements OnInit {
  json: any;
  map: any;
  bounds;
  markers = [];
  lead_uuid: string;
  position: any;
  property_name: string;
  property_stage: string;
  property_info: string;
  property_identifier: string;
  property_surface_area: string;
  property_floor: number;
  installed_capacity: string;
  output: string;
  payback: string;
  property_location: string;
  property_address: string;
  lead_name: string;
  lead_phone_number1: string;
  lead_phone_number2: string;

  constructor(private route: ActivatedRoute,
    public loaderService: LoaderService,
    private leadService: LeadService) {}

  ngOnInit() {
    this.position = { lat: 24.886, lng: -70.268 };
    this.bounds = new google.maps.LatLngBounds();
    this.drawMap(this.position);
  }

  drawMap(position) {
    // slow map load
    setTimeout(() => {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: position,
        zoom: 5,
        clickableIcons: false,
        mapTypeId: "satellite"
      });
      this.map.setTilt(45);
      this.route.params.subscribe(val => {
        this.lead_uuid = val.id;
        this.leadService.getMapJson(this.lead_uuid).subscribe(val => {
          console.log('returning the value obtained from API call');
          console.log(val);
          this.drawPerimeter(val['payload'].details.map_json);
          this.setDetails(val);
        });
      });
    }, 100);
  }

  setDetails(data) {
    data = data.payload.details;
    let map_data = JSON.parse(data.map_json);
    this.property_name = data.name;
    this.property_identifier = data.identifier;
    this.property_stage = data.stage;
    this.property_address = data.address;
    this.property_info = data.info;
    this.property_surface_area = map_data.area;
    this.property_floor = data.floors;
    this.installed_capacity = map_data.installed_capacity;
    this.output = map_data.output;
    this.payback = map_data.payback;
    this.property_location = data.area;
    this.lead_name = data.lead.first_name + ' ' + data.lead.last_name;
    this.lead_phone_number1 = data.lead.phone_number_1;
    this.lead_phone_number2 = data.lead.phone_number_2;
  }

  drawPerimeter(data) {
    console.log('map json received');
    console.log(data);
    data = JSON.parse(data);
    console.log('printing perimeter data');
    console.log(data["perimeter"]);

    // placing markers with infowindows
    let paths = [data.perimeter];
    for (let j = 0; j < data['perimeter'].length; j++) {
      this.bounds.extend(data.perimeter[j]);
      let content = "";
      content = content + "<b>PERIMETER</b><br/>";
      content = content + "<b>Coordinates = </b>" + data.perimeter[j].lat + ", " + data.perimeter[j].lng +
          "<br/>";
      let infowindow = new google.maps.InfoWindow({
          content: content,
          zIndex: 5
      });
      let marker = new google.maps.Marker({
          position: data.perimeter[j],
          map: this.map,
          title: "Perimeter"
      });

      marker.addListener("click", () => {
        infowindow.open(this.map, marker);
      });
    }

    // adding holes
    for (var i = 0; i < data['holes'].length; i++) {
      paths[i + 1] = data.holes[i].perimeter;
      for (var j = 0; j < data.holes[i].perimeter.length; j++) {
          this.bounds.extend(data.holes[i].perimeter[j]);
      }
      this.bounds.extend(data.holes[i].center);
      var content = "";
      content = content + "<b>OBSTRUCTION</b><br/>";
      content = content + "<b>Area = </b>" + data.holes[i].area + " sq meters<br/>";
      content = content + "<b>Type = </b>" + data.holes[i].type + "<br/>";
      content = content + "<b>Height = </b>" + data.holes[i].height + " meters<br/>";
      content = content + "<b>Comment = </b>" + data.holes[i].comment + "<br/>";
      content = content + "<b>Coordinates = </b>" + data.holes[i].center.lat + ", " + data.holes[i].center
          .lng + "<br/>";
      var infowindow = new google.maps.InfoWindow({
          content: content
      });
      var marker = new google.maps.Marker({
          position: data.holes[i].center,
          map: this.map,
          title: data.holes[i].type
      });

      marker.addListener("click", () => {
        infowindow.open(this.map, marker);
      });
    }

    //add nearby points
    for (var i = 0; i < data['nearby'].length; i++) {
      this.bounds.extend(data.nearby[i].center);
      var content = "";
      content = content + "<b>NEIGHBOURING ENTITY</b><br/>";
      content = content + "<b>Type = </b>" + data.nearby[i].type + "<br/>";
      content = content + "<b>Height = </b>" + data.nearby[i].height + " floors<br/>";
      content = content + "<b>Comment = </b>" + data.nearby[i].comment + "<br/>";
      content = content + "<b>Coordinates = </b>" + data.nearby[i].center.lat + ", " + data.nearby[i].center
          .lng + "<br/>";
      var infowindow = new google.maps.InfoWindow({
          content: content
      });
      var marker = new google.maps.Marker({
          position: data.nearby[i].center,
          map: this.map,
          title: data.nearby[i].type
      });

      marker.addListener("click", () => {
        infowindow.open(this.map, marker);
      });
    }

    // drawing perimeter
    let boundary = new google.maps.Polygon({
      paths: paths,
      geodesic: true,
      fillColor: '#FF0000',
      fillOpacity: 0.1,
      strokeColor: '#FF0000',
      strokeOpacity: 1,
      strokeWeight: 1
    });
    boundary.setMap(this.map);
    this.map.fitBounds(this.bounds);
  }
}
