import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Output,
  Renderer2,
  Renderer,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ClusterService } from "../../service/cluster/cluster.service";
import { LeadService } from "../../service/lead/lead.service";
declare var google: any;
declare var $: any;

@Component({
  selector: "app-add-cluster",
  templateUrl: "./add-cluster.component.html",
  styleUrls: ["./add-cluster.component.scss"],
})
export class AddClusterComponent implements OnInit {
  form: any;
  recommendationList = [];
  city_geo_point = "";
  administrativeName = "";
  recommendationReq = true;
  showChannelPartner = true;
  priorityLead: any = 0;

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddClusterComponent>,
    private toast: MessageToastService,
    private clusterService: ClusterService,
    private leadService: LeadService,
    private renderer: Renderer
  ) {
    // to close popup if we click anywhere else on the screen
    renderer.listenGlobal("document", "click", (event) => {
      let requestPopup = document.getElementById("mark-channel-partner-popup");
      if (
        !event.target.classList.contains("popup-action-btn") &&
        requestPopup
      ) {
        requestPopup.remove();
      }
    });
  }

  ngOnInit() {
    console.log("data received in this modal");
    console.log(this.data);

    this.recommendationList = this.data.recommendation_dropdown;
    this.administrativeName = this.data.state;
    this.showChannelPartner = this.data.show_channel_partner != undefined ? this.data.show_channel_partner : true;
    console.log("show channel partner------------->", this.showChannelPartner);
    this.form = this.fb.group({
      address: [this.data.address != null ? this.data.address : ""],
      city: [this.data.city != null ? this.data.city : "", Validators.required],
      recommendation: [this.data.recommendation, Validators.required],
      priority_level: [0, Validators.required]
    });
    if (this.data.recommendation_required != null && this.data.recommendation_required == false) {
      this.form.get('recommendation').setValidators(null);
      this.recommendationReq = false;
    }
    this.leadService.refreshNewPropertyList.subscribe((val) => {
      this.dialogRef.close();
    });
  }

  ngAfterViewInit() {
    let input = document.getElementById("city-input");
    var restrictOptions = {
      componentRestrictions: { country: "in" },
      fields: ["address_components", "geometry", "name"],
      types: ["(cities)"],
    };
    var autoComplete = new google.maps.places.Autocomplete(
      input,
      restrictOptions
    );
    google.maps.event.addListener(autoComplete, "place_changed", () => {
      let place = autoComplete.getPlace();
      this.city_geo_point = $("#city-input")[0].value;
      console.log("place object found out");
      console.log(place);
      place.address_components.forEach((item) => {
        console.log(
          item.types.includes("administrative_area_level_1"),
          this.administrativeName
        );
        if (item.types.includes("administrative_area_level_1")) {
          this.administrativeName = item.short_name;
        }
      });
      console.log('selected state: ', this.administrativeName);
      // this.administrativeName =
      //   place.address_components[
      //     place.address_components.length - 2
      //   ].short_name;
      this.form.get("city").setValue(this.city_geo_point);
      this.data.city = this.form.get("city").value;
      // $('#pac-input').val(this.display_text);
      window.dispatchEvent(new Event("resize"));
    });
    // this.setAdministrativeLevel(this.data.city);
    setTimeout(() => {
      this.form
        .get("city")
        .setValue(this.data.city != null ? this.data.city : "");
    }, 300);
  }

  setAdministrativeLevel(city) {
    let autocompleteService = new google.maps.places.AutocompleteService();
    let request = { input: city != null ? city : "" };
    autocompleteService.getPlacePredictions(
      request,
      (predictionsArr, placesServiceStatus) => {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { placeId: predictionsArr[0].place_id },
          (results, status) => {
            if (status === "OK") {
              console.log("reverse geocode: ", results);
              if (results[0]) {
                results[0].address_components.forEach((item) => {
                  console.log(
                    item.types.includes("administrative_area_level_1"),
                    this.administrativeName
                  );
                  if (item.types.includes("administrative_area_level_1")) {
                    this.administrativeName = item.short_name;
                  }
                });
                console.log(results[0].address_components);
              }
            }
          }
        );
      }
    );
  }

  submit() {
    console.log("current form value");
    console.log(this.form);
    this.form.get("recommendation").markAsTouched();
    this.form.get("city").markAsTouched();
    console.log(this.data.city, this.form.get('city').value, "<-------------");
    console.log("data,in submit new properties =>", this.city_geo_point);
    console.log("adadministrativeName===>>", this.administrativeName);
    let city = this.form.get('city').value.split(",");
    console.log(city, city.length, "<---------city"); 
    console.log(this.form.valid && city.length > 1 && this.administrativeName == city[1] && this.administrativeName != null
      && this.administrativeName != '', this.form.valid , city.length > 1, this.administrativeName == city[1], this.administrativeName != null, this.administrativeName != '', this.administrativeName);
    if (this.form.valid && city.length > 1 && this.administrativeName != null
      && this.administrativeName != '') {
      let tempRecommendation = this.form.get("recommendation").value;
      if (tempRecommendation == undefined || tempRecommendation == null || tempRecommendation == "") {
        tempRecommendation = "3_terra";
      }
      let data = {
        lead_uuid: this.data.lead.uuid,
        lead_property_uuid: this.data.uuid,
        city: this.city_geo_point ? this.city_geo_point : "",
        state: this.administrativeName,
        address: this.form.get("address").value,
        recommended_capacity: parseInt(tempRecommendation.split("_")[0]),
        recommended_structure_type: tempRecommendation.substr(
          tempRecommendation.indexOf("_") + 1
        ),
        priority_level: this.priorityLead ? 1 : 0,
      };
      console.log("priority level ,in submit new properties =>", this.priorityLead, data);
      this.leadService
        .updateNewProperty(data)
        .subscribe(
          (val) => {
            if (val && val["status"]) {
              this.toast.success("PROPERTY UPDATED SUCCESSFULLY");
              this.leadService.refreshNewPropertyList.next(true);
              this.dialogRef.close();
              this.status.emit("success");
            } else {
              this.toast.error("ERROR OCCURED");
            }
          },
          (error) => {
            this.toast.error("Please re-fill city field");
          }
        );
    } else if (city.length <= 1 && this.administrativeName != city[1] ) {
      this.toast.error("PLEASE SELECT CITY FROM DROPDOWN.");
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
