import { TitleCasePipe } from "@angular/common";
import { Inject } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { AuthService } from "../../service/auth-service/auth.service";
import { MenuService } from "../../service/sidebar/menu.service";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  userDetails;
  userRoles = '';
  userTypeOptions = [{'value': '', 'label': '--', 'sub_type': []}];
  userType = '--';
  authPayload;
  loop = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private titleCasePipe: TitleCasePipe,
    private toast: MessageToastService,
    private menu: MenuService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log("data found", JSON.parse(this.authService.getUser()));
    this.authPayload = JSON.parse(this.authService.getAuthPayload());
    this.authPayload.forEach((user:  any) => {
      this.userTypeOptions.push({'value': user.user_type, 'label': this.titleCasePipe.transform(user.user_type.replace('_', ' ')), 'sub_type': user.user_roles});
    });
    console.log("user type options", this.userTypeOptions);
    this.userDetails = JSON.parse(this.authService.getUser());
    if(this.userDetails && this.userDetails.user_type) {
      this.userType = this.userDetails.user_type;
    }
    this.setUserRoles(this.userDetails['user_roles']);
  }

  update(event) {
    console.log("user updated: ", event);

    let value = event.value;
    if(value) {
      let userRoles;
      this.userTypeOptions.forEach((userType) => {
        if(userType.value == value) {
          userRoles = userType.sub_type;
        }
      });
      this.setUserRoles(userRoles);

      // ad-hoc code, will clean later
      this.authPayload.forEach((user:  any) => {
        if(user.user_type == value) {
          this.userDetails.user_type = value;
          this.userDetails.user_roles = user.user_roles;
          this.userDetails.default_access = user.default_access;
          this.userDetails.cluster_details = user.cluster_details;
          this.userDetails.user_details = JSON.parse(JSON.stringify(user.user_details));
        }
      });
    } 
  }

  udpateRole() {
    console.log("updating role");
    this.authPayload.forEach((user:  any) => {
      if(user.user_type == this.userType) {
        if (user.user_type == "amplus" && user.user_roles.length == 0) {
          this.toast.error("NO USER ROLES ASSIGNED");
        } else {
          let userData = {
            user_type: user.user_type,
            user_roles: user.user_roles,
            user_details: JSON.parse(JSON.stringify(user.user_details)),
            cluster_details: user.cluster_details ? user.cluster_details : "",
            default_access: user.access_default_stage[0],
            token: user.token
          };
          this.authService.setSession(userData);
          this.firstAccessibleURL();
          setTimeout(() => {
            window.location.reload();
          }, 300);
          this.toast.success("SUCCESSFULLY LOGGED IN");
        }
      }
    });
  }

  firstAccessibleURL() {
    let access: string;
    let accessiblUrl;

    // console.log('in the login component');
    let userInfo = JSON.parse(this.authService.getUser());
    if (userInfo.user_type == "amplus" || userInfo.user_type == "partner") {
      access = userInfo.user_roles[0];
    } else {
      access = userInfo.user_type;
    }

    this.loop = true;
    this.menu.getAll().forEach(group => {
      accessiblUrl = "/" + group.state;
      if (this.loop) {
        group.children.forEach(item => {
          if (this.loop) {
            if (
              item["roles"].indexOf(access) >= 0 &&
              (!item["visible"] || item["visible"] != "false")
            ) {
              accessiblUrl = accessiblUrl + "/" + item.state;
              console.log("navigating to " + accessiblUrl);
              this.router.navigateByUrl(accessiblUrl);
              this.loop = false;
            }
          }
        });
      }
    });
  }

  setUserRoles(userRoles) {
    this.userRoles = '';
    if(!(userRoles && userRoles.length > 0)) { return; }
    userRoles.forEach((role, index) => {
      this.userRoles += this.titleCasePipe.transform(role.split('_')[0]) + ' ' + this.titleCasePipe.transform(role.split('_')[1]);
      if(index != userRoles.length - 1) {
        this.userRoles += ', ';
      }
    });
  }

}
