import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { ChannelPartnerLogsComponent } from "src/app/common/component/channel-partner-logs/channel-partner-logs.component";
import { AssingClusterComponent } from "src/app/common/component/assing-cluster/assing-cluster.component";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { UserService } from "src/app/common/service/user-service/user.service";
const colMap = {
  Name: "name",
  "Phone Number": "phone_number",
  "Created On": "created_on",
};
@Component({
  selector: "app-channel-partner",
  templateUrl: "./channel-partner.component.html",
  styleUrls: ["./channel-partner.component.scss"],
})
export class ChannelPartnerComponent implements OnInit {
  items: string[] = [];
  selectedItems: string[] = [];
  selectAllCheckbox: boolean = false;
  checkboxVisibility: any;
  anyCheckboxChecked :any;
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  isCollapsed = false;
  isEditMode = false;
  clusterValue: any;
  salesPartnerList = [];
  channelPartnerList = [];
  globalSalesPartnerList = [];
  clusterList = [];
  form: FormGroup;
  filterForm: FormGroup;
  formValid: boolean = false;
  CHANNEL_PARTNER_STATUS = [
    {
      status: "Select Status",
      value: null,
    },
    {
      status: "Pending",
      value: "pending",
    },
    {
      status: "Processing",
      value: "processing",
    },
    {
      status: "Lost",
      value: "lost",
    },
    {
      status: "Converted",
      value: "converted",
    },
  ];
  sidenavText: String = "CREATE CHANNEL PARTNER";
  currentState = { page: 1 };
  cluster_partner_map = {};
  dummy_obj = {
    aadhar_card: null,
    address: null,
    city: null,
    cluster: null,
    company_name: null,
    country_code: 91,
    district: null,
    email: null,
    name: null,
    pan_card: null,
    phone_number: "9990901955",
    pincode: null,
    sales_partner: null,
    status: null,
    uuid: "9e313188-f982-44ea-a644-a75f91f48484",
  };
  clusterFetched = false;
  channelPartnerEditAccess = false;
  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {
    // this.items =['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];

    let user = JSON.parse(this.authService.getUser());
    this.channelPartnerEditAccess =
      this.userService.getChannelPartnerEditAccess(user);
    this.filterForm = this.fb.group({
      misc: [""],
      status: [""],
      sales_partner_uuid: [""],
      cluster_uuid: [""],
      sales_partner_check: [""],
    });
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      status: ["", Validators.required],
      cluster_uuid: [null],
      sales_partner_uuid: [null],
      phone_number: ["", Validators.required],
      country_code: ["+91", Validators.required],
      city: [""],
      source: ["Astral", Validators.required],
    });
    this.getChannelPartners(this.currentState);
    this.getClusterDetails();
    this.form.valueChanges.subscribe(() => {
      this.formValid = this.form.valid;

      if (this.clusterFetched) {
        // this.salesPartnerList = this.getSalesPartnerList(this.form.get('cluster_uuid').value().trim());
      }
    });

    this.menuToggle.toggleMenu(false);
    this.menuToggle.menuToggle.subscribe((val) => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    });
    this.createClusterParnterMap();
  }

  //to get checkbox Uuid function here

  toggleSelectAll(checked: boolean) {
    if (checked) {
      this.selectedItems = this.items.slice();
   console.log("selectedItems All checked"+this.selectedItems)
    } else {
      this.selectedItems = [];
    }
    this.updateSelectAllCheckbox();
    console.log("selectedItems all unchecked"+this.selectedItems)
  }

  toggleSelectOne(item: any, checked: boolean) {
    if (checked) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      }
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.updateSelectAllCheckbox();
  }

  updateSelectAllCheckbox() {
    this.selectAllCheckbox = this.items.every((item) =>
      this.selectedItems.includes(item)
    );
    console.log("selectedItems one"+this.selectedItems)
    const selectedItems_uuid = this.selectedItems
  
    if(selectedItems_uuid.length == 0){
      this.anyCheckboxChecked = false;
    } else{
      this.anyCheckboxChecked = true;
    }

    this.anyCheckboxChecked //= this.selectedItems
  }


  //end here


  // assignSalesPartner(selectedItems_uuid){
    
  //   this.clusterService.postAssignSalesPartner(selectedItems_uuid,cluster_uuid,lead_property_uuid) 

  // }

  downloadList() {
    console.log("request for downloading list");
    let downloadState = JSON.parse(JSON.stringify(this.currentState));
    delete downloadState["page"];
    console.log("current angular download object");
    console.log(downloadState);
    downloadState["download"] = true;
    this.clusterService.downloadListByUrl(
      this.clusterService.downloadChannelPartnerURL,
      downloadState
    );
  }

  createClusterParnterMap() {
    this.clusterService.getAllSalesPartnerList().subscribe((res: any) => {
      if (res.status) {
        console.log("sales partner list: ", res.payload);
        res.payload["data"].forEach((partner: any) => {
          let cluster_id = "";
          this.globalSalesPartnerList.push(partner);
          if (partner.cluster_list.length > 0) {
            partner.cluster_list.forEach((cluster: any) => {
              cluster_id = cluster["uuid"];
              if (
                Object.keys(this.cluster_partner_map).indexOf(cluster_id) >= 0
              ) {
                this.cluster_partner_map[cluster_id].push(partner);
              } else {
                this.cluster_partner_map[cluster_id] = [
                  { uuid: "", name: "Select a partner" },
                  partner,
                ];
              }
            });
          }
          this.clusterFetched = true;
        });
        this.cluster_partner_map[""] = [{ uuid: "", name: "Select a partner" }];
        console.log("final map created: ", this.cluster_partner_map);
      }
    });
  }
  onSort(event) {
    console.log(event);

    // if clicked column is not in the column list, we return
    if (Object.keys(colMap).indexOf(event["column"].name) < 0) {
      return;
    }

    this.currentState["column_name"] = colMap[event["column"].name];
    this.currentState["sort_order"] = event["newValue"];

    // ngx tables by default sends you to 1st page after sorting
    this.offset = 0;
    this.currentState["page"] = 1;

    this.getChannelPartners(this.currentState);
  }

  assingCluster() {
    //console.log("row clicked ", event.row);
    let dialogRef = this.dialog.open(AssingClusterComponent, {
      data: this.selectedItems 
    });
  }

  

  onActivate(event) {
    const clickedCellIndex = event.cellIndex;
    const clickedColumnName = event.column.name;

    if (this.channelPartnerEditAccess) {
      if (
        event.type === "click" &&
        ![
          "Checkbox",
          "Status",
          "Cluster",
          "Sales Partner(Manager)",
          "Actions",
        ].includes(clickedColumnName)
      ) {
        console.log("row clicked ", event.row);
        let dialogRef = this.dialog.open(ChannelPartnerLogsComponent, {
          data: event.row,
        });
      }
    } else {
      if (event.type == "click") {
        console.log("row clicked ", event.row);
        let dialogRef = this.dialog.open(ChannelPartnerLogsComponent, {
          data: event.row,
        });
      }
    }
  }

  clusterChange(event, row) {}

  selected() {
    console.log(
      "cluster value changed: ",
      this.clusterValue,
      this.salesPartnerList,
      this.cluster_partner_map
    );
    if (
      Object.keys(this.cluster_partner_map).indexOf(this.clusterValue.trim()) >=
      0
    ) {
      this.salesPartnerList =
        this.cluster_partner_map[this.clusterValue.trim()];
    } else {
      this.salesPartnerList = [{ uuid: "", name: "Select a partner" }];
    }
    console.log("sales partner after: ", this.salesPartnerList);
  }

  getClusterDetails() {
    this.clusterService.getClusterList().subscribe((result: any) => {
      if (result && result.status) {
        this.clusterList.push({ uuid: "", identifier: "Select a cluster" });
        result.payload.clusters.forEach((cluster: any) => {
          this.clusterList.push(cluster);
        });
      }
    });
  }

  getSalesPartnerList(cluster) {
    let cluster_id = "";
    if (cluster && cluster["uuid"]) {
      cluster_id = cluster["uuid"];
    }
    let ans = this.cluster_partner_map[cluster_id];
    return ans;
  }

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        this.clusterService.updateChannelPartner(this.form.value).subscribe(
          (result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("PARTNER UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = "CREATE CHANNEL PARTNER";
              this.getChannelPartners(this.currentState);
            } else {
              this.toast.error("SOMETHING WENT WRONG");
            }
          },
          (error: any) => {
            this.toast.error("SOMETHING WENT WRONG");
          }
        );
      } else {
        this.clusterService.createChannelPartner(this.form.value).subscribe(
          (result: any) => {
            if (result && result.status) {
              this.reset();
              this.toast.success("CHANNEL PARTNER CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.sidenavText = "CREATE PARTNER";
              this.getChannelPartners(this.currentState);
            } else {
              this.toast.error("SOMETHING WENT WRONG");
            }
          },
          (error: any) => {
            this.toast.error("SOMETHING WENT WRONG");
          }
        );
      }
    }
  }

  editAccount(row) {
    let clusterUUID = row.cluster ? row.cluster.uuid : null;
    let salesPartnerUUID = row.sales_partner ? row.sales_partner.uuid : null;

    if (clusterUUID != null) {
      this.salesPartnerList = this.cluster_partner_map[clusterUUID];
    }

    this.form.patchValue(
      {
        uuid: row.uuid,
        name: row.name,
        email: row.email,
        sales_partner_check: row.sales_partner_check,
        status: row.status,
        cluster_uuid: clusterUUID,
        sales_partner_uuid: salesPartnerUUID,
        phone_number: row.phone_number,
        country_code: "+91",
        city: row.city,
        source: row.source != null ? row.source : "Astral",
      },
      {
        emitEvent: false,
      }
    );
    this.sidenavBar.toggle();
    this.sidenavText = "EDIT CHANNEL PARTNER";
    this.isEditMode = true;
  }

  filterSubmit() {
    // this.currentState['misc'] = this.filterForm.value;
    Object.keys(this.filterForm.value).forEach((val) => {
      if (
        this.filterForm.value[val] != "" &&
        this.filterForm.value[val] != null
      ) {
        //  alert(this.filterForm.value[val])
        this.currentState[val] = this.filterForm.value[val];
      } else {
        delete this.currentState[val];
      }
    });
    this.getChannelPartners(this.currentState);
    this.clearState();
    this.sidenavBar.close();
  }

  filter() {
    this.sidenavBar.open();
    this.sidenavText = "FILTER";
  }

  clearForm() {
    // resetting form data
    this.resetForm();
    // removing form data from state, only keeping cluster_id, pagenum and sort data
    let newState = {
      page: this.currentState.page,
    };
    this.currentState = JSON.parse(JSON.stringify(newState));
    this.getChannelPartners(this.currentState);
  }

  clearState() {
    this.currentState["page"] = 1;
  }

  resetForm() {
    this.filterForm.patchValue({
      misc: "",
      status: "",
      sales_partner_uuid: "",
      cluster_uuid: "",
      sales_partner_check:"",
    });
  }

  // selectedSalesPartner(row) {
  //   console.log(row);
  //   this.clusterService.getSalesPartnerList(row).subscribe((result: any) => {
  //     this.salesPartnerList = result.payload.data;
  //   });
  // }

  dropdwnPartnerUpdate(event, row, type) {
    console.log(
      "partner change: ",
      event,
      row.sales_partner.uuid,
      row.cluster.uuid,
      row
    );

    if (type == "status" && !row.status) {
      this.toast.error("STATUS CANNOT BE BLANK");
      return;
    } else if (type == "cluster" && !(row.cluster && row.cluster.uuid)) {
      this.toast.error("CLUSTER CANNOT BE NULL");
      return;
    } else if (
      type == "partner" &&
      !(row.sales_partner && row.sales_partner.uuid)
    ) {
      this.toast.error("SALES PARTNER CANNOT BE NULL");
      return;
    }

    let data = {
      uuid: row.uuid,
      country_code: "+91",
    };
    //sales_partner_check
    if (row.name) {
      data["name"] = row.name;
    }
    if (row.sales_partner_check) {
      data["sales_partner_check"] = row.sales_partner_check;
    }
    if (row.cluster && row.cluster.uuid) {
      data["cluster_uuid"] = row.cluster.uuid;
    }
    if (row.status) {
      data["status"] = row.status;
    }
    if (row.sales_partner && row.sales_partner.uuid) {
      data["sales_partner_uuid"] = row.sales_partner.uuid;
    }

    this.updatePartner(data);
  }

  updatePartner(data) {
    console.log("data", data);
    this.clusterService.updateChannelPartner(data).subscribe(
      (result: any) => {
        if (result && result.status) {
          this.toast.success("PARTNER UPDATED SUCCESSFULLY");
          // this.getChannelPartners(this.currentState);
        } else {
          this.toast.error(result.message.toUpperCase());
        }
      },
      (error: any) => {
        this.toast.error("SOMETHING WENT WRONG");
      }
    );
  }

  getCurrentSalesParnter(partner) {
    if (partner) {
      return partner.uuid;
    }
    return "";
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe((data) => {
      if (data == "success") {
        this.clusterService
          .deleteChannelPartner(row.uuid)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("CLUSTER TECHNICIAN DELETED SUCCESSFULLY");
              this.reset();
              this.getChannelPartners(this.currentState);
            } else {
              this.toast.error("OPERATION FAILED");
            }
          });
      }
    });
  }

  reset() {
    this.form.reset();
    this.sidenavText = "CREATE CHANNEL PARTNER";
    this.form.get("country_code").setValue("+91");
    this.form.get("source").setValue("Astral");
    this.isEditMode = false;
  }

  getChannelPartners(data) {
    this.clusterService.getChannelPartners(data).subscribe((result: any) => {
      if (result && result.status) {
        this.checkboxVisibility = result.payload.checkbox;

        const parsedData = result;
        const data = parsedData.payload.data;
        this.items = data.map((item) => item.uuid);

        console.log("sales partner------->", result["sales_partner"]);
        result["sales_partner"] =
          result["sales_partner"] && result["sales_partner"] != ""
            ? result["sales_partner"]
            : "";

        this.channelPartnerList = [];
        result.payload.data.forEach((ele: any) => {
          if (ele.uuid) {
            this.channelPartnerList.push(
              this.updatePartnerObj(JSON.parse(JSON.stringify(ele)))
            );
          }
        });

        console.log("final list: ", this.channelPartnerList);

        // this.clusterAdminDetails = [...this.channelPartnerList];
        this.offset = 0;
      }
    });
  }

  updatePartnerObj(obj) {
    if (!obj.cluster) {
      obj.cluster = { uuid: "", identifier: "Select a cluster" };
    }
    if (!obj.email) {
      obj.email = "";
    }
    if (!obj.name) {
      obj.name = "";
    }
    if (!obj.sales_partner) {
      obj.sales_partner = { uuid: "", name: "Select a partner" };
    }
    obj.status = obj.status != null ? obj.status : "";
    return obj;
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {}
}
