import {Component, Input, Host } from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group.component';

@Component({
    selector: 'checkbox',
    template: `
    <div (click)="toggleCheck()" style="display: flex; align-items: center; margin-bottom: 10px;">
        <input type="checkbox" [checked]="isChecked()" style="margin-right: 10px; "/>
        <div style="margin-right: 20px;">
            <ng-content></ng-content>
        </div>
    </div>`
})
export class CheckboxNewComponent {
    @Input() value: any;

    constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {
    }

    toggleCheck() {
        this.checkboxGroup.addOrRemove(this.value);
    }

    isChecked() {
        return this.checkboxGroup.contains(this.value);
    }
}
