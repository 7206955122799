import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from '../field.interface';

@Component({
  selector: "app-select",
  template: `
<mat-form-field class="demo-full-width margin-top" [formGroup]="group">
<mat-select [(ngModel)]="display_text" [placeholder]="field.label" [formControlName]="field.name">
<mat-option *ngFor="let item of fieldModified.options" [value]="item.key">{{item.value}}</mat-option>
</mat-select>
<mat-error *ngIf="req.hasError('required')">This field is required</mat-error>
</mat-form-field>
<br/>
`,
  styles: []
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  fieldModified: any;
  group: FormGroup;
  access: string;
  display_text: any;
  validation: any;
  optionObject: any;
  req: any;

  constructor() { }

  ngOnInit() {
    console.log('DROPDOWN - printing the field');
    console.log("dropdown field--------------->", this.field);
    this.fieldModified = JSON.parse(JSON.stringify(this.field));
    if (this.fieldModified.value != null) {
      this.optionObject = { key: this.field.value, value: this.field.value };
    }
    if (!this.fieldModified.options.includes(this.optionObject)) {
      this.fieldModified['options'].push(this.optionObject);
    }
    const removeByKey = (arr, key) => {
      const requiredIndex = arr.findIndex(el => {
        return el.key === String(key);
      });
      if (requiredIndex === -1) {
        return false;
      };
      return !!arr.splice(requiredIndex, 1);
    };
    removeByKey(this.fieldModified.options, '');
    this.req = this.group.get(this.field.name);
    console.log("group.get(field.name)", this.req.value);

    if (this.field.value == "") {
      this.field.value = null;
      this.display_text = null;
    }
    else {
      this.display_text = this.field.value;
    }
  }
}
