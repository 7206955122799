import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AMCService } from 'src/app/common/service/amc/amc.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { UserService } from 'src/app/common/service/user-service/user.service';

@Component({
  selector: 'app-amc-payments',
  templateUrl: './amc-payments.component.html',
  styleUrls: ['./amc-payments.component.scss']
})
export class AmcPaymentsComponent implements OnInit {

  @Input() amcUuid: string;
  canEditPayment: boolean = false;

  logs: any;
  formGroup: FormGroup
  payment_mode = [{ key: "Cash", value: "cash" }, { key: "Card", value: "card" }, { key: "Cheque", value: "cheque" }]; // , { key: "Refund", value: "refund"}
  // canEditSR: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private amcService: AMCService,
    private toast: MessageToastService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      payment_medium: ["", Validators.required],
      payment_amount: ["", Validators.required],
      remarks: ["", Validators.required],
    });
    this.getPaymentLogs();
    let user = JSON.parse(this.authService.getUser());
    this.canEditPayment = this.userService.getPaymentsAccess(user);
    console.log("can edit sr ----->", this.canEditPayment);
  }

  addPayment() {
    if (this.formGroup.valid) {
      let data = JSON.parse(JSON.stringify(this.formGroup.value));
      data.property_amc_id = this.amcUuid;
      this.amcService.addPaymentLog(data).subscribe(
        (data: any) => {
          if (data) {
            if (data.status) {
              this.toast.success("Payment log added!");
              this.formGroup.reset();
              this.getPaymentLogs();
              this.amcService.refreshNotifier.next("all");
            } else {
              this.toast.error(data.message.toUpperCase());
            }
          }
        },
        (error: any) => {
          this.toast.error("OPERATION FAILED");
        }
      );
    } else {
      this.formGroup.markAllAsTouched();
      this.formGroup.get("payment_medium").markAsDirty();;
      this.formGroup.get("payment_amount").markAsDirty();
      this.formGroup.get("remarks").markAsDirty();
    }

  }

  getPaymentLogs() {
    this.amcService.getPaymentLogs({
      property_amc_id: this.amcUuid
    }).subscribe(
      (response: any) => {

        this.logs = [...response.payload.data];
        console.log("logs>>>>>>>", this.logs);
        let paymentMedium = response.payload.drop_down_data.payment_medium;
        this.payment_mode = [];
        this.payment_mode.push({
          key: "",
          value: ""
        });
        Object.keys(paymentMedium).forEach((m) => {
          this.payment_mode.push({
            key: m,
            value: paymentMedium[m]
          });
        });
        // this.payment_mode = response.payload.drop_down_data.payment_medium;
      },
      (error: any) => {

      }
    )
  }
  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toast.success("Copied Successfully!")
  }
}
