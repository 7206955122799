import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.scss']
})
export class UserPropertiesComponent implements OnInit {
  @Input() lead_id: string;
  
  constructor() { }

  ngOnInit() {
  }

}
