import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClusterService } from "../cluster/cluster.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AMCService {
  updateSRList = new Subject<boolean>();

  planListUrl = "v1/amc/plans/list";
  amcUrl = "v1/amc/property";
  assetListUrl = "v1/amc/assets/list";
  assetUrl = "v1/amc/assets";
  logsUrl = "v1/amc/log";
  paymentLogsUrl = "v1/amc/payment/log";
  addPaymentLogUrl = "v1/amc/payment";
  schedulerUrl = "/v2/amc/scheduler";
  holidayUrl = "/v2/amc/holiday/list";
  technicianSchedulerUrl = "/v2/amc/scheduler/technician";

  globalAssetListUrl = "v2/amc/asset/list";
  globalAssetUrl = "v2/amc/asset";
  searchAssetUrl = "v1/amc/spare/filter";

  serviceRequestListUrl = "v1/amc/ticket/list";
  serviceRequestUrl = "v1/amc/ticket";
  serviceRequestLogUrl = "v1/amc/ticket/log";
  serviceListUrl = "v1/amc/services";
  globalServiceListUrl = "v2/amc/ticket/list";
  ticketServiceUrl = 'v1/amc/ticket/service';
  globalServiceFilterUrl = 'v2/amc/ticket/filter/data';
  srConfig = 'v1/amc/config';

  technicianUrl = "v1/amc/technician";

  spareItemUrl = "v1/amc/spare/list";
  addSpareItemUrl = "/v1/amc/spare";

  postCommentUrl = "v1/amc/comment";
  commentListUrl = "v1/amc/comment/list";

  warrantyItemUrl = "v2/amc/module";
  warrantyItemListUrl = "v2/amc/module/list";
  searchWarrantyItem = "v2/amc/module/search";

  solarCreditLog = "v1/amc/solar_credits";
  addMilestonesTriggerUrl = "v1/amc/milestone";
  milestonesListUrl = "v1/amc/milestone/list";
  referralListUrl = "api/customer/referral/list";
  amcDashboardFilterUrl = 'v2/amc/global/filter/data';
  amcDashboardListUrl = 'v2/amc/global/list';
  amcAnalyticsReportUrl = 'v1/report/analytics/amc';
  downloadCertificateUrl = 'v1/amc/assets/certificate';
  downloadProposalUrl = '/v3/proposal/pdf';
  communicationAttempt = "v1/property/communication-attempt";
  refreshNotifier: Subject<String> = new Subject<String>();
  refreshNewAssetList = new Subject();

  constructor(
    private http: HttpClient,
    private clusterService: ClusterService
  ) { }


  gettestUrl(propertId) {
    return this.http.get(environment.serverURl + this.communicationAttempt + '?uuid=' + propertId);
  }

  communicationAttemptPut(data, propertId) {
    return this.http.put(environment.serverURl + this.communicationAttempt + '?uuid=' + propertId, data);

    //return this.http.put(environment.serverURl + this.communicationAttempt + '?uuid=' + propertId);
  }

  getSRConfig() {
    return this.http.get(environment.serverURl + this.srConfig);
  }

  getAddedServiceList(params) {
    return this.http.get(environment.serverURl + this.ticketServiceUrl, { params: params });
  }

  addTicketService(data) {
    return this.http.post(environment.serverURl + this.ticketServiceUrl, data);
  }

  deleteTicketService(params) {
    return this.http.delete(environment.serverURl + this.ticketServiceUrl, { params: params });
  }

  getDropdownServiceList(params) {
    return this.http.get(environment.serverURl + this.serviceListUrl, { params: params });
  }

  createGlobalAsset(data) {
    return this.http.post(environment.serverURl + this.globalAssetUrl, data);
  }

  updateGlobalAsset(data) {
    return this.http.put(environment.serverURl + this.globalAssetUrl, data);
  }

  getGlobalAssetList(params) {
    return this.http.get(environment.serverURl + this.globalAssetListUrl, { params: params });
  }

  getGlobalAsset(params) {
    return this.http.get(environment.serverURl + this.globalAssetUrl, { params: params });
  }

  deleteGlobalAsset(params) {
    return this.http.delete(environment.serverURl + this.globalAssetUrl, { params: params });
  }

  createAMC(data) {
    return this.http.post(environment.serverURl + this.amcUrl, data);
  }

  updateAMCDetails(data) {
    return this.http.put(environment.serverURl + this.amcUrl, data);
  }

  getAMCDetails(propertId) {
    return this.http.get(environment.serverURl + this.amcUrl + '?property_uuid=' + propertId);
  }

  getAMCPlans(params) {
    return this.http.get(environment.serverURl + this.planListUrl, { params: params });
  }

  getAssetList(params) {
    return this.http.get(environment.serverURl + this.assetListUrl, {
      params: params
    });
  }

  createAsset(data) {
    return this.http.post(environment.serverURl + this.assetUrl, data);
  }

  updateAsset(data) {
    return this.http.put(environment.serverURl + this.assetUrl, data);
  }

  deleteAsset(params) {
    return this.http.delete(environment.serverURl + this.assetUrl, {
      params: params
    });
  }

  deletePropertyAMC(params) {
    return this.http.delete(environment.serverURl + this.amcUrl, { params: params });
  }

  getAMCLogs(params) {
    return this.http.get(environment.serverURl + this.logsUrl, {
      params: params
    });
  }

  getAMCSchedular(params) {
    return this.http.get(environment.serverURl + this.schedulerUrl, { params: params });
  }

  getHolidayList(params) {
    return this.http.get(environment.serverURl + this.holidayUrl, { params: params });
  }
  createServiceRequest(data) {
    return this.http.post(environment.serverURl + this.serviceRequestUrl, data);
  }

  getServiceRequestList(params) {
    return this.http.get(environment.serverURl + this.serviceRequestListUrl, { params: params });
  }

  getGlobalServiceRequestList(params) {
    return this.http.get(environment.serverURl + this.globalServiceListUrl, { params: params });
  }

  getServiceRequestDetails(params) {
    return this.http.get(environment.serverURl + this.serviceRequestUrl, { params: params });
  }

  getGlobalServiceRequestFilterData(params) {
    return this.http.get(environment.serverURl + this.globalServiceFilterUrl, { params });
  }

  updateServiceRequest(data) {
    return this.http.put(environment.serverURl + this.serviceRequestUrl, data);
  }

  getPaymentLogs(params) {
    return this.http.get(environment.serverURl + this.paymentLogsUrl, {
      params: params
    });
  }

  addPaymentLog(data) {
    return this.http.post(environment.serverURl + this.addPaymentLogUrl, data);
  }

  getSpareItemList(params) {
    return this.http.get(environment.serverURl + this.spareItemUrl, { params: params });
  }

  addSpareItem(data) {
    return this.http.post(environment.serverURl + this.addSpareItemUrl, data);
  }

  deleteSpareItem(params) {
    return this.http.delete(environment.serverURl + this.addSpareItemUrl, {
      params: params
    });
  }

  searchAsset(params) {
    return this.http.get(environment.serverURl + this.searchAssetUrl, { params: params });
  }

  postComment(data) {
    return this.http.post(environment.serverURl + this.postCommentUrl, data);
  }

  getTicketComments(params) {
    return this.http.get(environment.serverURl + this.serviceRequestLogUrl, {
      params: params
    });
  }

  addWarrantyItem(data) {
    return this.http.post(environment.serverURl + this.warrantyItemUrl, data);
  }

  updateWarrantyItem(data) {
    return this.http.put(environment.serverURl + this.warrantyItemUrl, data);
  }

  deleteWarrantyItem(params) {
    return this.http.delete(environment.serverURl + this.warrantyItemUrl, {
      params: params
    });
  }

  getWarrantyList(params) {
    return this.http.get(environment.serverURl + this.warrantyItemListUrl, {
      params: params
    });
  }

  searchWarrantyList(params) {
    return this.http.get(environment.serverURl + this.searchWarrantyItem, {
      params: params
    });
  }


  getSolarCreditLogs(params) {
    return this.http.get(environment.serverURl + this.solarCreditLog, {
      params: params
    });
  }

  addMilestonesTrigger(data) {
    return this.http.post(environment.serverURl + this.addMilestonesTriggerUrl, data);
  }


  getMilestonesList(params) {
    return this.http.get(environment.serverURl + this.milestonesListUrl, {
      params: params
    });
  }

  getReferralList(params) {
    return this.http.get(environment.serverURl + this.referralListUrl, {
      params: params
    });
  }

  updateSRLists() {
    this.updateSRList.next(true);
  }

  getAMCDashboardList(params) {
    return this.http.get(environment.serverURl + this.amcDashboardListUrl, { params: params });
  }
  getAMCDashboardFilterData() {
    return this.http.get(environment.serverURl + this.amcDashboardFilterUrl);
  }

  getAMCAnalyticsReport(params) {
    return this.http.get(environment.serverURl + this.amcAnalyticsReportUrl, { params: params });
  }

  updateAMCSchedular(data) {
    return this.http.put(environment.serverURl + this.schedulerUrl, data);
  }

  getTechnician(params) {
    return this.http.get(environment.serverURl + this.technicianSchedulerUrl, { params: params });
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }

  downloadListByUrl(url, data, name) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          name + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.xls'
        );
      });
  }

  downloadCertificateByUrl(url, data, name) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        headers: {
          'Content-type': 'application/pdf',
        },
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        console.log("response in download certificate by url ->", res);
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/pdf',
          name + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.pdf'
        );
      });
  }
}
