import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AMCService } from "../../service/amc/amc.service";
import { DatePipe } from "@angular/common";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: 'app-create-asset',
  templateUrl: './create-asset.component.html',
  styleUrls: ['./create-asset.component.scss']
})
export class ManageAssetComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  type_options = [{ 'type': 'Select', 'subtype': [''] }, { 'type': 'Inverters', 'subtype': ['', 'random', 'check'] }, { 'type': 'Modules', 'subtype': ['', 'random1', 'empty123'] }, { 'type': 'Structure', 'subtype': ['', 'random2', 'newcheck'] }, { 'type': 'BOS', 'subtype': ['', 'random2', 'newcheck'] }];
  make_options = [];
  specification_options = [];
  bool_options = [{ 'type': 'True' }, { 'type': 'False' }];
  assets_info = [];
  update_asset = false;
  module_item_list: any;
  dateOfPurchase: any;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private amcService: AMCService,
    private datepipe: DatePipe,
    public dialogRef: MatDialogRef<ManageAssetComponent>,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      property_uuid: [this.data.property_uuid, Validators.required],
      category: ["", Validators.required],
      make: ["", Validators.required],
      date_of_purchase: [this.datepipe.transform(new Date(), 'yyyy-MM-dd'),],
      serial_no: [""],
      specification: ["", Validators.required]
    });



    if (Object.keys(this.data.row).length > 0) {
      this.update_asset = true;
      this.patchForm();
    } else {
      this.form.get("category").valueChanges.subscribe((val) => {
        this.getWarrantyItemList({ category: val.toLowerCase() });
      });

      this.form.get("make").valueChanges.subscribe((val) => {
        this.selectMakeHandler(val);
      });

      this.form.get("specification").valueChanges.subscribe((val) => {
        this.selectSpecHandler(val);
      });
    }

  }

  patchForm() {
    let data = this.data.row;

    this.form.patchValue({
      property_uuid: data.uuid,
      category: data.warranty_module.category,
      make: data.warranty_module.make,
      specification: data.warranty_module.specification,
      serial_no: data.serial_no,
      date_of_purchase: data.date_of_purchase
    });
  }

  create() {
    if (this.form.valid) {
      if (this.assets_info.length <= 0 && !this.update_asset) {
        this.toast.error("YOU HAVE NOT ADDED ANY ASSET INFO");
        return;
      }
      let data = JSON.parse(JSON.stringify(this.form.value));
      data['date_of_purchase'] = this.datepipe.transform(data['date_of_purchase'], 'yyyy-MM-dd');

      let params = [];
      this.assets_info.forEach((item) => {
        params.push({
          lead_property_id: this.data.property_uuid,
          warranty_module_id: item.specification,
          serial_no: item.sr_number,
          date_of_purchase: this.datepipe.transform(item.date_obj, 'yyyy-MM-dd')
        });
      });
      if (this.update_asset) {
        data['assets_uuid'] = data['property_uuid'];
        delete data['property_uuid'];
        console.log('sending udpate package');
        console.log(data);
        this.amcService.updateAsset(data).subscribe((val: any) => {
          if (val && val['status']) {
            console.log('API result');
            console.log(val);
            this.status.emit("success");
            setTimeout(() => { this.dialogRef.close(); }, 300);
          }
        });
      } else {
        this.amcService.createAsset(params).subscribe((val: any) => {
          if (val && val['status']) {
            console.log('API result');
            console.log(val);
            this.toast.success("SUCCESSFULLY CREATED THE ASSET");
            this.status.emit("success");
            setTimeout(() => { this.dialogRef.close(); }, 300);
          }
        });
      }
    } else {
      this.toast.error("INVALID FORM - PLEASE FILL MANDATORY FIELDS");
    }
  }

  selectHandler(val) {
    this.make_options = [];
    this.specification_options = [];
    this.module_item_list.forEach(option => {
      if (option.category.toLowerCase() == val.toLowerCase()) {
        this.make_options.push(option.make);
      }
    });
    this.make_options = this.make_options.filter(
      (element, i) => i === this.make_options.indexOf(element)
    );
    this.form.get('make').setValue("");
    this.form.get('specification').setValue("");
  }

  selectMakeHandler(val) {
    this.form.get('date_of_purchase').setValue(val.date_of_purchase);
    this.specification_options = [];
    this.module_item_list.forEach(option => {
      if (option.make.toLowerCase() == val.toLowerCase()) {
        this.specification_options.push({
          label: option.specification,
          value: option.uuid
        });
      }
    });

    this.form.get('specification').setValue("");
  }

  selectSpecHandler(val) {
    console.log(val, "<----val in select spec handler");
    this.module_item_list.forEach(option => {
      console.log("option.uuid.toLowerCase()", option.uuid.toLowerCase(), "val.toLowerCase()", val.toLowerCase(), option.uuid.toLowerCase() == val.toLowerCase());
      if (option.uuid.toLowerCase() == val.toLowerCase()) {
        this.dateOfPurchase = option.date_of_purchase;
        if (this.dateOfPurchase == null) {
          this.form.get('date_of_purchase').setValue(this.datepipe.transform(new Date(), 'yyyy-MM-dd'));
        }
        else {
          this.form.get('date_of_purchase').setValue(this.dateOfPurchase);
        }
      }
    });
  }
  removeAssetInfo(info) {
    this.assets_info = this.assets_info.filter(obj => obj !== info);
  }


  editAssetInfo(info) {
    this.assets_info = this.assets_info.filter(obj => obj !== info);
    this.form.patchValue({
      date_of_purchase: info.date_obj,
      serial_no: info.sr_number
    });
  }

  addAssetInfo() {
    if (!this.form.valid) {
      this.toast.error("INVALID FORM - PLEASE FILL MANDATORY FIELDS");
      return;
    }
    if (this.form.get("serial_no").value == "" || this.form.get("date_of_purchase").value == "") {
      this.toast.error("INVALID FORM - Sr. No & Date both are required");
      return;
    }
    this.assets_info.push({
      'specification':this.form.get("specification").value,
      //'category': this.form.get("category").value,
      'sr_number': this.form.get("serial_no").value,
      'date': this.getDate(this.form.get("date_of_purchase").value),
      'date_obj': this.form.get("date_of_purchase").value
    });
    this.form.patchValue({
      date_of_purchase: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      serial_no: ""
    });
    this.assets_info = [...this.assets_info];
  }

  getDate(dateObj) {
    return this.datepipe.transform(dateObj, 'yyyy/MM/dd');
  }


  getWarrantyItemList(params) {
    this.amcService.searchWarrantyList(params).subscribe(
      (response: any) => {
        this.module_item_list = response.payload.data;
        this.selectHandler(params.category);
        console.log(">>>>>>>>>>>>", this.module_item_list);
      }
    );
  }

}
