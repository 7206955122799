import { Component, Inject ,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { ClusterService } from "../../service/cluster/cluster.service";

@Component({
  selector: "app-assing-cluster",
  templateUrl: "./assing-cluster.component.html",
  styleUrls: ["./assing-cluster.component.scss"],
})
export class AssingClusterComponent implements OnInit {
  clusterList = [];
  salesPartnerList = [];
  selectedItems_uuid :any;
  lead_property_uuid: any;
  salesPartnerUuid: any;
  salesPartnerCount: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clusterService: ClusterService,
    private dialogRef: MatDialogRef<AssingClusterComponent> ,
    private toast: MessageToastService,
  ) {
    console.log('Received data:', data); // Access the array data here

    this.selectedItems_uuid =data
  }


  

  ngOnInit() {
    this.getClusterList();
  }

  getClusterList() {
    this.clusterService.getClusterList().subscribe((val: any) => {
      console.log("global cluster list found out");
      console.log(val);
      if (val && val["status"]) {
        this.clusterList = [...val.payload.clusters];
        console.log("final cluster list");
      }
    });
  }

  dropdwnPartnerUpdate(event) {
    this.lead_property_uuid = event
    this.clusterService.getSalesPartnerListUuid(event).subscribe((val: any) => {
      if (val && val["status"]) {
        this.salesPartnerList = val.payload.data;
        this.salesPartnerCount = val.payload.data.length
      }
    });
  
  }

  dropdwnPartnerName(event) {
    //alert(event);
    this.salesPartnerUuid = event;
    // this.clusterService.getSalesPartnerListUuid(event).subscribe((val: any) => {
    //   if (val && val["status"]) {
    //     this.salesPartnerList = val.payload.data;
    //   }
    // });
  
  }

  assignSalesPartner() {

    this.clusterService.postAssignSalesPartner(this.selectedItems_uuid,this.salesPartnerUuid,this.lead_property_uuid).subscribe((res: any) => {
      if (res.status) {
        this.dialogRef.close();
        this.toast.success(res.message);
        window.location.reload();

      }else {
        this.toast.error(res.message);
      }


    }) 
  }


  onCloseClick(): void {
    this.dialogRef.close();
  }


  
}
