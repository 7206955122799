import { Component, Input, OnInit } from '@angular/core';
import { AMCService } from 'src/app/common/service/amc/amc.service';

@Component({
  selector: 'app-solar-credit',
  templateUrl: './solar-credit.component.html',
  styleUrls: ['./solar-credit.component.scss']
})
export class SolarCreditComponent implements OnInit {

  @Input() property_uuid: string;
  logs:any;

  constructor(
    private amcService: AMCService
  ) { }

  ngOnInit() {
    this.getSolarLogs();
  }

  getSolarLogs() {
    this.amcService.getSolarCreditLogs({
      lead_property_id: this.property_uuid
    }).subscribe((response: any)=>{
        this.logs = response.payload;        
    })
  }

}
