import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { LeadService } from "src/app/common/service/lead/lead.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { UserService } from 'src/app/common/service/user-service/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  lead_uuid: String = '';
  uuid: String = '';
  uuid1= '';
  selected_tab = 0;
  recommendationList = [];
  propertyList = [];
  hasViewAccess = false;

  constructor(
    private route: ActivatedRoute,
    private menuToggle: MenuToggleService,
    private leadService: LeadService,
    private authservice: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    let user = JSON.parse(this.authservice.getUser());
    this.hasViewAccess = this.userService.getReferralAccess(user);
    this.menuToggle.toggleMenu(false);
    this.route.queryParams.subscribe(params => {
      this.lead_uuid = params['id'];
      this.uuid = params['lead_uuid'];
      this.uuid1= params['leadPropertyUUID'];
      this.getRecommendationList();
      this.getPropertyList();
    });
  }

  getRecommendationList() {
    this.leadService.getLeadRecommendationList({ uuid: this.uuid1 }).subscribe((val: any) => {
      if(val && val.status) {
        this.recommendationList = val.payload.data;
      }
    });
  }

  getPropertyList(){
    this.leadService.getLeadPropertyList({ uuid: this.uuid1 }).subscribe((val: any)  => {
      if(val && val.status) {
        this.propertyList = val.payload.data;
      }
    });
  }


  onTabChange(event) {
    this.selected_tab = event.index;
  }

}
