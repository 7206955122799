import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";
declare var $: any;

@Component({
  selector: "app-float",
  template: `
    <mat-form-field class="demo-full-width" [formGroup]="group">
      <input
        matInput
        id="{{ field.name }}"
        [formControlName]="field.name"
        [placeholder]="field.label"
        type="text"
      />
      <mat-error *ngIf="group.get(field.name).hasError('required')"
        >{{ field.label }} is required</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('max')"
        >{{ field.label }} exceeds the maximum value of {{group.get(field.name).errors.max['max']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('min')"
        >{{ field.label }} is less than the minimum value of {{group.get(field.name).errors.min['min']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('maxlength')"
        >{{ field.label }} exceeds the maximum length of {{group.get(field.name).errors.maxlength['requiredLength']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('minlength')"
        >{{ field.label }} is less than the minimum length of {{group.get(field.name).errors.minlength['requiredLength']}}</mat-error
      >
    </mat-form-field>
    <br />
  `,
  styleUrls: ["./float.component.scss"]
})
export class FloatComponent implements OnInit, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  firstDecimal: boolean = false;

  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    $("#" + this.field.name)
      .keypress(function(event) {
        if (
          (event.which != 46 ||
            (event.which == 46 && $(this).val() == "") ||
            $(this)
              .val()
              .indexOf(".") != -1) &&
          (event.which < 48 || event.which > 57)
        ) {
          event.preventDefault();
        }
      })
      .on("paste", function(event) {
        event.preventDefault();
      });
  }
}
