import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2
} from "@angular/core";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";

@Directive({
  selector: '[appMarkChannelPartner]'
})
export class MarkChannelPartnerDirective {
  @Input("popup-tooltip") popupTooltipTitle: string;
  @Input() placement: string = "top";
  @Input() delay: string = "300";
  @Input() data: any;
  @Input() day: any;
  selectedFirstLabel = null;
  selectedSecondLabel = null;
  label = [
    {
      title: "Yes,I'm",
      action: "Converting...",
      src: 'assets/icon/present.png'
    },
    {
      title: "No,Cancel",
      action: "Saving",
      src: 'assets/icon/absent.png'
    }
  ];
  private popupTooltip: HTMLElement;
  private offset = 10;
  private isMarked: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private clusterService: ClusterService,
    private leadService: LeadService,
    private toast: MessageToastService
  ) {
    console.log("#DirectiveInit", el);
  }

  @HostListener("click", ["$event.target"]) onClick(element) {
    console.log("#DirectiveInit", "clicked");
    if (!this.isMarked) {
      this.show();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.popupTooltip, "ng-popup-tooltip-show");
    this.popupTooltip.hidden = false;
  }

  hide() {
    this.renderer.removeClass(this.popupTooltip, "ng-popup-tooltip-show");
    this.popupTooltip.hidden = true;
  }

  create() {
    this.popupTooltip = this.renderer.createElement("div");

    //Accept Button
    let acceptB = this.renderer.createElement("button");
    this.selectedFirstLabel = this.label[0];
    acceptB.innerHTML = this.selectedFirstLabel.title;
    acceptB.classList.add("btn");
    acceptB.classList.add("btn-success");
    acceptB.classList.add("popup-action-btn");
    acceptB.setAttribute("uuid", this.data.user_uuid);
    acceptB.addEventListener("click", () => {
      acceptB.innerHTML = this.selectedFirstLabel.action;
      this.convertLeadToPartner(acceptB);
    });

    //Absent Button
    let rejectB = this.renderer.createElement("button");
    this.selectedSecondLabel = this.label[1];
    rejectB.innerHTML = this.selectedSecondLabel.title;
    rejectB.classList.add("btn");
    rejectB.classList.add("btn-outline-danger");
    rejectB.classList.add("mr-4");
    rejectB.classList.add("popup-action-btn");
    rejectB.addEventListener("click", () => {
      this.hide();
    });

    let btnWrapper = this.renderer.createElement("div");
    btnWrapper.appendChild(rejectB);
    btnWrapper.appendChild(acceptB);
    this.popupTooltip.appendChild(
      this.renderer.createText(this.popupTooltipTitle)
    );
    this.renderer.appendChild(this.popupTooltip, btnWrapper);

    setTimeout(() => {
      this.popupTooltip.id = "mark-channel-partner-popup";
    }, 200);
    this.renderer.appendChild(document.body, this.popupTooltip);

    this.renderer.addClass(this.popupTooltip, "ng-popup-tooltip");
    this.renderer.addClass(
      this.popupTooltip,
      `ng-popup-tooltip-${this.placement}`
    );

    this.renderer.setStyle(
      this.popupTooltip,
      "-webkit-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.popupTooltip,
      "-moz-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.popupTooltip,
      "-o-transition",
      `opacity ${this.delay}ms`
    );
    this.renderer.setStyle(
      this.popupTooltip,
      "transition",
      `opacity ${this.delay}ms`
    );
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.popupTooltip.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "bottom") {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.popupTooltip, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.popupTooltip, "left", `${left}px`);
  }

  convertLeadToPartner(element) {
    if(!this.popupTooltip.classList.contains('ng-popup-tooltip-show')) {
      return;
    }
    this.clusterService
        .convertLeadToChannelPartner({uuid: this.data})
        .subscribe((result: any) => {
          if (result && result.status) {
              this.toast.success("LEAD CONVERTED TO CHANNEL PARTNER");
              this.leadService.refreshNewPropertyList.next("lead-notifier");
              this.hide();
          } else {
            element.innerHTML = this.selectedFirstLabel.title;
            this.toast.error(result.message.toUpperCase())
          }
        }, (error:any) => {
          console.log("uuid: -------------->", this.data);
          element.innerHTML = this.selectedFirstLabel.title;
          this.toast.error("SOMETHING WENT WRONG")
        });
  }
}
