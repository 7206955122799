import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadService } from 'src/app/common/service/lead/lead.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { FormBuilder } from '@angular/forms';
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { LoggerService } from 'src/app/common/service/logger/logger.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { LeadActivationModalComponent } from 'src/app/common/component/lead-activation-modal/lead-activation-modal.component';
import { Subscription } from 'rxjs';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { DeleteModalComponent } from 'src/app/common/component/delete-modal/delete-modal.component';
import { AddClusterComponent } from 'src/app/common/component/add-cluster/add-cluster.component';
import { CreateActivityLogComponent } from 'src/app/common/component/create-activity-log/create-activity-log.component';
import { SetReminderComponent } from 'src/app/common/component/set-reminder/set-reminder.component';
import { ActivityLogListComponent } from '../activity-log-list/activity-log-list.component';

declare var $: any;

const colMap = {
  Name: 'lead__first_name',
  'Phone Number': 'lead__phone_number_1',
  Address: 'address',
  Identifier: 'identifier',
  'Current Stage': 'current_stage',
  'Last Updated On': 'updated_on',
  Source: 'lead__lead_source',
  'Lead Status': 'lms_status',
  'Last Updated Stage': 'current_stage'
};

@Component({
  selector: 'app-pre-sales-lead',
  templateUrl: './pre-sales-lead.component.html',
  styleUrls: ['./pre-sales-lead.component.scss']
})
export class PreSalesLeadComponent implements OnInit {

  rows = [];
  count = 0;
  offset = 0;
  limit: number;
  leadList = [];
  listSize: number;
  defaultAccess = 'view';
  form: any;
  stageList: any = [];
  clusterList: any = [];
  search_text: any = [];
  salesAgent: any = [];
  epcAgent: any = [];
  formData: any = {};
  isCollapsed: boolean = false;
  isTechnicalUser: boolean = false;
  clusterView: boolean = false;
  selectedCluster: any;
  sortData: any;
  queueDropdown: any = [];
  isBusinessUser = false;
  isPartner = false;
  reassignCluster = false;
  isAdminUser = false;
  random_exp = -1;
  lmsStatusList: any = [];
  executiveList: any = [];
  designerList: any = [];
  discomeList: any = [];
  cityList: any = [];
  leadSourceList = [];
  pageCount = 0;
  subscriptionList = new Subscription();
  isFormValid = false;
  maxDate = new Date();

  // this will store data of filter, sort, cluster_id and page change
  currentState = { cluster_id: '', page: 1, column_name: '', sort_order: '' };

  queueStatusMap = [
    { key: 1, value: 'Running' },
    { key: 0, value: 'Idle' }
  ];

  accessList = [
    { name: 'Select an access level', value: '' },
    { name: 'View', value: 'view' },
    { name: 'Edit', value: 'edit' }
  ];

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  constructor(
    private leadService: LeadService,
    private authService: AuthService,
    private fb: FormBuilder,
    private clusterService: ClusterService,
    private dialog: MatDialog,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) {}

  ngOnInit() {
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });

    let user = this.authService.getUser();

    this.isTechnicalUser =
      JSON.parse(user)['user_roles'].indexOf('amplus_technical') >= 0
        ? true
        : false; 

    this.isBusinessUser =
      JSON.parse(user)['user_roles'].indexOf('amplus_business') >= 0
        ? true
        : false;
    this.isAdminUser =
      JSON.parse(user)['user_roles'].indexOf('amplus_admin') >= 0
        ? true
        : false;
    this.reassignCluster =
      this.isAdminUser && this.isBusinessUser ? true : false;
    this.isPartner = JSON.parse(user)['user_type'] == 'partner' ? true : false;

    this.defaultAccess = JSON.parse(user).default_access;

    this.form = this.fb.group({
      cluster_id: '',
      prev_stage_id: '',
      cur_stage_id: '',
      access: '',
      text: '',
      sales_agent_id: '',
      epc_agent_id: '',
      active_status: false,
      lms_status: '',
      executive_id: '',
      source:'',
      cluster_independent: false,
      date_from: 0,
      date_to: 0,
      recommended: false,
      last_activity_log_date_to: 0,
      last_activity_log_date_from: 0,
      first_activity_log_date_to: 0,
      first_activity_log_date_from: 0,
      designer_id: '',
      discom: '',
      city: ''
    });

    this.getFilterData();
    this.getLeadDetails(this.currentState);
    // this.subscriptionList.add(
    //   this.clusterService.globalCluster.subscribe(val => {
    //     if (val) {
    //       // this.selectedCluster = val;
    //       // this.form.get('cluster_id').setValue(this.selectedCluster.uuid);

    //       // this.currentState['cluster_id'] = this.selectedCluster.uuid;

          

    //       $('.funnel-sidenav').click();
    //       this.submit('cluster_change');
    //     }
    //   })
    // );
  }

  highlightRows() {
    Array.from(document.getElementsByTagName("datatable-body-row")).forEach(
      (row, idx) => {
        if (
          this.leadList[idx].is_recommended &&
          !row.classList.contains("highlight-row")
        ) {
          row.classList.add("highlight-row");
        } else if (
          !this.leadList[idx].is_recommended &&
          row.classList.contains("highlight-row")
        ) {
          row.classList.remove("highlight-row");
        }
      }
    );
  }


  getFilterData() {
    this.leadService.getPreSalesFilterData().subscribe(val => {
      this.stageList = [];
      this.stageList.push({ uuid: '', display_name: 'Select a stage' });
      this.stageList.push(...val['payload'].stages);

      this.epcAgent = [];
      this.epcAgent.push({ uuid: '', name: 'Select an epc agent' });
      this.epcAgent.push(...val['payload'].epc_partners);

      this.salesAgent = [];
      this.salesAgent.push({ uuid: '', name: 'Select a sales agent' });
      this.salesAgent.push(...val['payload'].sales_partners);

      this.lmsStatusList = [];
      this.lmsStatusList.push({ uuid: '', value: 'Select' });
      val['payload'].lms_status.forEach(status => {
        this.lmsStatusList.push({ uuid: status, value: status });
      });

      this.executiveList = [];
      this.executiveList.push({ uuid: '', name: 'Select' });
      this.executiveList.push(...val['payload'].executives);

      this.designerList = [];
      this.designerList.push({ uuid: '', name: 'Select' });
      this.designerList.push(...val['payload'].designers);

      this.discomeList = [];
      this.discomeList.push('');
      this.discomeList.push(...val['payload'].discom);

      this.cityList = [];
      this.cityList.push({ label: 'Select a city', value: '' });
      val['payload']['cities'].forEach((ele: any) => {
        this.cityList.push({label: ele, value: ele});
      });
      this.leadSourceList = [];
      this.leadSourceList.push(...val['payload'].lead_sources);

      this.clusterList = [];
      this.clusterList.push({ label: 'Any Cluster', value: '' });
      val['payload']['clusters'].forEach((ele: any) => {
        this.clusterList.push({label: ele.identifier, value: ele.uuid});
      });
      this.clearForm();
    });
  }

  combineNameAndNumber(data) {
    let option_text: string;
    if (data.number) {
      option_text = data.name + ' (' + <string>data.number + ')';
    } else {
      option_text = data.name;
    }
    return option_text;
  }

  onActivate(event) {
    console.log("event.cellIndex", event.cellIndex);
    if (event.type == "click" && event.cellIndex != 7 && event.cellIndex != 8 && event.cellIndex != 9 && event.cellIndex != 13 && event.cellIndex != 10) {
      console.log('**** event found out');
      console.log(event);
      setTimeout(() => {
        window.open('lead/view/stage-view?id=' + event.row.uuid);
      }, 300);
    }
  }

  getLeadDetails(data) {
    console.log('data sending for getting list');
    console.log(data);
    this.leadService.getPreSalesLeadList(data).subscribe((result: any) => {
      if (result && result.status) {
        this.pageCount = result['payload'].data.length;
        result.payload.data.forEach(val => {
          val['full_name'] =
            val.lead.first_name +
            (val.lead.last_name != '' ? ' ' + val.lead.last_name : '');

          if (Object.keys(val['executive']).length > 0) {
            val['executive'].name =
              val['executive'].name && val['executive'].name != ''
                ? val['executive'].name
                : '';
          } else {
            val['executive'] = { uuid: '', name: '' };
          }

          val['lms_status'] =
            val['lms_status'] && val['lms_status'] != ''
              ? val['lms_status']
              : '';

          val['phone_number'] =
            val.lead.phone_number_1 +
            (val.lead.phone_number2 ? ' , ' + val.lead.phone_number2 : '');

          val['current_stage'] = val.current_stage.display_name;
        });
        this.queueDropdown = result.payload.queue_status_drop_down;
        this.leadList = [...result.payload.data];
        this.limit = result.payload.data_per_page;
        this.count = result.payload.count;
        this.offset = data['page'] - 1;
        setTimeout(() => {
          this.highlightRows();
        }, 300);
      }
    });
  }

  onPage(event) {
    this.currentState['page'] = event.offset + 1;

    this.offset = event.offset;

    this.getLeadDetails(this.currentState);
  }

  editLead(event) {
    setTimeout(() => {
      window.open('lead/view/stage-view?id=' + event.uuid);
    }, 300);
  }

  propertyGeneration(event) {
    setTimeout(() => {
      window.open('lead/view/property-generation?id=' + event.uuid);
    }, 300);
  }

  configureIot(event) {
    setTimeout(() => {
      window.open('lead/view/configure-iot?id=' + event.uuid);
    }, 300);
  }
  configureAMC(event) {
    setTimeout(() => {
      window.open('lead/view/amc?id=' + event.uuid);
    }, 300);
  }

  activateProperty(event) {
    if (this.isTechnicalUser) {
      let dialogRef = this.dialog.open(LeadActivationModalComponent, {
        data: { activation_status: event.activation_status, uuid: event.uuid }
      });

      dialogRef.componentInstance.status.subscribe(data => {
        if (data == 'success') {
          this.getLeadDetails(this.currentState);
        }
      });
    }
  }

  openMap(event) {
    window.open('lead/map-view/' + event.uuid);
  }

  addProperty() {
    window.open('lead/view/stage-view?id=create');
  }

  sidenavToggle() {
    this.sidenavBar.open();
  }

  onSort(event) {
    console.log(event);

    // if clicked column is not in the column list, we return
    if (Object.keys(colMap).indexOf(event['column'].name) < 0) {
      return;
    }

    this.currentState['column_name'] = colMap[event['column'].name];
    this.currentState['sort_order'] = event['newValue'];

    // ngx tables by default sends you to 1st page after sorting
    this.offset = 0;
    this.currentState['page'] = 1;

    this.getLeadDetails(this.currentState);
  }

  checkFormValidity() {
    let validity = true;
    Object.keys(this.form.controls).forEach((key) => {
      console.log("checking ", key, "errortype ", ( this.form.get(key).errors != null && this.form.get(key).errors.length > 0));
      if(key != 'date_from' && key != 'date_to' && ( this.form.get(key).errors != null && this.form.get(key).errors.length > 0)) {
        validity = false;
      }
    });
    this.isFormValid = validity;
  }

  // submit function
  submit(mode = 'normal') {
    this.checkFormValidity();
    const clusterEnabled = !this.form.get("cluster_independent").value;
    console.log("filter form ", this.form);
    // when filter, sorting or page change occurs
    if (mode != 'cluster_change') {
      let dateFields = [ 'last_activity_log_date_to',
              'last_activity_log_date_from',
              'first_activity_log_date_to',
              'first_activity_log_date_from',];
      Object.keys(this.form.value).forEach(val => {
        console.log(val);
        if ( (dateFields.indexOf(val) >= 0) && this.form.get(val).value != 0 && this.form.get(val).value != null && this.form.get(val).value != '') {
          this.currentState[val] = Math.floor(this.form.get(val).value.getTime() / 1000);
        } else if (this.form.value[val] != '' && this.form.value[val] != null) {
          this.currentState[val] = this.form.value[val];
        } 
        else {
          delete this.currentState[val];
        }
      });
      console.log("currentState>>>>>>", this.currentState);
      // if(!clusterEnabled && Object.keys(this.currentState).indexOf('cluster_id') >= 0 ) {
      //   delete this.currentState['cluster_id'];
      // } else if (clusterEnabled && Object.keys(this.currentState).indexOf('cluster_id') < 0) {
      //   this.currentState['cluster_id'] = this.selectedCluster.uuid;
      // }
    }
    // when cluster change occurs
    else {
      this.clearForm();
    }

    this.clearState();
    this.getLeadDetails(this.currentState);
    this.sidenavBar.close();
  }

  clearForm() {
    // resetting form data
    this.resetForm();
    // removing form data from state, only keeping cluster_id, pagenum and sort data
    let newState = {
      cluster_id: '',
      page: this.currentState.page,
      column_name: this.currentState.column_name,
      sort_order: this.currentState.sort_order
    };
    this.currentState = newState;
  }

  // clear sorting, and page data
  clearState() {
    this.currentState['page'] = 1;
    this.currentState['sort_order'] = '';
    this.currentState['column_name'] = '';
  }

  resetForm() {
    this.form.patchValue({
      prev_stage_id: '',
      cur_stage_id: '',
      access: '',
      text: '',
      sales_agent_id: '',
      epc_agent_id: '',
      active_status: false,
      lms_status: '',
      executive_id: '',
      source: '',
      date_from: 0,
      date_to: 0,
      cluster_independent: false,
      recommended: false,
      last_activity_log_date_to: 0,
      last_activity_log_date_from: 0,
      first_activity_log_date_to: 0,
      first_activity_log_date_from: 0,
      designer_id: '',
      discom: '',
      city: ''
    });
  }

  optionSelect(key, row) {
    let data = { is_logging: key, property_uuid: row.uuid };
    this.loggerService.manageQueue(data).subscribe(val => {
      if (val && !val['status']) {
        this.toast.error('OPERATION FAILED');
      }
      if (val && val['status']) {
        this.toast.success('QUEUE STATUS UPDATED SUCCESSFULLY');
      }
      this.getLeadDetails(this.currentState);
    });
  }

  disableLead(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data == 'success') {
        this.leadService
          .disableLead({ property_id: row.uuid })
          .subscribe(val => {
            if (val && val['status']) {
              if (this.count == 1 && this.currentState.page > 1) {
                this.currentState['page'] = this.currentState['page'] - 1;
              }
              this.getLeadDetails(this.currentState);
              this.toast.success('LEAD DELETED SUCCESSFULLY');
            } else {
              this.toast.error('OPERATION FAILED');
            }
          });
      }
    });
  }

  downloadList(type) {
    console.log('request for downloading list');
    let downloadState = JSON.parse(JSON.stringify(this.currentState));
    delete downloadState['page'];
    console.log('current angular download object');
    console.log(downloadState);
    downloadState['download'] = 1;
    switch(type) {
      case 'lead-list':
        this.leadService.downloadListByUrl(type, this.leadService.leadListURL, downloadState);
        break;
      case 'employee-list':
        this.leadService.downloadListByUrl(type, this.leadService.downloadEmployeeUrl, downloadState);
        break;

    }
  }

  clusterReassign(row) {
    this.updateModal([row], true);
  }

  updateModal(body, multi) {
    let dialogRef = this.dialog.open(AddClusterComponent, {
      data: { data: body, multi: multi }
    });

    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
        this.currentState['page'] =
          this.pageCount == 1 && this.currentState['page'] > 1
            ? this.currentState['page'] - 1
            : this.currentState['page'];
        this.getLeadDetails(this.currentState);
      }
    });
  }

  sidenavOpen() {
    console.log('sidenav opened');
    $('app-lead-list-view').css({ height: '100%' });
    $('.funnel-filter-container').css({ 'padding-bottom': '0px' });
  }

  sidenavClose() {
    console.log('sidenav closed');
    $('app-lead-list-view').css({ height: 'auto' });
    $('.funnel-filter-container').css({ 'padding-bottom': '' });
  }

  executiveChange(uuid, row) {
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      executive_uuid: uuid
    };
    this.leadService.updateExecutive(data).subscribe(val => {
      if (val && val['status']) {
        this.getLeadDetails(this.currentState);
        this.toast.success('PROPERTY UPDATED SUCCESSFULLY');
      } else {
        this.toast.error('OPERATION FAILED');
      }
    });
  }

  onDesignerChange(row) {
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      designer_uuid: row.designer.uuid,
    };
    this.leadService.updateDesigner(data).subscribe(val => {
      if (val && val['status']) {
        this.getLeadDetails(this.currentState);
        this.toast.success('DESIGNER UPDATED SUCCESSFULLY');
      } else {
        this.toast.error('OPERATION FAILED');
      }
    });
  }


  lmsStatusChange(value, row) {
    console.log('status change');
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      lms_status: value
    };
    console.log(data);

    this.leadService.updateLmsStatus(data).subscribe(val => {
      if (val && val['status']) {
        this.getLeadDetails(this.currentState);
        this.toast.success('PROPERTY UPDATED SUCCESSFULLY');
      } else {
        this.toast.error('OPERATION FAILED');
      }
    });
  }

  viewActivityLog(row) {
    let dialogRef = this.dialog.open(ActivityLogListComponent, {
      data : row
    });
  }

  createActivityLog(row) {
    let dialogRef = this.dialog.open(CreateActivityLogComponent, {
      data : row
    });
  }

  setReminder(row) {
    let dialogRef = this.dialog.open(SetReminderComponent, {
      data: row
    });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }

}
