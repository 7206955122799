import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  ticketListUrl = "v1/property/pre-sales/ticket/list";
  ticketUrl = "v1/property/pre-sales/ticket";
  ticketLogUrl = 'v1/property/pre-sales/ticket/log';
  SERVICE_REQUEST_PRE_COMMISSION_TYPES = {
    'application_status': ['status_of_application', 'material_delivery_status', 'installation_status', 'net_meter_installation_status'],
    'sales_partner_assignment': ['delay_in_connecting_to_customer'],
    'communication': ['improper_communication', 'not_heard_from_homescape_after_request', 'meeting_missed', 'schedule_a_meeting/call_back',
      'not_able_to_connect_to_bd_partner', 'change_meeting_time'],
    'site_survey': ['schedule_a_site_survey', 'site_survey_not_done_properly', 'bad_behoviour_while_visit', 'not_able_to_connect_bd_partner'],
    'proposal': ['proposal_not_received', 'incorrect_proposal_received', 'requested_changes_in_proposal_not_received'],
    'final_commercial': ['bd_partner_not_reachable', 'payment_related_issues', 'payment_receipt_related_issues'],
    'customer_kyc': ['request_to_collect_kyc_docs', 'update_kyc_docs_ready_-_collection_request'],
    'nm_application': [''],
    'design_and_material_planning': ['request_for_changes_in_design_layout', 'request_to_shift_plant_location_rooftop', 'request_for_changes_in_plant_capacity',
      'request_for_specific_components'],
    'discom_noc_received': ['require_support_in_discom_NOC/Name_change/KYC_related_issue'],
    'material_dispatched': [],
    'material_received_at_site': ['defect in material received at site', 'material not properly kept at site', 'delay in material dispatch', 'wrong BOM received at site',
      'request for additional components in BOM', 'delivery team behaviour issue'],
    'installation': ['installation not yet started', 'payment milestone completed request to start installation', 'not satisfied with installation', 'request for changes in installation',
      'behavioural issue with installation team', 'site is not cleaned after installation', 'not informed about installation schedule', 'installation team not reached after scheduling the workday'],
    'site_qc': [''],
    'meter_installation': ['net meter not yet installed', 'net meter installation delayed'],
    'data_logger_configuration': ['request to install data logger', 'request for WiFi based logger', 'request for GPRS SIM based logger', 'issue with data logging and reporting', 'require zero export'],
    'site_commissioned': ['request for proper handover and training for the plant installed', 'site commissioned but the plant not switched on', 'payment collection request', 'handover documents not received'],
    'post_commissioning_request': ['post commissioning request'],
    'critical_complaint': ['critical complaint']
  }
  constructor(
    private http: HttpClient
  ) { }

  getTicketList(params) {
    return this.http.get(environment.serverURl + this.ticketListUrl, { params: params });
  }

  getGlobalTicketList() {
    return this.http.get(environment.serverURl + this.ticketListUrl);
  }

  createTicket(data) {
    return this.http.post(environment.serverURl + this.ticketUrl, data);
  }

  updateTicket(data) {
    return this.http.put(environment.serverURl + this.ticketUrl, data);
  }

  getTicket(params) {
    return this.http.get(environment.serverURl + this.ticketUrl, { params: params });
  }

  getTicketLogs(params) {
    return this.http.get(environment.serverURl + this.ticketLogUrl, { params: params });
  }


  downloadListByUrl(url, data) {
    this.http
      .get(environment.serverURl + url, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          'ticket-list' + '_' +
          timeObject.getDate() +
          '/' +
          timeObject.getMonth() +
          '/' +
          timeObject.getFullYear() +
          '__' +
          timeObject.getHours() +
          '-' +
          timeObject.getMinutes() +
          '-' +
          timeObject.getSeconds() +
          '.xls'
        );
      });
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }

}


