import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { DataSyncService } from "gojs-angular";
import { GojsAngularModule } from "gojs-angular";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgxChartsModule } from "@swimlane/ngx-charts";

import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./core";
import { ResetPasswordComponent } from "./common/component/reset-password/reset-password.component";
import { LoginComponent } from "./core/account-management/login/login.component";
import { LeadNoPropertyComponent } from "./core/lead-management/lead-no-property/lead-no-property.component";
import { LeadListViewComponent } from "./core/lead-management/lead-list-view/lead-list-view.component";
import { AddPropertyComponent } from "./common/component/add-property/add-property.component";
import { UserManagementComponent } from "./core/account-management/user-management/user-management.component";
import { ClusterManagementComponent } from "./core/cluster-management/cluster-management/cluster-management.component";
import { ClusterDepartmentManagementComponent } from "./core/cluster-management/cluster-department-management/cluster-department-management.component";
import { ClusterAdminManagementComponent } from "./core/cluster-management/cluster-admin-management/cluster-admin-management.component";
import { PropertyClusterAssignmentComponent } from "./core/lead-management/property-cluster-assignment/property-cluster-assignment.component";
import { LoginGuard } from "./common/service/login-guard/login.guard";
import { AssociateManagementComponent } from "./core/associate-management/associate-management/associate-management.component";
import { AuthGuard } from "./common/service/auth-guard/auth.guard";
import { MenuService } from "./common/service/sidebar/menu.service";
import { Error4Component } from "./common/component/error/error4/error4.component";
import { AddClusterComponent } from "./common/component/add-cluster/add-cluster.component";
import { TokenInterceptorService } from "./common/service/token-interceptor/token-interceptor.service";
import { StageViewComponent } from "./core/lead-management/stage-view/stage-view.component";
import { StageBarComponent } from "./core/lead-management/stage-bar/stage-bar.component";
import { ButtonComponent } from "./common/component/dynamic-form/button/button.component";
import { SelectComponent } from "./common/component/dynamic-form/select/select.component";
import { DateComponent } from "./common/component/dynamic-form/date/date.component";
import { RadiobuttonComponent } from "./common/component/dynamic-form/radiobutton/radiobutton.component";
import { CheckboxComponent } from "./common/component/dynamic-form/checkbox/checkbox.component";
import { TextAreaComponent } from "./common/component/dynamic-form/text-area/text-area.component";
import { FileUploadComponent } from "./common/component/dynamic-form/file-upload/file-upload.component";
import { InputComponent } from "./common/component/dynamic-form/input/input.component";
import { DynamicFieldDirective } from "./common/component/dynamic-form/dynamic-field/dynamic-field.directive";
import { DynamicFormComponent } from "./common/component/dynamic-form/dynamic-form/dynamic-form.component";
import { MapViewComponent } from "./core/lead-management/map-view/map-view.component";
import { FileSelectDirective, FileUploadModule } from "ng2-file-upload";
import { PartnerManagementComponent } from "./core/partner-management/partner-management/partner-management.component";
import { CreateStageComponent } from "./core/cluster-workflow/create-stage/create-stage.component";
import { CreateStageFieldComponent } from "./core/cluster-workflow/create-stage-field/create-stage-field.component";
import { CreateStageLinkComponent } from "./core/cluster-workflow/create-stage-link/create-stage-link.component";
import { EditStageLinkComponent } from "./core/cluster-workflow/edit-stage-link/edit-stage-link.component";
import { DashboardComponent } from "./core/lead-management/dashboard/dashboard.component";
import { SignOutComponent } from "./common/component/sign-out/sign-out.component";
import { MapComponent } from "./common/component/dynamic-form/map/map.component";
import { IotConfigurationComponent } from "./core/lead-management/iot-configuration/iot-configuration.component";
import { DepartmentStageAccessComponent } from "./core/cluster-workflow/department-stage-access/department-stage-access.component";
import { DeleteModalComponent } from "./common/component/delete-modal/delete-modal.component";
import { IotDeviceComponent } from "./common/component/iot-device/iot-device.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { DatePipe } from "@angular/common";
import { IotDeviceModalComponent } from "./common/component/iot-device-modal/iot-device-modal.component";
import { PartnerLinkComponent } from "./core/partner-management/partner-link/partner-link.component";
import { EditPartnerLinkComponent } from "./common/component/edit-partner-link/edit-partner-link.component";
import { LeadPerformanceComponent } from "./core/performance-reporting/lead-performance/lead-performance.component";
import "hammerjs";
import { GestureConfig } from "owl-ng";
import { DateTimeComponent } from "./common/component/dynamic-form/date-time/date-time.component";
import { GeoPointComponent } from "./common/component/dynamic-form/geo-point/geo-point.component";
import { FloatComponent } from "./common/component/dynamic-form/float/float.component";
import { IntegerComponent } from "./common/component/dynamic-form/integer/integer.component";
import { ValidationsComponent } from "./common/component/validations/validations.component";
import { LeadActivationModalComponent } from "./common/component/lead-activation-modal/lead-activation-modal.component";
import { ClusterGenerationComponent } from "./core/performance-reporting/cluster-generation/cluster-generation.component";
import { DepartmentOrganisationComponent } from "./core/people-management/department-organisation/department-organisation/department-organisation.component";
import { EditDepartmentComponent } from "./common/component/edit-department/edit-department/edit-department.component";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { PartnerOrganisationComponent } from "./core/partner-management/partner-org/partner-organisation/partner-organisation.component";
import { PropertyGenerationComponent } from "./core/performance-reporting/property-generation/property-generation/property-generation.component";
import { LeadReportComponent } from "./core/performance-reporting/lead-report/lead-report/lead-report.component";
import { YearSelectorComponent } from "./common/component/year-selector/year-selector/year-selector.component";
import { MonthSelectorComponent } from "./common/component/month-selector/month-selector/month-selector.component";
import { MatMenuModule } from "@angular/material/menu";
import { AddDepartmentComponent } from "./common/component/add-department/add-department.component";
import { ListGroupDirective } from "./common/directive/list-select/list-group.directive";
import { ListItemDirective } from "./common/directive/list-select/list-item.directive";
import { CustomerAssignmentComponent } from "./common/customer-assignment/customer-assignment.component";
import { WorkflowManagementComponent } from "./core/cluster-workflow/workflow-management/workflow-management.component";
import { from } from "rxjs";
import { InspectorComponent } from "./core/inspector/inspector.component";

import { ActivityLogModalComponent } from "./common/component/activity-log-modal/activity-log-modal.component";
import { CreateActivityLogComponent } from "./common/component/create-activity-log/create-activity-log.component";
import { SetReminderComponent } from "./common/component/set-reminder/set-reminder.component";
import { ActivityLogListComponent } from "./core/lead-management/activity-log-list/activity-log-list.component";
import { LogComponent } from "./common/component/log/log.component";
import {
  MatAutocompleteModule,
  MatButtonToggle,
  MatButtonToggleModule,
  MatExpansionModule,
  MatIconModule,
  MatTableModule,
} from "@angular/material";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { environment } from "src/environments/environment";
import { MessagingService } from "./common/service/firebase-messaging/messaging.service";
import { ActivityDashboardComponent } from "./core/performance-reporting/activity-dashboard/activity-dashboard.component";
import { TooltipDirective } from "./common/directive/tooltip/tooltip.directive";
import { AmcComponent } from "./core/lead-management/amc/amc.component";
import { ManageAmcPlanComponent } from "./core/lead-management/amc/manage-amc-plan/manage-amc-plan.component";
import { UploadFilesComponent } from "./core/manage-upload/upload-files/upload-files.component";
import { UploadTaskComponent } from "./core/manage-upload/upload-task/upload-task.component";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { CreateSrModalComponent } from "./common/component/create-sr-modal/create-sr-modal/create-sr-modal.component";
import { ManageSrComponent } from "./common/component/sr-log/sr-log.component";
import { LogListComponent } from "./common/component/log-list/log-list.component";
import { ManageAssetComponent } from "./common/component/create-asset/create-asset.component";
import { AmcLogComponent } from "./common/component/amc-log/amc-log.component";
import { AmcLogListComponent } from "./common/component/amc-log-item/amc-log-item.component";
import { DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { ClusterTechnicianManagementComponent } from "./core/cluster-management/cluster-technician-management/cluster-technician-management.component";
import { PropertyViewWrapperComponent } from "./core/lead-management/property-view-wrapper/property-view-wrapper.component";
import { ManageActivityLogComponent } from "./core/lead-management/manage-activity-log/manage-activity-log.component";
import { DropdownModule } from "primeng/dropdown";
import { ViewModeDirective } from "./common/directive/editable/view-mode.directive";
import { EditModeDirective } from "./common/directive/editable/edit-mode.directive";
import { EditableComponent } from "./common/directive/editable/editable.component";
import { AmcPaymentsComponent } from "./core/lead-management/amc/amc-payments/amc-payments.component";
import { SrSparePartsComponent } from "./common/component/sr-spare-parts/sr-spare-parts/sr-spare-parts.component";
import { StatusTextTransform } from "./core/pipes/status.text.transform.pipe";
import { SpareItemsComponent } from "./core/asset-management/spare-items/spare-items.component";
import { WarrantyItemsComponent } from "./core/asset-management/warranty-items/warranty-items.component";
import { SolarCreditComponent } from "./core/lead-management/amc/solar-credit/solar-credit.component";
import { MilestonesComponent } from "./core/performance-reporting/property-generation/milestones/milestones.component";
import { TuyaDeviceModalComponent } from "./common/component/tuya-device-modal/tuya-device-modal.component";
import { TuyaDeviceComponent } from "./common/component/tuya-device/tuya-device.component";
import { QueueStatusModalComponent } from "./common/component/queue-status-modal/queue-status-modal.component";
import { IotCleanPwdModalComponent } from "./common/component/iot-clean-pwd-modal/iot-clean-pwd-modal.component";
import { UserInfoComponent } from "./common/component/user-info/user-info.component";
import { TitleCasePipe } from "@angular/common";
import { CheckboxGroupComponent } from "./common/component/checkbox/checkbox-group.component";
import { CheckboxNewComponent } from "./common/component/checkbox/checkbox.component";
import { SrServicesComponent } from "./common/component/sr-services/sr-services.component";
import { GlobalServiceRequestsComponent } from "./core/maintenance/global-service-requests/global-service-requests.component";
import { TwoDigitDecimalDirective } from "./common/directive/two-digit-decimal.directive";
import { TruncatePipe } from "./common/truncate.pipe";
import { UserDetailsComponent } from "./core/lead-management/user-details/user-details.component";
import { ReferralComponent } from "./core/lead-management/amc/referral/referral.component";
import { ReferralDetailComponent } from "./common/component/referral-detail/referral-detail.component";
import { ConfirmationDialogComponent } from "./common/component/confirmation-dialog/confirmation-dialog.component";
import { RecommendedListViewComponent } from "./core/lead-management/recommended-list-view/recommended-list-view.component";
import { PropertyDetailModalComponent } from "./common/component/property-detail-modal/property-detail-modal.component";
import { RecommendationItemComponent } from './common/component/recommendation-item/recommendation-item.component';
import { EditRecommendationModalComponent } from './common/component/edit-recommendation-modal/edit-recommendation-modal.component';
// import { ManageProposalComponent } from './common/component/manage-proposal/manage-proposal.component';
import { AmcDashboardComponent } from './core/maintenance/amc-dashboard/amc-dashboard.component';
import { AmcSchedularComponent } from './core/maintenance/amc-schedular/amc-schedular.component';
import { DateAgoPipe } from './core/pipes/date-ago.pipe';

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { DisaggregationLabelModalComponent } from './common/component/raycon-label-modal/disaggregation-label-modal.component';
import { GlobalEquipmentModalComponent } from './common/component/global-equipment-modal/global-equipment-modal.component';
import { DisaggregationWindowSelectionComponent } from './common/component/disaggregation-window-selection/disaggregation-window-selection.component';
import { DiscomManagementComponent } from './core/account-management/discom-management/discom-management.component';
import { DiscomLeadListComponent } from './core/lead-management/discom-lead-list/discom-lead-list.component';
import { DiscomGenerationViewComponent } from './core/performance-reporting/property-generation/discom-generation-view/discom-generation-view.component';
import { ChannelPartnerComponent } from './core/people-management/channel-partner/channel-partner.component';
import { MarkChannelPartnerDirective } from './core/directives/mark-channel-partner.directive';
import { PreSalesLeadComponent } from './core/lead-management/pre-sales-lead/pre-sales-lead.component';
import { AddPaymentPlanComponent } from './common/component/add-payment-plan/add-payment-plan.component';
import { HaPropertiesComponent } from './core/lead-management/ha-properties/ha-properties.component';
import { UserPropertiesComponent } from './core/lead-management/user-details/user-properties/user-properties.component';
import { PaymentPlansComponent } from './common/component/finance/payment-plans/payment-plans.component';
import { AddPaymentModalComponent } from './common/component/finance/add-payment/add-payment.component';
import { AddERPCodeModalComponent } from "./common/component/finance/add-erp-code/add-erp-code.component";
import { PropertyPaymentLogComponent } from "./common/component/finance/property-payment-log/property-payment-log.component";
import { PaymentApprovalComponent } from './common/component/finance/payment-approval/payment-approval.component';
import { ApproveRejectPaymentDirective } from './common/directive/payment/approve-reject-payment.directive';
import { CreatePaymentPlanComponent } from "./common/component/finance/add-payment-plan/add-payment-plan.component";
import { ServiceTicketsComponent } from './common/component/service-tickets/service-tickets.component';
import { CreateTicketModalComponent } from './common/component/create-ticket-modal/create-ticket-modal.component';
import { TicketLogComponent } from './common/component/ticket-log/ticket-log.component';
import { EditPropertyComponent } from "./common/component/edit-property/edit-property.component";
import { TicketDashboardComponent } from "./common/component/ticket-dashboard/ticket-dashboard.component";
import { PropertyListComponent } from "./core/lead-management/property-list/property-list.component";
import { RayconComponent } from "./core/lead-management/raycon/raycon.component";
import { HomeAutomationComponent } from "./core/lead-management/home-automation/home-automation.component";
import { EditRoomComponent } from "./common/component/edit-room/edit-room.component";
import { AQIComponent } from "./core/lead-management/aqi/aqi.component";
import { ScheduleModule, View, WeekService, MonthService, YearService} from '@syncfusion/ej2-angular-schedule';
import { TatUpdateComponent } from './common/component/tat-update/tat-update.component';
import { ViewTechinicianComponent } from "./core/maintenance/amc-schedular/view-technician/view-technician.component";
import { SolarProductComponent } from './common/component/solar-product/solar-product.component';
import { ChannelPartnerLogsComponent } from "./common/component/channel-partner-logs/channel-partner-logs.component";
import { ActivityLogsComponent } from "./common/component/channel-partner-logs/activity-logs/activity-logs.component";
import { CreateLogComponent } from "./common/component/channel-partner-logs/create-log/create-log.component";
import { OpsManagementComponent } from "./core/people-management/ops-management/ops-management.component";
import { DisaggregationPropertiesComponent } from "./core/lead-management/disaggregation-properties/disaggregation-properties.component";
import { AQIPropertiesComponent } from "./core/lead-management/aqi-properties/aqi-properties.component";
import { IvrLogsComponent } from "./common/component/ivr-logs/ivr-logs.component";
import { MultiSelectModule } from 'primeng/multiselect';
import { HoldStageComponent } from "./core/lead-management/stage-bar/hold-stage/hold-stage.component";
import { ViewAssetComponent } from "./common/component/view-asset/view-asset.component";
import { CostSheetComponent } from "./core/asset-management/cost-sheet/cost-sheet.component";
import { ReassignClusterComponent } from "./common/component/reassign-cluster/reassign-cluster.component";
import { EMarketPlaceComponent } from "./common/component/e-market-place/e-market-place.component";
import { AssingClusterComponent } from './common/component/assing-cluster/assing-cluster.component';
import { AssigningSalesPartnerComponent } from './common/component/assigning-sales-partner/assigning-sales-partner.component';
import { ProposalComponent } from "./common/component/proposal/proposal.component";
import { InitiateRefundComponent } from './common/component/finance/initiate-refund/initiate-refund.component';
import { DynamicFileUploadComponent } from './common/component/dynamic-form/dynamic-file-upload/dynamic-file-upload.component';
import { InitiateTransferComponent } from './common/component/finance/initiate-transfer/initiate-transfer.component';
  

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ListGroupDirective,
    ListItemDirective,
    ResetPasswordComponent,
    LoginComponent,
    LeadNoPropertyComponent,
    LeadListViewComponent,
    AddPropertyComponent,
    UserManagementComponent,
    ClusterManagementComponent,
    ClusterDepartmentManagementComponent,
    ClusterAdminManagementComponent,
    OpsManagementComponent,
    PropertyClusterAssignmentComponent,
    AddClusterComponent,
    AssociateManagementComponent,
    Error4Component,
    StageViewComponent,
    StageBarComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    TextAreaComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    PartnerManagementComponent,
    CreateStageComponent,
    CreateStageFieldComponent,
    CreateStageLinkComponent,
    MapViewComponent,
    EditStageLinkComponent,
    DashboardComponent,
    SignOutComponent,
    MapComponent,
    FileUploadComponent,
    IotConfigurationComponent,
    DepartmentStageAccessComponent,
    DeleteModalComponent,
    IotDeviceComponent,
    IotDeviceModalComponent,
    PartnerLinkComponent,
    EditPartnerLinkComponent,
    LeadPerformanceComponent,
    DateTimeComponent,
    GeoPointComponent,
    FloatComponent,
    IntegerComponent,
    ValidationsComponent,
    LeadActivationModalComponent,
    ClusterGenerationComponent,
    DepartmentOrganisationComponent,
    EditDepartmentComponent,
    PartnerOrganisationComponent,
    PropertyGenerationComponent,
    LeadReportComponent,
    YearSelectorComponent,
    MonthSelectorComponent,
    AddDepartmentComponent,
    ListGroupDirective,
    ListItemDirective,
    CustomerAssignmentComponent,
    WorkflowManagementComponent,
    InspectorComponent,
    ActivityLogModalComponent,
    CreateActivityLogComponent,
    SetReminderComponent,
    ActivityLogListComponent,
    ManageActivityLogComponent,
    LogComponent,
    ActivityDashboardComponent,
    TooltipDirective,
    AmcComponent,
    ManageAmcPlanComponent,
    UploadFilesComponent,
    UploadTaskComponent,
    CreateSrModalComponent,
    ManageSrComponent,
    LogListComponent,
    ManageAssetComponent,
    AmcLogComponent,
    AmcLogListComponent,
    ClusterTechnicianManagementComponent,
    PropertyViewWrapperComponent,
    ViewModeDirective,
    EditModeDirective,
    EditableComponent,
    SpareItemsComponent,
    AmcPaymentsComponent,
    SrSparePartsComponent,
    StatusTextTransform,
    WarrantyItemsComponent,
    SolarCreditComponent,
    MilestonesComponent,
    TuyaDeviceModalComponent,
    TuyaDeviceComponent,
    QueueStatusModalComponent,
    IotCleanPwdModalComponent,
    UserInfoComponent,
    CheckboxGroupComponent,
    CheckboxNewComponent,
    SrServicesComponent,
    GlobalServiceRequestsComponent,
    TwoDigitDecimalDirective,
    TruncatePipe,
    UserDetailsComponent,
    ReferralComponent,
    ReferralDetailComponent,
    ConfirmationDialogComponent,
    RecommendedListViewComponent,
    PropertyDetailModalComponent,
    ChannelPartnerLogsComponent,
    ActivityLogsComponent,
    CreateLogComponent,
    RecommendationItemComponent,
    EditRecommendationModalComponent,
    // ManageProposalComponent,
    PropertyPaymentLogComponent,
    DisaggregationLabelModalComponent,
    GlobalEquipmentModalComponent,
    DisaggregationWindowSelectionComponent,
    AmcDashboardComponent,
    DateAgoPipe,
    DiscomManagementComponent,
    DiscomLeadListComponent,
    DiscomGenerationViewComponent,
    ChannelPartnerComponent,
    MarkChannelPartnerDirective,
    PreSalesLeadComponent,
    AddPaymentPlanComponent,
    HaPropertiesComponent,
    UserPropertiesComponent,
    PaymentPlansComponent,
    HaPropertiesComponent,
    UserPropertiesComponent,
    AddPaymentModalComponent,
    PaymentApprovalComponent,
    ApproveRejectPaymentDirective,
    PaymentPlansComponent,
    HaPropertiesComponent,
    UserPropertiesComponent,
    AddPaymentPlanComponent,
    CreatePaymentPlanComponent,
    ServiceTicketsComponent,
    CreateTicketModalComponent,
    TicketLogComponent,
    EditPropertyComponent,
    AddERPCodeModalComponent,
    TicketDashboardComponent,
    PropertyListComponent,
    RayconComponent,
    HomeAutomationComponent,
    EditRoomComponent,
    AQIComponent,
    AmcSchedularComponent,
    TatUpdateComponent,
    ViewTechinicianComponent,
    SolarProductComponent,
    DisaggregationPropertiesComponent,
    AQIPropertiesComponent,
    IvrLogsComponent,
    HoldStageComponent,
    ViewAssetComponent,
    CostSheetComponent,
    ReassignClusterComponent,
    EMarketPlaceComponent,
    AssingClusterComponent,
    AssigningSalesPartnerComponent,
    ProposalComponent,
    InitiateRefundComponent,
    DynamicFileUploadComponent,
    InitiateTransferComponent,
  ],
  imports: [
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    GojsAngularModule,
    BrowserModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    DragulaModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    ScheduleModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxDatatableModule,
    LoadingBarRouterModule,
    NgbModule.forRoot(),
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    FileUploadModule,
    NgxChartsModule,
    DropDownListModule,
    ButtonModule,
    DropdownModule,
    MatTableModule,
    MatIconModule,
    MatExpansionModule,
    NgxEchartsModule.forRoot({ echarts })
  ],
  exports: [MatDialogModule],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    DragulaService,
    MessagingService,
    MenuService,
    WeekService,
    MonthService,
    YearService,
    DynamicFileUploadComponent,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    TitleCasePipe,
  ],
  entryComponents: [
    ResetPasswordComponent,
    EditStageLinkComponent,
    AddPropertyComponent,
    AddClusterComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    GeoPointComponent,
    DateComponent,
    DateTimeComponent,
    IntegerComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    CheckboxNewComponent,
    CheckboxGroupComponent,
    FloatComponent,
    TextAreaComponent,
    FileUploadComponent,
    DynamicFormComponent,
    SignOutComponent,
    MapComponent,
    DeleteModalComponent,
    IotDeviceModalComponent,
    EditPartnerLinkComponent,
    LeadActivationModalComponent,
    EditDepartmentComponent,
    MonthSelectorComponent,
    YearSelectorComponent,
    AddDepartmentComponent,
    CustomerAssignmentComponent,
    ActivityLogModalComponent,
    CreateActivityLogComponent,
    SetReminderComponent,
    ActivityLogListComponent,
    ManageAmcPlanComponent,
    CreateSrModalComponent,
    ManageSrComponent,
    ManageAssetComponent,
    AmcLogComponent,
    AmcLogListComponent,
    TuyaDeviceModalComponent,
    TuyaDeviceComponent,
    QueueStatusModalComponent,
    IotCleanPwdModalComponent,
    UserInfoComponent,
    ReferralDetailComponent,
    ConfirmationDialogComponent,
    PropertyDetailModalComponent,
    ChannelPartnerLogsComponent,
    ActivityLogsComponent,
    CreateLogComponent,
    RecommendedListViewComponent,
    RecommendationItemComponent,
    EditRecommendationModalComponent,
    // ManageProposalComponent,
    DisaggregationLabelModalComponent,
    GlobalEquipmentModalComponent,
    DisaggregationWindowSelectionComponent,
    AddPaymentPlanComponent,
    AddPaymentModalComponent,
    CreatePaymentPlanComponent,
    CreateTicketModalComponent,
    TicketLogComponent,
    EditPropertyComponent,
    AddERPCodeModalComponent,
    TicketDashboardComponent,
    PropertyListComponent,
    RayconComponent,
    HomeAutomationComponent,
    EditRoomComponent,
    AQIComponent,
    TatUpdateComponent,
    ViewTechinicianComponent,
    SolarProductComponent,
    DisaggregationPropertiesComponent,
    AQIPropertiesComponent,
    IvrLogsComponent,
    HoldStageComponent,
    ViewAssetComponent,
    CostSheetComponent,
    ReassignClusterComponent,
    EMarketPlaceComponent,
    AssingClusterComponent,
    AssigningSalesPartnerComponent,
    ProposalComponent,
    InitiateRefundComponent,
    DynamicFileUploadComponent,
    InitiateTransferComponent
  ],
})
export class AppModule {}
