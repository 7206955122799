import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
    
    // authService: AuthService
  ) {

   }

  //cluster creation
  getClusterCreationAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //add property
  getAddPropertyAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'associate':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }




  getFinanceAccess(user){
    switch (user.user_type) {

      case 'amplus':
        if (user.user_roles.includes('hs_finance')) {
          return "homescape_finance";
        }
        else if (user.user_roles.includes('amplus_finance')) {
          return "amplus_finance";
        }
        else {
          return 'other';
        }
      default:
        return 'false';
    }
  }

  //delete leads
  getDeleteLeadAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }
  //property, activity, acquisition, generation dashboard is handled in menu.service.ts, in roles.

  /**----------------------------------- Lead Manangement --------------------------------------------*/
  //-----------------------------------cluster properties-----------------------------------
  //all leads
  getAllLeadsCPAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      default:
        return false;
    }
  }

  //assigned leads
  getAssignedLeadsCPAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'associate':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getLeadStatusAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'associate':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getAssignedExecutiveAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getAssignedDesignerAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //property details view access is for everybody, so changes are made here

  // property details edit acess
  getActivityLogsEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'associate':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //cluster generation is visible for all

  //-----------------------------------iot----------------------------------- 
  getIotViewAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_technical')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getIotEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_technical')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //-----------------------------------amc----------------------------------- 
  // amc details (create/edit/delete)
  getAMCDetailsAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //payments
  getPaymentsAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      default:
        return false;
    }
  }
  // service requests
  getCreateSRAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }
  // (edit/delete)
  getEditSRRequest(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  // changing Payment status
  paymentStatusAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //warranty modules
  getWarrantyModuleAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }


  //adding  Initiate Transfer Refund Access
  getInitiateTransferRefundAccess(user){
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else {
          return false;
        }
     
      default:
        return false;
    }
  }

  //------------------------------ User Details---------------------------------
  //adding payment logs
  getAddPaymentAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_business')) {
          return true;
        }
        else {
          return false;
        }
      case 'associate':
        if (user.user_roles.includes('associate_business')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //re-assign cluster
  getReassignClusterAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }
  //solar credits are visible to all
  //referrals and recommendations are visible to all except finance 
  getReferralAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_technical')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_analytics')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'discom_user':
        return true;
      case 'cluster_technician':
        return true;
      case 'partner':
        return true;
      case 'associate':
        return true;
      default:
        return false;
    }
  }

  //-------------------------------------New Properties---------------------------------------------------
  //upload leads
  getUploadLeadsAcces(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      case 'associate':
        return true;
      default:
        return false;
    }
  }

  getChangingLeadStatusNPAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      case 'associate':
        return true;
      default:
        return false;
    }
  }

  getChangingExecutiveNPAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getActivatePropertyAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_technical')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  //payment logs---------------------------------------------------------------------------

  getPaymentLogViewAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_technical')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_analytics')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'discom_user':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_business')) {
          return true;
        }
        else if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else {
          return false;
        }
      case 'associate':
        return true;
      default:
        return false;
    }
  }

  getAddPaymentPlanAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'partner':
        if (user.user_roles.includes('partner_business')) {
          return true;
        }
        else if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else {
          return false;
        }
      case 'associate':
        if (user.user_roles.includes('associate_business')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getApproveRejectSkipAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      default:
        return false;
    }
  }

  getAdminAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  getApproveRejectPaymentAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('hs_finance')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_finance')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  ticketEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_executive')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        // else if (user.user_roles.includes('amplus_analytics')) {
        //   return true;
        // }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else if (user.user_roles.includes('partner_epc')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      case 'associate':
        return true;
      default:
        return false;
    }
  }

  paymentPlanDeleteAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  benchmarkEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }

  // channel partner

  getChannelPartnerEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_business')) {
          return true;
        }
        // else if (user.user_roles.includes('amplus_analytics')) {
        //   return true;
        // }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_sales_manager')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_ops_manager')) {
          return true;
        }
        else {
          return false;
        }
      case 'partner':
        if (user.user_roles.includes('partner_sales')) {
          return true;
        }
        else {
          return false;
        }
      case 'cluster_admin':
        return true;
      default:
        return false;
    }
  }

  // emarket place access
  getEmarketEditAccess(user) {
    switch (user.user_type) {
      case 'amplus':
        if (user.user_roles.includes('amplus_designer')) {
          return true;
        }
        else if (user.user_roles.includes('amplus_admin')) {
          return true;
        }
        else {
          return false;
        }
      default:
        return false;
    }
  }


    // emarket place access
    getEmarketEditAccessDesigner(user) {
      switch (user.user_type) {
        case 'amplus':
          if (user.user_roles.includes('amplus_designer')) {
            return true;
          }
          else {
            return false;
          }
        default:
          return false;
      }
    }

   // emarket place access cluster admin


    getClusterAdminEditAccess(user) {
    switch (user.user_type) {
      case 'cluster_admin':
        return true;
      default:
        return false;
    }
  }
  



}


