import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatOption } from "@angular/material";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute } from "@angular/router";
import * as shape from "d3-shape";
import * as echarts from "echarts";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConfirmationDialogComponent } from "src/app/common/component/confirmation-dialog/confirmation-dialog.component";
import { DisaggregationWindowSelectionComponent } from "src/app/common/component/disaggregation-window-selection/disaggregation-window-selection.component";
import { GlobalEquipmentModalComponent } from "src/app/common/component/global-equipment-modal/global-equipment-modal.component";
import { DisaggregationLabelModalComponent } from "src/app/common/component/raycon-label-modal/disaggregation-label-modal.component";
import { AqiService } from "src/app/common/service/aqi.service";
import { DisaggregationService } from "src/app/common/service/disaggregation/disaggregation.service";
import { GenerationService } from "src/app/common/service/generation/generation.service";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { TuyaService } from "src/app/common/service/tuya.service";

declare var $: any;

@Component({
  selector: "app-raycon",
  templateUrl: "./raycon.component.html",
  styleUrls: ["./raycon.component.scss"],
})
export class RayconComponent implements OnInit {
  selectedProperty: any;
  graph_freq: number = 1;
  tab_selected = 0;
  status = true;
  totalHomeConsumption = 0;
  energy_flow: any;
  chartData: any = [{ name: "", series: [] }];
  tileData: any;
  leadName = "";
  form: any;
  propertyID = "";
  leadPhoneNumber = "";
  showTiles = true;
  minDate: any = "";
  maxDate: any = "";
  prevFreq = 1;
  freqForm: any;
  isBarSelected: boolean = false;
  isBarSelected2: boolean = false;
  monthMap = {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };

  reverseMonthMap = {
    Jan: "0",
    Feb: "1",
    Mar: "2",
    Apr: "3",
    May: "4",
    Jun: "5",
    Jul: "6",
    Aug: "7",
    Sep: "8",
    Oct: "9",
    Nov: "10",
    Dec: "11",
  };
  observableMarkerList = [];
  freq_dropdown = [
    { key: "Daily", value: 1 }
  ];
  global_freq = [
    { key: "Daily", value: 1 },
    { key: "Monthly", value: 2 }
  ];

  disaggregationData;
  disaggregationData_2;
  disaggregationGraphData = [
    { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
  ];
  disaggregationGraphData_2 = [
    { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
  ];
  pieCatData = [{ name: "no_data", value: 0 }];
  pieEquipmentData = [{ name: "no_data", value: 0 }];
  equipmentLabels = [];
  catLabels = [];
  prevDisaggregationChecked = true;
  disaggregationXLabel = "Time";
  disaggregationYLabel = "Power (W)";
  catTotal = 0;
  equipmentTotal = 0;
  detectedLast: any;
  presentLast: any;

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  tooltipDisabled = false;
  xAxisLabel = "Hours";
  tooltip = "Hour";
  showYAxisLabel = true;
  yAxisLabel = "Power Generated(kW)";
  showGridLines = true;
  innerPadding = 0;
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  noBarWhenZero = true;

  // graph_freq color scheme
  colors1 = [
    "#f59240",
    "#5c6bc0",
    "#29b6f6",
    "#ffee58",
    "#ef5350",
    "#868e96",
    "#77EDB4",
    "#44611F",
    "#910F7F",
    "#DFBCDE",
    "#71DCF6",
    "#5B5901",
    "#71510A",
    "#A567B8",
    "#5916E6",
    "#C7517F",
    "#14A2E9",
    "#AD90A7",
    "#03796D",
    "#E60433",
    "#CA4B39",
    "#2F60F9",
    "#B8206F",
    "#A173CE",
    "#1C99EB",
    "#D7F76A",
    "#B22205",
    "#FC8C75",
    "#B866C7",
    "#256E2F",
    "#C8821F",
    "#1B6398",
    "#18A3FB",
    "#141130",
    "#0A687A",
  ];
  colors = ["#5c6bc0"];
  colorScheme = {
    domain: this.colors,
  };
  areaColorScheme = { domain: this.colors1 };
  schemeType = "ordinal";


  // line; area
  autoScale = true;
  timeline = false;

  analyticsForm;
  filterForm;

  // raycon chart
  myChart: echarts.ECharts;
  chartOptions;
  markerList = [];
  filteredMarkerList = [];
  labelList = [];
  echartInitialized = false;
  disaggregationMarkerLabel = "";
  disaggregationMarkerColor = "";
  disaggregationDetectedEquipments = [];
  disaggregationPresentEquipments = [];
  powerParameter = [
    { key: "Active Power", value: "active_power" },
    { key: "Reactive Power", value: "reactive_power" },
    { key: "Apparent Power", value: "apparent_power" }
  ];
  power: string = "active_power";
  powerForm: any;
  currentEChartPointerEvent;
  addMarkerList = [];
  deleteMarkerList = [];
  rayconStartTime = 0;
  rayconEndTime = 0;
  rayconFrequency = 2;
  rayconDeviceId = "";
  markerFilterForm: any;
  activityList = [{ key: " - ON", value: "On" }, { key: " - OFF", value: "Off" }, { key: "", value: "All" }];
  disaggregationLabelMap = {};
  disaggregationLabelMap_2 = {};
  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  @ViewChild("allCatSelected", { static: false })
  private allCatSelected: MatOption;
  @ViewChild("allEquipmentSelected", { static: false })
  private allEquipmentSelected: MatOption;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  constructor(
    private generationService: GenerationService,
    private route: ActivatedRoute,
    private menuToggle: MenuToggleService,
    private tuyaService: TuyaService,
    private toast: MessageToastService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private aqiService: AqiService,
    private leadService: LeadService,
    private dialog: MatDialog,
    private disaggregationService: DisaggregationService
  ) {
    this.chartOptions = {
      tooltip: {
        trigger: "axis",
      },
      dataset: {
        dimensions: ["date", "value"],
        // source: dummyData['default'],
        source: [
          {
            date: 1623322822000,
            value: 1,
          },
          {
            date: 1623326422000,
            value: 2,
          },
          {
            date: 1623330022000,
            value: 3,
          },
        ],
      },
      grid: {
        height: 300,
        tooltip: {
          show: true,
        },
      },
      xAxis: {
        type: "time",
        minorTick: {
          show: true,
          splitNumber: 5,
        },
        splitLine: {
          lineStyle: {
            color: "#999",
          },
        },
        minorSplitLine: {
          show: true,
          lineStyle: {
            color: "#ddd",
          },
        },
        axisLabel: {
          formatter: (val, valstr) => {
            console.log("formatter up ", val);
            return this.datepipe.transform(new Date(val), "hh:mm:ss a");
          },
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Value",
          type: "line",
          connectNulls: true,
          symbolSize: 5,
          dimensions: ["date", "value"],
          sampling: "min",
          markLine: {
            animation: false,
            symbol: "none",
            // lineStyle: {
            //   type: "solid",
            //   width: 1.5,
            //   color: "#000000",
            // },
            data: [],
          },
        },
      ],
      color: ["#f49340"],
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
          top: 400,
          realtime: false,
          height: 60,
          labelFormatter: (val, valstr) => {
            console.log("formatter down ", val);
            return val
              ? this.datepipe.transform(new Date(val), "hh:mm a")
              : "NaN";
          },
        },
      ],
    };
  }

  ngOnInit() {
    this.tileData = [];
    this.maxDate = new Date();

    this.powerForm = this.fb.group({
      type: ["active_power"],
    });

    this.powerForm.get("type").valueChanges.subscribe((opt) => {
      this.power = opt;
      this.getRayconData();
    });

    this.markerFilterForm = this.fb.group({
      equipment: [""],
      markerActivity: [""]
    });

    this.filterForm = this.fb.group({
      catFilters: [["select all"]],
      equipmentFilters: [["select all"]],
    });


    // filter for daily, monthly, yearly
    this.form = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()],
    });

    this.freqForm = this.fb.group({
      chart_freq: ["1", Validators.required],
    });

    // else if(this.tab_selected != 0){
    console.log("here---->");
    this.freqForm.get("chart_freq").valueChanges.subscribe((val: any) => {
      if (this.tab_selected == 0) {
        this.freqForm.get("chart_freq").setValue("1");
        this.freq_dropdown = [{ key: "Daily", value: 1 }];
        this.graph_freq = 1;
      }
      else {
        console.log("frequency changed ", this.freqForm.get("chart_freq"));
        console.log(val);
        console.log(this.prevFreq);
        if (val != this.prevFreq) {
          console.log("same value already present");
          this.radioChange({ value: val });
        }
        this.prevFreq = val;
      }
    });
    // }


    // getting property uuid from url
    this.route.queryParams.subscribe((params) => {
      this.selectedProperty = { uuid: params["id"] };
      // getting property details
      this.generationService
        .getPropertyDetails({ uuid: params["id"] })
        .subscribe((val) => {
          if (val && val["status"]) {
            console.log("property details");
            console.log(val);
            // this.minDate = new Date(val["payload"].logger_installed_on * 1000);
            this.minDate = new Date('01-01-2019');

            this.leadName =
              val["payload"].lead_details.first_name +
              " " +
              val["payload"].lead_details.last_name;
            this.leadPhoneNumber = val["payload"].lead_details.phone_number_1;
            this.propertyID = val["payload"].property_id;
          }
        });
      this.initTimeParams();
      this.initEchart();
      this.getRayconData();
    });
    // closing the sidemenu on page load
    this.menuToggle.toggleMenu(false);
    this.isBarSelected = false;
    this.isBarSelected2 = false;
    this.markerFilterForm.valueChanges.subscribe((res: any) => {
      if (res) {
        console.log("form value change ", res);
        this.filterMarkerList(res);
      }
    });
  }

  toggleAllSelection(type) {
    console.log("all toggled");
    if (this.allSelectedCheck(type)) {
      const filterData =
        type == "catFilters"
          ? this.catLabels
          : this.equipmentLabels;
      this.filterForm.controls[type].patchValue([...filterData, "select all"]);
    } else {
      this.filterForm.controls[type].patchValue([]);
    }
    this.drawDisaggregationGraph();
  }

  toggleSingleSelection(type) {
    if (this.allSelectedCheck(type)) {
      this.toggleAll(type, false);
    }

    const filterLen =
      type == "catFilters"
        ? this.catLabels.length
        : this.equipmentLabels.length;

    if (this.filterForm.controls[type].value.length == filterLen) {
      this.toggleAll(type, true);
    }
    this.drawDisaggregationGraph();

  }

  allSelectedCheck(type) {
    if (type == "catFilters" && this.allCatSelected.selected) {
      return true;
    } else if (
      type == "equipmentFilters" &&
      this.allEquipmentSelected.selected
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleAll(type, select) {
    if (type == "catFilters") {
      select ? this.allCatSelected.select() : this.allCatSelected.deselect();
    }
    if (type == "equipmentFilters") {
      select
        ? this.allEquipmentSelected.select()
        : this.allEquipmentSelected.deselect();
    }
  }

  filterMarkerList(res?) {
    res = res == null ? this.markerFilterForm.value : res;
    const labelFilter = res.equipment;
    const activityFilter = res.markerActivity;
    this.filteredMarkerList = this.markerList.filter(x => x.name.includes(labelFilter));
    this.filteredMarkerList = this.filteredMarkerList.filter(x => x.name.includes(activityFilter));
    this.filteredMarkerList.sort((a, b) =>
      a.xAxis > b.xAxis ? 1 : b.xAxis > a.xAxis ? -1 : 0
    );
  }

  ngAfterViewInit() {
    this.addEChartClickListener();
  }

  addEChartClickListener() {
    document.documentElement.addEventListener("click", (event) => { this.documentClickhandler(event); });
  }

  documentClickhandler(event) {
    console.log("MARKER: whole document clicked ", event);
    this.removeMarkerOptionsMenu();
    if (event && event.target["className"] != "opt") {
      // update
    } else {
      console.log("MARKER: menu option clicked ", this.currentEChartPointerEvent);
      if (event) {
        if (event.target["innerHTML"].trim().toLowerCase() == "delete") {
          const marker = this.markerList.find(x => x.xAxis == this.currentEChartPointerEvent.data["xAxis"]);
          console.log("deleting marker ", marker, this.currentEChartPointerEvent);
          if (marker) {
            this.clearMarker(marker);
          }
          return;
        } else if (this.disaggregationMarkerLabel != '') {
          let label = this.disaggregationMarkerLabel;
          switch (event.target["innerHTML"].trim().toLowerCase()) {
            case "on":
              label += " - ON";
              break;
            case "off":
              label += " - OFF";
              break;
          }
          let equip = this.labelList.filter(
            (x) => x.label == this.disaggregationMarkerLabel
          );
          this.placeMarker(label, equip[0]["equipment"]);
        } else {
          this.toast.error("NO EQUIPMENT SELECTED");
        }
      }
    }
  }

  destroyEChartListeners() {
    document.documentElement.removeEventListener("click", (event) => { this.documentClickhandler(event); });

  }

  // adding empty columns in bar graph data
  addEmptyColumns(data, length) {
    let empty = "  ";
    for (let i = 0; i < 5 - length; i++) {
      data.push({
        name: empty.repeat(i + 1),
        value: 0,
      });
    }

    return data;
  }

  // different frequency daily, monthly, yearly require different formats of timestamp in API call
  getTimestamp(option) {
    let timestamp = 0;
    if (option == 1) {
      timestamp = Math.floor(this.form.get("day").value.getTime() / 1000);
    } else if (option == 2) {
      console.log("inside month timestamp");
      console.log(this.form.get("month").value);
      console.log("passing values");
      console.log(
        "year: " + this.form.get("month").value.toString().split(" ")[3]
      );
      console.log(
        "month: " +
        this.reverseMonthMap[
        this.form.get("month").value.toString().split(" ")[1]
        ]
      );
      let timeObject = new Date(
        Date.UTC(
          this.form.get("month").value.toString().split(" ")[3],
          this.reverseMonthMap[
          this.form.get("month").value.toString().split(" ")[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
      console.log("final time object");
      console.log(timestamp);
    } else if (option == 3) {
      let timeObject = new Date(
        Date.UTC(
          this.form.get("year").value.toString().split(" ")[3],
          this.reverseMonthMap[
          this.form.get("year").value.toString().split(" ")[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
    }

    return timestamp;
  }

  // refreshing the page whenever freq (daily, monthly) is changed
  radioChange(event) {
    this.chartData = [];
    this.graph_freq = event.value;
    this.changeLabel(this.graph_freq);
    this.pageRefresh();
  }

  // on select bar
  onSelectBar(event) {
    if (this.graph_freq == 1) {
      this.isBarSelected = true;
      this.isBarSelected2 = false;
      this.rayconStartTime = Math.floor(
        this.getSelectedTimestamp(this.getTimestamp(1), event.series)
      ); //1623322822;
      this.rayconEndTime = this.rayconStartTime + this.rayconFrequency * 60 * 60 * 1000;
      this.initEchart();
      this.getRayconData();
    }
    else {
      this.isBarSelected = false;
      this.isBarSelected2 = false;
    }
  }
  // on select bar
  onSelectBar2(event) {
    if (this.graph_freq == 1) {
      this.isBarSelected = false;
      this.isBarSelected2 = true;
      this.rayconStartTime = Math.floor(
        this.getSelectedTimestamp(this.getTimestamp(1), event.series)
      ); //1623322822;
      this.rayconEndTime = this.rayconStartTime + this.rayconFrequency * 60 * 60 * 1000;
      this.initEchart();
      this.getRayconData();
    }
    else {
      this.isBarSelected = false;
      this.isBarSelected2 = false;
    }
  }

  // returns in milliseconds
  getSelectedTimestamp(timestamp, time) {
    time = time.split(":");
    console.log(time);
    let hh = parseInt(time[0]) - 1;
    let mm = parseInt(time[1]);
    let date = new Date(timestamp * 1000);
    date.setHours(hh, mm, 0, 0);
    return date.getTime();
  }

  // changing graph labels based on freq
  changeLabel(option) {
    switch (option) {
      case 1:
        this.xAxisLabel = "Hours";
        this.yAxisLabel = "Power Generated(kW)";
        this.tooltip = "Hour";
        break;
      case 2:
        this.xAxisLabel = "Days";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Day";
        break;
      case 3:
        this.xAxisLabel = "Months";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Month";
        break;
      case 4:
        this.xAxisLabel = "Years";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Year";
        break;
      default:
        this.xAxisLabel = "Days";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Day";
    }
  }

  // converting tooltip time (float to hours, time format)
  getTooltipTime(text) {
    text = text.toString();
    let hour = text.split(".")[0];
    let minutes = NaN;
    if (text.split(".")[1]) {
      minutes = parseInt(
        text.split(".")[1].length == 1
          ? text.split(".")[1] + "0"
          : text.split(".")[1]
      );
      minutes = Math.floor(minutes * 0.6);
    }

    return (
      hour +
      ":" +
      (!isNaN(minutes)
        ? (Math.floor(minutes / 10) == 0 ? "0" : "") + minutes.toString()
        : "00")
    );
  }

  getTooltipTimeMod(text) {
    return text.toString();
  }

  onTabChanged(event) {
    console.log("tab changed");
    console.log(event);
    this.tab_selected = event.index;
    this.pageRefresh();
  }

  pageRefresh(event?) {
    if (this.tab_selected == 0) {
      this.freqForm.get("chart_freq").setValue("1");
      this.freq_dropdown = [{ key: "Daily", value: 1 }];
    } else {
      this.freq_dropdown = this.global_freq;
    }

    if (this.tab_selected != 0) {
      this.destroyEChartListeners();
      this.resetMarkerFilters();
    }

    switch (this.tab_selected) {
      case 0: // raycon data
        this.initTimeParams();
        if (event != "date-change") {
          this.initEchart();
        }
        this.getRayconData();
        break;
      case 1: // disaggregation data
        this.getDisaggregationData();
        break;
    }
  }

  initTimeParams() {
    this.rayconStartTime = Math.floor(
      this.getMidnightTimestamp(this.getTimestamp(1))
    ); //1623322822;
    this.rayconEndTime = this.rayconStartTime + this.rayconFrequency * 60 * 60 * 1000;
  }

  resetMarkerFilters() {
    this.markerFilterForm.patchValue({
      equipment: "",
      markerActivity: ""
    });
  }

  initEchart() {
    setTimeout(() => {
      this.myChart = echarts.init(
        document.getElementById("data-chart") as HTMLCanvasElement
      );
      this.myChart.setOption(this.chartOptions);
      console.log("raycon chart---", this.chartOptions);
      if (!this.isBarSelected2 && !this.isBarSelected) {
        this.myChart.on("click", (params) => {
          console.log("clicked on map ", params);
          this.addMarker(params);
        });
      }
    }, 300);
  }

  getRayconData() {
    this.addMarkerList = [];
    this.deleteMarkerList = [];
    this.markerList = [];
    this.filteredMarkerList = [];

    const queryParams = {
      from_time: this.rayconStartTime,
      to_time: this.rayconEndTime,
      property_uuid: this.selectedProperty.uuid,
    };

    const queryParamsSeconds = {
      from_time: Math.floor(this.rayconStartTime / 1000),
      to_time: Math.floor(this.rayconEndTime / 1000),
      property_uuid: this.selectedProperty.uuid,
    };

    const rayconParams = {
      from_time: Math.floor(this.rayconStartTime / 1000),
      to_time: Math.floor(this.rayconEndTime / 1000),
      property_uuid: this.selectedProperty.uuid,
      parameter: this.power,
    }

    const markerList = this.disaggregationService
      .getMarkerList(queryParams)
      .pipe(catchError((e) => of(e.toString())));
    const globalEquipmentList = this.disaggregationService
      .getGlobalEquipmentList()
      .pipe(catchError((e) => of(e.toString())));
    const rayconData = this.disaggregationService
      .getDisaggregationData(rayconParams)
      .pipe(catchError((e) => of(e.toString())));
    const observableList = this.disaggregationService
      .getObservableList(rayconParams)
      .pipe(catchError((e) => of(e.toString())));


    forkJoin([markerList, globalEquipmentList, rayconData, observableList]).subscribe(
      (data: any[]) => {
        console.log("joined data ->", data);

        let rayconDataset = [];
        this.rayconDeviceId = "";
        this.chartOptions.series[0]["markLine"]["data"] = this.markerList;
        this.chartOptions["dataset"]["source"] = rayconDataset;
        this.myChart.setOption(this.chartOptions);

        if (data[0] && data[0].status && data[1] && data[1].status) {
          console.log("marker List data ", data[0], data[1]);
          this.fetchMarkerList(
            data[0].payload["disaggregation"],
            data[1].payload["equipment_list"]
          );
        } else {
          this.toast.error("SOME ERROR OCCURED");
          return;
        }

        if (data[2] && data[2].status) {
          if (Object.keys(data[2].payload["data"]).length > 0) {
            Object.keys(data[2].payload["data"]).forEach((key) => {
              this.rayconDeviceId = key;
              data[2].payload["data"][key].forEach((obj) => {
                rayconDataset.push({
                  date: obj["utc_timestamp"],
                  value:
                    obj[this.power]
                });
              });
            });
          }
        }

        if (data[3] && data[3].status) {
          console.log("Observable List data -> ", data[3]);
          this.fetchObservableList(
            data[3].payload["data"]
          );
        }
        console.log("RAYCON: final dataset: ", rayconDataset);
        this.chartOptions["dataset"]["source"] = rayconDataset;
        this.updateMarkers();
      }
    );
  }

  // returns in milliseconds
  getMidnightTimestamp(timestamp) {
    let date = new Date(timestamp * 1000);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }

  addMarker(params) {
    this.currentEChartPointerEvent = params;

    setTimeout(() => {
      this.createMarkerOptionsMenu(params);
    }, 300);
  }

  placeMarker(label, equipment) {
    const params = this.currentEChartPointerEvent;
    if (label == "") {
      this.toast.error("PLEASE SELECT AN EQUIPMENT BEFORE MARKING");
      return;
    }
    const marker = {
      name: label,
      xAxis: params.value["date"],
      symbol: "none",
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: true,
          formatter: (params) => {
            console.log("marker label ", params);
            return this.datepipe.transform(
              new Date(params.value),
              "hh:mm:ss a"
            );
          },
          position: "end",
        },
      },
      lineStyle: {
        type: "solid",
        width: 1.5,
        color: this.disaggregationMarkerColor,
      },
      equipment: equipment,
      uuid: "",
    };
    this.markerList.push(marker);
    this.markerList.sort((a, b) =>
      a.xAxis > b.xAxis ? 1 : b.xAxis > a.xAxis ? -1 : 0
    );
    this.filterMarkerList();

    this.addMarkerList.push(this.saveMarkerDao(marker));
    this.updateMarkers();
  }

  getMarkerColor(marker, percent) {
    const color = marker["lineStyle"]["color"];
    return this.shade(color, percent);
  }

  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }

  shade(col, light) {
    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }

    return this.color(r, g, b);
  }

  removeMarkerOptionsMenu() {
    const ele = document.getElementById("marker-optn");
    if (ele) {
      ele.remove();
    }
    this.showTooltip(true);
  }

  createMarkerOptionsMenu(params) {
    console.log("creating sub menu");

    let idx = -1;
    if (params.componentType == "series") {
      if (this.disaggregationMarkerLabel == "") {
        this.toast.error("PLEASE SELECT AN EQUIPMENT BEFORE MARKING");
        return;
      }
      idx = this.markerList.findIndex((x) => x.xAxis == params.value["date"]);
      console.log("series found ", idx);
      if (idx >= 0) return;
      this.addMarkerSubMenu(params, '');
    } else if (params.componentType == "markLine") {
      idx = this.markerList.findIndex((x) => x.xAxis == params.data["xAxis"]);
      console.log("markLine found ", idx);
      if (idx >= 0) { this.addMarkerSubMenu(params, 'del'); }
    }
  }

  addMarkerSubMenu(params, type?) {
    console.log("adding marker menu ", params, type);
    this.showTooltip(false);
    let div = document.createElement("div");
    const chart = document.getElementById("data-chart");
    div.setAttribute("id", "marker-optn");
    div.style.left =
      (chart.offsetLeft + params.event["offsetX"]).toString() + "px";
    div.style.top =
      (chart.offsetTop + params.event["offsetY"] - 150).toString() + "px";
    if (type != "del") {
      div.innerHTML =
        '\
    <style>\
    .custom-menu {\
      width: 80px;\
      border-radius: 3px;\
      background-color: white; \
      box-shadow: 0 0 5px 1px #cacaca;\
   }\
    .custom-menu .opt {\
      padding: 8px;\
      border-radius: inherit;\
      cursor: pointer;\
   }\
    .custom-menu .opt:hover {\
      background-color: #fbe1ca;\
   }\
    \
    </style>\
    <div class="custom-menu">\
    <div class="opt"> On </div>\
    <div class="opt"> Off </div>\
    </div>\
    ';
    } else {
      div.innerHTML =
        '\
    <style>\
    .custom-menu {\
      width: 80px;\
      border-radius: 3px;\
      background-color: white; \
      box-shadow: 0 0 5px 1px #cacaca;\
   }\
    .custom-menu .opt {\
      padding: 8px;\
      border-radius: inherit;\
      cursor: pointer;\
   }\
    .custom-menu .opt:hover {\
      background-color: #fbe1ca;\
   }\
    \
    </style>\
    <div class="custom-menu">\
    <div class="opt"> Delete </div>\
    </div>\
    ';
    }

    div.style.position = "absolute";
    chart.appendChild(div);
  }

  showTooltip(activate) {
    if (this.myChart) {
      this.chartOptions.grid["tooltip"]["show"] = activate;
      this.myChart.setOption(this.chartOptions);
    }
  }

  updateMarkers() {
    this.chartOptions.series[0]["markLine"]["data"] = this.markerList;
    if (this.myChart) {
      console.log("updating chart ", this.chartOptions);
      this.myChart.setOption(this.chartOptions);
    }
  }

  clearMarker(marker) {
    const idx = this.markerList.findIndex((x) => x.xAxis == marker.xAxis);
    const addMarkerIdx = this.addMarkerList.findIndex((x) => x.event_time == marker["xAxis"]);

    if (marker.uuid) {
      console.log("deleting marker api", marker);
      this.deleteMarkerList.push(marker);
    } else if (addMarkerIdx >= 0) {
      console.log("deleting from addmarkerlist");
      this.addMarkerList.splice(addMarkerIdx, 1);
    }
    if (idx != -1) {
      this.markerList.splice(idx, 1);
      this.updateMarkers();
    }
  }

  getMarkerActivity(marker) {
    return marker.name.includes(' - ON') ? 'assets/images/green_circle.png' : 'assets/images/red_circle.png';
  }

  checkNewMarker(marker) {
    return this.addMarkerList.findIndex((x) => x.event_time == marker["xAxis"]) >= 0;
  }

  clearAllMarkers() {
    this.markerList.forEach((marker) => {
      if (marker.uuid) {
        this.deleteMarkerList.push(marker);
      }
    });
    this.markerList = [];
    this.filteredMarkerList = [];
    this.disaggregationMarkerLabel = "";
    this.disaggregationMarkerColor = "";
    this.updateMarkers();
  }

  createLabel() {
    let dialogRef = this.dialog.open(DisaggregationLabelModalComponent, {
      data: this.labelList,
    });

    const subs = dialogRef.componentInstance.label.subscribe((val) => {
      if (val && val.toString().length > 0) {
        this.addLabel(val);
        subs.unsubscribe();
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  addLabel(val) {
    console.log("adding label: ", val);
    if (this.labelList.findIndex((x) => x.label == val.name) < 0) {
      this.labelList.push({
        label: val.name,
        color: this.colors1[this.labelList.length],
        equipment: val,
      });
    }
  }

  removeLabel(label, event) {
    event.stopPropagation();

    console.log(
      "remove label clicked ",
      this.labelList[0].label,
      label["label"]
    );
    const idx = this.labelList.findIndex(
      (x) => x.label.toString().trim() == label["label"].toString().trim()
    );
    console.log("idx found ", idx);
    if (idx >= 0) {
      console.log("removing ....");
      if (this.disaggregationMarkerLabel == this.labelList[idx].label) {
        this.disaggregationMarkerLabel = "";
        this.disaggregationMarkerColor = "";
      }
      this.labelList.splice(idx, 1);
      console.log("removed ", this.labelList);
    }

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "MARKERS DELETE",
        desc: "Do you want to delete all markers belonging to this equipment as well?",
      },
    });

    const sub = dialogRef.componentInstance.option.subscribe((data) => {
      if (data) {
        sub.unsubscribe();
        if (data == "success") {
          let newList = [];
          this.markerList.forEach((marker) => {
            if (
              marker.name.toString().replace(" - ON", "") != label.label &&
              marker.name.toString().replace(" - OFF", "") != label.label
            ) {
              newList.push(marker);
            } else if (marker.uuid) {
              console.log("pushing in delete marker ", marker, this.deleteMarkerList);
              this.deleteMarkerList.push(marker);
            }
          });
          this.markerList = [...newList];
          this.filteredMarkerList = [...newList];
          this.filterMarkerList();
          console.log("removing markers from graph ", this.deleteMarkerList);
          this.updateMarkers();
        }
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      sub.unsubscribe();
    });
  }

  changeSelectedLabel(label, event) {
    console.log("changing selected label ", event, label);

    this.highLightLabel(event, label);

    this.disaggregationMarkerLabel = label.label;
    this.disaggregationMarkerColor = label.color;

    this.myChart.setOption(this.chartOptions);
  }

  highLightLabel(event, label) {
    this.removeAllHighLights();
    let ele = event.target.closest(".marker__wrapper");
    ele.style.border = "2px solid " + label.color.toString();
    ele.style.borderRadius = "5px";
  }

  removeAllHighLights() {
    const labels: any = document.getElementsByClassName(
      "marker-label__wrapper"
    )[0].children;
    [...labels].forEach((label) => {
      label.style.border = "none";
    });
  }

  createGlobalEquipment() {
    let dialogRef = this.dialog.open(GlobalEquipmentModalComponent);
  }


  fetchObservableList(observable) {
    console.log("fetching marker list ", observable);
    let res = [];
    this.labelList = [];
    if (observable.length > 0) {
      observable.forEach((data) => {
        console.log("data observable---", data);
        res.push({
          name: "Algo : " + data.algo_prediction + " - " + "ML : " + data.ml_prediction,
          xAxis: data.observable_event_time,
          symbol: "none",
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: true,
              formatter: (params) => {
                console.log("marker label ", params);
                return params.name;
              },
              position: "end",
            },
          },
          lineStyle: {
            type: "dotted",
            width: 1.8,
            color: "#dd6b47",
          },
          uuid: this.selectedProperty.uuid,
        });

        this.markerList = res;
        this.filteredMarkerList = [...res];
        this.filterMarkerList();
      });
    }
  }

  fetchMarkerList(data, equipmenList) {
    console.log("fetching marker list ", data);
    let res = [];
    this.labelList = [];
    if (data.length > 0) {
      data.forEach((marker) => {
        const equipment = equipmenList.find((x) => x.uuid == marker.device_id);
        this.addLabel(equipment);

        res.push({
          name: equipment.name + " - " + marker.event_type.toUpperCase(),
          xAxis: marker.event_time,
          symbol: "none",
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: true,
              formatter: (params) => {
                console.log("marker label ", params);
                return this.datepipe.transform(
                  new Date(params.value),
                  "hh:mm:ss a"
                );
              },
              position: "end",
            },
          },
          lineStyle: {
            type: "solid",
            width: 1.5,
            color: this.labelList.find((x) => x.label == equipment.name)[
              "color"
            ],
          },
          equipment: equipment,
          uuid: marker.uuid,
        });

        this.markerList = res;
        this.filteredMarkerList = [...res];
        this.filterMarkerList();
      });
    }
  }

  saveMarkerDao(marker) {
    return {
      device_id: marker["equipment"]["uuid"],
      activity_type: "",
      equipment_type: marker["equipment"]["type"],
      event_time: marker["xAxis"], //Math.floor(marker["xAxis"] / 1000),
      event_type: marker["name"].split(" - ")[1],
    };
  }

  saveMarkers() {
    console.log("saving marker list ", this.markerList);

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "SAVE MARKERS",
        desc: "Save all the current markers?",
      },
    });

    const sub = dialogRef.componentInstance.option.subscribe((data) => {
      if (data) {
        sub.unsubscribe();
        if (data == "success") {
          // save marker api call
          let requests = [],
            tags = [];
          if (this.addMarkerList.length > 0) {
            let requestData = [];
            console.log("SAVING: adding markers");
            tags.push("addMarker");
            requests.push(
              this.disaggregationService
                .saveMarkerList({
                  property_uuid: this.selectedProperty.uuid,
                  data: this.addMarkerList,
                })
                .pipe(catchError((e) => of(e.toString())))
            );
          }

          if (this.deleteMarkerList.length > 0) {
            let requestData = [];
            console.log("SAVING: deleting markers");
            this.deleteMarkerList.forEach((marker) => {
              requestData.push(marker.uuid);
            });
            tags.push("deleteMarker");
            requests.push(
              this.disaggregationService
                .deleteMarker({
                  property_uuid: this.selectedProperty.uuid,
                  point_uuids: requestData,
                })
                .pipe(catchError((e) => of(e.toString())))
            );
          }

          if (tags.length > 0) {
            forkJoin(requests).subscribe((data: any[]) => {
              if (data[0] && data[0].status) {
                tags[0] == "addMarker"
                  ? console.log("SAVING: markers added successfully")
                  : console.log("SAVING: marker removed successfully");
                if (tags[0] == "addMarker") {
                  this.addMarkerList = [];
                } else {
                  this.deleteMarkerList = [];
                }
              }
              if (tags.length > 1 && data[1] && data[1].status) {
                console.log("SAVING: marker removed successfully");
                this.deleteMarkerList = [];
              }
              this.toast.success("MARKERS SAVED SUCCESSFULLY");
            });
          } else {
            this.toast.success("MARKER LIST IS ALREADY UPDATED");
          }
        }
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      sub.unsubscribe();
    });
  }

  selectRayconTime() {
    let dialogRef = this.dialog.open(DisaggregationWindowSelectionComponent, {
      data: {
        startTime: this.rayconStartTime,
        frequency: this.rayconFrequency,
      },
    });

    const sub = dialogRef.componentInstance.timeRange.subscribe((val) => {
      sub.unsubscribe();
      if (val && val.length > 0) {
        console.log("value sent back ", val);
        this.rayconStartTime = parseInt(val.split("**")[0]);
        this.rayconFrequency = parseInt(val.split("**")[1]);
        this.rayconEndTime =
          this.rayconStartTime + this.rayconFrequency * 60 * 60 * 1000;
        console.log(
          "current timings ",
          this.rayconStartTime,
          this.rayconEndTime,
          this.rayconFrequency
        );
        this.getRayconData();
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      sub.unsubscribe();
    });
  }

  getDisaggregationData() {
    // TODO: fetch disaggregation data for both graphs/pie charts
    let params = {};
    if (this.graph_freq == 2) {
      params = {
        timestamp: this.getTimestamp(2),
        property_uuid: this.selectedProperty.uuid,
        filter_type: 'monthly'
      }
    }
    else {
      params = {
        timestamp: this.getTimestamp(1),
        property_uuid: this.selectedProperty.uuid,
        filter_type: 'daily'
      }
    }


    this.tuyaService
      .getDisaggregationData(params)
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.status) {
              this.disaggregationData = [
                ...res.payload.data.daily_equipment_type,
              ];
              this.disaggregationData_2 = [
                ...res.payload.data.daily_consumption,
              ];
              this.getPieGraphData(res.payload.data);
              this.disaggregationDetectedEquipments = [];
              this.disaggregationPresentEquipments = [];
              if (Object.keys(res.payload.data).indexOf('equipment_detected') >= 0) {
                this.disaggregationDetectedEquipments = res.payload.data['equipment_detected'];
                this.detectedLast = this.disaggregationDetectedEquipments[this.disaggregationDetectedEquipments.length - 1];
              }
              if (Object.keys(res.payload.data).indexOf('equipment_present') >= 0) {
                this.disaggregationPresentEquipments = res.payload.data['equipment_present'];
                this.presentLast = this.disaggregationPresentEquipments[this.disaggregationPresentEquipments.length - 1];
              }
              console.log(
                "disagregation data in api",
                [
                  ...res.payload.data.daily_equipment_type,
                ],
                [
                  ...res.payload.data.daily_consumption,
                ]
              );
              this.getDisaggregationLabels();
              this.drawDisaggregationGraph();
              this.createTooltipLabelMap();
            } else {
              this.toast.error(res.message);
            }
          }
        },
        (err) => {
          this.toast.error(err);
        }
      );
  }

  createTooltipLabelMap() {
    const colorMap = this.generateColorMap();
    const colorMap2 = this.generateColorMap2();// generates map for equipment_name <-> color
    this.disaggregationLabelMap = {};
    this.disaggregationData.forEach((point: any) => {
      let res = [];
      point['series'].forEach((obj) => {
        obj['color'] = colorMap[obj['name']];
        res.push(obj);
      });
      this.disaggregationLabelMap[point['name']] = JSON.parse(JSON.stringify(res));
    });

    console.log("final map: ", this.disaggregationLabelMap);

    this.disaggregationLabelMap_2 = {};
    this.disaggregationData_2.forEach((point: any) => {
      let res = [];
      point['series'].forEach((obj) => {
        obj['color'] = colorMap2[obj['name']];
        res.push(obj);
      });
      this.disaggregationLabelMap_2[point['name']] = JSON.parse(JSON.stringify(res));
      // this.disaggregationLabelMap_2[point['name']] = point['series'];
    });
  }

  generateColorMap2() {
    let res = {};
    this.catLabels.forEach((label, idx) => {
      res[label] = this.colors1[idx];
    });
    return res;
  }

  generateColorMap() {
    let res = {};
    this.equipmentLabels.forEach((label, idx) => {
      res[label] = this.colors1[idx];
    });
    return res;
  }


  getPieGraphData(data) {
    this.pieCatData = [];
    this.pieEquipmentData = [];
    this.catTotal = 0;
    this.equipmentTotal = 0;
    if (data && Object.keys(data).length > 0) {
      if (
        data.consumption_category &&
        Object.keys(data.consumption_category).length > 0
      ) {
        Object.keys(data.consumption_category).forEach((key) => {
          this.catTotal += data.consumption_category[key];
        });
        Object.keys(data.consumption_category).forEach((key) => {
          this.pieCatData.push({
            name: key,
            value: (data.consumption_category[key] / this.catTotal) * 100,
          });
        });
      } else {
        this.pieCatData = [{ name: "no_data", value: 0 }];
      }

      if (data.equipment_type && Object.keys(data.equipment_type).length > 0) {
        Object.keys(data.equipment_type).forEach((key) => {
          this.equipmentTotal += data.equipment_type[key];
        });
        Object.keys(data.equipment_type).forEach((key) => {
          if (key != 'all') {
            this.pieEquipmentData.push({
              name: key,
              value: (data.equipment_type[key] / this.equipmentTotal) * 100,
            });
          }
        });
      } else {
        this.pieEquipmentData = [{ name: "no_data", value: 0 }];
      }
    }
  }


  drawDisaggregationGraph() {
    this.setDisaggregationAxisLabels();
    if (
      this.disaggregationData &&
      Object.keys(this.disaggregationData).length > 0
    ) {
      this.disaggregationGraphData = [];
      this.disaggregationData.forEach((dp: any) => {
        let localPoint = { name: dp.name, series: [] };
        dp.series.forEach((point: any) => {
          if (
            this.filterForm.controls.equipmentFilters.value.indexOf(
              point.name
            ) >= 0
          ) {
            localPoint.series.push({ name: point.name, value: point.value });
          } else {
            localPoint.series.push({ name: point.name, value: 0 });
          }
        });
        this.disaggregationGraphData.push(localPoint);
      });
    } else {
      this.disaggregationGraphData = [
        { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
      ];
    }
    if (
      this.disaggregationData_2 &&
      Object.keys(this.disaggregationData_2).length > 0
    ) {
      this.disaggregationGraphData_2 = [];
      this.disaggregationData_2.forEach((dp: any) => {
        let localPoint = { name: dp.name, series: [] };
        dp.series.forEach((point: any) => {
          if (
            this.filterForm.controls.catFilters.value.indexOf(point.name) >= 0
          ) {
            localPoint.series.push({ name: point.name, value: point.value });
          } else {
            localPoint.series.push({ name: point.name, value: 0 });
          }
        });
        this.disaggregationGraphData_2.push(localPoint);
      });
    } else {
      this.disaggregationGraphData_2 = [
        { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
      ];
    }

    console.log(
      "final disaggregation data ",
      this.disaggregationGraphData,
      this.disaggregationGraphData_2
    );
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
  }

  downloadRayconData() {
    const rayconParams = {
      from_time: Math.floor(this.rayconStartTime / 1000),
      to_time: Math.floor(this.rayconEndTime / 1000),
      property_uuid: this.selectedProperty.uuid,
      download: 1,
    }
    this.disaggregationService
      .getDisaggregationData(rayconParams).subscribe(
        (res: any) => {
          const timeObject = new Date();
          this.downLoadFile(
            res,
            'application/vnd.mx-excel',
            'raycon-report' + '_' +
            timeObject.getDate() +
            '/' +
            timeObject.getMonth() +
            '/' +
            timeObject.getFullYear() +
            '__' +
            timeObject.getHours() +
            '-' +
            timeObject.getMinutes() +
            '-' +
            timeObject.getSeconds() +
            '.xls'
          );
        }
      );
  }

  getDisaggregationLabels() {
    this.equipmentLabels = [];
    this.catLabels = [];
    if (this.disaggregationData && this.disaggregationData.length > 0) {
      this.disaggregationData.forEach((dp: any) => {
        dp.series.forEach((point: any) => {
          if (point.name && this.equipmentLabels.indexOf(point.name) < 0) {
            this.equipmentLabels.push(point.name);
          }
        });
      });
    }

    if (this.disaggregationData_2 && this.disaggregationData_2.length > 0) {
      this.disaggregationData_2.forEach((dp: any) => {
        dp.series.forEach((point: any) => {
          if (point.name && this.catLabels.indexOf(point.name) < 0) {
            this.catLabels.push(point.name);
          }
        });
      });
    }

    this.filterForm.controls.catFilters.patchValue([...this.catLabels, "select all"]);
    this.filterForm.controls.equipmentFilters.patchValue([
      ...this.equipmentLabels,
      "select all",
    ]);
  }

  // input data -> [{'start_on_local': "2021-01-04 00:00:00", ..} ...]
  // output data -> [{ name: "2021-01-04 00:00:00", series: [{name: 'category-type', value: 123}, ...] }, ...]
  cleanDisaggregationData(data, type, filterList) {
    let cleanedData = [];
    let prevDate = "";
    if (data && data.length > 0) {
      let localSeries = [];
      data.forEach((dp) => {
        if (dp["start_on_local"] != prevDate) {
          if (localSeries.length > 0) {
            cleanedData.push({
              name: this.datepipe.transform(new Date(prevDate), "hh:mm a"),
              series: localSeries,
            });
            localSeries = [];
          }
          prevDate = dp["start_on_local"];
        }
        if (dp[type] && dp[type] != "") {
          if (filterList.indexOf(dp[type]) >= 0) {
            localSeries.push({
              name: dp[type],
              value:
                dp["energy_consumption"] && dp["energy_consumption"] != ""
                  ? dp["energy_consumption"]
                  : 0,
            });
          } else {
            localSeries.push({ name: dp[type], value: 0 });
          }
        }
      });
    } else {
      return [{ name: "Disaggregation", series: [{ name: "", value: 0 }] }];
    }

    cleanedData.forEach((point: any) => {
      let newSeries = [];
      point.series.forEach((dp: any) => {
        let idx = newSeries.map((ele) => ele.name).indexOf(dp.name);
        if (idx >= 0) {
          newSeries[idx]["value"] += dp.value;
        } else {
          newSeries.push({ name: dp.name, value: dp.value });
        }
      });
      point.series = JSON.parse(JSON.stringify(newSeries));
    });

    console.log("returning disaggregation ", cleanedData);
    return cleanedData;
  }

  setDisaggregationAxisLabels() {
    this.disaggregationXLabel = "Time";
    this.disaggregationYLabel = "Power (W)";
  }

  getModelObj(model) {
    // let res = '';
    // Object.keys(model).forEach((key) => {
    //   res += key.toString() + ':' + model[key].toString() + ', '
    // });
    // return res

    // let res = [];
    // this.equipmentLabels.forEach((label, idx) => {
    //   this.disaggregationLabelMap[model['series']].forEach((obj) => {
    //     if(obj['name'] = label) {
    //       obj['color'] = this.colors1[idx];
    //       res.push(JSON.parse(JSON.stringify(obj)));
    //     }
    //   });
    // });

    return this.disaggregationLabelMap[model['series']];
  }
  getModelObj2(model) {
    console.log("model------------>", model);
    return this.disaggregationLabelMap_2[model['series']];
  }

  // function called whenever date is selected
  onChange(event) {
    console.log("date changed");
    console.log(event);
    this.pageRefresh("date-change");
  }

  disableViewChange() {
    setTimeout(() => {
      $(
        "button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button"
      ).css("pointer-events", "none");
    }, 300);
  }

  ngOnDestroy() {
    this.destroyEChartListeners();
  }
}

