import { Directive, HostBinding, Input, Inject } from '@angular/core';
import { ListGroupDirective } from './list-group.directive';

@Directive({
  selector: '[appListItem]',
  exportAs: 'listDirective'
})
export class ListItemDirective {
  @HostBinding('class.stage-highlight')
  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this._open = value;
    if(value) {
      this.listGroup.closeOtherLinks(this);
    }
  }

  protected _open: boolean;
  protected listGroup: ListGroupDirective;

  constructor(@Inject(ListGroupDirective) group: ListGroupDirective) { 
    this.listGroup = group;
  }

  ngOnInit() {
    this.listGroup.addLink(this);
  }

  ngOnDestroy() {
    this.listGroup.removeLink(this);
  }

  toggle() {
    console.log('toggling the color');
    if(!this.open)
   { this.open = !this.open;}
  }

}
