import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ActivityLogService } from 'src/app/common/service/activity-log/activity-log.service';
import { LoaderService } from 'src/app/common/service/loader/loader.service';
import { ActivatedRoute } from '@angular/router';
import { CreateActivityLogComponent } from 'src/app/common/component/create-activity-log/create-activity-log.component';
import { AccountService } from 'src/app/common/service/account/account.service';
import { UserService } from 'src/app/common/service/user-service/user.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';

@Component({
  selector: 'app-manage-activity-log',
  templateUrl: './manage-activity-log.component.html',
  styleUrls: ['./manage-activity-log.component.scss']
})
export class ManageActivityLogComponent implements OnInit {
  radio_selected = 'all';
  modal_header = 'Activity List';
  all_logs = [];
  display_data = [];
  data: any
  propertyData:any;
  hasActivityLogEditAccess = false;
  selectedFilter = {value: 'all'};

  constructor(
    private menuToggle: MenuToggleService,
    private activityLogService: ActivityLogService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private userService:UserService,
    private accountService: AccountService

  ) { }

  ngOnInit() {
    this.menuToggle.toggleMenu(false);

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });
    this.route.queryParams.subscribe(params => {
      let lead_uuid = params['id'];
      if(!this.data) {
        this.data = {
          lead_property_uuid: lead_uuid
        };
      }
      let user = JSON.parse(this.authService.getUser());
      this.hasActivityLogEditAccess = this.userService.getActivityLogsEditAccess(user);
      this.getPropertyData({property_id: lead_uuid});
      this.getDisplayData(this.selectedFilter);
    });
    
  }

  getDisplayData(event) {
    this.selectedFilter = event;
    this.activityLogService.getActivityLogList(this.data).subscribe(val => {
      if(val && val['status']) {
        console.log('log list');
        console.log(val['payload']);
        this.all_logs = val['payload'].logs;
        if(event.value == 'all') {
          this.modal_header = 'Activity List';
          this.display_data = this.all_logs;
        } else if(event.value == 'activity_log') {
          this.modal_header = 'Logs';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on == null
          );
        } else if(event.value == 'reminder') {
          this.modal_header = 'Reminders';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on != null
          );
        } else if(event.value == 'notes') {
          this.modal_header = 'Notes';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on == null && (val.log_type == 'log' || val.log_type == 'note')
          );
        }
      }
    });
  }

  getPropertyData(data) {
    this.accountService.getPropertyDetails(data).subscribe(res => {
      if(res && res['status']) {
        console.log('property details found out');
        console.log(res);
        this.propertyData = res['payload'];
      }
    });
  }

  createActivityLog() {
    let dialogRef = this.dialog.open(CreateActivityLogComponent, {
      data : {
        lead: {
          uuid: this.propertyData.uuid
        },
        uuid: this.propertyData.property_uuid,
        is_profile: true
      }
    });
    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success') {
         this.getDisplayData(this.selectedFilter);
      }
    });
  }
}
