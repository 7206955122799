import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import * as shape from "d3-shape";
import { AqiService } from "src/app/common/service/aqi.service";
import { GenerationService } from "src/app/common/service/generation/generation.service";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";

declare var $: any;

@Component({
    selector: "app-aqi",
    templateUrl: "./aqi.component.html",
    styleUrls: ["./aqi.component.scss"],
})
export class AQIComponent implements OnInit {

    selectedProperty: any;
    graph_freq: number = 1;
    tab_selected = 0;
    status = true;
    totalHomeConsumption = 0;
    energy_flow: any;
    chartData: any = [{ name: "", series: [] }];
    tileData: any;
    leadName = "";
    form: any;
    propertyID = "";
    leadPhoneNumber = "";
    aqiData = [{ name: "AQI", series: [] }];
    aqiGraphData = [{ name: "AQI", series: [] }];
    aqiYAxisLabel = "PM 2.5";
    aqiXAxisLabel = "Time";
    aqiLabels = [];
    aqiSelectedLabels = [];
    aqiFreshLoad = true;
    aqiPrevChecked = true;
    aqiDataType = [
        { key: "PM 2.5", value: "pm25_value" },
        { key: "PM 10", value: "pm10" },
        { key: "PM 1", value: "pm1" },
        // { key: "Temperature", value: "temperature" },
        { key: "Humidity", value: "humidity_value" },
        { key: "CO2", value: "co2_value" },
        { key: "Temperature", value: "temp_current" }
    ];
    aqiFreqForm: any;
    updateLegends = true;
    chartOptions: any;
    filterForm;
    minDate: any = "";
    maxDate: any = "";
    prevFreq = 1;
    freqForm: any;
    monthMap = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December",
    };

    reverseMonthMap = {
        Jan: "0",
        Feb: "1",
        Mar: "2",
        Apr: "3",
        May: "4",
        Jun: "5",
        Jul: "6",
        Aug: "7",
        Sep: "8",
        Oct: "9",
        Nov: "10",
        Dec: "11",
    };

    freq_dropdown = [
        { key: "Daily", value: 1 },
        { key: "Monthly", value: 2 },
        { key: "Yearly", value: 3 },
        { key: "Lifetime", value: 4 },
    ];
    //chart Options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    tooltipDisabled = false;
    xAxisLabel = "Hours";
    tooltip = "Hour";
    showYAxisLabel = true;
    yAxisLabel = "Power Generated(kW)";
    showGridLines = true;
    innerPadding = 0;
    barPadding = 8;
    groupPadding = 16;
    roundDomains = false;
    maxRadius = 10;
    minRadius = 3;
    noBarWhenZero = true;
    // graph_freq color scheme
    colors1 = [
        "#f59240",
        "#5c6bc0",
        "#29b6f6",
        "#ffee58",
        "#ef5350",
        "#868e96",
        "#77EDB4",
        "#44611F",
        "#910F7F",
        "#DFBCDE",
        "#71DCF6",
        "#5B5901",
        "#71510A",
        "#A567B8",
        "#5916E6",
        "#C7517F",
        "#14A2E9",
        "#AD90A7",
        "#03796D",
        "#E60433",
        "#CA4B39",
        "#2F60F9",
        "#B8206F",
        "#A173CE",
        "#1C99EB",
        "#D7F76A",
        "#B22205",
        "#FC8C75",
        "#B866C7",
        "#256E2F",
        "#C8821F",
        "#1B6398",
        "#18A3FB",
        "#141130",
        "#0A687A",
    ];
    colors = ["#5c6bc0"];
    colorScheme = {
        domain: this.colors,
    };
    areaColorScheme = { domain: this.colors1 };
    schemeType = "ordinal";
    // line interpolation
    curve = shape.curveLinear;
    rangeFillOpacity = 0.15;

    // line; area
    autoScale = true;
    timeline = false;


    constructor(
        private generationService: GenerationService,
        private route: ActivatedRoute,
        private menuToggle: MenuToggleService,
        private toast: MessageToastService,
        private fb: FormBuilder,
        private datepipe: DatePipe,
        private aqiService: AqiService,
        private leadService: LeadService,
        private dialog: MatDialog
    ) {
        this.chartOptions = {
            tooltip: {
                trigger: "axis",
            },
            dataset: {
                dimensions: ["date", "value"],
                // source: dummyData['default'],
                source: [
                    {
                        date: 1623322822000,
                        value: 1,
                    },
                    {
                        date: 1623326422000,
                        value: 2,
                    },
                    {
                        date: 1623330022000,
                        value: 3,
                    },
                ],
            },
            grid: {
                height: 300,
                tooltip: {
                    show: true,
                },
            },
            xAxis: {
                type: "time",
                minorTick: {
                    show: true,
                    splitNumber: 5,
                },
                splitLine: {
                    lineStyle: {
                        color: "#999",
                    },
                },
                minorSplitLine: {
                    show: true,
                    lineStyle: {
                        color: "#ddd",
                    },
                },
                axisLabel: {
                    formatter: (val, valstr) => {
                        console.log("formatter up ", val);
                        return this.datepipe.transform(new Date(val), "hh:mm:ss a");
                    },
                },
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    name: "Value",
                    type: "line",
                    connectNulls: true,
                    symbolSize: 5,
                    dimensions: ["date", "value"],
                    sampling: "min",
                    markLine: {
                        animation: false,
                        symbol: "none",
                        // lineStyle: {
                        //   type: "solid",
                        //   width: 1.5,
                        //   color: "#000000",
                        // },
                        data: [],
                    },
                },
            ],
            color: ["#f49340"],
            dataZoom: [
                {
                    type: "inside",
                },
                {
                    type: "slider",
                    top: 400,
                    realtime: false,
                    height: 60,
                    labelFormatter: (val, valstr) => {
                        console.log("formatter down ", val);
                        return val
                            ? this.datepipe.transform(new Date(val), "hh:mm a")
                            : "NaN";
                    },
                },
            ],
        };
    }
    ngOnInit() {

        this.aqiFreqForm = this.fb.group({
            type: ["pm25_value"],
        });

        this.aqiFreqForm.get("type").valueChanges.subscribe((opt) => {
            this.drawAQIGraph();
        });

        // filter for daily, monthly, yearly
        this.form = this.fb.group({
            day: [new Date()],
            month: [new Date()],
            year: [new Date()],
        });

        this.freqForm = this.fb.group({
            chart_freq: ["1", Validators.required],
        });

        this.freqForm.get("chart_freq").valueChanges.subscribe((val: any) => {
            console.log("frequency changed ", this.freqForm.get("chart_freq"));
            console.log(val);
            if (val != this.prevFreq) {
                console.log("same value already present");
                this.radioChange({ value: val });
            }
            this.prevFreq = val;
        });

        // getting property uuid from url
        this.route.queryParams.subscribe((params) => {
            this.selectedProperty = { uuid: params["id"] };
            // getting property details
            this.generationService
                .getPropertyDetails({ uuid: params["id"] })
                .subscribe((val) => {
                    if (val && val["status"]) {
                        console.log("property details");
                        console.log(val);
                        // this.minDate = new Date(val["payload"].logger_installed_on * 1000);
                        this.minDate = new Date('01-01-2019');

                        this.leadName =
                            val["payload"].lead_details.first_name +
                            " " +
                            val["payload"].lead_details.last_name;
                        this.leadPhoneNumber = val["payload"].lead_details.phone_number_1;
                        this.propertyID = val["payload"].property_id;
                    }
                });
            // on initial page load, first populating the tiles
            this.getAQIData();
            // this.getEnergyFlow();
        });
        // closing the sidemenu on page load
        this.menuToggle.toggleMenu(false);
    }

    // function called whenever date is selected
    onChange(event) {
        console.log("date changed");
        console.log(event);
        this.pageRefresh("date-change");
    }

    getTuyaTooltipTime(date) {
        // return date;
        try {
            return date.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
        } catch (e) {
            return new Date(date).toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
        }
    }

    disableViewChange() {
        setTimeout(() => {
            $(
                "button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button"
            ).css("pointer-events", "none");
        }, 300);
    }

    getAQIData() {
        console.log("fetching aqi data");
        this.aqiFreshLoad = true;
        this.aqiService
            .getAQIData({
                timestamp: this.getTimestamp(1),
                property_uuid: this.selectedProperty.uuid,
            })
            .subscribe((res: any) => {
                if (res && res.status) {
                    console.log("aqi data found", res);
                    this.aqiData = res.payload.data;
                    this.drawAQIGraph();
                }
            })
    }

    // refreshing the page whenever freq (daily, monthly, yearly) is changed
    radioChange(event) {
        this.chartData = [];
        this.graph_freq = event.value;
        this.changeLabel(this.graph_freq);
        this.pageRefresh();
    }

    pageRefresh(event?) {
        this.freqForm.get("chart_freq").setValue("1");
        this.freq_dropdown = [{ key: "Daily", value: 1 }];
        this.getAQIData();
    }


    // changing graph labels based on freq
    changeLabel(option) {
        switch (option) {
            case 1:
                this.xAxisLabel = "Hours";
                this.yAxisLabel = "Power Generated(kW)";
                this.tooltip = "Hour";
                break;
            case 2:
                this.xAxisLabel = "Days";
                this.yAxisLabel = "Energy Generated(kWh)";
                this.tooltip = "Day";
                break;
            case 3:
                this.xAxisLabel = "Months";
                this.yAxisLabel = "Energy Generated(kWh)";
                this.tooltip = "Month";
                break;
            case 4:
                this.xAxisLabel = "Years";
                this.yAxisLabel = "Energy Generated(kWh)";
                this.tooltip = "Year";
                break;
            default:
                this.xAxisLabel = "Days";
                this.yAxisLabel = "Energy Generated(kWh)";
                this.tooltip = "Day";
        }
    }

    downloadAQIData() {
        this.aqiService
            .downloadAQIData({
                timestamp: this.getTimestamp(1),
                property_uuid: this.selectedProperty.uuid,
                download: 1,
            })
            .subscribe((res) => {
                const timeObject = new Date();
                this.leadService.downLoadFile(
                    res,
                    "application/vnd.mx-excel",
                    "aqi_data_" +
                    timeObject.getDate() +
                    "/" +
                    timeObject.getMonth() +
                    "/" +
                    timeObject.getFullYear() +
                    "__" +
                    timeObject.getHours() +
                    "-" +
                    timeObject.getMinutes() +
                    "-" +
                    timeObject.getSeconds() +
                    ".xlsx"
                );
            });
    }

    drawAQIGraph() {
        this.setGraphAxisLabels();
        this.updateAQILegends();
        console.log(
            "drawing the AQI graph",
            this.aqiSelectedLabels,
            this.aqiLabels
        );

        const type = this.aqiFreqForm.get("type").value;
        let data = [];

        Object.keys(this.aqiData).forEach((device) => {
            // if device_id is string then use device.trim().toString()
            if (this.aqiSelectedLabels.indexOf(device.trim()) >= 0) {
                data.push({
                    name: device,
                    series: this.cleanAQIData(this.aqiData[device], type),
                });
            } else {
                data.push({ name: device, series: [] });
            }
        });
        this.aqiGraphData = data;

        console.log("final graph data", this.aqiGraphData);
    }

    updateAQILegends() {
        console.log(
            "change got here",
            this.aqiSelectedLabels,
            this.aqiPrevChecked,
            this.aqiFreshLoad
        );

        this.aqiLabels = ["all"];
        this.aqiLabels.push(...Object.keys(this.aqiData));

        if (this.aqiFreshLoad) {
            this.aqiFreshLoad = false;
            this.aqiSelectedLabels = JSON.parse(JSON.stringify(this.aqiLabels));
        } else {
            if (this.aqiSelectedLabels.indexOf("all") >= 0 && !this.aqiPrevChecked) {
                this.aqiSelectedLabels = JSON.parse(JSON.stringify(this.aqiLabels));
            }

            if (this.aqiSelectedLabels.indexOf("all") < 0 && this.aqiPrevChecked) {
                this.aqiSelectedLabels = [];
            }

            if (
                this.aqiSelectedLabels.indexOf("all") >= 0 &&
                this.aqiPrevChecked &&
                this.aqiSelectedLabels.length != this.aqiLabels.length
            ) {
                const idx = this.aqiSelectedLabels.indexOf("all");
                this.aqiSelectedLabels.splice(idx, 1);
            }
        }

        this.aqiPrevChecked = this.aqiSelectedLabels.indexOf("all") >= 0;
    }

    cleanAQIData(data, type) {
        console.log("data got for cleaning", data);
        let cleanedData = [];

        console.log(
            this.datepipe.transform(new Date(this.getTimestamp("1")), "yyyy")
        );

        data.forEach((point: any) => {
            if (
                this.datepipe.transform(new Date(point["timestamp_local"]), "yyyy") ==
                this.datepipe.transform(new Date(this.getTimestamp("1") * 1000), "yyyy")
            ) {
                if (point[type]) {
                    cleanedData.push({
                        name: new Date(point["timestamp_local"]),
                        value: point[type],
                    });
                }
            }
        });

        return cleanedData;
    }

    setGraphAxisLabels() {
        this.xAxisLabel = "Time";
        let type = this.aqiDataType.find(
            (x) => x.value == this.aqiFreqForm.get("type").value
        ).key;
        this.aqiYAxisLabel =
            type + (this.getUnits(type) ? " (" + this.getUnits(type) + ")" : "");
    }

    getUnits(type) {
        switch (type.toLowerCase()) {
            case "power":
                return "W";
            case "current":
                return "A";
            case "voltage":
                return "V";
            case "pm 2.5":
                return "ug/m^3";
            case "pm 10":
                return "ug/m^3";
            case "pm 1":
                return "ug/m^3";
            case "co2":
                return "ug/m^3";
            case "temperature":
                return "C";
            case "humidity":
                return "%";
            case "energy":
                return "kWh";
            default:
                return "kW";
        }
    }

    getGraphTooltipLabel(text) {
        let type = "";
        type = this.aqiDataType.find(
            (x) => x.value == this.aqiFreqForm.get("type").value
        ).key;
        return (
            type +
            ": " +
            text +
            " " +
            (this.getUnits(type) ? this.getUnits(type) : "")
        );
    }

    // different frequency daily, monthly, yearly require different formats of timestamp in API call
    getTimestamp(option) {
        let timestamp = 0;
        if (option == 1) {
            timestamp = Math.floor(this.form.get("day").value.getTime() / 1000);
        } else if (option == 2) {
            console.log("inside month timestamp");
            console.log(this.form.get("month").value);
            console.log("passing values");
            console.log(
                "year: " + this.form.get("month").value.toString().split(" ")[3]
            );
            console.log(
                "month: " +
                this.reverseMonthMap[
                this.form.get("month").value.toString().split(" ")[1]
                ]
            );
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("month").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("month").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
            console.log("final time object");
            console.log(timestamp);
        } else if (option == 3) {
            let timeObject = new Date(
                Date.UTC(
                    this.form.get("year").value.toString().split(" ")[3],
                    this.reverseMonthMap[
                    this.form.get("year").value.toString().split(" ")[1]
                    ]
                )
            );
            timestamp = Math.floor(timeObject.getTime() / 1000);
        }

        return timestamp;
    }

}
