import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DatePipe } from '@angular/common';
import { FinanceService } from 'src/app/common/service/finance/finance.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentModalComponent implements OnInit {


  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  type_options = [];
  types = [];
  // type_options = [{'label':'Troubleshooting', 'value':'troubleshooting'}, {'label':'Maintenance', 'value':'maintenance'}, {'label':'Repair', 'value':'repair'}, {'label':'Relocation', 'value':'relocation'}];
  // types = [
  //           {'type': 'troubleshooting', 'subtype': [{'label':'Plant is not working', 'value':'plant_not_working'}]}, 
  //           {'type': 'maintenance', 'subtype': [{'label':'Panel Cleaning', 'value':'panel_cleaning'}, {'label':'Plant Regular Checkup', 'value':'plant_checkup'}]},
  //           {'type': 'repair', 'subtype': [{'label':'Mounting Structure', 'value':'mounting_structure'},{'label':'Solar Panels', 'value':'solar_panels'},{'label':'Inverters', 'value':'inverters'},{'label':'Meters', 'value':'meters'}, {'label':'Others', 'value':'others'}]}, {'type': 'relocation', 'subtype': [{'label':'Need help with moving plant', 'value':'need_help_moving'}]}];
  subtype_options = [{ 'label': '', 'value': '' }];
  subtype = "";
  status_options = [];
  // status_options = [
  //                   {'label':'Open','value': 'open'}, 
  //                   {'label':'Visitation Scheduled','value': 'visitation_scheduled'},
  //                   {'label':'Visitation Completed', 'value':'visitation_completed'},
  //                   {'label':'Material Order Raised', 'value':'material_order_raised'},
  //                   {'label':'Material Reached Tentative', 'value':'material_reached_tentative'},
  //                   {'label':'Material Reached Confirmed', 'value':'material_reached_confirmed'},
  //                   {'label':'In - Progress', 'value':'inprogress'},
  //                   {'label':'Completed', 'value':'completed'},
  //                   {'label':'Rejected', 'value':'rejected'}
  //                 ];
  payment_options = [{ 'label': 'Cash', 'value': 'Cash' }, { 'label': 'Credit Card', 'value': 'Credit Card' }];
  mode_options = [{ 'label': 'Prepaid', 'value': 'Prepaid' }, { 'label': 'Postpaid', 'value': 'Postpaid' }];
  payment_status_options = [{ 'label': 'Paid', 'value': 'paid' }, { 'label': 'Unpaid', 'value': 'unpaid' }];
  randomList = [{ 'value': 'a', 'label': 'b' }, { 'value': 'ad', 'label': 'sb' }, { 'value': 'add', 'label': 'ssb' }, { 'value': 'asdss', 'label': 'ssdsb' }];

  cars;
  selectedCar: string;
  timeSlot = 28800000;
  time_slots = [
    { 'label': '8:00 - 10:00', 'value': 28800000 }, // milliseconds from 12 midnight
    { 'label': '10:00 - 12:00', 'value': 36000000 },
    { 'label': '12:00 - 14:00', 'value': 43200000 },
    { 'label': '14:00 - 16:00', 'value': 50400000 },
    { 'label': '16:00 - 18:00', 'value': 57600000 }
  ];

  technicianList: any;
  selectedTechnician: string;
  minDate = new Date();
  payment__mode: any;


  uploadingFiles: boolean = false;
  payment_mode = [{ label: 'S2B Pay', value: 's2bpay' }, { label: "Cheque", value: "cheque" },{ 'label': 'Online Transfer', 'value': 'online_transfer' }]; // , { key: "Refund", value: "refund"}
  stage_payment_options = [
    // {'label': 'NEFT', 'value': 'neft'},
    // {'label': 'RTGS', 'value': 'rtgs'},
    // {'label': 'DD', 'value': 'demand_draft'},
    // {'label': 'Net Banking', 'value': 'net_banking'},
    // {'label': 'UPI', 'value': 'upi'},
    // {'label': 'Cash', 'value': 'cash'},
    // {'label': 'Card', 'value': 'card'},
    { 'label': 'S2B Pay', 'value': 's2bpay' },
    { 'label': 'Cheque', 'value': 'cheque' },
    { 'label': 'Online Transfer', 'value': 'online_transfer' },
  ]

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private amcService: AMCService,
    private financeService: FinanceService,
    public dialogRef: MatDialogRef<AddPaymentModalComponent>,
    private datepipe: DatePipe
  ) {
  }

  ngOnInit() {
    console.log('data in init');
    console.log(this.data);

    this.form = this.fb.group({
      lead_property_uuid: [this.data.property_uuid, Validators.required],
      payment_medium: ["", Validators.required],
      payment_amount: ["", Validators.compose([Validators.required,Validators.min(0.01), Validators.max(99999999), Validators.maxLength(8)])],
      remarks: ["", Validators.required],
      approval_status: "pending_finance",
      images: [[]]
    });

  }

  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  validForm() {
    Object.keys(this.form.controls).forEach((key) => {
      // console.log("controls ", this.form.get(key));
      if (this.form.get(key).errors && this.form.get(key).errors['required']) {
        return false;
      }
    });
    return true;
  }



  submit() {

    console.log(this.form.value.payment_medium);
    if(this.form.value.payment_medium == 's2bpay'){
      this.form
      .get("remarks")
      .setValue('NA');
    }
    if (this.form.valid) {
      if (!this.uploadingFiles) {
        let data = JSON.parse(JSON.stringify(this.form.value));
        let imageList = [];
        data.images.forEach(image => {
          imageList.push({
            url: image,
            type: "default",
            fileType: image.substr(image.lastIndexOf(".") + 1),
            date: new Date()
          });
        });
        data.documents = JSON.stringify(imageList);
        this.financeService.addPayment(data).subscribe((val: any) => {
          if (val && val.status) {
            this.toast.success("PAYMENT ADDED SUCCESSFULLY");
            this.status.emit("success");
            this.dialogRef.close();
          }
          else {
            this.toast.error(val.message.toUpperCase());
          }
        });
      } else {
        this.toast.error("PLEASE WAIT, UPLOAD IN PROGRESS...");
      }

    } else {
      console.log(this.form);
      if(this.form.value.payment_amount <= 0){
        this.toast.error("PAYMENT AMOUNT SHOULD BE GREATER THAN 0.");
      }
      else{
      this.toast.error("PLEASE FILL MANDATORY FIELDS");
      }
    }
  }

}
