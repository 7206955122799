import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MatMenuTrigger } from "@angular/material/menu";
import * as shape from "d3-shape";
import { GenerationService } from "src/app/common/service/generation/generation.service";
import { ActivatedRoute } from "@angular/router";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { TuyaService } from "src/app/common/service/tuya.service";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { AqiService } from "src/app/common/service/aqi.service";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { locale } from "moment";
import { MatDialog, MatOption } from "@angular/material";
import * as echarts from "echarts";
import { DisaggregationLabelModalComponent } from "src/app/common/component/raycon-label-modal/disaggregation-label-modal.component";
import { ConfirmationDialogComponent } from "src/app/common/component/confirmation-dialog/confirmation-dialog.component";
import { GlobalEquipmentModalComponent } from "src/app/common/component/global-equipment-modal/global-equipment-modal.component";
import { DisaggregationService } from "src/app/common/service/disaggregation/disaggregation.service";
import { DisaggregationWindowSelectionComponent } from "src/app/common/component/disaggregation-window-selection/disaggregation-window-selection.component";
import { multi } from "src/app/common/component/helpers/chartData";
// import * as dummyData from 'src/app/core/performance-reporting/property-generation/property-generation/min_dis.json';
declare var $: any;

@Component({
  selector: 'app-discom-generation-view',
  templateUrl: './discom-generation-view.component.html',
  styleUrls: ['./discom-generation-view.component.scss']
})
export class DiscomGenerationViewComponent implements OnInit {

  selectedProperty: any;
  graph_freq: number = 1;
  tab_selected = 1;
  status = true;
  totalHomeConsumption = 0;
  energy_flow: any;
  chartData: any = [{ name: "", series: [] }];
  tileData: any;
  leadName = "";
  form: any;
  propertyID = "";
  leadPhoneNumber = "";
  totalEnergyGenerated = "";
  showTiles = true;
  minDate: any = "";
  maxDate: any = "";
  prevFreq = 1;
  freqForm: any;
  monthMap = {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };

  reverseMonthMap = {
    Jan: "0",
    Feb: "1",
    Mar: "2",
    Apr: "3",
    May: "4",
    Jun: "5",
    Jul: "6",
    Aug: "7",
    Sep: "8",
    Oct: "9",
    Nov: "10",
    Dec: "11",
  };

  freq_dropdown = [
    { key: "Daily", value: 1 },
    { key: "Monthly", value: 2 },
    { key: "Yearly", value: 3 },
    { key: "Lifetime", value: 4 },
  ];
  global_freq = [
    { key: "Daily", value: 1 },
    { key: "Monthly", value: 2 },
    { key: "Yearly", value: 3 },
    { key: "Lifetime", value: 4 },
  ];

  analytics_type = [
    { key: "Power", value: "cur_power" },
    { key: "Current", value: "cur_current" },
    { key: "Voltage", value: "cur_voltage" },
    { key: "Energy", value: "cur_energy" },
    { key: "Switch I", value: "switch_1" },
    { key: "Switch II", value: "switch_2" },
  ];

  disaggregationData;
  disaggregationData_2;
  disaggregationGraphData = [
    { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
  ];
  disaggregationGraphData_2 = [
    { name: "Disaggregation", series: [{ name: "dummy", value: 0 }] },
  ];
  pieCatData = [{ name: "no_data", value: 0 }];
  pieEquipmentData = [{ name: "no_data", value: 0 }];
  equipmentLabels = [];
  catLabels = [];
  prevDisaggregationChecked = true;
  disaggregationXLabel = "Time";
  disaggregationYLabel = "Power (W)";
  catTotal = 0;
  equipmentTotal = 0;
  tuyaDpCount = 0;

  consumptionLineGraphData = [];
  generationLineGraphData = [];
  consumptionBarGraphData = [{ name: "", series: [{ name: "", value: 0 }] }];
  consumptionBarGraphStatus = false;
  generationBarGraphData = [];

  // tuyaData and onsetData contains the complete data stored locally
  // tuyaGraphData has the data currently displayed
  analyticsData = {};
  analyticsGraphData = {};
  tuyaYAxisLabel = "Power Generated";
  tuyaXAxisLabel = "Time";
  tuyaLabels = [];
  tuyaSelectedLabels = [];
  freshLoad = true;
  prevChecked = true;
  isCollapsed = false;

  aqiData = [{ name: "AQI", series: [] }];
  aqiGraphData = [{ name: "AQI", series: [] }];
  aqiYAxisLabel = "PM 2.5";
  aqiXAxisLabel = "Time";
  aqiLabels = [];
  aqiSelectedLabels = [];
  aqiFreshLoad = true;
  aqiPrevChecked = true;
  aqiDataType = [
    { key: "PM 2.5", value: "pm_2.5" },
    { key: "PM 10", value: "pm_10" },
    { key: "Temperature", value: "temperature" },
    { key: "Humidity", value: "humidity" },
  ];
  aqiFreqForm: any;
  updateLegends = true;

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  tooltipDisabled = false;
  xAxisLabel = "Hours";
  tooltip = "Hour";
  showYAxisLabel = true;
  yAxisLabel = "Power Generated(kW)";
  showGridLines = true;
  innerPadding = 0;
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  noBarWhenZero = true;

  // graph_freq color scheme
  colors1 = [
    "#f59240",
    "#5c6bc0",
    "#29b6f6",
    "#ffee58",
    "#ef5350",
    "#868e96",
    "#77EDB4",
    "#44611F",
    "#910F7F",
    "#DFBCDE",
    "#71DCF6",
    "#5B5901",
    "#71510A",
    "#A567B8",
    "#5916E6",
    "#C7517F",
    "#14A2E9",
    "#AD90A7",
    "#03796D",
    "#E60433",
    "#CA4B39",
    "#2F60F9",
    "#B8206F",
    "#A173CE",
    "#1C99EB",
    "#D7F76A",
    "#B22205",
    "#FC8C75",
    "#B866C7",
    "#256E2F",
    "#C8821F",
    "#1B6398",
    "#18A3FB",
    "#141130",
    "#0A687A",
  ];
  colors = ["#5c6bc0"];
  colorScheme = {
    domain: this.colors,
  };
  areaColorScheme = { domain: this.colors1 };
  schemeType = "ordinal";

  // line interpolation
  curve = shape.curveLinear;
  rangeFillOpacity = 0.15;

  // line; area
  autoScale = true;
  timeline = false;

  analyticsForm;
  filterForm;

  // raycon chart
  myChart: echarts.ECharts;
  chartOptions;
  markerList = [];
  filteredMarkerList = [];
  labelList = [];
  echartInitialized = false;
  disaggregationMarkerLabel = "";
  disaggregationMarkerColor = "";
  currentEChartPointerEvent;
  addMarkerList = [];
  deleteMarkerList = [];
  rayconStartTime = 0;
  rayconEndTime = 0;
  rayconFrequency = 2;
  rayconDeviceId = "";
  markerFilterForm: any;
  propertyDetails: any;
  activityList = [{key: " - ON", value: "On"}, {key: " - OFF", value: "Off"}, {key: "", value: "All"}];

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;

  @ViewChild("allCatSelected", { static: false })
  private allCatSelected: MatOption;
  @ViewChild("allEquipmentSelected", { static: false })
  private allEquipmentSelected: MatOption;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  constructor(
    private generationService: GenerationService,
    private route: ActivatedRoute,
    private menuToggle: MenuToggleService,
    private toast: MessageToastService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private dialog: MatDialog,
  ) {
    this.chartOptions = {
      tooltip: {
        trigger: "axis",
      },
      dataset: {
        dimensions: ["date", "value"],
        // source: dummyData['default'],
        source: [
          {
            date: 1623322822000,
            value: 1,
          },
          {
            date: 1623326422000,
            value: 2,
          },
          {
            date: 1623330022000,
            value: 3,
          },
        ],
      },
      grid: {
        height: 300,
        tooltip: {
          show: true,
        },
      },
      xAxis: {
        type: "time",
        minorTick: {
          show: true,
          splitNumber: 5,
        },
        splitLine: {
          lineStyle: {
            color: "#999",
          },
        },
        minorSplitLine: {
          show: true,
          lineStyle: {
            color: "#ddd",
          },
        },
        axisLabel: {
          formatter: (val, valstr) => {
            console.log("formatter up ", val);
            return this.datepipe.transform(new Date(val), "hh:mm:ss a");
          },
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Value",
          type: "line",
          connectNulls: true,
          symbolSize: 5,
          dimensions: ["date", "value"],
          sampling: "min",
          markLine: {
            animation: false,
            symbol: "none",
            // lineStyle: {
            //   type: "solid",
            //   width: 1.5,
            //   color: "#000000",
            // },
            data: [],
          },
        },
      ],
      color: ["#f49340"],
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
          top: 400,
          realtime: false,
          height: 60,
          labelFormatter: (val, valstr) => {
            console.log("formatter down ", val);
            return val
              ? this.datepipe.transform(new Date(val), "hh:mm a")
              : "NaN";
          },
        },
      ],
    };
  }

  ngOnInit() {
    this.tileData = [];
    this.maxDate = new Date();

    this.markerFilterForm = this.fb.group({
      equipment: [""],
      markerActivity: [""]
    });

    this.filterForm = this.fb.group({
      tuyaFilters: [["all"]],
      catFilters: [["all"]],
      equipmentFilters: [["all"]],
    });

    this.analyticsForm = this.fb.group({
      type: ["cur_power"],
    });

    this.aqiFreqForm = this.fb.group({
      type: ["pm_2.5"],
    });

    

    // filter for daily, monthly, yearly
    this.form = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()],
    });

    this.freqForm = this.fb.group({
      chart_freq: ["1", Validators.required],
    });

    this.freqForm.get("chart_freq").valueChanges.subscribe((val: any) => {
      console.log("frequency changed ", this.freqForm.get("chart_freq"));
      console.log(val);
      if (val != this.prevFreq) {
        console.log("same value already present");
        this.radioChange({ value: val });
      }
      this.prevFreq = val;
    });

    // getting property uuid from url
    this.route.queryParams.subscribe((params) => {
      this.selectedProperty = { uuid: params["id"] };
      // getting property details
      this.generationService
        .getPropertyDetails({ uuid: params["id"] })
        .subscribe((val) => {
          if (val && val["status"]) {
            console.log("property details");
            console.log(val);
            this.minDate = new Date('01-01-2019');
            this.propertyDetails = val["payload"];
            this.propertyID = val["payload"].property_id;
            console.log("propertyDetails", this.propertyDetails);
          }
        });
        this.getGenerationData(this.graph_freq);
    });
    // closing the sidemenu on page load
    this.menuToggle.toggleMenu(false);

    // resizing the content whenever menu is toggled
    this.menuToggle.menuToggle.subscribe((val) => {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    });

    this.markerFilterForm.valueChanges.subscribe((res: any) => {
      if(res) {
        console.log("form value change ", res);
        this.filterMarkerList(res);
      }
    });
  }

  filterMarkerList(res?) {
    res = res == null ? this.markerFilterForm.value : res;
    const labelFilter = res.equipment;
    const activityFilter = res.markerActivity;
    this.filteredMarkerList = this.markerList.filter(x => x.name.includes(labelFilter));
    this.filteredMarkerList = this.filteredMarkerList.filter(x => x.name.includes(activityFilter));
    this.filteredMarkerList.sort((a, b) =>
      a.xAxis > b.xAxis ? 1 : b.xAxis > a.xAxis ? -1 : 0
    );
  }
  // getting generation data
  getGenerationData(option) {
    console.log("making a call to " + this.selectedProperty.uuid);

    let timestamp = this.getTimestamp(option);

    this.generationService
      .getGenerationData({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp,
      })
      .subscribe((val) => {
        if (val && val["status"]) {
          if (val["payload"].total_energy == -1) {
            this.status = false;
            this.toast.error("NO DATA FOUND");
          } else {
            this.status = true;
            let dataLength = val["payload"].data.length;

            console.log("generation data");
            console.log(val);

            // line and bar graph require data in different formats
            if (option == 1) {
              this.generationLineGraphData = this.correctLineGraphData(
                val["payload"].data
              );
            } else {
              this.generationBarGraphData = this.correctBarGraphData(
                val["payload"].data
              );

              // if there are only 4 (or less) columns then adding extra columns
              if (dataLength < 4) {
                this.generationBarGraphData = this.addEmptyColumns(
                  this.generationBarGraphData,
                  dataLength
                );
              }
            }
          }
          this.totalEnergyGenerated = val["payload"].total_energy;
        } else {
          this.status = false;
          this.toast.error("NO DATA FOUND");
        }
      });
  }

  // adding empty columns in bar graph data
  addEmptyColumns(data, length) {
    let empty = "  ";
    for (let i = 0; i < 5 - length; i++) {
      data.push({
        name: empty.repeat(i + 1),
        value: 0,
      });
    }

    return data;
  }

  // different frequency daily, monthly, yearly require different formats of timestamp in API call
  getTimestamp(option) {
    let timestamp = 0;
    if (option == 1) {
      timestamp = Math.floor(this.form.get("day").value.getTime() / 1000);
    } else if (option == 2) {
      console.log("inside month timestamp");
      console.log(this.form.get("month").value);
      console.log("passing values");
      console.log(
        "year: " + this.form.get("month").value.toString().split(" ")[3]
      );
      console.log(
        "month: " +
          this.reverseMonthMap[
            this.form.get("month").value.toString().split(" ")[1]
          ]
      );
      let timeObject = new Date(
        Date.UTC(
          this.form.get("month").value.toString().split(" ")[3],
          this.reverseMonthMap[
            this.form.get("month").value.toString().split(" ")[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
      console.log("final time object");
      console.log(timestamp);
    } else if (option == 3) {
      let timeObject = new Date(
        Date.UTC(
          this.form.get("year").value.toString().split(" ")[3],
          this.reverseMonthMap[
            this.form.get("year").value.toString().split(" ")[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
    }

    return timestamp;
  }


  // refreshing the page whenever freq (daily, monthly, yearly) is changed
  radioChange(event) {
    this.chartData = [];
    this.graph_freq = event.value;
    this.changeLabel(this.graph_freq);
    if (this.tab_selected != 6) {
      this.pageRefresh();
    }
  }

  // changing graph labels based on freq
  changeLabel(option) {
    switch (option) {
      case 1:
        this.xAxisLabel = "Hours";
        this.yAxisLabel = "Power Generated(kW)";
        this.tooltip = "Hour";
        break;
      case 2:
        this.xAxisLabel = "Days";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Day";
        break;
      case 3:
        this.xAxisLabel = "Months";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Month";
        break;
      case 4:
        this.xAxisLabel = "Years";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Year";
        break;
      default:
        this.xAxisLabel = "Days";
        this.yAxisLabel = "Energy Generated(kWh)";
        this.tooltip = "Day";
    }
  }

  // line graph data correction
  correctLineGraphData(data, series = "Power"): any {
    let new_data = [];
    Object.keys(data).map((key: any) => {
      var point;
      if (data[key]) point = data[key];
      else {
        point = 0;
      }
      key = parseFloat(key);
      new_data.push({ value: point, name: key });
    });
    new_data = [{ name: series, series: new_data }];
    return new_data;
  }

  // bar graph data correction
  correctBarGraphData(data): any {
    let new_data = [];
    Object.keys(data).map((key: any) => {
      var point;
      if (data[key]) point = data[key];
      else point = 0;
      if (this.graph_freq == 1) {
        key = parseFloat(key);
      } else if (this.graph_freq == 3) {
        key = this.monthMap[key];
      }
      new_data.push({ name: key, value: point });
    });

    if (Object.keys(new_data).length < 10) {
      for (let i = 0; i < 17 - Object.keys(new_data).length; i++) {
        let empty = " ";
        new_data.push({
          name: empty.repeat(i + 1),
          value: 0,
        });
      }
    }
    return new_data;
  }

  // converting tooltip time (float to hours, time format)
  getTooltipTime(text) {
    text = text.toString();
    let hour = text.split(".")[0];
    let minutes = NaN;
    if (text.split(".")[1]) {
      minutes = parseInt(
        text.split(".")[1].length == 1
          ? text.split(".")[1] + "0"
          : text.split(".")[1]
      );
      minutes = Math.floor(minutes * 0.6);
    }

    return (
      hour +
      ":" +
      (!isNaN(minutes)
        ? (Math.floor(minutes / 10) == 0 ? "0" : "") + minutes.toString()
        : "00")
    );
  }

  getTooltipTimeMod(text) {
    return text.toString();
  }

  onTabChanged(event) {
    console.log("tab changed");
    // window.dispatchEvent(new Event("resize"));
    console.log(event);
    this.tab_selected = event.index;
    this.pageRefresh();
  }

  // getting energy data
  getNetEnergyData(option) {
    let timestamp = this.getTimestamp(option);
    this.generationService
      .getNetEnergyData({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp,
      })
      .subscribe((val) => {
        if (val && val["status"]) {
          console.log("net energy data found out");
          console.log(val);

          if (val["payload"].total_energy != -1) {
            if (option == 1) {
              this.consumptionLineGraphData = [];
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val["payload"].consumption_data,
                  "Consumption Data"
                )[0]
              );
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val["payload"].generation_data,
                  "Generation Data"
                )[0]
              );
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val["payload"].grid_export,
                  "Grid Export Data"
                )[0]
              );
              this.status = true;
              console.log("final consumption data found out - for daily");
              console.log(this.consumptionLineGraphData);
            } else {
              let dataList = [
                { name: "Consumption", data: val["payload"].consumption_data },
                { name: "Generation", data: val["payload"].generation_data },
                { name: "Grid Export", data: val["payload"].grid_export },
              ];
              this.consumptionBarGraphData =
                this.correctGroupedBarGraphData(dataList);
              this.consumptionBarGraphStatus = true;
              this.status = true;
              console.log(
                "final consumption data found out - for monthly/yearly/lifetime"
              );
              console.log(this.consumptionBarGraphData);
            }
            if (option == 3 || option == 4) {
              this.noBarWhenZero = true;
            } else {
              this.noBarWhenZero = false;
            }
          } else {
            this.status = false;
            this.toast.error("NO DATA FOUND");
          }
          this.totalEnergyGenerated = val["payload"].total_energy;
        } else {
          this.status = false;
          this.toast.error("NO DATA FOUND");
        }
      });
  }

  // getting energy flow data
  getEnergyFlow() {
    let timestamp = this.getTimestamp(this.graph_freq);
    this.generationService
      .getEnergyFlow({
        filter_by: this.graph_freq,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp,
      })
      .subscribe((val) => {
        if (val && val["status"]) {
          if (val["payload"].solar_generation != -1) {
            this.energy_flow = val["payload"] ? val["payload"] : [];
            this.status = true;
            console.log("energy flow data fetched");
            console.log(this.energy_flow);
          } else {
            this.status = false;
            this.toast.error("NO DATA FOUND");
          }
        } else {
          this.status = false;
          this.toast.error("NO DATA FOUND");
        }
      });
  }


  pageRefresh(event?) {
    this.freq_dropdown = this.global_freq;
    switch (this.tab_selected) {
      case 1: // generation tab
        this.getGenerationData(this.graph_freq);
        break;
      case 2: // net energy tab
        window.dispatchEvent(new Event("resize"));
        this.getNetEnergyData(this.graph_freq);
        break;
    }
  }

  // initTimeParams() {
  //   this.rayconStartTime = Math.floor(
  //     this.getMidnightTimestamp(this.getTimestamp(1)) 
  //   ); //1623322822;
  //   this.rayconEndTime = this.rayconStartTime + this.rayconFrequency * 60 * 60 * 1000;
  // }


  // initEchart() {
  //   setTimeout(() => {
  //     this.myChart = echarts.init(
  //       document.getElementById("data-chart") as HTMLCanvasElement
  //     );
  //     this.myChart.setOption(this.chartOptions);
  //   }, 300);
  // }



  // returns in milliseconds
  getMidnightTimestamp(timestamp) {
    let date = new Date(timestamp * 1000);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }

 

  hex2(c) {
    c = Math.round(c);
    if (c < 0) c = 0;
    if (c > 255) c = 255;

    var s = c.toString(16);
    if (s.length < 2) s = "0" + s;

    return s;
  }

  color(r, g, b) {
    return "#" + this.hex2(r) + this.hex2(g) + this.hex2(b);
  }

  shade(col, light) {
    // TODO: Assert that col is good and that -1 < light < 1

    var r = parseInt(col.substr(1, 2), 16);
    var g = parseInt(col.substr(3, 2), 16);
    var b = parseInt(col.substr(5, 2), 16);

    if (light < 0) {
      r = (1 + light) * r;
      g = (1 + light) * g;
      b = (1 + light) * b;
    } else {
      r = (1 - light) * r + light * 255;
      g = (1 - light) * g + light * 255;
      b = (1 - light) * b + light * 255;
    }

    return this.color(r, g, b);
  }


  showTooltip(activate) {
    if (this.myChart) {
      this.chartOptions.grid["tooltip"]["show"] = activate;
      this.myChart.setOption(this.chartOptions);
    }
  }


  createLabel() {
    let dialogRef = this.dialog.open(DisaggregationLabelModalComponent, {
      data: this.labelList,
    });

    const subs = dialogRef.componentInstance.label.subscribe((val) => {
      if (val && val.toString().length > 0) {
        this.addLabel(val);
        subs.unsubscribe();
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  addLabel(val) {
    console.log("adding label: ", val);
    if (this.labelList.findIndex((x) => x.label == val.name) < 0) {
      this.labelList.push({
        label: val.name,
        color: this.colors1[this.labelList.length],
        equipment: val,
      });
    }
  }

  // input data is of the form -> [{ '2021-01-04 00:01:23' : 2465 }, ...]
  // output data is of the form -> [{ name: '2021-01-04 00:01:23', value: 2465}, ...]
  cleanData(data, factor = 1, cummulative=false): any {
    // console.log("data got for cleaning", data);
    let cleanedData = [];
    let sum = 0;
    console.log("cummulative data found: ", cummulative);
    if (data && data != undefined && data.length > 0) {
      data.forEach((point) => {
        Object.keys(point).forEach((key) => {
          cleanedData.push({
            name: parseInt(key) * 1000,
            value: parseFloat(this.getValue(point[key], factor).toFixed(2)) + sum,
          });
          if(cummulative) {sum += parseFloat(this.getValue(point[key], factor).toFixed(2));}
        });
      });
    }
    return cleanedData;
  }

  normalizeTimeseries(data, cummulative=false) {
    const set = new Set();
    let res = [];
    data.forEach((device) => {
      device['series'].length > 0 && device['series'].forEach((dp) => set.add(dp.name));
    });
    const time_series = Array.from(set).sort(this.sortFunc);
    // console.log("timeseries found out ", time_series);
    data.forEach((device) => {
      let value = [];
      let prevValue = 0;
      time_series.forEach((timeData: number) => {
        const idx = device['series'].length > 0 ? device['series'].findIndex(x => x.name == timeData) : -1;
        value.push({name: new Date(timeData), value: idx >= 0 ? device['series'][idx]['value'] : prevValue});
        if(cummulative) { prevValue = idx >= 0 ? device['series'][idx]['value'] : prevValue; }
      });
      res.push({name: device['name'], series: value});
    });
    console.log("final data output ", res);
    return res;
  } 


  sortFunc(x, y) {
    return x - y;
  }

  mapFunc(x) {
    return new Date(x);
  }

  cleanAQIData(data, type) {
    console.log("data got for cleaning", data);
    let cleanedData = [];

    console.log(
      this.datepipe.transform(new Date(this.getTimestamp("1")), "yyyy")
    );

    data.forEach((point: any) => {
      if (
        this.datepipe.transform(new Date(point["timestamp_local"]), "yyyy") ==
        this.datepipe.transform(new Date(this.getTimestamp("1") * 1000), "yyyy")
      ) {
        cleanedData.push({
          name: new Date(point["timestamp_local"]),
          value: point[type],
        });
      }
    });

    return cleanedData;
  }

  getValue(data, factor) {
    if (data && !isNaN(data) && typeof data === "number") {
      return data / factor;
    }

    return 0;
  }

  correctGroupedBarGraphData(dataList) {
    let finalData = [];

    Object.keys(dataList[0].data).forEach((key) => {
      let obj = {};
      obj["name"] = this.graph_freq === 3 ? this.monthMap[key.toString()] : key;
      obj["series"] = [];
      dataList.forEach((data) => {
        obj["series"].push({
          name: data.name,
          value: data.data[key] ? data.data[key] : 0,
        });
      });
      finalData.push(obj);
    });

    return finalData;
  }

  setGraphAxisLabels() {
    this.xAxisLabel = "Time";
    if (this.tab_selected == 6) {
      let type = this.analytics_type.find(
        (x) => x.value == this.analyticsForm.get("type").value
      ).key;
      this.tuyaYAxisLabel = type + " (" + this.getUnits(type) + ")";
    } else if (this.tab_selected == 7) {
      let type = this.aqiDataType.find(
        (x) => x.value == this.aqiFreqForm.get("type").value
      ).key;
      this.aqiYAxisLabel =
        type + (this.getUnits(type) ? " (" + this.getUnits(type) + ")" : "");
    }
  }

  setDisaggregationAxisLabels() {
    this.disaggregationXLabel = "Time";
    this.disaggregationYLabel = "Power (W)";
  }

  getTuyaTooltipTime(date) {
    // return date;
    try {
      return date.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    } catch (e) {
      return new Date(date).toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
  }

  getGraphTooltipLabel(text) {
    let type = "";
    if (this.tab_selected == 6) {
      type = this.analytics_type.find(
        (x) => x.value == this.analyticsForm.get("type").value
      ).key;
    } else {
      type = this.aqiDataType.find(
        (x) => x.value == this.aqiFreqForm.get("type").value
      ).key;
    }
    return (
      type +
      ": " +
      text +
      " " +
      (this.getUnits(type) ? this.getUnits(type) : "")
    );
  }

  getUnits(type) {
    switch (type.toLowerCase()) {
      case "power":
        return "W";
      case "current":
        return "A";
      case "voltage":
        return "V";
      case "pm 2.5":
        return "ug/m^3";
      case "pm 10":
        return "ug/m^3";
      case "temperature":
        return "C";
      case "humidity":
        return "%";
      case "energy":
        return "kWh";
      default:
        return "kW";
    }
  }

 

  allSelectedCheck(type) {
    if (type == "catFilters" && this.allCatSelected.selected) {
      return true;
    } else if (
      type == "equipmentFilters" &&
      this.allEquipmentSelected.selected
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleAll(type, select) {
   
    if (type == "catFilters") {
      select ? this.allCatSelected.select() : this.allCatSelected.deselect();
    }
    if (type == "equipmentFilters") {
      select
        ? this.allEquipmentSelected.select()
        : this.allEquipmentSelected.deselect();
    }
  }

  // function called whenever date is selected
  onChange(event) {
    console.log("date changed");
    console.log(event);
    this.pageRefresh("date-change");
  }

  disableViewChange() {
    setTimeout(() => {
      $(
        "button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button"
      ).css("pointer-events", "none");
    }, 300);
  }

}
