import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class GenerationService {
  baseURL: any;
  generationURL = "v2/report/generation/dashboard";
  benefitsURL = 'v2/report/generation/benefits';
  propertyURL = 'v1/report/generation/dashboard/details';
  consumptionURL = 'v2/report/consumption/dashboard';
  netEnergyURL = 'v2/report/consumption/dashboard/net';
  energyFLowURL = 'v2/report/analytics/flow';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  getGenerationData(data) {
    return this.http.get(this.baseURL + this.generationURL, {
      params: data
    });
  }

  getBenefits(data) {
    return this.http.get(this.baseURL + this.benefitsURL, {
      params: data
    });
  }

  getPropertyDetails(data) {
    return this.http.get(this.baseURL + this.propertyURL, {
      params: data
    });
  }

  getConsumptionData(data) {
    return this.http.get(this.baseURL + this.consumptionURL, {
      params: data
    });
  }

  getEnergyFlow(data) {
    return this.http.get(this.baseURL + this.energyFLowURL, {
      params: data
    });
  }

  getNetEnergyData(data) {
    return this.http.get(this.baseURL + this.netEnergyURL, {
      params: data
    });
  }
}
