import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { AMCService } from "src/app/common/service/amc/amc.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-sr-modal',
  templateUrl: './create-sr-modal.component.html',
  styleUrls: ['./create-sr-modal.component.scss']
})
export class CreateSrModalComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  form: any;
  type_options = [];
  types = [];
  // type_options = [{'label':'Troubleshooting', 'value':'troubleshooting'}, {'label':'Maintenance', 'value':'maintenance'}, {'label':'Repair', 'value':'repair'}, {'label':'Relocation', 'value':'relocation'}];
  // types = [
  //           {'type': 'troubleshooting', 'subtype': [{'label':'Plant is not working', 'value':'plant_not_working'}]}, 
  //           {'type': 'maintenance', 'subtype': [{'label':'Panel Cleaning', 'value':'panel_cleaning'}, {'label':'Plant Regular Checkup', 'value':'plant_checkup'}]},
  //           {'type': 'repair', 'subtype': [{'label':'Mounting Structure', 'value':'mounting_structure'},{'label':'Solar Panels', 'value':'solar_panels'},{'label':'Inverters', 'value':'inverters'},{'label':'Meters', 'value':'meters'}, {'label':'Others', 'value':'others'}]}, {'type': 'relocation', 'subtype': [{'label':'Need help with moving plant', 'value':'need_help_moving'}]}];
  subtype_options = [{ 'label': '', 'value': '' }];
  subtype = "";
  status_options = [];
  // status_options = [
  //                   {'label':'Open','value': 'open'}, 
  //                   {'label':'Visitation Scheduled','value': 'visitation_scheduled'},
  //                   {'label':'Visitation Completed', 'value':'visitation_completed'},
  //                   {'label':'Material Order Raised', 'value':'material_order_raised'},
  //                   {'label':'Material Reached Tentative', 'value':'material_reached_tentative'},
  //                   {'label':'Material Reached Confirmed', 'value':'material_reached_confirmed'},
  //                   {'label':'In - Progress', 'value':'inprogress'},
  //                   {'label':'Completed', 'value':'completed'},
  //                   {'label':'Rejected', 'value':'rejected'}
  //                 ];
  payment_options = [{ 'label': 'Cash', 'value': 'Cash' }, { 'label': 'Credit Card', 'value': 'Credit Card' }];
  mode_options = [{ 'label': 'Prepaid', 'value': 'Prepaid' }, { 'label': 'Postpaid', 'value': 'Postpaid' }];
  payment_status_options = [{ 'label': 'Paid', 'value': 'paid' }, { 'label': 'Unpaid', 'value': 'unpaid' }];
  randomList = [{ 'value': 'a', 'label': 'b' }, { 'value': 'ad', 'label': 'sb' }, { 'value': 'add', 'label': 'ssb' }, { 'value': 'asdss', 'label': 'ssdsb' }];
  holidayCheck: any;
  holidayList = [];
  timeForm: any;
  cars;
  selectedCar: string;
  timeSlot = 28800000;
  time_slots = [
    { 'hour': 8, 'label': '8:00 - 10:00', 'value': 28800000 }, // milliseconds from 12 midnight
    { 'hour': 10, 'label': '10:00 - 12:00', 'value': 36000000 },
    { 'hour': 12, 'label': '12:00 - 14:00', 'value': 43200000 },
    { 'hour': 14, 'label': '14:00 - 16:00', 'value': 50400000 },
    { 'hour': 16, 'label': '16:00 - 18:00', 'value': 57600000 }
  ];

  global_time_slots = [
    { 'hour': 8, 'label': '8:00 - 10:00', 'value': 28800000 }, // milliseconds from 12 midnight
    { 'hour': 10, 'label': '10:00 - 12:00', 'value': 36000000 },
    { 'hour': 12, 'label': '12:00 - 14:00', 'value': 43200000 },
    { 'hour': 14, 'label': '14:00 - 16:00', 'value': 50400000 },
    { 'hour': 16, 'label': '16:00 - 18:00', 'value': 57600000 }
  ];
  technicianList: any;
  selectedTechnician: string;
  minDate = new Date();
  maintainance_type = 'breakdown_maintenance';
  uploadingFiles: boolean = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private amcService: AMCService,
    public dialogRef: MatDialogRef<CreateSrModalComponent>,
    private datepipe: DatePipe
  ) {
  }

  ngOnInit() {
    console.log('data in init');
    console.log(this.data);

    console.log("config data ", JSON.parse(localStorage.getItem("sr-config")));
    const config = JSON.parse(localStorage.getItem("sr-config"));

    this.type_options = [];
    this.types = [];

    this.timeForm = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()],
    });

    Object.keys(config.ticket_type).forEach((key: any) => {
      this.type_options.push({ 'label': config.ticket_type[key]['title'], 'value': key });
      let subtype = [];
      Object.keys(config.ticket_type[key]['subtype']).forEach((sKey: any) => {
        subtype.push({ 'label': config.ticket_type[key]['subtype'][sKey], 'value': sKey });
      });
      this.types.push({ 'type': key, 'subtype': subtype });
    });

    this.status_options = [];
    Object.keys(config.ticket_status).forEach((key: any) => {
      this.status_options.push({ 'label': key, 'value': config.ticket_status[key] });
    });

    this.form = this.fb.group({
      property_uuid: [this.data.property_uuid, Validators.required],
      type: ["Select", Validators.required],
      technician_uuid: ["", Validators.required],
      payment_status: ["unpaid", Validators.required],
      scheduled_on: [new Date((new Date((new Date()).setHours(0, 0, 0, 0))).getTime() + 28800000), Validators.required],
      time_slot: [28800000],
      status: ["open", Validators.required],
      description: [""],
      cost_visitation_gst: [18, Validators.required],
      cost_service_gst: [18, Validators.required],
      images: [[]]
    });

    this.form.get('time_slot').valueChanges.subscribe((res: any) => {
      this.onTimeSlotChange();
    });

    this.clusterService.getClusterTechnicians(this.data.cluster_uuid).subscribe((res: any) => {
      if (res && res.status) {
        console.log('technician list found out');
        console.log(res);
        this.technicianList = [];
        res.payload.data.forEach((ele: any) => {
          this.technicianList.push({ label: ele.name, value: ele.uuid });
        });
      }
    });

    this.form.get("type").valueChanges.subscribe(val => {
      console.log(val);
      this.maintainance_type = val;
      if (val == "breakdown_maintenance") {
        this.breakdownHandle(val);
      }
      else if (val == "regular_maintenance") {
        this.regularHandle(val);
      }
    });

    this.form.get('scheduled_on').valueChanges.subscribe((res: any) => {
      this.holidayCheck = this.datepipe.transform(res, 'EEEE, MMMM d, y');
      this.getHolidays();
      if (this.maintainance_type == 'breakdown_maintenance') {
        console.log(res, "res in breakdown handle");
        let dt = new Date(res.setHours(0, 0, 0, 0));
        console.log(dt);
        if (dt > this.minDate) {
          this.time_slots = this.global_time_slots;
        }
        else {
          let now = new Date().getHours();
          this.time_slots = [];
          for (var i = 0; i < this.global_time_slots.length; i++) {
            if (this.global_time_slots[i].hour >= now)
              this.time_slots.push(this.global_time_slots[i]);
          }
        }
        let time = 28800000;
        if (this.time_slots.length != 0) {
          time = this.time_slots[0].value;
          // this.form.patchValue({ "time_slot": time });
        }
        else if (this.time_slots.length == 0) {
          time = 28800000;
          // this.minDate.setDate(this.minDate.getDate() + 1);
          // if (new Date() < this.minDate) {
          //   this.time_slots = [];
          // }
          // else {
            this.time_slots = this.global_time_slots;
          // }
          // this.form.patchValue({ "time_slot": time });
        }
        console.log(time, "<---- time");
        res = new Date(new Date(res.setHours(0, 0, 0, 0)).getTime() + time);

        console.log(res, "res in breakdown handle");
      }
      else if (this.maintainance_type == 'regular_maintenance') {
        // this.minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        this.minDate = new Date();

        this.time_slots = this.global_time_slots;
        res = new Date(new Date(res.setHours(0, 0, 0, 0)).getTime() + 28800000);

        console.log(res, "res in regular handle");
      }
    });
  }

  updateUploadStatus(event) {
    this.uploadingFiles = event;
  }

  validForm() {
    Object.keys(this.form.controls).forEach((key) => {
      // console.log("controls ", this.form.get(key));
      if (this.form.get(key).errors && this.form.get(key).errors['required']) {
        return false;
      }
    });
    return true;
  }

  onTimeSlotChange() {
    console.log("changing slot ", this.form.get("time_slot").value);
    const midnightObj = new Date(this.form.get("scheduled_on").value).setHours(0, 0, 0, 0);
    const dateObj = new Date((new Date(midnightObj)).getTime() + parseInt(this.form.get("time_slot").value));
    console.log("time slot changed ", midnightObj, new Date(midnightObj), new Date(midnightObj).getTime(), dateObj.getTime());
    this.form.get("scheduled_on").setValue(dateObj);
  }

  breakdownHandle(val) {
    console.log('value found in the type... ');
    console.log(val);
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.minDate = new Date(this.minDate.setHours(0, 0, 0, 0));
    let now = new Date().getHours();
    this.time_slots = [];
    let time = 0;
    for (var i = 0; i < this.global_time_slots.length; i++) {
      if (this.global_time_slots[i].hour >= now)
        this.time_slots.push(this.global_time_slots[i]);
    }
    if (this.time_slots.length == 0) {
      time = 28800000;
      this.minDate.setDate(this.minDate.getDate() + 1);
      // if (new Date() < this.minDate) {
      //   this.time_slots = [];
      // }
      // else {
        this.time_slots = this.global_time_slots;
      // }
    }
    else {
      time = this.time_slots[0].value;
    }
    console.log('time slots->', this.time_slots);
    this.form.get("scheduled_on").setValue(this.minDate);
    this.form.get("time_slot").setValue(time);
  }

  regularHandle(val) {
    console.log('value found in the type... ');
    console.log(val);
    // this.minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    this.minDate = new Date();
    this.form.get("scheduled_on").setValue(this.minDate);
    this.time_slots = this.global_time_slots;
    console.log('time slots->', this.time_slots);
  }

  submit() {
    if (this.validForm()) {
      let data = JSON.parse(JSON.stringify(this.form.value));
      let imageList = [];
      data.images.forEach(image => {
        imageList.push({
          url: image,
          type: "default",
          fileType: image.substr(image.lastIndexOf(".") + 1),
          date: new Date()
        });
      });

      data.images = JSON.stringify(imageList);
      const midnightObj = new Date(this.form.get("scheduled_on").value).setHours(0, 0, 0, 0);
      const dateObj = new Date((new Date(midnightObj)).getTime() + parseInt(this.form.get("time_slot").value));
      data.scheduled_on = Math.floor(dateObj.getTime() / 1000);
      console.log(midnightObj, dateObj, data.scheduled_on, "midnightObj, dateObj, data.scheduled_on");
      // data.scheduled_on = Math.floor(this.form.get("scheduled_on").value.getTime() / 1000);

      this.amcService.createServiceRequest(data).subscribe((val: any) => {
        if (val && val.status) {
          this.toast.success("REQUEST CREATED SUCCESSFULLY");
          this.status.emit("success");
          this.dialogRef.close();
        }
      });
    } else {
      this.toast.error("PLEASE FILL MANDATORY FIELDS");
      console.log(this.form);
    }
  }

  getHolidays() {
    let year = new Date().getFullYear();
    let stDate = year.toString() + ", 1, 1";
    let params = {
      start_date: Math.floor(new Date(stDate).getTime() / 1000),
      end_date: this.getTimestamp(1) + 365 * 24 * 60 * 60
    };
    this.amcService.getHolidayList(params).subscribe(result => {
      let res = result['payload']['data'];
      for (var i = 0; i < res.length; i++) {
        let date = new Date(res[i].date * 1000);
        res[i].date = this.datepipe.transform(date, 'EEEE, MMMM d, y');
      }
      this.holidayList = res;
      for (var j = 0; j < this.holidayList.length; j++) {
        if (this.holidayList[j].date == this.holidayCheck) {
          this.toast.warning("Selected Day is a Holiday (" + this.holidayList[j].name + ")");
        }
      }
    });
  }

  getTimestamp(option) {
    let timestamp = 0;
    if (option == 1) {
      timestamp = Math.floor(this.timeForm.get("day").value.getTime() / 1000);
    }
    return timestamp;
  }
}