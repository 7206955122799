import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth-service/auth.service";
import { MenuService } from "../sidebar/menu.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private menuService: MenuService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(this.auth.getUser());

    console.log('Navigating to');
      console.log(next);
    if (
      this.auth.getAccessToken() != null &&
      user != null &&
      this.hasPermission(user, next)
    ) {
      console.log('Auth Guard: returning true');
      return true;
    } else {
      if (this.auth.getAccessToken() == null) {
        this.router.navigate(["/login"]);
      } else if (!this.hasPermission(user, next)) {
        this.router.navigate(["/error"]);
      }
      console.log('Auth Guard: returning false');
      return false;
    }
  }

  hasPermission(user, route): boolean {
    let components = this.menuService.getAll();
    let access = [];
    let found = false;
    // let user = JSON.parse(data);
    if (user.user_type == "amplus" || user.user_type == "partner") {
      access.push(...user.user_roles);
    } else {
      access.push(user.user_type);
    }

    let item_text = route.url[1].path;
    let group_text = route.url[0].path;
    console.log('next auth role');
    console.log(route.url[0].path);
    console.log('user access');
    console.log(access);

    components.forEach(group => {
      if(group_text == group.state)
      {group.children.forEach(item => {
        if (item.state == item_text) {
          if (access.some(r => item["roles"].indexOf(r) >= 0)) {
            found = true;
          }
        }
      });}
    });
    return found;
  }
}
