
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnInit, Injectable } from '@angular/core';
import { FileUploadLogicService } from '../file-upload-logic.service';
//import { InitiateTransferComponent } from '../../finance/initiate-transfer/initiate-transfer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-file-upload',
  templateUrl: './dynamic-file-upload.component.html',
  styleUrls: ['./dynamic-file-upload.component.scss']
})
@Injectable()
export class DynamicFileUploadComponent implements OnInit {
  paymentRefund ="v1/finance/payment/refund"; 
  cancelledChequeImgURL: string;
   approvalOfBusinessImgURL: string;
   businessHeadUrl  :any;
   cancelledChequeUrl :any;

  imgData: string;
  imgType : any;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  private url = environment.serverURl+this.paymentRefund;

  ngOnInit() {

  }

  
  constructor(
    private http: HttpClient,
//    private initiateTransfer:InitiateTransferComponent,
    private fileUploadLogic :FileUploadLogicService,

    ) { }

    onFileSelected() {
     
      const fileInputElement: HTMLInputElement = this.fileInput.nativeElement;
      const fileToUpload = fileInputElement.files[0];
      this.fileUploadLogic.fileURLInitiateTransfer(fileToUpload);

      }
    

  refundPayment(uuid,amountRefund,accountHolderName,accountNumber,ifscCode,businessHeadUrl,cancelledChequeUrl ) { 

    const data = {
      lead_property_uuid: uuid,
      cancelled_cheque_url: cancelledChequeUrl,
      business_approval_url:businessHeadUrl,
      refund_amount: amountRefund,
      account_holder_name: accountHolderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      approval_status: 'pending_amplus_finance',
    };
  
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        Authorization: localStorage.getItem("token"),
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(this.url, data, httpOptions);
  }
}
