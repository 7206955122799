import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LeadService } from "../../service/lead/lead.service";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: "app-solar-product",
  templateUrl: "./solar-product.component.html",
  styleUrls: ["./solar-product.component.scss"],
})
export class SolarProductComponent implements OnInit {
  form: any;
  product_type = [
    {label: 'Terra', value: 'terra'},
    {label: 'Aerial', value: 'aerial'},
    {label: 'Atrium', value: 'atrium'},
    {label: 'Select a product', value: ''},
  ];
  product_subtype_map = {
    '': [{value: '', label: 'Select sub type'}],
    terra: [
      { value: "flushmount", label: "Flushmount" },
      { value: "0.5m", label: "0.5m" },
      { value: "1.2m", label: "1.2m" },
      { value: "2m", label: "2m" },
      { value: "2.5m", label: "2.5m" },
    ],
    aerial: [
      { value: "aerial_water_proofed", label: "Aerial Water Proofed" },
      { value: "aerial_normal", label: "Aerial Normal" },
    ],
    atrium: [
      { value: "atrium_dark_brown", label: "Atrium Dark Brown" },
      { value: "atrium_light_brown", label: "Atrium Light Brown" },
    ],
  };
  formDisabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SolarProductComponent>,
    private fb: FormBuilder,
    private leadService: LeadService,
    private toast: MessageToastService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      products: this.fb.array([])
    });
    this.getProducts();
  }

  get products() {
    return this.form.controls.products as FormArray;
  }

  getProductType(idx) {
    return this.product_type;
  } 

  getSubType(idx) {
    let type = this.products.controls[idx].get('type').value;
    return this.product_subtype_map[type];
  }

  getProducts() {
    this.leadService.getSolarProductList({'uuid': this.data.uuid}).subscribe((res: any) => {
      if(res && res.status) {
        this.products.controls = [];
        res.payload['data'].forEach((item: any) => {
          this.products.push(this.fb.group({
            type: [item.type],
            sub_type: [item.sub_type],
            capacity: [item.capacity],
            uuid: [item.uuid],
            lead_property_id: [this.data.uuid]
          }));
        });

        if(this.products.controls.length == 0) {
          this.addProductForm();
        }
      }
    });
  }

  deleteProduct(idx) {
    if(this.products.length > 0) {
      if(idx >= this.products.length) { return; }
      let product_uuid = this.products.controls[idx].get('uuid').value;

      if(product_uuid) {
        this.leadService.deleteSolarProduct({'uuid': product_uuid}).subscribe((res: any) => {
          if (res && res.status) {
            this.products.removeAt(idx);
            if(this.products.controls.length == 0) {
              this.addProductForm();
            }
            this.toast.success(res.message.toUpperCase());
          } else {
            this.toast.error(res.message.toUpperCase());
          }
        });
      } else {
        this.products.removeAt(idx);
      }
    }
  }

  addProductForm(idx?, item?) {
    // adding the item at the end by default
    if(idx == null) idx = this.products.length;
    if(idx > this.products.length + 1) return;
    if(item == null) item = this.getEmptyProduct();

    if(idx != 0 && this.products.length > 0 && this.products.controls[idx-1].get('uuid').value == '') { return; }

    const productForm = this.fb.group({
      'uuid': [item.uuid],
      'lead_property_id': [item.lead_property_id, Validators.required],
      'type': [item.type, Validators.required],
      'sub_type': [item.sub_type, Validators.required],
      'capacity': [item.capacity, Validators.required]
    });
    
    console.log('adding product: ', item);
    this.products.insert(idx, productForm);
  }

  submitProduct(idx) {
    let product = this.products.controls[idx].value;
    console.log("submitting product: ", product, this.isValidProduct(product));
    if(!this.isValidProduct(product)) {
      this.toast.error('INVALID PRODUCT');
      return
    }
    
    if(product['uuid']){
      this.leadService.updateSolarProduct(product).subscribe((res: any) => {
        if (res && res.status) {
          this.toast.success("PRODUCT UPDATE SUCCESSFULLY");
        } else {
          this.toast.error(res.message.toUpperCase());
        }
      });
    } else {
      this.leadService.addSolarProduct(product).subscribe((res: any) => {
        if (res && res.status) {
          this.products.controls[idx].get('uuid').setValue(res.payload['data']['uuid']);
          this.toast.success("PRODUCT ADDED SUCCESSFULLY");
        } else {
          this.toast.error(res.message.toUpperCase());
        }
      });
    }
  }

  isValidProduct(product) {
    Object.keys(product).forEach((key) => {
      if(product[key] == null || product[key] == '') {
        return false;
      }
    });
    return true;
  }

  getEmptyProduct(): any {
    return {
      'uuid': '',
      'lead_property_id': this.data.uuid,
      'type': '',
      'sub_type': '',
      'capacity': ''
    };
  }

  submit() {
    console.log("submitting new stage");
  }
}
